/* eslint-disable react/prop-types */
import AgencyLayout from "components/shared/AgencyLayout";
import MultipleSelection from "components/shared/MultiSelection";
import React, { useEffect, useState } from "react";
import ChangePassword from "./designs/ChangePassword";
import Modal from "components/shared/Modal";
import { adminSidebar } from "components/shared/constants/agencyConstants";
import { useDispatch, useSelector } from "react-redux";
import { agencyUpdate } from "store/action/user";
import Image from "components/shared/Image";

const AgencyAdmin = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const user = useSelector((state) => state.users.user);
  const [formData, setFormData] = useState({
    agencyName: "",
    agencyDomain: "",
    password: "",
    agencyTagline: "",
    email: "",
    backgroundColor: "",
    textColor: "",
    file: "",
    avatar: "",
  });

  useEffect(() => {
    setFormData({ ...user, agencyName: user?.username });
  }, [user]);

  const openAddTutorHandler = () => {
    setModal(true);
  };

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        avatar: URL.createObjectURL(file),
        file,
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const form = new FormData();

    for (const key in formData) {
      if (formData[key] !== "") {
        form.append(key, formData[key]);
      }
    }
    dispatch(agencyUpdate(form));
  };

  return (
    <AgencyLayout sidebarData={adminSidebar} currentPage="Agency Settings">
      <Modal
        open={modal}
        close={() => setModal(false)}
        element={<ChangePassword close={() => setModal(false)} />}
      />
      <form
        className="box-shadow mx-auto w-full rounded-lg  bg-white p-8 lg:w-4/5 xl:w-3/5"
        onSubmit={handleSave}
      >
        <div className="flex flex-col gap-3 ">
          <div>
            <div className="pb-1 text-left text-lg font-bold text-black">
              Agency Profile Information
            </div>
          </div>

          <div className="relative flex flex-col items-center">
            {formData.avatar ? (
              <Image
                src={formData.avatar}
                alt="Uploaded"
                className="size-20 rounded-full"
              />
            ) : (
              <div className="size-20 rounded-full bg-gray-200"></div>
            )}
            <input
              type="file"
              onChange={handleImageChange}
              className="absolute inset-0 opacity-0"
            />
            <div className="text-sm font-medium text-odinPrimary">
              Upload Agency Picture
            </div>
          </div>
          <div className="flex flex-col justify-center gap-3">
            <MultipleSelection
              name="Agency Name"
              placeholder="Agency Name"
              value={formData.agencyName}
              onChange={(value) => handleChange("agencyName", value)}
              required
            />
            <MultipleSelection
              name="Agency Tagline"
              placeholder="Tagline"
              type="textArea"
              value={formData.agencyTagline}
              onChange={(value) => handleChange("agencyTagline", value)}
            />

            <div className="grid w-full grid-cols-1 items-end gap-2  md:grid-cols-2">
              <MultipleSelection
                name="Agency Theme"
                type="colorPicker"
                placeholder="Background Color"
                value={formData.backgroundColor}
                onChange={(value) => handleChange("backgroundColor", value)}
                required
              />
              <MultipleSelection
                type="colorPicker"
                placeholder="Text Color"
                value={formData.textColor}
                onChange={(value) => handleChange("textColor", value)}
                required
              />
            </div>

            <div
              className="w-fit cursor-pointer font-bold text-black"
              onClick={openAddTutorHandler}
            >
              Change Password?
            </div>
          </div>

          <div className="flex justify-end ">
            <div className="flex items-center justify-center gap-2 ">
              <div
                className="cursor-pointer rounded-lg border-2 border-black bg-black px-6 py-2 font-semibold text-white"
                onClick={handleSave}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      </form>
    </AgencyLayout>
  );
};

export default AgencyAdmin;
