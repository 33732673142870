/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { verifyOtp } from "store/action/user";
import {
  AGENCY_FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
} from "components/utils/functions";

const Otp = () => {
  const [otp, setOtp] = useState();
  const forgotUser = useSelector((state) => state.users.forgotUser);
  const agency = useSelector((state) => state.supers.agency);
  const { backgroundColor } = agency || {};

  const dispatch = useDispatch();
  const onChange = (value) => {
    setOtp(value);
  };
  const navigate = useNavigate();

  const getForgotPasswordUrl = () => {
    return agency ? AGENCY_FORGOT_PASSWORD_URL : FORGOT_PASSWORD_URL;
  };

  useEffect(() => {
    if (!forgotUser.email) {
      navigate(getForgotPasswordUrl());
    }
  }, [forgotUser.email, agency, navigate]);

  const handleSubmit = () => {
    if (otp?.length === 6) {
      dispatch(verifyOtp({ otp, email: forgotUser?.email }));
    } else {
      toast.error("enter your Otp");
    }
  };
  return (
    <div className="flex h-full flex-col justify-between">
      <div
        className={` text-2xl font-bold ${
          backgroundColor ? "text-secondary" : "text-odinPrimary"
        }`}
      >
        Code Verification
      </div>
      <div className="text-[15px] font-semibold text-secondary">
        Enter OTP (One time password) sent to {forgotUser?.email}{" "}
      </div>

      <div
        className={`text-left text-sm font-bold ${
          backgroundColor ? "text-secondary" : "text-odinPrimary"
        }`}
      >
        <OtpInput
          value={otp}
          onChange={onChange}
          numInputs={6}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            border: "1px solid #B5B5B5",
            margin: "auto",
            borderRadius: "5px",
          }}
          renderInput={(props) => <input {...props} />}
        />
      </div>

      <button
        type="submit"
        onClick={handleSubmit}
        className={` ${
          backgroundColor
            ? "bg-[--bg-primary-color] "
            : "bg-gradient-to-b from-[#0114B0] to-[#010B60] "
        } w-full rounded-lg  py-2 font-semibold text-white`}
      >
        Verify Code
      </button>
    </div>
  );
};

export default Otp;
