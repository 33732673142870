/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  users: [],
  accessToken: "",
  user: null,
  agency: {},
  forgotUser: {},
  students: [],
  tutors: [],
  subscribedTutors: [],
  subscribedStudents: [],
  posts: [],
  classRequest: [],
  files: [],
  notificationList: [],
  events: [],
  payment: [],
  notification: [],
  chatList: [],
  wallet: {},
  checkDomainValid: { data: false },
  paymentMethod: {},
  studentDetails: {},
  agencyDetail: {},
  emailList: [],
  studentActivities: [],
  connectedAccountStatus: null,
};

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsersSlice: (state, action) => {
      return {
        ...state,
        users: action.payload.users,
      };
    },
    getChatListSlice: (state, action) => {
      return {
        ...state,
        chatList: action.payload,
      };
    },

    updateChatListSlice: (state, action) => {
      const newMessage = action.payload;
      const existingIndex = state.chatList.findIndex(
        (chat) => chat.roomId === newMessage.room
      );

      if (existingIndex !== -1) {
        state.chatList[existingIndex].message = newMessage.message;
        state.chatList[existingIndex].createdAt = newMessage.createdAt;
      }

      state.chatList.sort((a, b) => {
        if (!a.createdAt) return 1;
        if (!b.createdAt) return -1;
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },

    setNotificationList: (state, action) => {
      return {
        ...state,
        notificationList: action.payload,
      };
    },
    getUsersFiles: (state, action) => {
      return {
        ...state,
        files: action.payload.files,
      };
    },
    deleteChatListSlice: (state, action) => {
      const { id } = action.payload;
      const data = state.chatList.filter((chat) => chat.roomId !== id);
      return {
        ...state,
        chatList: data,
      };
    },
    deleteFileSlice: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        files: state.files.filter((req) => req.id !== id),
      };
    },
    deletePostSlice: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        posts: state.posts.filter((req) => req.id !== id),
      };
    },
    resetFiles: (state, action) => {
      const existingIndex = state.files.findIndex(
        (file) => file.filename === action.payload.filename
      );

      if (existingIndex !== -1) {
        const updatedFiles = state.files.map((file, index) =>
          index === existingIndex ? { ...file, filename: "" } : file
        );
        return {
          ...state,
          files: updatedFiles,
        };
      }
      // If the file does not exist, return the state as is (do nothing)
      return state;
    },
    updatePost: (state, action) => {
      console.log("action.payload", action.payload);

      const existingIndex = state.posts.findIndex(
        (post) => post.id === action.payload.id
      );

      if (existingIndex !== -1) {
        const updatedFiles = state.posts.map((post, index) =>
          index === existingIndex ? { ...post, ...action.payload } : post
        );
        return {
          ...state,
          posts: updatedFiles,
        };
      }
    },
    addUsersFiles: (state, action) => {
      const { payload } = action;
      const existingFileIndex = state.files.findIndex(
        (file) => file.id === payload.id
      );
      const existingPostIndex = state.posts.findIndex(
        (post) => post.id === payload.id
      );

      if (existingPostIndex !== -1) {
        const updatedPosts = state.posts.map((post, index) =>
          index === existingPostIndex ? { ...post, ...payload } : post
        );
        return {
          ...state,
          posts: updatedPosts,
          files:
            existingFileIndex !== -1
              ? state.files.map((file, index) =>
                  index === existingFileIndex ? { ...file, ...payload } : file
                )
              : [...state.files, payload],
        };
      }

      if (existingFileIndex !== -1) {
        const updatedFiles = state.files.map((file, index) =>
          index === existingFileIndex ? { ...file, ...payload } : file
        );
        return {
          ...state,
          files: updatedFiles,
          posts: updatedFiles,
        };
      } else {
        return {
          ...state,
          files: [...state.files, payload],
          posts: [...state.posts, payload],
        };
      }
    },

    setTokenSlice: (state, action) => {
      return {
        ...state,
        accessToken: action.payload,
      };
    },
    updateClassSlice: (state, action) => {
      const { requestId, subscriptionId } = action.payload;
      return {
        ...state,
        classRequest: state.classRequest.map((req) => {
          if (req.id === requestId) {
            return {
              ...req,
              classes: req.classes.filter((cls) => cls.id !== subscriptionId),
            };
          }
          return req;
        }),
      };
    },
    updateAllClassSlice: (state, action) => {
      const { studentId } = action.payload;
      return {
        ...state,
        classRequest: state.classRequest.filter(
          (req) => req.studentId !== studentId
        ),
      };
    },

    getAgencySlice: (state, action) => {
      return {
        ...state,
        agency: action.payload,
      };
    },
    getAgencyDetailSlice: (state, action) => {
      return {
        ...state,
        agencyDetail: action.payload,
      };
    },
    getPaymentMethodSlice: (state, action) => {
      return {
        ...state,
        paymentMethod: action.payload,
      };
    },
    setForgetUserSlice: (state, action) => {
      return {
        ...state,
        forgotUser: action.payload,
      };
    },
    getStudentsSlice: (state, action) => {
      return {
        ...state,
        students: action.payload,
      };
    },
    getStudentsPaymentSlice: (state, action) => {
      return {
        ...state,
        payment: action.payload,
      };
    },
    getTutorsSlice: (state, action) => {
      return {
        ...state,
        tutors: action.payload,
      };
    },
    getSubscribedTutorsSlice: (state, action) => {
      return {
        ...state,
        subscribedTutors: action.payload,
      };
    },
    getStudentDetailSlice: (state, action) => {
      return {
        ...state,
        studentDetails: action.payload,
      };
    },
    checkDomain: (state, action) => {
      return {
        ...state,
        checkDomainValid: action.payload,
      };
    },
    getStudentActivitiesSlice: (state, action) => {
      return {
        ...state,
        studentActivities: action.payload,
      };
    },
    getSubscribedStudentsSlice: (state, action) => {
      return {
        ...state,
        subscribedStudents: action.payload,
      };
    },
    getClassRequestSlice: (state, action) => {
      return {
        ...state,
        classRequest: action.payload,
      };
    },
    getCalenderSlice: (state, action) => {
      const temp = action?.payload?.map((element) => ({
        ...element,
        start: moment.utc(element.start).toDate(),
        end: moment.utc(element.end).toDate(),
        title: element?.title && element?.title[0],
      }));
      return {
        ...state,
        events: temp,
      };
    },
    loginUserSlice: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    },
    getUserNotificationSlice: (state, action) => {
      return {
        ...state,
        notification: action.payload,
      };
    },
    getUserSlice: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    getPostsSlice: (state, action) => {
      return {
        ...state,
        posts: action.payload,
      };
    },
    walletHistory: (state, action) => {
      return {
        ...state,
        wallet: action.payload,
      };
    },
    logoutSlice: (state, action) => {
      sessionStorage.removeItem("odin-auth-token");
      sessionStorage.removeItem("odin-auth-role");
      return {
        ...state,
        user: {},
        accessToken: "",
        refreshToken: "",
      };
    },
    getConnectedAccountStatusSlice: (state, action) => {
      return {
        ...state,
        connectedAccountStatus: action.payload,
      };
    },
    getMails: (state, action) => {
      return {
        ...state,
        emailList: action.payload,
      };
    },
  },
});

export const {
  getMails,
  updatePost,
  deletePostSlice,
  checkDomain,
  resetFiles,
  logoutSlice,
  getUserSlice,
  getUsersSlice,
  setTokenSlice,
  getPostsSlice,
  walletHistory,
  getUsersFiles,
  addUsersFiles,
  loginUserSlice,
  getTutorsSlice,
  getAgencySlice,
  deleteFileSlice,
  getChatListSlice,
  getStudentsSlice,
  getCalenderSlice,
  updateClassSlice,
  setForgetUserSlice,
  updateAllClassSlice,
  setNotificationList,
  deleteChatListSlice,
  updateChatListSlice,
  getAgencyDetailSlice,
  getClassRequestSlice,
  getPaymentMethodSlice,
  getStudentDetailSlice,
  getStudentsPaymentSlice,
  getUserNotificationSlice,
  getSubscribedTutorsSlice,
  getStudentActivitiesSlice,
  getSubscribedStudentsSlice,
  getConnectedAccountStatusSlice,
} = users.actions;
export default users.reducer;
