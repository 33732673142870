/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Switch } from "antd";
import { BASE_IMG_URL } from "components/shared/constants";
import PlansList from "./designs/PlansList";
import { Link } from "react-router-dom";

const ReSubscribe = ({ agencyFormData, close }) => {
  const [tab, setTab] = useState(0);
  const [subscriptionPlan, setSubscriptionPlan] = useState({
    planType: "",
    paymentMethod: "",
  });

  const handleSubscriptionPlan = (value) => {
    setTab(1);
    setSubscriptionPlan({ ...subscriptionPlan, planType: value });
  };

  return (
    <div className="w-full">
      <div className={`p-6 ${!close && "lg:p-24"}`}>
        <div className="relative flex justify-between ">
          <div className="w-fit border-b-2 border-[#0115B5] text-[30px] font-bold text-odinPrimary">
            {tab === 0
              ? "Choose Subscription Plan"
              : tab === 1
              ? "Select Payment Method"
              : "Billing information"}
          </div>
          <div className="flex-1 border-b-2"></div>
          <div className="absolute right-0 size-fit rounded-lg bg-odinPrimary p-2">
            <img src={`${BASE_IMG_URL}/img/help-icon.svg`} alt="" />
          </div>
        </div>
        {tab === 0 && (
          <div className="mb-10 mt-[62px] flex flex-row justify-center gap-7 text-[16px] font-bold text-odinPrimary">
            <div>Monthly Plan</div>
            <Switch style={{ background: "#0115B5" }} disabled />
            {/* <div>Yearly Plan</div> */}
          </div>
        )}
        <PlansList
          agencyFormData={agencyFormData}
          selectedPlan={handleSubscriptionPlan}
          close={close}
        />
        {!close && (
          <div className="mt-12 flex items-center justify-between">
            <Link to="/" className="flex cursor-pointer items-center gap-2">
              <img src={`${BASE_IMG_URL}/img/back-arrow-button.svg`} alt="" />
              <div className="font-bold text-odinPrimary">Back</div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReSubscribe;
