/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MultipleSelection from "../../../shared/MultiSelection";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/action/user";
import { toObject } from "components/utils/functions";
import { Select } from "antd";

const ProfileSetting = ({ onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const { Option } = Select;
  const [formData, setFormData] = useState({
    subject: [],
    languages: [],
    chargesPerHour: "",
    description: "",
  });

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = () => {
    const form = new FormData();

    for (const key in formData) {
      if (key === "subject" || key === "languages") {
        form.append(key, JSON.stringify(formData[key]));
      } else {
        form.append(key, formData[key]);
      }
    }

    dispatch(updateUser({ form, role: "tutor" }));
    onClose();
  };

  useEffect(() => {
    setFormData({
      subject: toObject(user?.subject),
      languages: toObject(user?.languages),
      chargesPerHour: user?.chargesPerHour,
      description: user?.description,
    });
  }, [user]);

  return (
    <div className="flex flex-col gap-3">
      <div>
        <div className="pb-1 text-left text-lg font-bold text-black">
          Profile Settings
        </div>
        <div className="w-full border-t-[1px] border-ternary"></div>
      </div>

      <MultipleSelection
        name="Subjects"
        type="multiSelect"
        dropDown
        value={formData?.subject}
        getTags={(value) => handleInputChange("subject", value)}
      />
      <div className="text-sm font-medium text-secondary mb-2">Languages</div>
      <Select
        mode="multiple"
        allowClear
        size="large"
        placeholder="Select Languages"
        value={formData.languages}
        onChange={(value) => handleInputChange("languages", value)}
        style={{ width: "100%" }}
      >
        <Option value="English">English</Option>
        <Option value="Spanish">Spanish</Option>
        <Option value="French">French</Option>
        <Option value="Italian">Italian</Option>
        <Option value="Dutch">Dutch</Option>
        <Option value="Thai">Thai</Option>
      </Select>
      <MultipleSelection
        name="Charges Per Hour"
        type="number"
        placeholder="$15"
        dropDown
        value={formData?.chargesPerHour}
        onChange={(value) => handleInputChange("chargesPerHour", value)}
      />
      <MultipleSelection
        name="Profile Description"
        placeholder="Profile Description"
        type="textArea"
        value={formData?.description}
        onChange={(value) => handleInputChange("description", value)}
      />

      <div className="flex justify-end">
        <div className="flex items-center justify-center gap-2">
          <div
            className="cursor-pointer rounded-lg border-2 border-secondary px-6 py-2 font-semibold text-black"
            onClick={onClose}
          >
            Cancel
          </div>
          <button
            className="rounded-lg border-2 border-black bg-black px-6 py-2 font-semibold text-white"
            onClick={submitHandler}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
