import AgencyLayout from "components/shared/AgencyLayout";
import {
  filterTutorOptions,
  studentSidebar,
  studentTutorsColumn,
} from "components/shared/constants/agencyConstants";
import InputTextField from "components/ui/InputTextField";
import TableUi from "components/ui/Table";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getAgencyTutorsAction } from "store/action/user";
import { useDispatch, useSelector } from "react-redux";
import { IoEyeOutline } from "react-icons/io5";
import FilterDropdown from "components/ui/DropDown/FilterDropDown";

const StudentTutors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [tutors, setTutors] = useState([]);
  const data = useSelector((state) => state.users.tutors);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const dropdownData = [
    {
      name: "View Details",
      icon: <IoEyeOutline size={25} />,
    },
  ];
  useEffect(() => {
    if (data) {
      if (searchTerm !== "") {
        const filteredTutors = data.filter((tutor) =>
          tutor?.User?.firstName
            ?.toLowerCase()
            .includes(searchTerm?.toLowerCase())
        );
        setTutors(filteredTutors);
      } else {
        setTutors(data);
      }
    }
  }, [searchTerm, data]);

  useEffect(() => {
    dispatch(getAgencyTutorsAction());
  }, [dispatch]);

  const viewRedirectHandler = (value) => {
    navigate(`/tutor/${value?.id}`);
  };
  const applyFilter = (filter, value) => {
    if (filter && value) {
      const filteredData = data.filter((tutor) => {
        if (filter === "username") {
          const fullName =
            `${tutor.User.firstName} ${tutor.User.lastName}`.toLowerCase();
          return fullName.includes(value.toLowerCase());
        }
        return tutor[filter]
          ?.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setTutors(filteredData);
    } else {
      setTutors(data);
    }
  };

  const resetFilter = () => {
    setTutors(data);
  };

  return (
    <AgencyLayout currentPage="Tutors" sidebarData={studentSidebar}>
      <div className="flex h-full flex-col justify-between gap-6">
        <div className="mb-2 flex items-end">
          <div className="w-fit border-b-2 border-black text-xl font-bold text-black">
            Tutors List
          </div>
          <span className="flex-1 border-b-[1px]"></span>
        </div>
        <div className="flex items-center justify-between  gap-4">
          <div className="flex h-10 w-fit justify-center rounded-xl bg-white p-2 md:w-[17%]">
            <FilterDropdown
              placeholder="Filter"
              onApplyFilter={applyFilter}
              onResetFilter={resetFilter}
              filterOptions={filterTutorOptions}
            />
          </div>

          <div className="hidden h-10 flex-1 items-center gap-2 rounded-xl bg-white md:flex">
            <InputTextField
              icon={<FaSearch />}
              placeholder="Search"
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 rounded-xl bg-white py-2 md:hidden">
          <InputTextField
            icon={<FaSearch />}
            type="submit"
            placeholder="Search"
          />
        </div>
        <div className=" flex-1">
          <div className="h-full rounded-lg bg-white">
            <TableUi
              data={tutors}
              headerBg="#303030"
              headerColor="#fff"
              rowSelection
              columns={studentTutorsColumn}
              pageSize={6}
              loading={isLoading}
              actionOptions={dropdownData}
              viewRedirect={viewRedirectHandler}
            />
          </div>
        </div>
      </div>
    </AgencyLayout>
  );
};

export default StudentTutors;
