/* eslint-disable no-undef */
import API from "./base.service";

export const registerApi = async (payload) => {
  if (payload.role === "student") {
    return API.post("/user/register-student", payload);
  } else {
    return API.post("/user/register-tutor", payload);
  }
};

export const agencyLoginApi = async (payload) =>
  API.post("/agency/login", payload);

export const loginUsersApi = async (payload) =>
  API.post("/user/login", payload);

export const forgetPasswordApi = async (payload) => {
  return API.post("/user/forget-password", payload);
};
export const verifyOTPdApi = async (payload) => {
  return API.post("/user/verify-otp", payload);
};
export const setPasswordApi = async (payload) => {
  return API.post("/user/set-password", payload);
};

export const refreshTokenApi = async (payload) =>
  API.post("/user/refresh-token", payload);

export const agencyRegisterApi = async (payload) =>
  API.post("/agency/register", payload);

export const agencyDataByDomainApi = async (payload) => {
  return API.get(`/agency/${payload}.odin.onl`);
};

export const agencyInviteHandlerApi = async (payload) => {
  if (payload.inviteeRole === "tutor") {
    return API.post("/invitation/invite-tutor", payload);
  } else {
    return API.post("/invitation/invite-student", payload);
  }
};
export const agencyStudentsApi = async () => API.get("/agency/agency/students");

export const agencyTutorsApi = async (payload) => {
  if (payload === "student") {
    return API.get("/student/tutors");
  } else {
    return API.get("/agency/agency/tutors");
  }
};

export const studentSubscribedTutorsApi = async () =>
  API.get("/subscription/subscribed-tutors");

export const getSubscribedStudentsApi = async () =>
  API.get("/subscription/subscribed-students");

export const createTutorTimetableApi = async (payload) =>
  API.post("/tutors/timetable", payload);

export const getCurrentUserApi = async () => API.get("/user/current-user");

export const subscribeToTutorApi = async (payload) =>
  API.post("/subscription/subscribe", payload);

export const getPostsApi = async () => API.get("/post/posts");
export const getTutorPostsApi = async () => API.get("/post/tutorPosts");
export const getStudentPostsApi = async () => API.get("/post/studentPosts");

export const getClassRequestApi = async (payload) => {
  if (payload) {
    return API.get(`/subscription/subscription-requests/${payload}`);
  } else {
    return API.get(`/subscription/get-classes`);
  }
};

export const deleteTutorApi = async (payload) => {
  return API.delete(`/tutors/${payload.id}`);
};
export const deleteStudentApi = async (payload) => {
  return API.delete(`/student/${payload.userId}`);
};
export const updateStudentApi = async (payload) => {
  if (payload.role === "tutor") {
    return API.put("/user/update-tutor", payload.form);
  } else {
    return API.put("/user/update-student", payload);
  }
};
export const updateTutorApi = async (payload) => {
  return API.put(`/tutors/tutor/${payload.userId}`, payload);
};
export const userNotificationApi = async (payload) =>
  API.post(`/user/notifications`, payload);

export const userChangePasswordApi = async (payload) =>
  API.post(`/user/change-password`, payload);

export const userFilesApi = async (payload) => {
  if (payload.role === "tutor") {
    return API.get(`/upload/user/${payload.id}`);
  } else {
    return API.get(`/upload/agency/${payload.id}`);
  }
};
export const getUserChatListApi = async (payload) => {
  if (payload.role === "tutor") {
    return API.get(`/chat/list`);
  } else if (payload.role === "student") {
    return API.get(`/chat/chatList`);
  } else {
    return API.get(`/chat/chatListAgency`);
  }
};
export const deleteUserFileApi = async (payload) =>
  API.delete(`/upload/file/${payload.id}`);

export const connectedAccountStatusApi = async () => API.get(`/stripe/status`);

export const UploadHandout = async (payload) => {
  // return API.post(`/upload/upload`, payload.form);
  const userId = payload.form.get("userId");
  if (userId) {
    return API.post(`/post/tutor`, payload.form);
  } else {
    return API.post(`/post/agency`, payload.form);
  }
};
export const UpdateHandout = async (payload) => {
  const id = payload.form.get("id"); // Extract the ID from FormData
  payload.form.delete("id");
  return API.put(`/upload/upload/${id}`, payload.form);
};

export const getNotificationUser = async (role) => {
  if (role === "student") {
    return API.get(`/notifications/student-notification`);
  } else if (role === "tutor") {
    return API.get(`/notifications/tutor-notification`);
  } else {
    return API.get(`/notifications/agency-notification`);
  }
};

export const getNotificationStudent = async () => {
  return API.get(`/notifications/student-notification`);
};

export const getNotificationTutor = async () => {
  return API.get(`/notifications/tutor-notification`);
};

export const getAgencyWalletHistory = async () => {
  return API.get(`/stripe/history-student-by-agency`);
};
export const cancelSubscription = async () => {
  return API.get(`/stripe/cancel-subscription`);
};
export const agencyUpdate = async (payload) => {
  return API.put(`/agency/update`, payload);
};
export const agencyUpdateDetail = async (payload) => {
  return API.put(`/agency/update_detail`, payload);
};
export const agencyDelete = async (payload) => {
  return API.delete(`/agency/${payload.id}`);
};
export const tutorAcceptClassApi = async (payload) => {
  return API.post(`/subscription/respond`, payload);
};
export const tutorAcceptClassAll = async (payload) => {
  return API.post(`/subscription/accept-all`, payload);
};

export const addPostByAgency = async (payload) => {
  return API.post(`/post/agency`, payload);
};

export const studentHistory = () => {
  return API.get(`/stripe/history-student`);
};
export const agencyPaymentMethod = () => {
  return API.get(`/stripe/payment_method`);
};
export const getPlans = () => {
  return API.get("/stripe/products");
};

export const studentDetail = (payload) => {
  return API.get(`/student/info/${payload.id}`);
};
export const studentActivities = (payload) => {
  return API.get(`/student/subscriptions/${payload.id}`);
};

export const checkDomainValid = (payload) => {
  return API.post(`/agency/checkDomain`, payload);
};
export const createReview = (payload) => {
  return API.post(`/tutors/tutors/reviews`, payload);
};
export const getCountry = () => {
  return API.get(`/agency/country`);
};
export const getState = (payload) => {
  console.log("🚀 ~ getState ~ payload:", payload);
  return API.post(`/agency/state`, payload);
};
export const getCity = (payload) => {
  return API.post(`/agency/city`, payload);
};
export const getAgencyDetails = async (payload) => {
  return API.get(`/agency/detail/${payload.id}`);
};
export const getMailForAgency = async (payload) => {
  return API.post(`/user/agencydropdown`, payload);
};
export const getMailForTutor = async (payload) => {
  if (payload.role === "tutor") {
    console.log("Tutor");
    return API.post(`/user/tutordropdown`, { agencyId: payload.agencyId });
  } else {
    console.log("student");

    return API.post(`/user/studentdropdown`);
  }
};
export const verifySubscription = async (subscriptionId) => {
  try {
    const response = await API.post("/stripe/verify-subscription", {
      subscriptionId: subscriptionId?.subscription,
    });
    return response.data;
  } catch (error) {
    toast.error("Failed to verify subscription.");
    throw error;
  }
};
