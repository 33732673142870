/* eslint-disable react/prop-types */
import React from "react";

const Tabs = ({ data, selected, onSelect }) => {
  return (
    <div className="w-full border-b-2 pb-2">
      <div className="flex flex-wrap gap-2">
        {data?.map((item, idx) => (
          <div
            key={idx}
            onClick={() => onSelect(idx)}
            className={`flex h-fit cursor-pointer gap-2 rounded-t-xl  ${
              selected === idx ? "bg-black" : "bg-white"
            } px-4 md:px-8 py-3 text-lg font-bold ${
              selected === idx ? "text-white" : "bg-black"
            }`}
          >
            <div className="flex items-center justify-center gap-2">
              {selected === idx ? item?.iconWhite : item?.icon}
              <div>{item?.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
