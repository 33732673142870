/* eslint-disable tailwindcss/no-custom-classname */
import AgencyLayout from "components/shared/AgencyLayout";
import React, { useState } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import Tabs from "components/shared/Tabs";
import Modal from "components/shared/Modal";
import TimeTableSettings from "./design/TimeTableSettings";
import ContactSetting from "./design/ContactSetting";
import ProfileSetting from "./design/ProfileSetting";
import EducationSetting from "./design/EducationSetting";
import GeneralSetting from "./design/GeneralSetting";
import NotificationSetting from "./design/NotificationSetting";
import { tutorSidebar } from "components/shared/constants/agencyConstants";

const data = [
  {
    name: "General Settings",
    icon: <img src={`${BASE_IMG_URL}/img/person-black.svg`} alt="" />,
    iconWhite: <img src={`${BASE_IMG_URL}/img/person.svg`} alt="" />,
  },
];

const TutorSetting = () => {
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [settingOptions, setSettingoptions] = useState();

  const openAddTutorHandler = (value) => {
    setModal(true);
    setSettingoptions(value);
  };

  const onSelect = (index) => {
    setTab(index);
  };

  const onClose = () => {
    setModal(false);
  };

  const rendered = (e) => {
    switch (e) {
      case 0:
        return <ContactSetting onClose={onClose} />;
      case 1:
        return <ProfileSetting onClose={onClose} />;
      case 2:
        return <EducationSetting onClose={onClose} />;
      case 3:
        return <TimeTableSettings onClose={onClose} />;
      default:
        return <ContactSetting />;
    }
  };

  const TabsRendered = (e) => {
    switch (e) {
      case 0:
        return <GeneralSetting openAddTutorHandler={openAddTutorHandler} />;
      case 1:
        return <NotificationSetting />;
      // case 2:
      //   return <Payment />;
      // case 2:
      //   return <Feedback />;
      default:
        return <GeneralSetting openAddTutorHandler={openAddTutorHandler} />;
    }
  };

  return (
    <AgencyLayout currentPage="Settings" sidebarData={tutorSidebar}>
      <Modal
        footer={false}
        open={modal}
        close={(e) => setModal(e)}
        element={rendered(settingOptions)}
        width={1000}
      />

      <div className="flex h-full flex-col gap-6">
        <div>
          <Tabs data={data} selected={tab} onSelect={onSelect} />
        </div>
        {TabsRendered(tab)}
      </div>
    </AgencyLayout>
  );
};

export default TutorSetting;
