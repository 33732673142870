/* eslint-disable react/prop-types */
import { BASE_IMG_URL } from 'components/shared/constants'
import React from 'react'

export const settingGeneralOptions = [
  {
    name: "Contact Settings",
    desc: "To change subscription plan. Click here to",
  },
  {
    name: "Profile Settings",
    desc: "To change subscription plan. Click here to",
  },
  {
    name: "Education Settings",
    desc: "To change subscription plan. Click here to",
  },
  {
    name: "Timetable Settings",
    desc: "To change payment methods. Click here to",
  }
];

const GeneralSetting = ({openAddTutorHandler}) => {
  return (
    <div className="flex flex-1 flex-col items-center  gap-4">
          {settingGeneralOptions?.map((item, idx) => (
            <div
              key={idx}
              className="h-fit w-full cursor-pointer rounded-lg bg-white p-6"
              onClick={() => openAddTutorHandler(idx)}
            >
              <div className="text-xl font-semibold text-black">
                {item?.name}
              </div>
              <div className="flex justify-end">
                {" "}
                <img src={`${BASE_IMG_URL}/img/book.svg`} alt="" />
              </div>
              <div className="text-sm text-secondary">
                {item?.desc}{" "}
                <span className="font-bold text-black">Add Changes</span>{" "}
              </div>
            </div>
          ))}
        </div>
  )
}

export default GeneralSetting