/* eslint-disable react/prop-types */
import React from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import { Link } from "react-router-dom";

const Header = ({ logo, noLogo, rightArea, className, role, path }) => {
  return (
    <div
      className={`flex h-[100px] flex-wrap items-center justify-between px-6   ${className} `}
    >
      <Link
        to={
          role === "student"
            ? "/student/dashboard"
            : role === "tutor"
            ? "/tutor/dashboard"
            : path
            ? "/"
            : "/dashboard"
        }
        className="flex items-center justify-center "
      >
        {!noLogo ? (
          logo ? (
            <div className="lg:hidden">{logo}</div>
          ) : (
            <img src={`${BASE_IMG_URL}/img/logo-white.svg`} alt="" />
          )
        ) : (
          <></>
        )}
      </Link>
      {rightArea}
    </div>
  );
};

export default Header;
