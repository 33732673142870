/* eslint-disable react/prop-types */
import React from "react";

const Image = ({ src, ...props }) => {
  const handleError = (e) => {
    e.target.src = "/img/person-black.svg"; // Path to the default image
  };
  return (
    <img
      src={src || "/img/person-black.svg"}
      onError={handleError}
      {...props}
    />
  );
};

export default Image;
