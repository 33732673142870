import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/agency/agency-form");
  };
  return (
    <div className="w-full bg-white pt-16">
      <div className="m-auto flex w-full max-w-[1200px] flex-col px-4">
        <div className="relative flex grow flex-col items-center justify-center overflow-hidden rounded-2xl bg-[#0114b0] p-8 text-center text-white">
          <motion.div
            style={{
              backgroundImage: "url('/img/svgviewer-output.svg')",
              backgroundPosition: "center",
            }}
            animate={{
              backgroundPosition: [
                "0% 0%",
                "100% 0%",
                "100% 100%",
                "0% 100%",
                "50% 50%",
              ],
            }}
            transition={{
              duration: 15,
              ease: "easeInOut",
              repeat: false,
            }}
            className="absolute inset-0 z-0"
          ></motion.div>
          <div className="relative z-10">
            <h1 className="mb-4 max-w-xl text-4xl font-bold md:text-5xl">
              Ready to Transform Your Tutoring Business?
            </h1>
            <p className="mb-6 text-[15px]">
              Sign up for Odin today and experience the future of tutoring
              management. Get started now!
            </p>
            <motion.button
              className="rounded-xl border border-white bg-[#0114b0] px-6 py-3 text-white hover:bg-white hover:text-[#0114b0]"
              whileHover={{ scale: 1, y: -5 }}
              onClick={handleNavigate}
            >
              GET STARTED
            </motion.button>
          </div>
        </div>

        <footer className="bg-white pb-6 text-gray-700">
          <div className="mx-auto max-w-6xl">
            <div className="mt-8 flex flex-col items-center justify-between border-t pt-6 sm:flex-row">
              <p className="text-gray-500">
                © ODIN 2024 Techvention. All rights reserved.
              </p>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-500 hover:underline">
                  Privacy Policy
                </a>
                <a href="#" className="text-gray-500 hover:underline">
                  Terms & Conditions
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
