/* eslint-disable react/prop-types */
/* eslint-disable tailwindcss/no-custom-classname */
import { Divider } from "antd";
import InputTextField from "components/ui/InputTextField";
import React from "react";

const AddTutor = ({ name }) => {
  return (
    <div>
      <div className="text-xl font-bold">
        Add {name || "Tutor"} to Odin Software
      </div>
      <Divider className="my-2 bg-secondary" />
      <div className="py-2 font-medium">Email</div>
      <InputTextField
        className="mb-3 border-2 py-3"
        type="text"
        placeholder="e.g., Abc@company.com"
      />
      <div className="flex items-center justify-center gap-4 p-4 font-bold">
        <div className="border-1px-solid flex h-10 w-24 items-center justify-center rounded-lg  border-2  bg-white text-center text-black">
          Cancel
        </div>
        <div className="flex h-10 w-24 items-center justify-center rounded-lg border-2 border-[black] bg-black text-center text-white">
          Add
        </div>
      </div>
    </div>
  );
};

export default AddTutor;
