import { Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const EventPopup = ({ updateHandler, zoom }) => {
  return (
    <div className=" ">
      <div className="text-xl font-bold">Action</div>
      <Divider className="my-2 bg-gray-100" />

      <div className="flex size-full w-full items-center gap-3 p-4 pt-6 text-center">
        <div
          className="flex-1 cursor-pointer rounded-full bg-primary py-2 text-primary"
          onClick={updateHandler}
        >
          Reschedule Class{" "}
        </div>
        <Link
          to={zoom}
          target="_blank"
          className="flex-1 rounded-full bg-primary py-2 text-primary hover:text-white"
        >
          Zoom Link
        </Link>
      </div>
    </div>
  );
};
EventPopup.propTypes = {
  updateHandler: PropTypes.func.isRequired,
  zoom: PropTypes.any,
};
export default EventPopup;
