/* eslint-disable react/prop-types */
import { BASE_IMG_URL } from "components/shared/constants";
import React from "react";

const TimeSlots = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="mt-10 pb-1 text-left text-3xl font-bold text-black">
        Contact Settings
      </div>
      <div className="mt-10 pb-1 text-left text-lg font-bold text-black">
        Upcoming Classes
      </div>
      <div className="flex flex-col gap-3">
        {[
          { border: "#5BA2BD", background: "#60D2FF80" },
          { border: "#FFAC33", background: "#FFAC3380" },
          { border: "#FF0000", background: "#FF212180" },
        ].map((item, idx) => (
          <div
            key={idx}
            className="flex justify-between p-4"
            style={{
              borderLeft: `3px solid ${item?.border}`,
              background: item?.background,
            }}
          >
            <div className="flex flex-col gap-4 text-secondary">
              <div className="text-xl">Math Class Details</div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam.
              </div>
            </div>
            <img src={`${BASE_IMG_URL}/img/book.svg`} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeSlots;
