import React, { useRef, useState } from "react";
import { Progress } from "antd";
import { BASE_IMG_URL } from "components/shared/constants";
import PropTypes from "prop-types";

const UploadFileArea = ({ file, setFile, fileName, setFileName }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setUploadProgress(100);
    }
  };

  const handleReset = () => {
    setFileName("");
    setFile(null);
    setUploadProgress(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input value
    }
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="relative m-8 flex flex-col items-center gap-3 rounded-lg border-2 border-dotted p-6">
        <input
          type="file"
          className="absolute inset-0 cursor-pointer opacity-0"
          onChange={handleFileChange}
          ref={fileInputRef} // Add ref to the input
        />
        <img
          className="size-8"
          src={`${BASE_IMG_URL}/img/cloud.svg`}
          alt="Upload"
        />
        <div className="text-lg font-semibold text-black">
          Choose a file or drag & drop it here
        </div>
        <div className="cursor-pointer rounded-lg bg-black px-4 py-2 text-white">
          Browse File
        </div>
      </div>
      {(file || fileName) && (
        <div className="rounded-lg border-2 p-2">
          <div className="flex justify-between">
            <div>{file?.name || fileName}</div>
            <div className="cursor-pointer" onClick={handleReset}>
              ✖️
            </div>
          </div>
          <Progress
            percent={uploadProgress}
            status={uploadProgress === 100 ? "success" : "active"}
            strokeColor="#828282"
            showInfo={false}
            style={{ margin: "0px" }}
          />
        </div>
      )}
    </div>
  );
};

UploadFileArea.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  setFileName: PropTypes.string,
};

export default UploadFileArea;
