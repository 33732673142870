import React from "react";
// import { Column } from "@ant-design/plots";

const DemoColumn = () => {
  // const data = [
  //   {
  //     type: "1",
  //     sales: 1,
  //   },
  //   {
  //     type: "2",
  //     sales: 2,
  //   },
  //   {
  //     type: "3",
  //     sales: 4,
  //   },
  //   {
  //     type: "4",
  //     sales: 5,
  //   },
  //   {
  //     type: "5",
  //     sales: 4,
  //   },
  //   {
  //     type: "6",
  //     sales: 3,
  //   },
  //   {
  //     type: "7",
  //     sales: 2,
  //   },
  //   {
  //     type: "8",
  //     sales: 1,
  //   },
  // ];

  // const config = {
  //   data,
  //   xField: "type",
  //   yField: "sales",
  //   label: {
  //     position: "top",
  //     style: {
  //       fill: "#FFFFFF",
  //       opacity: 0.6,
  //     },
  //   },
  //   xAxis: {
  //     label: {
  //       autoHide: true,
  //       autoRotate: false,
  //     },
  //   },
  //   meta: {
  //     type: {
  //       alias: "类别",
  //     },
  //     sales: {
  //       alias: "销售额",
  //     },
  //   },
  //   minColumnWidth: 20,
  //   maxColumnWidth: 20,
  // };

  return (
    <div className="h-[150px] w-52">
      {/* <Column {...config} /> */}
    </div>
  );
};

export default DemoColumn;
