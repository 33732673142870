/* eslint-disable no-debugger */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { BiMessageRounded } from "react-icons/bi";
import { FaRegBell } from "react-icons/fa";
import { BsPersonGear } from "react-icons/bs";
import { Link } from "react-router-dom";
import { pathnameHandler } from "components/utils/functions";
import DropDownUi from "components/ui/DropDown";
import NotificationDropdown from "./NotificationDropdown";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "./Avatar";
import { getNotification } from "store/action/user";

const HeaderRightArea = ({ setShowSidebar, showSidebar }) => {
  const { user, notificationList } = useSelector((state) => state.users);
  const role = sessionStorage.getItem("odin-auth-role");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotification(role));
  }, []);

  return (
    <div className="flex items-center gap-2">
      <Link
        to={role ? `${pathnameHandler()}/chats` : `/agency/chats`}
        className="relative"
      >
        <BiMessageRounded size={30} />
        {notificationList?.length ? (
          <span className="absolute right-[2px] top-[-2px] flex size-4 items-center justify-center rounded-[50%] bg-primary text-[8px] text-primary">
            <div>{notificationList?.length}</div>
          </span>
        ) : null}
      </Link>
      <div className="relative">
        <DropDownUi
          arrow={false}
          placement="bottom"
          item={<FaRegBell size={30} className="cursor-pointer" />}
          dropdownRender={<NotificationDropdown role={role} />}
        />
      </div>
      {!role && (
        <Link
          to={`${
            window.location.pathname?.includes("/agency/admin")
              ? "/dashboard"
              : "/agency/admin"
          }`}
        >
          <BsPersonGear size={30} />
        </Link>
      )}
      <Link
        to={
          user?.role === "student"
            ? "/student/settings"
            : user?.role === "tutor"
            ? "/tutor/settings"
            : "/dashboard"
        }
      >
        <Avatar
          avatar={user?.avatar}
          alt={user?.firstName + " " + user?.lastName}
          className="size-12 rounded-full"
        />{" "}
      </Link>
      <GiHamburgerMenu
        className="block lg:hidden"
        onClick={() => setShowSidebar(!showSidebar)}
      />
    </div>
  );
};

export default HeaderRightArea;
