import React from "react";
import { Divider } from "antd";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterArea = () => {
  return (
    <div className="text-white">
      <div className="flex gap-5">
        <Link
          to="https://web.facebook.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook color="white" size={30} />
        </Link>
        <Link
          to="https://www.instagram.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram color="white" size={30} />
        </Link>
        <Link
          to="https://www.linkedin.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin color="white" size={30} />
        </Link>
      </div>
      <Divider className="bg-white" />
      <div className="flex flex-col-reverse justify-between text-center md:flex-row">
        <div className="mt-4 md:mt-0">
          {new Date()?.getFullYear()} Odin Inc. All rights reserved.
        </div>
        <div className="flex flex-wrap gap-4">
          <div>Honor Code</div>
          <div>Cookie Notice</div>
          <div>Privacy Policy</div>
          <div>General Policies</div>
          <div>IP Rights</div>
        </div>
      </div>
    </div>
  );
};

export default FooterArea;
