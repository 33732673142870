import AgencyLayout from "components/shared/AgencyLayout";
import {
  agencyTutorsColumn,
  filterTutorOptions,
} from "components/shared/constants/agencyConstants";
import InputTextField from "components/ui/InputTextField";
import TableUi from "components/ui/Table";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { GoTrash } from "react-icons/go";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getAgencyTutorsAction } from "store/action/user";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/shared/Modal";
import AddTutor from "../AgencyDashboard/designs/AddTutor";
import { tutorDeleteAction, tutorUpdateAction } from "store/action/timelines";
import DeleteScreen from "components/shared/Sidebar/designs/DeleteScreen";
import UpdateTutor from "./UpdateModal";
import FilterDropdown from "components/ui/DropDown/FilterDropDown";
import { EditOutlined } from "@ant-design/icons";

const actionOptions = [
  {
    name: "View Tutor",
    icon: <IoEyeOutline size={20} />,
  },
  {
    name: "Edit Tutor",
    icon: <EditOutlined size={20} />,
  },
  {
    name: "Delete",
    icon: <GoTrash size={20} />,
  },
];

const AgencyTutors = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModel, setDeleteModel] = useState("");
  const [tutor, setTutor] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [tutors, setTutors] = useState([]);
  const [modal, setModal] = useState(false);
  const data = useSelector((state) => state.users.tutors);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const viewRedirectHandler = (value) => {
    navigate(`/agency/tutors/${value?.id}`);
  };

  const deleteTutor = (index, value) => {
    setTutor(value);
    setDeleteModel("deleteTutor");
    setModal(true);
  };
  const EditTutor = (index, value) => {
    setTutor(value);
    setDeleteModel("Edit");
    setModal(true);
  };
  const handleDeleteHandler = () => {
    dispatch(tutorDeleteAction(tutor));
    setTutors(data.filter((item) => item.id !== tutor.id));
    setModal(false);
    setDeleteModel("");
    setTutor({});
  };
  const handleUpdateHandler = (values) => {
    dispatch(tutorUpdateAction(values));
    setTutors((prevTutors) =>
      prevTutors.map((tutor) =>
        tutor.userId === values.userId ? { ...tutor, ...values } : tutor
      )
    );
    setModal(false);
    setDeleteModel("");
    setTutor({});
  };
  useEffect(() => {
    if (data) {
      if (searchTerm !== "") {
        const filteredTutors = data.filter((tutor) =>
          tutor.username.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setTutors(filteredTutors);
      } else {
        setTutors(data);
      }
    }
  }, [searchTerm, data]);

  useEffect(() => {
    dispatch(getAgencyTutorsAction());
  }, []);

  const applyFilter = (filter, value) => {
    if (filter && value) {
      const filteredData = data.filter((tutor) =>
        tutor[filter]?.toString().toLowerCase().includes(value.toLowerCase())
      );
      setTutors(filteredData);
    } else {
      setTutors(data);
    }
  };

  const resetFilter = () => {
    setTutors(data);
  };

  return (
    <AgencyLayout currentPage="Tutors">
      <Modal
        open={modal}
        close={() => setModal(false)}
        element={
          deleteModel === "deleteTutor" ? (
            <DeleteScreen
              handleDeleteHandler={handleDeleteHandler}
              title="tutor"
              onClose={() => setModal(false)}
            />
          ) : deleteModel === "Edit" ? (
            <UpdateTutor
              handleUpdateHandler={handleUpdateHandler}
              title="tutor"
              user={tutor}
              onClose={() => setModal(false)}
            />
          ) : (
            <AddTutor name="Tutor" close={() => setModal(false)} />
          )
        }
      />

      <div className="flex h-full flex-col justify-between gap-6">
        <div className="mb-2 flex items-end">
          <div className="w-fit border-b-2 border-black text-2xl font-bold text-black">
            Tutors List
          </div>
          <span className="flex-1 border"></span>
        </div>
        <div className="flex items-center justify-between  gap-4">
          <div className="flex h-10 w-fit justify-center rounded-xl bg-white p-2 md:w-[17%]">
            <FilterDropdown
              placeholder="Filter"
              onApplyFilter={applyFilter}
              onResetFilter={resetFilter}
              filterOptions={filterTutorOptions}
            />
          </div>

          <div className="hidden h-10 flex-1 items-center gap-2 rounded-xl bg-white md:flex">
            <InputTextField
              icon={<FaSearch />}
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e)}
            />
          </div>
          <div
            className="flex h-10 w-fit cursor-pointer items-center justify-center gap-2 rounded-lg bg-black px-2 py-1 text-center text-white md:w-1/4 lg:w-1/5"
            onClick={() => setModal(true)}
          >
            <FaPlus color="white" /> <span>Add Tutor</span>
          </div>
        </div>
        <div className="flex items-center gap-2 rounded-xl bg-white py-2 md:hidden">
          <InputTextField
            icon={<FaSearch />}
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e)}
          />
        </div>
        <div className=" flex-1">
          <div className="h-full rounded-lg bg-white p-4">
            <TableUi
              data={tutors}
              headerBg="#303030"
              headerColor="#fff"
              rowSelection
              columns={agencyTutorsColumn}
              pageSize={6}
              edit
              loading={isLoading}
              editRow={EditTutor}
              deleteRow={deleteTutor}
              viewRedirect={viewRedirectHandler}
              actionOptions={actionOptions}
            />
          </div>
        </div>
      </div>
    </AgencyLayout>
  );
};

export default AgencyTutors;
