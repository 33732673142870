/* eslint-disable no-undef */
import AgencyLayout from "components/shared/AgencyLayout";
import {
  agencyStudentsColumn,
  filterStudentOptions,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import InputTextField from "components/ui/InputTextField";
import TableUi from "components/ui/Table";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { GoTrash } from "react-icons/go";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getAgencyStudentsAction } from "store/action/user";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/shared/Modal";
import AddTutor from "../AgencyDashboard/designs/AddTutor";
import DeleteScreen from "components/shared/Sidebar/designs/DeleteScreen";
import { studentDeleteAction } from "store/action/timelines";
import FilterDropdown from "components/ui/DropDown/FilterDropDown";

const actionOptions = [
  {
    name: "View Student",
    icon: <IoEyeOutline size={20} />,
  },
  {
    name: "Delete",
    icon: <GoTrash size={20} />,
  },
];

const AgencyStudents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [deleteModel, setDeleteModel] = useState("");
  const [student, setStudent] = useState({});
  const [students, setStudents] = useState([]);
  const data = useSelector((state) => state.users.students);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const role = sessionStorage.getItem("odin-auth-role");

  const viewRedirectHandler = (value) => {
    if (role === "tutor") {
      navigate(`/tutor/student/${value?.id}`);
    } else {
      navigate(`/agency/students/${value?.id}`);
    }
  };
  const deleteStudent = (index, value) => {
    setStudent(value);
    setDeleteModel("deleteStudent");
    setModal(true);
  };
  const handleStudentHandler = () => {
    dispatch(studentDeleteAction(student));
    setStudents(data.filter((item) => item.id !== student.id));
    setModal(false);
    setDeleteModel("");
    setStudent({});
  };

  useEffect(() => {
    dispatch(getAgencyStudentsAction());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      if (search !== "") {
        const filteredStudents = data.filter((student) =>
          student.username.toLowerCase().includes(search.toLowerCase())
        );
        setStudents(filteredStudents);
      } else {
        setStudents(data);
      }
    }
  }, [search, data]);

  const applyFilter = (filter, value) => {
    if (filter && value) {
      const filteredData = data.filter((student) =>
        student[filter]?.toString().toLowerCase().includes(value.toLowerCase())
      );
      setStudents(filteredData);
    } else {
      setStudents(data);
    }
  };

  const resetFilter = () => {
    setStudents(data);
  };

  return (
    <AgencyLayout
      currentPage="Students"
      sidebarData={role === "tutor" ? tutorSidebar : null}
    >
      <Modal
        open={modal}
        close={() => setModal(false)}
        element={
          deleteModel === "deleteStudent" ? (
            <DeleteScreen
              handleDeleteHandler={handleStudentHandler}
              title="student"
              onClose={() => setModal(false)}
            />
          ) : (
            <AddTutor name="Student" close={() => setModal(false)} />
          )
        }
      />
      <div className="flex h-full flex-col justify-between gap-6">
        <div className="mb-2 flex items-end">
          <div className="w-fit border-b-2 border-black text-2xl font-bold text-black">
            Student List
          </div>
          <span className="flex-1 border"></span>
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex h-10 w-fit justify-center rounded-xl bg-white p-2 md:w-[17%]">
            <FilterDropdown
              placeholder="Filter"
              onApplyFilter={applyFilter}
              onResetFilter={resetFilter}
              filterOptions={filterStudentOptions}
            />
          </div>
          <div className="hidden h-10 flex-1 items-center gap-2 rounded-xl bg-white md:flex">
            <InputTextField
              icon={<FaSearch />}
              placeholder="Search"
              value={search}
              onChange={(val) => setSearch(val)}
            />
          </div>
          <div
            className="flex h-10 w-fit cursor-pointer items-center justify-center gap-2 rounded-lg bg-black px-2 py-1 text-center text-white md:w-1/4 lg:w-1/5"
            onClick={() => setModal(true)}
          >
            <FaPlus color="white" /> <span>Add Student</span>
          </div>
        </div>
        <div className="flex items-center gap-2 rounded-xl bg-white py-2 md:hidden">
          <InputTextField
            icon={<FaSearch />}
            placeholder="Search"
            value={search}
            onChange={(val) => setSearch(val)}
          />
        </div>
        <div className=" flex-1">
          <div className="h-full rounded-lg bg-white p-4">
            <TableUi
              data={students}
              headerBg="#303030"
              headerColor="#fff"
              rowSelection
              columns={agencyStudentsColumn}
              pageSize={6}
              loading={isLoading}
              deleteRow={deleteStudent}
              viewRedirect={viewRedirectHandler}
              actionOptions={actionOptions}
            />
          </div>
        </div>
      </div>
    </AgencyLayout>
  );
};

export default AgencyStudents;
