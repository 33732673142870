/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import Modal from "components/shared/Modal";
import AddDegree from "./AddDegree";
import TableUi from "components/ui/Table";
import { GoTrash } from "react-icons/go";
import { EducationDegreeColumn } from "components/shared/constants/agencyConstants";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/action/user";
import { toObject } from "components/utils/functions";
import MultipleSelection from "components/shared/MultiSelection";
import { LiaUserEditSolid } from "react-icons/lia";

const dropdownData = [
  {
    name: "Delete",
    icon: <GoTrash size={25} />,
  },
  {
    name: "Edit",
    icon: <LiaUserEditSolid size={25} />,
  },
];

const EducationSetting = ({ onClose }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [currentDegree, setCurrentDegree] = useState(null);
  const user = useSelector((state) => state.users.user);
  const [formData, setFormData] = useState({
    education: [],
    experience: 0,
    skills: [],
  });

  useEffect(() => {
    setFormData({
      education:
        typeof user?.education === "string"
          ? toObject(user?.education)
          : user?.education,
      experience: user?.experience,
      skills: [],
    });
  }, [user]);

  const addDegreeHandler = (value) => {
    if (currentDegree) {
      const updatedEducation = formData.education.map((degree) =>
        degree.name === currentDegree.name ? value : degree
      );
      setFormData({ ...formData, education: updatedEducation });
    } else {
      setFormData({ ...formData, education: [...formData.education, value] });
    }
    setModal(false);
    setCurrentDegree(null);
  };

  const handleEdit = (idx, degree) => {
    console.log("🚀 ~ handleEdit ~ degree:", degree);
    setCurrentDegree(degree);
    setModal(true);
  };

  const handleSubmit = () => {
    const form = new FormData();

    for (const key in formData) {
      if (formData?.education || formData?.skills) {
        form.append(key, JSON.stringify(formData[key]));
      } else {
        form.append(key, formData[key]);
      }
    }

    dispatch(updateUser({ form, role: "tutor" }));
    onClose();
  };

  const deleteEducationHandler = (index, value) => {
    const updatedTableData = formData.education.filter(
      (item, i) => !(item.name === value.name)
    );

    setFormData({
      ...formData,
      education: updatedTableData,
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <Modal
        footer={false}
        open={modal}
        close={(e) => {
          setModal(e);
          setCurrentDegree(null);
        }}
        element={
          <AddDegree
            setData={addDegreeHandler}
            close={() => {
              setModal(false);
              setCurrentDegree(null);
            }}
            degree={currentDegree}
          />
        }
      />

      <div>
        <div className="pb-1 text-left text-lg font-bold text-black">
          Education Settings
        </div>
        <div className="w-full border-t border-ternary"></div>
      </div>
      <div>
        <div className="flex items-center justify-between p-3">
          <div className="text-xs text-secondary ">Add Degree Details</div>
          <div>
            <img
              className="size-6 cursor-pointer"
              src={`${BASE_IMG_URL}/img/Vector.svg`}
              alt=""
              onClick={() => {
                setModal(true);
                setCurrentDegree(null);
              }}
            />
          </div>
        </div>
        <TableUi
          columns={EducationDegreeColumn}
          data={formData?.education}
          pageSize={3}
          actionOptions={dropdownData}
          deleteRow={deleteEducationHandler}
          editRow={handleEdit}
          edit
        />
      </div>
      <MultipleSelection
        className="mb-3 border-2 py-3"
        name="Experience"
        type="number"
        placeholder="Experience"
        value={formData?.experience}
        onChange={(value) => setFormData({ ...formData, experience: value })}
      />
      <div className="flex justify-end">
        <div className="flex items-center justify-center gap-2">
          <div
            className="cursor-pointer rounded-lg border-2 border-secondary px-6 py-2 font-semibold text-black"
            onClick={onClose}
          >
            Cancel
          </div>
          <div
            className="cursor-pointer rounded-lg border-2 border-black bg-black px-6 py-2 font-semibold text-white"
            onClick={handleSubmit}
          >
            Update
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationSetting;
