import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearRedirectRoute } from 'store/slices/redirect';

const RedirectComponent = () => {
    const navigate = useNavigate();
    const redirectRoute = useSelector((state) => state.redirect);
    const dispatch = useDispatch();

    useEffect(() => {
        if (redirectRoute) {
            navigate(redirectRoute);

            dispatch(clearRedirectRoute()); // Clear the redirection route after redirection
        }
    }, [redirectRoute]);

    return null; // This component doesn't render anything
};

export default RedirectComponent;
