import React from "react";
import Header from "components/shared/Header";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "store/action/user";
import AuthLayout from "components/shared/AuthLayout";

const ForgetPassword = () => {
  const agency = useSelector((state) => state.supers.agency);
  const { backgroundColor } = agency || {};

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    const payload = { ...values };
    if (backgroundColor) {
      payload.sub = true;
    }
    dispatch(forgetPassword(payload));
  };
  return (
    <AuthLayout>
      <div className="flex h-screen flex-col bg-[#F9F9F9]">
        <Header
          className={
            backgroundColor
              ? "bg-[--bg-primary-color]"
              : "bg-gradient-to-b from-[#0114B0] to-[#010B60]"
          }
          path={"/"}
        />
        <div className="flex flex-1  items-center justify-center  ">
          <div className="flex h-[350px] w-[550px] flex-col justify-between rounded-sm border-2 bg-[#FFF] px-16 py-12 text-center">
            <div
              className={`text-2xl font-bold ${
                backgroundColor ? "text-secondary" : "text-odinPrimary"
              }
`}
            >
              Forgot Password
            </div>
            <Form form={form} layout="vertical" enabled onFinish={onFinish}>
              <div
                className={`text-left text-sm font-bold ${
                  backgroundColor ? "text-secondary" : "text-odinPrimary"
                }`}
              >
                <div className="text-base">Email</div>

                <Form.Item
                  name="email"
                  label=""
                  className="mb-4"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input
                    placeholder="abc@gmail.com"
                    type="email"
                    className="custom-input mb-4"
                  />
                </Form.Item>
              </div>
              <button
                type="submit"
                className={` ${
                  backgroundColor
                    ? "bg-[--bg-primary-color] "
                    : "bg-gradient-to-b from-[#0114B0] to-[#010B60] "
                }
                  w-full rounded-lg  py-2 font-semibold text-white
                `}
              >
                Reset Password
              </button>
            </Form>
          </div>
        </div>
      </div>{" "}
    </AuthLayout>
  );
};

export default ForgetPassword;
