/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import { BASE_IMG_URL } from "components/shared/constants";
import Tabs from "components/shared/Tabs";
import Profile from "./designs/Profile";
import Handouts from "./designs/Handouts";
import {
  agencySidebar,
  studentSidebar,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import { useParams } from "react-router-dom";
import API from "store/services/base.service";
import { useDispatch, useSelector } from "react-redux";
import { getUserFiles } from "store/action/user";
// import { setLoading, unsetLoading } from "store/slices/alert";

const data = [
  {
    name: "Tutor Profile",
    icon: <img src={`${BASE_IMG_URL}/img/person-black.svg`} alt="" />,
    iconWhite: <img src={`${BASE_IMG_URL}/img/person.svg`} alt="" />,
  },
  {
    name: "Handouts",
    icon: <img src={`${BASE_IMG_URL}/img/hangout-logo.svg`} alt="" />,
    iconWhite: (
      <img src={`${BASE_IMG_URL}/img/hangout-logo-white.svg`} alt="" />
    ),
  },
];

const CustomTutorProfile = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const role = sessionStorage.getItem("odin-auth-role");
  const [tab, setTab] = useState(0);
  const [tutorData, setTutorData] = useState({});
  const isSubscribed = useSelector((state) => state.users.subscribedTutors); // Adjust based on your actual state structure

  const getData = () => {
    API.get(`/tutors/tutor/${param?.id}`)
      .then((res) => {
        setTutorData(res?.data?.data);
      })
      .catch(() => {});
  };
  const onSelect = (index) => {
    setTab(index);
  };
  const tutorId = String(param?.id); // Convert to string if needed
  const subscribedTutorIds = isSubscribed.map((tutor) => String(tutor.id)); // Convert all to string

  const filteredData =
    role === "student"
      ? subscribedTutorIds.includes(tutorId)
        ? data
        : data.filter((item) => item.name !== "Handouts")
      : data;

  useEffect(() => {
    if (param?.id) {
      getData();
      dispatch(getUserFiles({ ...param, role: "tutor" }));
    }
  }, [param?.id]);

  const rendered = (e) => {
    switch (e) {
      case 0:
        return <Profile tutorData={tutorData} />;
      case 1:
        return <Handouts />;
      default:
        return <Profile />;
    }
  };

  return (
    <AgencyLayout
      currentPage="Tutors"
      sidebarData={
        role === "student"
          ? studentSidebar
          : role === "tutor"
          ? tutorSidebar
          : agencySidebar
      }
    >
      <div className="flex flex-col justify-between gap-4">
        <Tabs data={filteredData} selected={tab} onSelect={onSelect} />
        {rendered(tab)}
      </div>
    </AgencyLayout>
  );
};

export default CustomTutorProfile;
