/* eslint-disable react/prop-types */
import React from "react";
import { Divider, Skeleton } from "antd";
import { useSelector } from "react-redux";

const BillingInfo = ({ connectedAccount }) => {
  const isLoading = useSelector((state) => state.notifications.isLoading);
  return (
    <div className="h-full rounded-lg bg-white p-4 shadow-md">
      <div className="flex">
        <div className="border-b-2 border-secondary text-xl font-semibold">
          Bussiness Information
        </div>
        <div className="flex-1 border-b-2"></div>
      </div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <div className="mt-4 flex flex-col gap-2 text-sm font-semibold text-secondary">
            <div className="flex text-start">
              <span className="w-[200px]">Name:</span>
              <span className="font-medium">
                {connectedAccount?.business_profile?.name}
              </span>
            </div>
            <div className="flex text-start">
              <span className="w-[200px]">Email:</span>
              <span className="font-medium">{connectedAccount?.email}</span>
            </div>
            <div className="flex">
              <span className="w-[200px]">Phone:</span>
              <span className="font-medium">
                {connectedAccount?.business_profile?.support_phone}{" "}
              </span>
            </div>
            <div className="flex">
              <span className="w-[200px]">Website:</span>
              <span className="font-medium">
                {connectedAccount?.business_profile?.url}{" "}
              </span>
            </div>
          </div>
          <Divider />
          <div className="flex flex-col gap-2 text-sm font-semibold text-secondary">
            <div className="flex justify-between text-lg">
              <span>Total (USD)</span>
              <span className="w-32  text-black">
                $ {connectedAccount?.totalEarnings || 0}
              </span>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default BillingInfo;
