import AgencyLayout from "components/shared/AgencyLayout";
import { handTableColumn } from "components/shared/constants/agencyConstants";
import InputTextField from "components/ui/InputTextField";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";
import TableUi from "components/ui/Table";
import Upload from "./designs/Upload";
import Modal from "components/shared/Modal";
import { GoTrash } from "react-icons/go";
import { LiaUserEditSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import DeleteScreen from "components/shared/Sidebar/designs/DeleteScreen";
import { UserFileDelete, getUserFiles } from "store/action/user";
// import FilterDropdown from "components/ui/DropDown/FilterDropDown";
import { handleDownload } from "components/utils/functions";
import { DownloadOutlined } from "@ant-design/icons";

const actionOptions = [
  {
    name: "Edit",
    icon: <LiaUserEditSolid size={20} />,
  },
  {
    name: "Delete",
    icon: <GoTrash size={20} />,
  },
  {
    name: "Download",
    icon: <DownloadOutlined style={{ fontSize: "20px" }} />,
  },
];

const AgencyHandouts = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [file, setFile] = useState();
  const [deleteModel, setDeleteModel] = useState("");
  const [search, setSearch] = useState("");
  const user = useSelector((state) => state.users.user);
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const files = useSelector((state) => state.users.files);

  const deleteFile = (index, value) => {
    setFile(value);
    setDeleteModel("Delete");
    setModal(true);
  };

  const EditFile = (index, value) => {
    setFile(value);
    setDeleteModel("Edit");
    setModal(true);
  };

  const handleDeleteHandler = () => {
    dispatch(UserFileDelete(file));
    setData(data.filter((item) => item.id !== file.id));
    setModal(false);
    setDeleteModel("");
    setFile({});
  };
  const ModalHandler = () => {
    setModal(true);
    setFile({});
  };

  useEffect(() => {
    if (user?.id) {
      dispatch(getUserFiles(user));
    }
  }, [user?.id]);

  useEffect(() => {
    setData(files);
  }, [files]);

  useEffect(() => {
    if (data) {
      if (search !== "") {
        const filtered = files.filter((file) =>
          file.filename.toLowerCase().includes(search.toLowerCase())
        );
        setData(filtered);
      } else {
        setData(files);
      }
    }
  }, [search, files]);

  return (
    <AgencyLayout currentPage="Handouts">
      <Modal
        footer={false}
        open={modal}
        close={(e) => setModal(e)}
        element={
          deleteModel === "Delete" ? (
            <DeleteScreen
              handleDeleteHandler={handleDeleteHandler}
              title="File"
              onClose={() => setModal(false)}
            />
          ) : deleteModel === "Edit" ? (
            <Upload
              close={() => setModal(false)}
              fileId={file.id}
              setFileId={setFile}
              group="handout"
            />
          ) : (
            <Upload close={() => setModal(false)} group="handout" />
          )
        }
      />
      <div className="flex h-full flex-col justify-between gap-6">
        <div className="mb-2 flex items-end">
          <div className="w-fit border-b-2 border-black text-xl font-bold text-black">
            Handouts
          </div>
          <span className="flex-1 border-b"></span>
        </div>
        <div className="flex items-center justify-between  gap-4">
          <div className=" flex  h-10 flex-1 items-center gap-2 rounded-xl bg-white">
            <InputTextField
              icon={<FaSearch />}
              type="text"
              onChange={(val) => setSearch(val)}
              placeholder="Search"
            />
          </div>
          <div
            className="flex h-10 w-fit cursor-pointer items-center justify-center gap-2 rounded-lg bg-black px-2 py-1 text-center text-white md:w-1/4 lg:w-1/5"
            onClick={ModalHandler}
          >
            <AiOutlineCloudUpload color="white" /> <span>Upload</span>
          </div>
        </div>

        <div className="flex-1">
          <div className="h-full rounded-lg bg-white p-4">
            <TableUi
              data={data}
              headerBg="#303030"
              headerColor="#fff"
              pageSize={10}
              columns={handTableColumn}
              deleteRow={deleteFile}
              edit
              editRow={EditFile}
              download
              loading={isLoading}
              downloadRow={handleDownload}
              actionOptions={actionOptions}
            />
          </div>
        </div>
      </div>
    </AgencyLayout>
  );
};

export default AgencyHandouts;
