/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const PieChart = ({ data }) => {
  console.log("🚀 ~ PieChart ~ data:", typeof data);

  const [state, setState] = useState({
    options: {
      chart: {
        type: "donut",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [],
  });

  useEffect(() => {
    if (data && data.length) {
      const planNames = data.map((item) => item.name);
      const counts = data.map((item) => item.value);

      setState((prevState) => ({
        ...prevState,
        options: { ...prevState.options, labels: planNames },
        series: counts,
      }));
    }
  }, [data]);

  return (
    <div className="w-full">
      <Chart
        options={state.options}
        series={state.series}
        type="donut"
        height={300}
      />
    </div>
  );
};

export default PieChart;
