/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import { SlOptionsVertical } from "react-icons/sl";
import DropDownUi from "components/ui/DropDown";
import StudentInfoDropdown from "./StudentInfoDropdown";
import Modal from "components/shared/Modal";
import { useSelector } from "react-redux";
import API from "store/services/base.service";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { displaySubject, isEmptyObject } from "components/utils/functions";
import Subscribed from "components/shared/Subscribed";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "components/shared/Checkout.js";
import { loadStripe } from "@stripe/stripe-js";
import { Dropdown } from "antd";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";
import ReviewForm from "components/ui/Review";
import { MoreOutlined } from "@ant-design/icons";
import SkeletonWithAvatar from "components/shared/Skeleton";

const fromDollarToCent = (amount) => Number(amount * 100);

const TutorInfoCard = ({ data }) => {
  const param = useParams();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [stripePromise, setStripePromise] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [key, setKey] = useState();
  const role = sessionStorage.getItem("odin-auth-role");
  const user = useSelector((state) => state.users.user);

  const [formData, setFormData] = useState({
    tutorId: "",
    studentId: "",
    timezone: "",
    classes: [],
    amount: 0,
    subject: "",
    paid: true,
  });
  const handleSubscribe = () => {
    setKey("subscribe");
    setModal(true);
  };
  const clickHandler = (key) => {
    if (key === "subscribe") {
      setKey(key);
      setModal(true);
    } else if (key === "review") {
      setKey(key);
      setModal(true);
    } else if (key === "message") {
      API.post("/chat/createChat", {
        id: user?.userId,
        role: user?.role,
        email: data?.User.email,
        createdAt: Date.now(),
      })
        .then((res) => {
          navigate(`/student/chat/${res.data.data.room}`);
        })
        .catch((err) => toast.error(err));
    } else if (key === "unsubscribe") {
      API.post("/subscription/unsubscribe", {
        studentId: user?.id,
        tutorId: Number(param?.id),
      })
        .then((res) => {
          toast.success("Unsubscribed Successfully");
        })
        .catch((err) => toast.error(err));
    }
  };

  const bookedSlots = (value, amount, subject) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setFormData({
      ...formData,
      classes: value,
      tutorId: data?.id,
      studentId: user?.id,
      amount,
      timezone,
      subject,
    });
    setKey("pay");
  };

  useEffect(() => {
    API.post("/stripe/intent", { amount: fromDollarToCent(10) })
      .then((res) => {
        setClientSecret(res.data?.client_secret);
        setStripePromise(
          loadStripe(
            "pk_test_51OlsJgJVe6WBmKLIW4VucQG72Mw0ICSatuvfIZeRAZgR9r3yM5kBO6N12NoWcmx2mIBmpoRvdFGkyc1Ni5vRh9YC00rRsJpmtD",
            {
              stripeAccount: res.data?.connectedAccountId,
            }
          )
        );
      })
      .catch((err) => toast.error(err));
  }, []);
  const handleSubscription = async (clientSecret, classes) => {
    if (clientSecret && classes) {
      try {
        const response = await API.post(
          "/subscription/subscribe",
          JSON.parse(classes)
        );

        if (response.status === 200) {
          toast.success(response.data.message);
          navigate(window.location.pathname);
        } else {
          toast.error("An unexpected error occurred. Please try again later.");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 500) {
            console.error(
              "Internal Server Error:",
              error.response.data.error || error.message
            );
            navigate(window.location.pathname);

            toast.error(error.response.data.message);
          } else {
            console.error("Error:", error.response.data.error || error.message);
            toast.error("An error occurred. Please try again later.");
          }
        }
      }
    }
  };
  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const classes = new URLSearchParams(window.location.search).get("classes");

    if (clientSecret && classes) {
      handleSubscription(clientSecret, classes);
    }
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  const slotConfirmed = () => {
    console.log("confimred");
  };
  const handleNavigate = () => {
    API.post("/chat/chat", {
      email: data.User.email,
      id: user.id,
      createdAt: Date.now(),
    }).then((response) => {
      navigate(`/agency/chat/${response.data.data.room}`);
    });
  };

  const items = [
    {
      key: "2",
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={handleNavigate}>
          Message
        </a>
      ),
    },
  ];

  return (
    <div className="box-shadow col-span-3 h-full rounded-lg bg-white p-4">
      {isEmptyObject(data) ? (
        <SkeletonWithAvatar rows={4} />
      ) : (
        <div className="relative h-full">
          <Modal
            open={modal}
            close={(e) => setModal(e)}
            element={
              key === "subscribe" ? (
                <Subscribed
                  chargesPerHour={data?.chargesPerHour}
                  bookedSlots={bookedSlots}
                  data={data}
                />
              ) : key === "review" ? (
                <ReviewForm close={(e) => setModal(e)} tutorId={data.id} />
              ) : (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    slotConfirmed={slotConfirmed}
                    classes={JSON.stringify(formData)}
                  />
                </Elements>
              )
            }
          />
          <div className="flex flex-col gap-4 sm:flex-row ">
            <Avatar
              avatar={data?.url}
              alt={data?.User?.firstName}
              className="m-auto size-24 rounded-full"
            />
            <div className="flex flex-1 flex-col justify-center gap-2">
              {role ? (
                <div className="flex justify-end">
                  <DropDownUi
                    item={<SlOptionsVertical className="cursor-pointer" />}
                    dropdownRender={
                      <StudentInfoDropdown clickHandler={clickHandler} />
                    }
                  />
                </div>
              ) : (
                <div className="flex justify-end">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                  >
                    <MoreOutlined className="cursor-pointer" />
                  </Dropdown>
                </div>
              )}
              <div className="text-2xl font-bold">
                {data?.User?.firstName + " " + data?.User?.lastName}
              </div>
              <div className="text-sm text-secondary">
                Experience:{" "}
                <span className="font-semibold text-black">
                  {data?.experience} Years
                </span>
              </div>

              <div className="flex items-center gap-3">
                <img
                  className="size-4"
                  src={`${BASE_IMG_URL}/img/cap.svg`}
                  alt=""
                />
                <div className="font-bold text-black">
                  {displaySubject(data?.subject)}

                  {/* {data?.subject && toObject(data?.subject)[0]} */}
                </div>
              </div>

              <div className="flex items-center gap-2">
                <img
                  className="size-4"
                  src={`${BASE_IMG_URL}/img/star.svg`}
                  alt=""
                />
                <div className="font-bold text-black">{data.rating}</div>
                <div className="font-bold"></div>
              </div>
            </div>
          </div>

          <div className="pb-6">
            <div className="py-6 text-base text-secondary">
              {data?.description}
            </div>
          </div>
          {user?.role === "student" && (
            <div className="absolute bottom-0 right-0 mt-3 flex justify-end">
              <button
                className="flex h-10 w-24 items-center justify-center rounded-lg border-2 border-[black] bg-black text-center text-white outline-none"
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TutorInfoCard;
