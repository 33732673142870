import React from "react";
import StudentInfoCard from "./StudentInfoCard";
import ClassList from "./ClassList";
import TableUi from "components/ui/Table";
import {
  groupByProperty,
  studentActivityTableColumn,
} from "components/shared/constants/agencyConstants";
import { useSelector } from "react-redux";

const StudentProfile = () => {
  const data = useSelector((state) => state.users.studentDetails);
  const activity = useSelector((state) => state.users.studentActivities);
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const classes = groupByProperty(activity, "subject");

  return (
    <div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-4 lg:grid-cols-5">
        <StudentInfoCard data={data} activity={classes} />
        <ClassList data={classes} />
      </div>

      <div className="my-6 flex items-end">
        <div className=" w-fit border-b-2 border-black text-xl font-bold">
          Student Activity
        </div>
        <div className="flex-1 border-b-2 border-secondary"></div>
      </div>
      <div className="box-shadow h-fit overflow-auto rounded-lg bg-white p-4">
        <TableUi
          data={activity}
          columns={studentActivityTableColumn}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default StudentProfile;
