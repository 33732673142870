/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { currentWeek } from "components/utils/functions";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DemoLine = ({ students, tutors }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true, // Show the toolbar
          tools: {
            download: true, // Hide download icon
            selection: false, // Hide selection icon
            zoom: false, // Hide zoom icon
            zoomin: false, // Hide zoom-in icon
            zoomout: false, // Hide zoom-out icon
            pan: false, // Hide pan icon
            reset: false, // Hide reset icon
          },
        },
      },

      xaxis: {
        categories: currentWeek(),
      },
      markers: {
        size: 0,
      },
    },
    series: [
      {
        name: "Number of Tutors",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Number of Students",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  useEffect(() => {
    setState({
      ...state,
      series: [
        {
          name: "Number of Tutors",
          data: tutors,
        },
        {
          name: "Number of Students",
          data: students,
        },
      ],
    });
  }, [students, tutors]);

  return (
    <div className="w-full">
      <Chart
        options={state.options}
        series={state.series}
        type="line"
        height={300}
      />
    </div>
  );
};

export default DemoLine;
