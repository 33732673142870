/* eslint-disable react/prop-types */
import MultipleSelection from "components/shared/MultiSelection";
import React, { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import API from "store/services/base.service";

const Plan = ({ currentPlan, updatedPlan }) => {
  const [editFeatureKey, setEditFeatureKey] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    price: "",
    features: { feature1: "hello all is free", feature2: "unlimited" }
  });

  useEffect(() => {
    setFormData({
      id: currentPlan?.id,
      name: currentPlan.name,
      description: currentPlan?.description,
      price: currentPlan?.prices?.unit_amount,
      features: currentPlan?.metadata
    })
  }, [currentPlan])

  const onChange = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const submit = () => {
    API.post("/stripe/update-product", formData)
      .then(res => updatedPlan())
      .catch((err) => console.log(err))
  }

  const handleFeatureEdit = (key) => {
    setEditFeatureKey(key);
  };

  const handleFeatureChange = (key, value) => {
    setFormData({
      ...formData,
      features: {
        ...formData.features,
        [key]: value
      }
    });
  };

  const handleFeatureDelete = (key) => {
    setFormData({
      ...formData,
      features: {
        ...formData.features,
        [key]: null
      }
    });
  };

  const addFeaturehandler = () => {
    setFormData({ ...formData, features: { ...formData.features, [`feature${Object.entries(formData.features)?.length + 1}`]: "Type..." } })
  }

  return (
    <div>
      <div className="flex items-end">
        <div className="border-b-2 border-odinPrimary text-2xl font-bold text-odinPrimary ">
          {currentPlan?.name}
        </div>
        <div className="flex-1 border-b-2"></div>
      </div>
      <div className="mt-6">
        <MultipleSelection
          name="Name"
          placeholder="Name"
          value={formData?.name}
          onChange={val => onChange("name", val)}

        />
        <MultipleSelection
          name="Description"
          placeholder="Description"
          value={formData?.description}
          onChange={val => onChange("description", val)}
        />
        <MultipleSelection
          name="Price"
          placeholder="$15"
          value={formData?.price}
          onChange={val => onChange("price", val)}
        />
        <div className="text-lg">Features</div>
        <div className="ml-auto w-fit rounded-lg bg-black p-2 text-white" onClick={addFeaturehandler}>Add Feature</div>
        <ul className="mt-2 flex flex-col gap-2">
          {Object.entries(formData.features).filter(([key, value]) => value !== null).map(([key, value]) => (
            <li key={key} className="flex w-full justify-between">
              {editFeatureKey === key ? (
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleFeatureChange(key, e.target.value)}
                  onBlur={() => setEditFeatureKey(null)}
                  autoFocus
                />
              ) : (
                <span onClick={() => handleFeatureEdit(key)}>{value}</span>
              )}
              <button className="w-fit" onClick={() => handleFeatureDelete(key)}><BiTrash color="#000" size={15} /></button>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6 flex items-center justify-between">
        {/* <div className=" flex gap-2">
          {["Video Confering", "Handouts", "Payments", "Branding"].map(
            (item, idx) => (
              <div
                key={idx}
                className={`rounded-lg border-2  border-odinPrimary px-2 py-1 text-odinPrimary ${(item === "Payments" || item === "Branding") &&
                  name === "Basic Package"
                  ? "border-secondary text-secondary"
                  : ""
                  }`}
              >
                {item}
              </div>
            )
          )}
        </div> */}
        <div className="flex items-center justify-center gap-2 ">
          <div className="rounded-lg border-2 border-secondary px-6 py-1 font-semibold text-black">
            Cancel
          </div>
          <div className="button-background-gradient rounded-lg border-2 border-odinPrimary px-6 py-1 font-semibold text-white" onClick={submit}>
            Update
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
