import {
  ADD_POST_BY_AGENCY,
  ADD_REVIEW,
  AGENCY_ACCEPT_CLASS,
  AGENCY_CARD_PAYMENT_METHOD,
  AGENCY_DELETE,
  AGENCY_LOGIN,
  AGENCY_REGISTER,
  AGENCY_STUDENTS,
  AGENCY_TUTORS,
  AGENCY_UPDATE,
  AGENCY_UPDATE_DETAIL,
  CANCEL_SUBSCRIPTION,
  CHANGE_PASSWORD,
  CREATE_TUTOR_TIMETABLE,
  DELETE_USER_FILE,
  FORGET_PASSWORD,
  GET_AGENCY_BY_DOMAIN,
  GET_AGENCY_DETAIL,
  GET_AGENCY_WALLET,
  GET_ALL_COUNTRIES,
  GET_CITY_STATE,
  GET_CLASS_REQUEST,
  GET_CONNECTED_ACCOUNT_STATUS,
  GET_COUNTRY_STATE,
  GET_CURRENT_USER,
  GET_MAIL_FOR_AGENCY,
  GET_MAIL_FOR_TUTOR,
  GET_NOTIFICATION,
  GET_POST,
  GET_STUDENT_ACTIVITIES,
  GET_STUDENT_DETAIL,
  GET_STUDENT_POST,
  GET_SUBSCRIBED_STUDENTS,
  GET_TUTOR_POST,
  LOGIN_USER,
  REGISTER_USER,
  SET_PASSWORD,
  STUDENTS_SUBSCRIBED_TUTORS,
  STUDENT_PAYMENT_HISTORY,
  SUBSCRIBE_TO_TUTOR,
  TUTOR_ACCEPT_CLASS,
  TUTOR_ACCEPT_CLASS_ALL,
  UPDATE_HANDOUT,
  UPDATE_USER,
  UPLOAD_HANDOUT,
  USER_FILES,
  VALID_SUBDOMAIN,
  VERIFY_OTP,
} from "store/types/user";

export const loginAction = (value) => {
  return {
    type: LOGIN_USER,
    payload: value,
  };
};

export const agencyLoginAction = (value) => {
  return {
    type: AGENCY_LOGIN,
    payload: value,
  };
};

export const agencyRegisterAction = (value) => {
  return {
    type: AGENCY_REGISTER,
    payload: value,
  };
};

export const registerAction = (value) => {
  return {
    type: REGISTER_USER,
    payload: value,
  };
};

export const getAgencyByDomainAction = (value) => {
  return {
    type: GET_AGENCY_BY_DOMAIN,
    payload: value,
  };
};

export const getAgencyTutorsAction = () => {
  return {
    type: AGENCY_TUTORS,
  };
};

export const getSubscribedTutorsAction = () => {
  return {
    type: STUDENTS_SUBSCRIBED_TUTORS,
  };
};

export const getSubscribedStudentsAction = () => {
  return {
    type: GET_SUBSCRIBED_STUDENTS,
  };
};

export const getAgencyStudentsAction = (value) => {
  return {
    type: AGENCY_STUDENTS,
    payload: value,
  };
};

export const createTutotTimeTableAction = (value) => {
  return {
    type: CREATE_TUTOR_TIMETABLE,
    payload: value,
  };
};

export const getCurrentUser = () => {
  return {
    type: GET_CURRENT_USER,
  };
};

export const updateUser = (value) => {
  return {
    type: UPDATE_USER,
    payload: value,
  };
};

export const subscribeToTutor = (value) => {
  return {
    type: SUBSCRIBE_TO_TUTOR,
    payload: value,
  };
};

export const getPosts = (value) => {
  return {
    type: GET_POST,
    payload: value,
  };
};
export const addPostByAgency = (value) => {
  return {
    type: ADD_POST_BY_AGENCY,
    payload: value,
  };
};
export const getTutorPosts = (value) => {
  return {
    type: GET_TUTOR_POST,
    payload: value,
  };
};
export const getStudentPosts = (value) => {
  return {
    type: GET_STUDENT_POST,
    payload: value,
  };
};

export const getClassRequest = (value) => {
  return {
    type: GET_CLASS_REQUEST,
    payload: value,
  };
};

export const getConnectedAccountStatus = () => {
  return {
    type: GET_CONNECTED_ACCOUNT_STATUS,
  };
};
export const forgetPassword = (value) => {
  return {
    type: FORGET_PASSWORD,
    payload: value,
  };
};

export const verifyOtp = (value) => {
  return {
    type: VERIFY_OTP,
    payload: value,
  };
};
export const setPassword = (value) => {
  return {
    type: SET_PASSWORD,
    payload: value,
  };
};
export const changePassword = (value) => {
  return {
    type: CHANGE_PASSWORD,
    payload: value,
  };
};
export const getUserFiles = (value) => {
  return {
    type: USER_FILES,
    payload: value,
  };
};

export const UserFileDelete = (value) => {
  return {
    type: DELETE_USER_FILE,
    payload: value,
  };
};
export const uploadFile = (value) => {
  return {
    type: UPLOAD_HANDOUT,
    payload: value,
  };
};
export const updateFile = (value) => {
  return {
    type: UPDATE_HANDOUT,
    payload: value,
  };
};
export const getNotification = (value) => {
  return {
    type: GET_NOTIFICATION,
    payload: value,
  };
};

export const getAgencyWallet = (value) => {
  return {
    type: GET_AGENCY_WALLET,
    payload: value,
  };
};
export const agencyUpdate = (value) => {
  return {
    type: AGENCY_UPDATE,
    payload: value,
  };
};
export const agencyUpdateDetail = (value) => {
  return {
    type: AGENCY_UPDATE_DETAIL,
    payload: value,
  };
};
export const agencyDelete = (value) => {
  return {
    type: AGENCY_DELETE,
    payload: value,
  };
};

export const agencyAcceptClass = (value) => {
  return {
    type: AGENCY_ACCEPT_CLASS,
    payload: value,
  };
};
export const tutorAcceptClass = (value) => {
  return {
    type: TUTOR_ACCEPT_CLASS,
    payload: value,
  };
};

export const tutorAcceptClassAll = (value) => {
  return {
    type: TUTOR_ACCEPT_CLASS_ALL,
    payload: value,
  };
};
export const cancelSubs = () => {
  return {
    type: CANCEL_SUBSCRIPTION,
  };
};
export const studentPaymentHistory = () => {
  return {
    type: STUDENT_PAYMENT_HISTORY,
  };
};

export const cardPaymentMethod = () => {
  return {
    type: AGENCY_CARD_PAYMENT_METHOD,
  };
};

export const getStudentDetail = (payload) => {
  return {
    type: GET_STUDENT_DETAIL,
    payload,
  };
};
export const getStudentActivities = (payload) => {
  return {
    type: GET_STUDENT_ACTIVITIES,
    payload,
  };
};
export const subdomainCheck = (payload) => {
  return {
    type: VALID_SUBDOMAIN,
    payload,
  };
};
export const giveReview = (payload) => {
  return {
    type: ADD_REVIEW,
    payload,
  };
};

export const getAllCountry = () => {
  return {
    type: GET_ALL_COUNTRIES,
  };
};

export const getAllStateOfCountry = (payload) => {
  console.log("🚀 ~ getAllStateOfCountry ~ payload:", payload);
  return {
    type: GET_COUNTRY_STATE,
    payload,
  };
};
export const getAllCityOfState = (payload) => {
  return {
    type: GET_CITY_STATE,
    payload,
  };
};
export const getAgencyDetail = (payload) => {
  return {
    type: GET_AGENCY_DETAIL,
    payload,
  };
};
export const getMailForAgency = (payload) => {
  return {
    type: GET_MAIL_FOR_AGENCY,
    payload,
  };
};
export const getMailForTutor = (payload) => {
  return {
    type: GET_MAIL_FOR_TUTOR,
    payload,
  };
};
