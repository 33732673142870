import React from "react";
import { BASE_IMG_URL } from "components/shared/constants";

const AgencyLoadingScreen = () => {
  return (
    <div className="flex h-fit w-[90%] flex-col justify-between rounded-md bg-white p-8 shadow-md md:w-[80%] lg:w-[50%]">
      <div className="flex flex-col items-center justify-center gap-6 ">
        <img
          className="animate-spin"
          src={`${BASE_IMG_URL}/img/Loading.svg`}
          alt=""
        />
        <div className="flex justify-items-center text-center text-2xl font-bold text-odinPrimary">
          Please sit back and relax. Your site will be ready shortly.
        </div>
      </div>
    </div>
  );
};

export default AgencyLoadingScreen;
