/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import TimePickerUi from "components/ui/TimePicker";
import { createTutotTimeTableAction, registerAction } from "store/action/user";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { isEmptyObject } from "components/utils/functions";
import { toast } from "react-toastify";

const initialSchedule = {
  monday: [{ startTime: "", endTime: "" }],
  tuesday: [{ startTime: "", endTime: "" }],
  wednesday: [{ startTime: "", endTime: "" }],
  thursday: [{ startTime: "", endTime: "" }],
  friday: [{ startTime: "", endTime: "" }],
  saturday: [{ startTime: "", endTime: "" }],
  sunday: [{ startTime: "", endTime: "" }],
};

const TimeTableSettings = ({ onClose, setFormData, allData }) => {
  const [schedule, setSchedule] = useState(initialSchedule);
  const [selectedDays, setSelectedDays] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const existingSchedule = user?.timetable;

  useEffect(() => {
    if (existingSchedule && !isEmptyObject(existingSchedule)) {
      const formattedSchedule = Object.fromEntries(
        Object.entries(existingSchedule).map(([day, slots]) => [
          day,
          slots.map((slot) => ({
            startTime: dayjs(slot.startTime, "HH:mm"),
            endTime: dayjs(slot.endTime, "HH:mm"),
          })),
        ])
      );
      const mergedSchedule = { ...initialSchedule, ...formattedSchedule };
      setSchedule(mergedSchedule);

      // Set selected days based on existing schedule
      const days = Object.keys(existingSchedule).filter((day) =>
        existingSchedule[day].some((slot) => slot.startTime && slot.endTime)
      );
      setSelectedDays(days);
    }
  }, [existingSchedule]);

  const addSlot = useCallback((day) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: [...prevSchedule[day], { startTime: "", endTime: "" }],
    }));
  }, []);

  const removeSlot = useCallback((day, index) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day].filter((slot, i) => i !== index),
    }));
  }, []);

  const handleStartTimeChange = useCallback((day, index, value) => {
    const startTime = value;
    const endTime = startTime.clone().add(1, "hour");

    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day].map((slot, i) =>
        i === index ? { startTime, endTime } : slot
      ),
    }));
  }, []);

  const handleEndTimeChange = useCallback((day, index, value) => {
    const endTime = value;
    const startTime = endTime.clone().subtract(1, "hour");

    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day].map((slot, i) =>
        i === index ? { startTime, endTime } : slot
      ),
    }));
  }, []);

  const hasEmptySlots = useCallback(
    (day) => {
      return schedule[day].some(
        (slot) => slot.startTime === "" || slot.endTime === ""
      );
    },
    [schedule]
  );

  const handleCheckboxChange = useCallback(
    (day) => {
      if (selectedDays.includes(day)) {
        setSelectedDays(
          selectedDays.filter((selectedDay) => selectedDay !== day)
        );
        // Clear the slots for the unchecked day
        setSchedule((prevSchedule) => ({
          ...prevSchedule,
          [day]: [{ startTime: "", endTime: "" }],
        }));
      } else {
        if (selectedDays.some((selectedDay) => hasEmptySlots(selectedDay))) {
          toast.error(
            "Please fill in all time slots before selecting a new day."
          );
        } else {
          setSelectedDays([...selectedDays, day]);
        }
      }
    },
    [selectedDays, hasEmptySlots]
  );

  const submitHandler = useCallback(() => {
    const nonEmptySchedule = Object.fromEntries(
      Object.entries(schedule).filter(([day, slots]) =>
        slots.some((slot) => slot.startTime !== "" && slot.endTime !== "")
      )
    );

    const formattedSchedule = Object.fromEntries(
      Object.entries(nonEmptySchedule).map(([day, slots]) => [
        day,
        slots.map((slot) => ({
          startTime: slot.startTime.isValid()
            ? slot.startTime.format("HH:mm")
            : "",
          endTime: slot.endTime.isValid() ? slot.endTime.format("HH:mm") : "",
        })),
      ])
    );

    if (isEmptyObject(formattedSchedule)) {
      return toast.error("Please select time slot");
    }

    if (setFormData) {
      const { stepOneData, stepTwoData, stepThreeData } = allData;
      const finalData = {
        ...stepOneData,
        ...stepTwoData,
        ...stepThreeData,
        timetable: formattedSchedule,
      };
      const formDataTemp = new FormData();

      Object.entries(finalData).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          if (Array.isArray(value)) {
            // Filter out empty objects from the array
            const filteredArray = value.filter((item) => !isEmptyObject(item));
            if (filteredArray.length > 0) {
              formDataTemp.append(key, JSON.stringify(filteredArray));
            }
          } else if (typeof value === "object") {
            if (!isEmptyObject(value)) {
              formDataTemp.append(key, JSON.stringify(value));
              if (key === "avatar") {
                formDataTemp.append("file", value?.fileList[0]?.originFileObj);
              }
            }
          } else {
            formDataTemp.append(key, value);
          }
        }
      });

      dispatch(registerAction(formDataTemp));
    } else {
      dispatch(createTutotTimeTableAction(formattedSchedule));
      onClose();
    }
  }, [dispatch, schedule]);

  return (
    <div className="flex flex-col gap-3 font-medium ">
      <div className="text-left text-lg font-bold text-black">
        Time Table Settings
      </div>
      <div className="text-left  font-bold text-black">1 hour Slot</div>
      <div className="my-5 w-full border-t-2 border-ternary"></div>
      {Object.keys(schedule).map((day) => (
        <div
          key={day}
          className="flex gap-3 border-b border-[#EEE] pb-4 text-xs"
        >
          <input
            type="checkbox"
            checked={selectedDays.includes(day)}
            onChange={() => handleCheckboxChange(day)}
            className="size-4 text-black"
          />
          <div className="flex w-full justify-between">
            <div className="text-base text-secondary">
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </div>
            <div>
              {!selectedDays.includes(day) ? (
                <span className="text-[#FF5858]"> Unavailable</span>
              ) : (
                <div className="flex flex-col gap-2">
                  {schedule[day].map((slot, index) => {
                    const prevEndTime =
                      index > 0 ? schedule[day][index - 1].endTime : null;
                    const disabled = prevEndTime
                      ? {
                          disabledHours: () =>
                            [
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                              15, 16, 17, 18, 19, 20, 21, 22, 23,
                            ].slice(0, dayjs(prevEndTime).hour()),
                          disabledMinutes: () =>
                            [
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                              15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                              39, 40, 41, 42, 43, 44, 45, 46, 47,
                            ].slice(0, dayjs(prevEndTime).minute()),
                        }
                      : null;
                    return (
                      <div className="flex items-center gap-2" key={index}>
                        <TimePickerUi
                          value={slot.startTime}
                          onChange={(value) =>
                            handleStartTimeChange(day, index, value)
                          }
                          disabled={disabled}
                        />
                        <div>-</div>
                        <TimePickerUi
                          value={slot.endTime}
                          onChange={(value) =>
                            handleEndTimeChange(day, index, value)
                          }
                          disabled={disabled}
                        />

                        {index !== 0 ? (
                          <img
                            src={`${BASE_IMG_URL}/img/deletelogo.svg`}
                            alt=""
                            onClick={() => removeSlot(day, index)}
                            className="ml-2 cursor-pointer"
                          />
                        ) : (
                          <img
                            src={`${BASE_IMG_URL}/img/plusblack.svg`}
                            alt=""
                            onClick={() => addSlot(day)}
                            className={`cursor-pointer ${
                              index !== 0 ? "ml-2" : "ml-2"
                            }`}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="my-5 w-full "></div>
      <div className="flex  justify-end ">
        <div className="flex items-center justify-center gap-2 ">
          {!allData && (
            <div
              className="cursor-pointer rounded-lg border-2 border-secondary px-6 py-2 font-semibold text-black"
              onClick={onClose}
            >
              Cancel
            </div>
          )}
          <div
            className="cursor-pointer rounded-lg border-2 border-black bg-black px-6 py-2 font-semibold text-white"
            onClick={submitHandler}
          >
            {setFormData ? "Save" : " Update"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTableSettings;
