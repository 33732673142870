/* eslint-disable react/prop-types */
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";

const HeaderRightArea = ({ setShowSidebar, showSidebar }) => {
  return (
    <div className="flex items-center gap-2 text-white">
      <div className="flex size-10 items-center justify-center rounded-[50%] border-4 border-lightGrey">
        <div className="text-xs font-bold">AS</div>
      </div>
      <GiHamburgerMenu
        className="block lg:hidden"
        onClick={() => setShowSidebar(!showSidebar)}
      />
    </div>
  );
};

export default HeaderRightArea;
