import React, { useState } from "react";
import { FaChevronDown, FaArrowLeft } from "react-icons/fa";
import DropDownUi from ".";
import PropTypes from "prop-types";

const FilterDropdown = ({
  filterOptions,
  onApplyFilter,
  onResetFilter,
  placeholder,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter);
    setDropdownVisible(false);
  };

  const handleFilterValueChange = (e) => {
    setFilterValue(e.target.value);
  };

  const applyFilter = () => {
    onApplyFilter(selectedFilter, filterValue);
    setDropdownVisible(false);
  };

  const resetFilter = () => {
    setSelectedFilter("");
    setFilterValue("");
    onResetFilter();
    setDropdownVisible(false);
  };
  const handleBack = () => {
    setSelectedFilter("");
    setFilterValue("");
  };
  return (
    <DropDownUi
      arrow={false}
      placement="bottomCenter"
      visible={dropdownVisible}
      onVisibleChange={setDropdownVisible}
      item={
        <div className="flex w-20 items-center justify-between">
          <div>Filter</div>
          <FaChevronDown size={15} color="#000" />
        </div>
      }
      dropdownRender={
        <div className="flex min-w-[12rem] flex-col gap-3 rounded-lg bg-white p-4">
          {!selectedFilter ? (
            filterOptions.map((option) => (
              <div
                key={option.value}
                className="cursor-pointer"
                onClick={() => handleFilterSelection(option.value)}
              >
                {option.label}
              </div>
            ))
          ) : (
            <>
              <div className="flex items-center gap-3">
                <FaArrowLeft className="cursor-pointer" onClick={handleBack} />
                <div>
                  {
                    filterOptions.find(
                      (option) => option.value === selectedFilter
                    )?.label
                  }
                </div>{" "}
              </div>
              <input
                type="text"
                className="mt-2 rounded-md border p-2"
                placeholder={placeholder || `Enter ${selectedFilter}`}
                value={filterValue}
                onChange={handleFilterValueChange}
              />
              <div className="mt-2 flex justify-end gap-2 text-xs font-semibold">
                <div
                  className="cursor-pointer text-secondary hover:text-black"
                  onClick={resetFilter}
                >
                  Cancel
                </div>
                <div
                  className="cursor-pointer text-secondary hover:text-black"
                  onClick={applyFilter}
                >
                  Apply
                </div>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};
FilterDropdown.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
export default FilterDropdown;
