/* eslint-disable no-debugger */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import { RiEditLine } from "react-icons/ri";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { MdArrowBackIos } from "react-icons/md";
import socket from "store/services/socketConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Popconfirm } from "antd";
import { MoreOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import API from "store/services/base.service";
import { pathnameHandler } from "components/utils/functions";
import { useSelector } from "react-redux";
import AvatarImage from "components/shared/AgencyLayout/designs/Avatar";
import { deleteChatListSlice } from "store/slices/users";

const ChatScreen = ({
  data,
  backHandler,
  messages,
  dispatch,
  updateChatListSlice,
}) => {
  const defaultRole = "agency";
  const scroll = useRef();

  const navigate = useNavigate();
  const role = sessionStorage.getItem("odin-auth-role") || defaultRole;
  const user = useSelector((state) => state.users.user);
  const params = useParams();

  const [message, setMessage] = useState("");

  const handleDeleteHandler = () => {
    API.delete(`/chat/${params.id}`)
      .then((res) => {
        dispatch(deleteChatListSlice(params));
        navigate(`${pathnameHandler()}/chats`);
      })
      .catch((err) => console.log(err));
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim() === "") {
      return;
    }

    socket.emit("send_message", {
      room: params?.id,
      userId: user?.userId || user?.id,
      role: role || defaultRole,
      message,
      createdAt: new Date().toISOString(),
    });
    setMessage("");
    dispatch(
      updateChatListSlice({
        room: params?.id,
        userId: user?.userId || user?.id,
        role: role || defaultRole,
        message,
        createdAt: new Date().toISOString(),
      })
    );
  };

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="relative flex h-[85vh] flex-col overflow-hidden rounded-xl bg-white">
      <div className="sticky top-0 z-10 flex w-full items-center justify-between border-b-2 p-4  pb-3">
        <div className="flex items-center gap-3">
          <MdArrowBackIos onClick={backHandler} className="block lg:hidden" />
          <div className="relative">
            <AvatarImage
              avatar={data?.avatar}
              alt={data?.username}
              className="size-14 rounded-full "
            />
            {data?.online && (
              <div className="absolute bottom-0 right-0 size-3 rounded-[50%] border-2 border-white bg-lime-500" />
            )}
          </div>
          <div>
            <div className="font-semibold ">{data?.username}</div>
            <div className="text-sm text-secondary">Active Now</div>
          </div>
        </div>
        <div className="flex justify-end">
          <Popconfirm
            title="Delete the Conversation"
            description="Are you sure to delete this Conversation?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            onConfirm={handleDeleteHandler}
          >
            <Button icon={<MoreOutlined />} />
          </Popconfirm>
        </div>
      </div>
      <div className="flex flex-1 flex-col overflow-auto px-4">
        {messages.map((item, idx) => (
          <div key={idx} ref={scroll}>
            {item.role !== role ? (
              <div key={idx} className="ml-2 mt-2">
                <div className="my-1 flex items-center gap-2">
                  {item.message && (
                    <>
                      <AvatarImage avatar={data?.avatar} alt={data?.username} />
                      <div className="w-fit max-w-[60%]  rounded-lg bg-[#000] p-2 text-white">
                        {item.message}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              item.message && (
                <div className="ml-auto mr-2 mt-2 w-fit max-w-[60%] rounded-lg bg-lightGrey p-2 text-secondary">
                  {item.message}
                </div>
              )
            )}
          </div>
        ))}
      </div>
      <form
        className="sticky bottom-0 z-10 flex items-center gap-2 border-t-2 bg-white p-4 pt-3"
        onSubmit={sendMessage}
      >
        <div className="flex flex-1 items-center gap-2 rounded-lg border-2 bg-white p-2">
          <RiEditLine color="#000" size={25} />
          <input
            type="text"
            placeholder="Type your message"
            className="w-full border-none outline-none"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="flex items-center gap-2 rounded-lg border-2 border-black bg-black p-2 px-3 text-white"
        >
          <div>Send</div>
          <PiPaperPlaneRightFill color="#fff" />
        </button>
      </form>
    </div>
  );
};

export default ChatScreen;
