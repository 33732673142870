import React from 'react'

const Payments = () => {
  return (
    <div><div className="flex items-end">
    <div className="border-b-2 border-odinPrimary text-2xl font-bold text-odinPrimary ">
    Payments Settings
    </div>
    <div className="flex-1 border-b-2"></div>
  </div></div>
  )
}

export default Payments