/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

import { useDispatch, useSelector } from "react-redux";
import Image from "components/shared/Image";
import { subdomainCheck } from "store/action/user";
import InputTextField from "components/ui/InputTextField";
import { BASE_IMG_URL } from "components/shared/constants";
import { Spin } from "antd";

const AgencyDetails = ({ handleAgencyForm, data }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const checkDomainValid = useSelector((state) => state.users.checkDomainValid);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const [formData, setFormData] = useState({
    agencyName: data?.agencyName || "",
    agencyDomain: data?.agencyDomain || "",
    password: data?.password || "",
    agencyTagline: data?.agencyTagline || "",
    email: data?.email || "",
    backgroundColor: data?.backgroundColor || "#828282",
    textColor: data?.textColor || "#fff",
    subscriptionType: "",
    file: "",
  });

  const handleImageUpload = (e) => {
    console.log("first");
    const uploadedFile = e?.target?.files[0];
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
        setFormData({ ...formData, file: uploadedFile });
        setFile(uploadedFile);
        sessionStorage.setItem("uploadedFile", reader.result);
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const handlePlusIconClick = () => {
    fileInputRef.current.click(); // Trigger a click on the file input
  };
  const handleClearImage = () => {
    setImageUrl(null);
    setFile(null);
    document.getElementById("image-input").value = "";
  };
  const debouncedSubdomainCheck = useCallback(
    debounce((value) => {
      dispatch(subdomainCheck({ agencyDomain: value }));
    }, 300), // 300ms delay
    []
  );
  const handleInputChange = (field, value) => {
    if (field === "agencyDomain") {
      debouncedSubdomainCheck(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkDomainValid) {
      toast.error("Please enter a valid subdomain.");
      return;
    }
    handleAgencyForm({
      ...formData,
      file,
      agencyDomain: `${formData.agencyDomain}.odin.onl`,
    });
  };

  return (
    <div className="flex h-fit w-[90%] flex-col justify-between rounded-md bg-white p-8 shadow-md md:w-4/5 lg:w-[50%]">
      <form className="flex flex-col gap-3" onSubmit={(e) => handleSubmit(e)}>
        <div className="text-center text-2xl font-bold text-odinPrimary">
          Agency Registration Form
        </div>
        <div className="flex flex-col items-center">
          <div className="flex justify-center p-2">
            <div className="relative size-24 rounded-[50%] bg-ternary">
              <input
                type="file"
                accept="image/*"
                id="image-input"
                className="absolute inset-0 size-full opacity-0"
                onChange={handleImageUpload}
                required
                ref={fileInputRef}
              />
              {imageUrl ? (
                <>
                  <Image
                    src={imageUrl}
                    alt="Uploaded"
                    className="absolute inset-0 size-24 rounded-[50%] object-cover object-top"
                  />
                  <FaTimesCircle
                    className="absolute bottom-0 right-0 cursor-pointer rounded-[50%] border-2 border-white bg-white text-odinPrimary"
                    size={30}
                    onClick={handleClearImage}
                  />
                </>
              ) : (
                <FaPlusCircle
                  className="absolute bottom-0 right-0 cursor-pointer rounded-[50%] border-2 border-white bg-white text-odinPrimary"
                  size={30}
                  id="image-input"
                  onClick={handlePlusIconClick}
                />
              )}
            </div>
          </div>
          <div className="text-sm font-medium text-odinPrimary">
            Upload Agency Logo
          </div>
        </div>
        <div>
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            placeholder="Agency Name"
            value={formData.agencyName}
            onChange={(value) => handleInputChange("agencyName", value)}
            required
          />
          <div className="flex gap-2">
            <div className="text-xl text-[#F15D5D]">*</div>
            <div className="text-sm text-secondary">Choose a site name</div>
          </div>

          <div className="flex items-center rounded-xl border-2 border-secondary">
            <div className="hidden w-[10%] items-center justify-center rounded-l-lg bg-[#F0F0F0] py-4 md:flex">
              <Image
                src={`${BASE_IMG_URL}/img/check-icon.svg`}
                alt=""
                className="size-6 rounded-full"
              />
            </div>
            <InputTextField
              className="w-[69%] rounded-xl border-none p-4 outline-none md:w-[70%] md:rounded-none"
              type="text"
              placeholder="Sub Domain"
              value={formData.agencyDomain}
              onChange={(value) =>
                handleInputChange("agencyDomain", value.toLowerCase())
              }
              required
            />
            <div className="w-2/5 rounded-r-lg bg-[#F0F0F0] p-4 text-center md:w-1/5 md:px-8">
              .odin.onl
            </div>
          </div>
          {!checkDomainValid?.data && (
            <div className="mt-2 text-sm text-red-500">
              {checkDomainValid?.error}
            </div>
          )}
          {checkDomainValid?.data && (
            <div className="flex items-center rounded-xl border-2 border-secondary bg-[#36A64F]">
              <div className=" p-2 px-8 text-white">
                Your site name is available.
              </div>
            </div>
          )}

          <div className="px-2 py-4 font-normal text-secondary">
            Your Odin URL will be https://XYZ.odin.onl
          </div>
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            placeholder="Agency Tagline"
            value={formData.agencyTagline}
            onChange={(value) => handleInputChange("agencyTagline", value)}
            required
          />
          <InputTextField
            className="mb-3 border-2 py-3"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(value) => handleInputChange("email", value)}
            required
          />
          <InputTextField
            className="mb-3 border-2 py-3"
            type="password"
            placeholder="Set Password"
            value={formData.password}
            onChange={(value) => handleInputChange("password", value)}
            required
          />
        </div>
        <div className="p-4 font-bold">Agency Theme</div>
        <div className="flex w-full flex-col justify-center gap-6 md:flex-row ">
          <InputTextField
            className="mb-3 border-2 py-3"
            type="colorPicker"
            placeholder="Background Color"
            value={formData.backgroundColor}
            onChange={(value) => handleInputChange("backgroundColor", value)}
            required
          />
          <InputTextField
            className="mb-3 border-2 py-3"
            type="colorPicker"
            placeholder="Text Color"
            value={formData.textColor}
            onChange={(value) => handleInputChange("textColor", value)}
            required
          />
        </div>
        <div className="flex items-center justify-between text-odinPrimary">
          <Link to="/" className="flex gap-2 p-4 font-bold">
            <img src={`${BASE_IMG_URL}/img/arrow-left.svg`} alt="" />
            <div>Back</div>
          </Link>
          <button
            type="submit"
            className="flex cursor-pointer items-center gap-2 border-none font-bold outline-none"
          >
            {isLoading && <Spin />}
            <div>Subscription</div>
            <img
              src={`${BASE_IMG_URL}/img/arrow-left.svg`}
              alt=""
              className="-scale-x-100"
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default AgencyDetails;
