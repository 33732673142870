import React from "react";
import { BASE_IMG_URL } from "components/shared/constants";

const LogoArea = () => {
  return (
    <div className="ml-32 flex h-[70px] items-center">
      <img src={`${BASE_IMG_URL}/img/logo.svg`} alt="" />
    </div>
  );
};

export default LogoArea;
