/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";

const TutorCard = ({ heading, data, noItem, viewAll }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const handleSearchIconClick = () => {
    setShowSearch(!showSearch);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data?.filter((item) =>
    (item?.username || `${item?.User?.firstName} ${item?.User?.lastName}`)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  return (
    <div className="flex h-fit w-full flex-col rounded-lg bg-white p-4">
      <div className="mb-2 flex justify-between ">
        <div className="flex w-full items-end font-semibold">
          <div className="border-b-2 border-secondary">{heading}</div>
          <div className="flex-1 border-b-2 "></div>
        </div>
        <div className="flex gap-2">
          <FiSearch
            className="cursor-pointer"
            onClick={handleSearchIconClick}
          />
        </div>
      </div>
      {showSearch && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full rounded border px-2 py-1"
          />
        </div>
      )}
      <div className="flex  flex-1 flex-col justify-center gap-3 overflow-auto pt-4 text-center text-sm text-secondary">
        {filteredData?.length > 0 ? (
          filteredData?.map((item, idx) => (
            <div
              key={idx}
              className="flex items-center justify-between gap-2 pr-1"
            >
              <div className="flex items-center gap-2">
                {item?.id ? (
                  <Link to={`/tutor/student/${item?.id}`}>
                    <Avatar
                      avatar={item?.avatar || item?.url || ""}
                      alt={item?.username || data?.user?.username}
                    />
                  </Link>
                ) : (
                  <Link to={`/tutor/student/${item?.studentId}`}>
                    <Avatar
                      avatar={item?.avatar || item?.url || ""}
                      alt={item?.username || data?.user?.username}
                    />
                  </Link>
                )}
                <div className="text-start">
                  <div className="text-sm font-semibold text-black">
                    {item?.username || data?.user?.username}
                  </div>
                  <div className="text-xs">
                    Grade:{" "}
                    <span className="font-semibold">
                      {item?.grade || data?.studentGrade}
                    </span>
                  </div>
                </div>
              </div>
              {(item?.id || filteredData?.StudentId) && (
                <Link
                  to={
                    `/tutor/student/${item?.id}` ||
                    `/tutor/student/${data?.StudentId}`
                  }
                  className="rounded-lg bg-black px-2 py-1 text-xs text-white"
                >
                  View Profile
                </Link>
              )}
            </div>
          ))
        ) : (
          <>{noItem}</>
        )}
      </div>
      {viewAll && (
        <Link
          to={viewAll}
          className="mt-2 w-full cursor-pointer rounded-lg bg-black py-1 text-center text-sm text-white"
        >
          View All
        </Link>
      )}
    </div>
  );
};

export default TutorCard;
