/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */

export const generateTimeSlots = () => {
  const startTime = 9;
  const endTime = 17;
  const timeSlots = [];

  for (let hour = startTime; hour <= endTime; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      // Convert 24-hour format to 12-hour format
      const displayHour = hour > 12 ? hour - 12 : hour;
      const period = hour >= 12 ? "PM" : "AM";

      const time = `${displayHour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")} ${period}`;

      timeSlots.push(time);
    }
  }
  return timeSlots;
};

export const checkAuthentication = (
  navigate,
  tempSubdomain,
  setAuthenticated
) => {
  const tokenString = sessionStorage.getItem("odin-auth-token");
  const role = sessionStorage.getItem("odin-auth-role");

  if (tokenString) {
    if (tempSubdomain) {
      if (role) {
        setAuthenticated(true);
        if (window.location.pathname === "/") {
          navigate(`${role}/dashboard`);
        }
      } else {
        setAuthenticated(false);
      }
    } else {
      setAuthenticated(true);
      if (window.location.pathname === "/") {
        navigate("/dashboard");
      }
      if (window.location.pathname === "/") {
        navigate("/admin/dashboard");
      }
    }
  } else {
    setAuthenticated(false);
  }
};

export const pathnameHandler = () => {
  const pathname = window.location.pathname;
  if (pathname.includes("/agency/")) {
    return "/agency";
  } else if (pathname.includes("/tutor/")) {
    return "/tutor";
  } else if (pathname.includes("/student/")) {
    return "/student";
  } else if (pathname.includes("/admin/")) {
    return "/admin";
  }
};

export function capitalizeFirstWord(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toObject(str) {
  if (typeof str === "string" && str !== "" && str !== "[]") {
    try {
      return JSON.parse(str);
    } catch (e) {
      console.error("Failed to parse string to JSON:", e);
      return [];
    }
  } else if (typeof str === "object" && Array.isArray(str)) {
    return str;
  } else {
    return [];
  }
}

export function createRoom(studentId, TutorId) {
  return `${studentId}&${TutorId}`;
}

export const getSubdomain = () => {
  const hostname = window.location.hostname;

  if (!hostname) return null;
  const parts = hostname.split(".");

  // Check if the hostname has a subdomain part
  if (parts.length > 1) {
    if (parts[0] === "www" || parts[0] === "portal") {
      return null;
    }
    return parts[0];
  }

  return null;
};

export function getGmt() {
  const d = new Date();
  const gmt = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
  return gmt;
}

export function currentWeek() {
  const datesArray = [];
  const currentDate = new Date();

  for (let i = 6; i >= 0; i--) {
    const date = new Date(currentDate);
    date.setDate(currentDate.getDate() - i);
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    datesArray.push(formattedDate);
  }

  return datesArray;
}

export const validateEmail = (email) => {
  if (email === "") {
    return "Email is required";
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return "Invalid email format";
  }

  return null;
};

export const validatePassword = (password) => {
  if (password === "") {
    return "Password is required";
  }

  if (password.length < 8) {
    return "Password must be at least 8 characters long";
  }

  return null; // Return null if validation passes
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isValidImageUrl = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const setupUserFromInvitation = (
  locationSearch,
  handleChange,
  getSubdomain,
  defaultRole = "tutor"
) => {
  const searchParams = new URLSearchParams(locationSearch);
  const invitation = searchParams.get("invitation");
  if (invitation) {
    const username = invitation.split("@")[0];
    handleChange("username", username);
    handleChange("email", invitation);
    handleChange("role", defaultRole);
    handleChange("agencyDomain", `${getSubdomain()}.odin.onl`);
  }
};
export const setupStudentFromInvitation = (locationSearch, handleChange) => {
  const searchParams = new URLSearchParams(locationSearch);
  const invitation = searchParams.get("invitation");
  if (invitation) {
    const username = invitation.split("@")[0];
    handleChange("username", username);
    handleChange("email", invitation);
  }
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

export const isValidSubdomain = (subdomain) => {
  // Regex to check if subdomain is valid
  const regex = /^[a-zA-Z]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;
  // Check length constraint
  if (subdomain.length < 1 || subdomain.length > 63) {
    return false;
  }
  // Check consecutive hyphens
  if (subdomain.includes("--")) {
    return false;
  }
  // Check regex
  return regex.test(subdomain);
};

export const displaySubject = (subject) => {
  if (typeof subject === "string") {
    return toObject(subject);
  } else if (Array.isArray(subject)) {
    if (subject.length > 1) {
      return (
        <span title={subject.join(" / ")}>
          {subject.slice(0, 2).join(" / ")} / ...
        </span>
      );
    } else {
      return subject[0];
    }
  } else {
    return null;
  }
};

export const AGENCY_FORGOT_PASSWORD_URL = "/agency/forget-password";
export const FORGOT_PASSWORD_URL = "/forget-password";

export const handleDownload = async (idx, value) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = value.url;
  if (value.url.includes("image")) {
    downloadLink.target = "_blank";
  }
  downloadLink.setAttribute("download", value.filename); // Suggest a filename for the download
  document.body.appendChild(downloadLink); // Append to the body to make it work in Firefox
  downloadLink.click();
  document.body.removeChild(downloadLink); // Remove the link after clicking
};
