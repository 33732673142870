/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  format,
  add,
  startOfWeek,
  endOfWeek,
  isBefore,
  isSameWeek,
} from "date-fns";
import { toast } from "react-toastify";
import { Select, message } from "antd";

const Subscribed = ({
  bookedSlots,
  data,
  chargesPerHour,
  update,
  selectedEvent,
}) => {
  const [subject, setSubject] = useState(selectedEvent?.title ?? "");
  const [selectedDay, setSelectedDay] = useState({ index: -1, day: "" });
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentWeekStart, setCurrentWeekStart] = useState(
    startOfWeek(new Date())
  );
  const currentWeekEnd = endOfWeek(currentWeekStart);
  const weekRange = `${format(currentWeekStart, "d")} - ${format(
    currentWeekEnd,
    "d MMM"
  )}`;

  const [classes, setClasses] = useState([]);

  useEffect(() => {
    if (data?.timetable) {
      let timetable = data.timetable;

      if (typeof timetable === "string") {
        try {
          timetable = JSON.parse(timetable);
        } catch (error) {
          console.error("Error parsing timetable JSON:", error);
          timetable = {};
        }
      }

      if (typeof timetable === "object" && Object.keys(timetable).length > 0) {
        const daysOfWeek = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        const output = [];

        let dayIndex = -1;

        for (const day of daysOfWeek) {
          dayIndex++;

          const slots = data?.timetable[day];

          if (
            !slots ||
            (slots.length === 1 &&
              slots[0].startTime === "" &&
              slots[0].endTime === "")
          ) {
            continue;
          }

          const formattedSlots = slots.map((slot) => ({
            start: slot.startTime,
            end: slot.endTime,
          }));

          output.push({
            day: day.charAt(0).toUpperCase() + day.slice(1),
            dayIndex,
            slots: formattedSlots,
          });
        }

        setClasses(output);
      }
    } else {
      setClasses([]);

      console.log("No valid timetable data available");
    }
  }, [data, currentWeekStart]);

  const toggleDay = (index, day) => {
    if (selectedDay.index === index) {
      setSelectedDay({ index: -1, day: "" });
      setSelectedSlots([]); // Reset selected slots when a day is deselected
    } else {
      setSelectedDay({ index, day });
      setSelectedSlots([]); // Reset selected slots when a day is selected
    }
  };

  const toggleSlot = (day, slot, dayIndex) => {
    const dayNumber = format(
      add(currentWeekStart, { days: dayIndex }),
      "dd MM yyyy"
    );
    const [days, months, years] = dayNumber.split(" ").map(Number);
    const utcDate = new Date(years, months - 1, days);
    const dateInLocal = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    const date = dateInLocal.toISOString().slice(0, 10);

    setSelectedSlots((prevSelectedSlots) => {
      const hasDay = prevSelectedSlots?.some(
        (selectedDay) => selectedDay?.day === day
      );
      if (hasDay) {
        const updatedSelectedSlots = prevSelectedSlots.map((selectedDay) => {
          if (selectedDay?.day === day) {
            if (
              selectedDay.slots?.some(
                (item) => item.start === slot.start && item.end === slot.end
              )
            ) {
              const updatedSlots = selectedDay.slots.filter(
                (item) => !(item.start === slot.start && item.end === slot.end)
              );
              return {
                ...selectedDay,
                slots: updatedSlots,
              };
            } else {
              if (update) {
                toast.error("Only one slot allowed to update at a time");
                return selectedDay;
              } else {
                return {
                  ...selectedDay,
                  slots: [...selectedDay.slots, slot],
                };
              }
            }
          }
          return selectedDay;
        });
        return updatedSelectedSlots.filter(
          (selectedDay) => selectedDay?.slots?.length !== 0
        );
      } else {
        if (prevSelectedSlots?.length > 0 && update) {
          toast.error("Only one slot allowed to update at a time");
          return prevSelectedSlots;
        } else {
          return [
            ...prevSelectedSlots,
            {
              day: day?.toLowerCase(),
              date,
              slots: [slot],
            },
          ];
        }
      }
    });
  };

  const handlePayClick = () => {
    if (!subject || !total) {
      return message.error("Please select a subject or slot.");
    }
    bookedSlots(selectedSlots, chargesPerHour, subject);
    setSelectedSlots([]);
  };

  const isCurrentWeek = isSameWeek(currentWeekStart, new Date(), {
    weekStartsOn: 1,
  });

  useEffect(() => {
    let totalSlots = 0;
    selectedSlots?.forEach((day) => {
      totalSlots += day?.slots?.length;
    });
    setTotal(totalSlots);
  }, [selectedSlots]);

  const nextWeek = () => {
    setCurrentWeekStart(add(currentWeekStart, { weeks: 1 }));
  };

  const previousWeek = () => {
    const newWeekStart = add(currentWeekStart, { weeks: -1 });
    const todayStartOfWeek = startOfWeek(new Date());

    // Check if the new week start date is before the current week's start date
    if (!isBefore(newWeekStart, todayStartOfWeek)) {
      setCurrentWeekStart(newWeekStart);
    } else {
      // Optionally, you can display a message or simply do nothing
      console.log("Cannot go back to previous weeks before the current week");
    }
  };

  const { Option } = Select;

  return (
    <div className="z-[10000000000]">
      <div className="text-lg font-bold">
        {update ? "Reschedule the Class" : "Subscribed to Tutor"}
      </div>
      <div className="my-10">
        <div>Select Subject</div>
        <Select
          value={subject}
          onChange={(e) => setSubject(e)}
          className="mb-2 w-full"
        >
          {data?.subject?.map((item, i) => (
            <Option value={item} key={i}>
              {item}
            </Option>
          ))}
        </Select>
        <div className="flex justify-between">
          <div className="border-b-2">Available Slots</div>
          <div className="flex gap-2">
            <div className="rounded-lg border-2 p-2">{weekRange}</div>
            <button
              className="rounded-lg border-2 p-2"
              onClick={previousWeek}
              disabled={isCurrentWeek}
            >
              Prev
            </button>
            <button className="rounded-lg border-2 p-2" onClick={nextWeek}>
              Next
            </button>
          </div>
        </div>
        <div className="my-10 flex flex-col gap-4">
          {classes?.length > 0 &&
            classes?.map((classItem, idx) => {
              const dayNumber = format(
                add(currentWeekStart, { days: classItem.dayIndex }),
                "dd MM yyyy"
              );
              const [days, months, years] = dayNumber.split(" ").map(Number);
              const utcDate = new Date(years, months - 1, days).setHours(
                0,
                0,
                0,
                0
              );
              const isPast = utcDate < new Date().setHours(0, 0, 0, 0);

              return (
                <>
                  <div key={idx} className="flex gap-2 ">
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={selectedDay.index === idx}
                        onChange={() =>
                          !isPast && toggleDay(idx, classItem.day.toLowerCase())
                        }
                        disabled={isPast} // Disable checkbox if the day is in the past
                      />

                      <div className="w-48 rounded-lg border-2 p-2 text-center">
                        {`${classItem.day} - ${format(
                          add(currentWeekStart, { days: classItem.dayIndex }),
                          "dd/MM"
                        )}`}
                      </div>
                    </div>
                    {selectedDay.index === idx && !isPast && (
                      <div className="ml-8 flex flex-wrap gap-2">
                        {classItem.slots.map((slot, slotIdx) => (
                          <div
                            key={slotIdx}
                            className={`w-24 cursor-pointer rounded-lg border-2 p-2 text-center ${
                              selectedSlots
                                ?.find((item) => item?.day === selectedDay?.day)
                                ?.slots?.some(
                                  (item) =>
                                    item?.start === slot?.start &&
                                    item?.end === slot?.end
                                )
                                ? "bg-primary text-white"
                                : ""
                            }`}
                            onClick={() =>
                              toggleSlot(
                                classItem.day.toLowerCase(),
                                slot,
                                classItem.dayIndex
                              )
                            }
                          >
                            {slot.start}-{slot.end}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="border-b-2" />
                </>
              );
            })}
          {classes?.length === 0 && (
            <div className="text-xl text-gray-600">No Time Slot available</div>
          )}
        </div>
      </div>
      {update ? (
        <div className="py-10"></div>
      ) : (
        <div className="flex justify-between py-10">
          <div>
            <div className="text-xs">Credit Hours</div>
            <div className="font-bold">Total Payment</div>
          </div>
          <div className="font-bold">
            <div className="text-xs">{total} hours / Per Month</div>
            <div className="text-lg">$ {total * chargesPerHour || 0}</div>
          </div>
        </div>
      )}
      <button
        className="w-full rounded-lg bg-black py-2 text-center text-white"
        onClick={handlePayClick}
      >
        Book Your Slot
      </button>
    </div>
  );
};

export default Subscribed;
