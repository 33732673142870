import StudentSignIn from "components/modules/StudentSignIn";
import React from "react";
import { useParams } from 'react-router-dom';

const CustomStudentSignInPage = () => {
  const {customAgency} = useParams();
  return <StudentSignIn customAgency={customAgency}/>;
};

export default CustomStudentSignInPage;
