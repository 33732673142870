import {
  // filterHandoutOptions,
  handTableColumn,
} from "components/shared/constants/agencyConstants";
import InputTextField from "components/ui/InputTextField";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import TableUi from "components/ui/Table";
import { useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import { handleDownload } from "components/utils/functions";

const actionOptions = [
  {
    name: "Download",
    icon: <DownloadOutlined size={25} />,
  },
];
const Handouts = () => {
  const [data, setData] = useState();
  const [searchTerm, setSearchTerm] = useState();

  const files = useSelector((state) => state.users.files);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  useEffect(() => {
    if (files) {
      if (searchTerm !== "") {
        const filtered = files.filter((file) =>
          file?.filename?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setData(filtered);
      } else {
        setData(files);
      }
    }
  }, [searchTerm, files]);

  useEffect(() => {
    setData(files);
  }, [files]);

  return (
    <div className="flex h-full flex-col justify-between gap-4">
      <div className="flex items-center justify-between  gap-4">
        <div className="flex h-10 flex-1 items-center gap-2 rounded-xl bg-white">
          <InputTextField
            icon={<FaSearch />}
            type="text"
            onChange={(val) => setSearchTerm(val)}
            placeholder="Search"
          />
        </div>
      </div>

      <div className="flex flex-1 gap-4 rounded-lg bg-white p-4">
        <div className="size-full">
          <TableUi
            headerBg="#303030"
            data={data}
            headerColor="#fff"
            rowSelection
            columns={handTableColumn}
            pageSize={6}
            loading={isLoading}
            actionOptions={actionOptions}
            download
            downloadRow={handleDownload}
          />
        </div>
      </div>
    </div>
  );
};

export default Handouts;
