/* eslint-disable no-undef */
import AgencyLayout from "components/shared/AgencyLayout";
import React, { useEffect, useState } from "react";
import ChatLists from "./design/ChatLists";
import ChatScreen from "./design/ChatScreen";
import { useParams } from "react-router-dom";
import ComposeScreen from "./design/Compose";
import socket from "store/services/socketConfig";
// import API from "store/services/base.service";
import {
  agencySidebar,
  studentSidebar,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import { useDispatch, useSelector } from "react-redux";
import { getChatList } from "store/action/super";
import { setNotificationList, updateChatListSlice } from "store/slices/users";
import API from "store/services/base.service";

const ParticularChat = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showChat, setShowChat] = useState(false);
  const [showCompose, setShowCompose] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [messages, setMessages] = useState([]);
  const ChatList = useSelector((state) => state.users.chatList);
  const user = useSelector((state) => state.users.user);
  const role = sessionStorage.getItem("odin-auth-role");
  const [currentRoom, setCurrentRoom] = useState(null);

  useEffect(() => {
    if (user) {
      dispatch(getChatList(user));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (currentRoom) {
      socket.emit("leave_room", { room: currentRoom });
    }

    setCurrentRoom(params?.id);
    socket.emit("join_room", { room: params?.id });
    socket.on("chat_history", (data) => {
      if (typeof data === "string") {
        setMessages(JSON.parse(data));
        const newUser = ChatList.find((chat) => chat.roomId === params?.id);
        if (newUser) {
          setFilteredData(newUser);
        }
      }
    });
    socket.on("receive_message", (data) => {
      setMessages((list) => [...list, data]);
      dispatch(updateChatListSlice(data));
    });
    return () => {
      socket.off("chat_history");
      socket.off("receive_message");
    };
  }, [params?.id, currentRoom, ChatList, dispatch]);

  // useEffect(() => {
  //   if (currentRoom) {
  //     socket.emit("leave_room", { room: currentRoom });
  //   }

  //   setCurrentRoom(params?.id);
  //   setMessages([]);
  //   socket.emit("join_room", { room: params?.id });
  //   socket.on("chat_history", (data) => {
  //     if (typeof data === "string") {
  //       setMessages(JSON.parse(data));
  //       const newUser = ChatList.find((chat) => chat.roomId === params?.id);
  //       if (newUser) {
  //         setFilteredData(newUser);
  //       }
  //     }
  //   });
  //   socket.on("receive_message", (data) => {
  //     setMessages((list) => [...list, data]);
  //     dispatch(updateChatListSlice(data));
  //   });
  //   return () => {
  //     socket.off("chat_history");
  //     socket.off("receive_message");
  //   };
  // }, [params?.id]);
  const fetchSeen = (endpoint) => {
    API.get(endpoint)
      .then((res) => {
        dispatch(setNotificationList([]));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let seenEndpoint;
    if (role === "student") {
      seenEndpoint = `/chat/seen/${user?.userId}`;
    } else if (role === "tutor") {
      seenEndpoint = `/chat/seen/${user?.userId}`;
    } else {
      seenEndpoint = `/chat/agency/${user?.id}`;
    }

    if (user) {
      fetchSeen(seenEndpoint);
    }
  }, [role, user?.userId, user?.id, params?.id]);

  const chatClickHandler = () => {
    setShowChat(true);
  };

  const showComposeHandler = () => {
    setShowCompose(true);
  };

  const backHandler = () => {
    setShowCompose(false);
    setShowChat(false);
  };

  useEffect(() => {
    if (params?.id) {
      const newUser = ChatList.find((chat) => chat.roomId === params?.id);
      setFilteredData(newUser);
    }
  }, [params?.id, ChatList]);

  return (
    <AgencyLayout
      sidebarData={
        user?.role === "student"
          ? studentSidebar
          : user?.role === "tutor"
          ? tutorSidebar
          : agencySidebar
      }
    >
      <div className="mx-auto grid h-full w-[98%] grid-cols-1 gap-2 lg:grid-cols-3">
        <div
          className={`col-span-1 ${
            (showChat || showCompose) && "hidden"
          } lg:block`}
        >
          <ChatLists
            data={ChatList}
            showComposeHandler={showComposeHandler}
            chatClickHandler={chatClickHandler}
          />
        </div>
        <div
          className={`col-span-2 ${
            !(showChat || showCompose) && "hidden"
          } lg:block`}
        >
          {showCompose ? (
            <ComposeScreen backHandler={backHandler} />
          ) : (
            <ChatScreen
              data={filteredData}
              messages={messages}
              backHandler={backHandler}
              dispatch={dispatch}
              updateChatListSlice={updateChatListSlice}
            />
          )}
        </div>
      </div>
    </AgencyLayout>
  );
};

export default ParticularChat;
