/* eslint-disable no-undef */
import AgencyLayout from "components/shared/AgencyLayout";
import React, { useEffect, useState } from "react";
import ChatLists from "./design/ChatLists";
import ComposeScreen from "./design/Compose";
import { useDispatch, useSelector } from "react-redux";
import {
  agencySidebar,
  studentSidebar,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import { getChatList } from "store/action/super";
import { pathnameHandler } from "components/utils/functions";
import { useNavigate } from "react-router-dom";

const Chats = () => {
  const [showChat, setShowChat] = useState(false);
  const [showCompose, setShowCompose] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const ChatList = useSelector((state) => state.users.chatList);

  const role = sessionStorage.getItem("odin-auth-role");

  const chatClickHandler = (room) => {
    setShowChat(true);
    navigate(`${pathnameHandler()}/chat/${room.roomId}`);
  };

  const showComposeHandler = () => {
    setShowCompose(true);
  };

  const backHandler = () => {
    setShowChat(false);
    setShowCompose(false);
  };
  useEffect(() => {
    if (user) {
      dispatch(getChatList(user));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (ChatList && ChatList.length > 0) {
      chatClickHandler(ChatList[0]);
    }
  }, [ChatList]);

  return (
    <AgencyLayout
      sidebarData={
        role === "student"
          ? studentSidebar
          : role === "tutor"
          ? tutorSidebar
          : agencySidebar
      }
    >
      <div className="mx-auto grid h-full w-[98%] grid-cols-1 gap-2 lg:grid-cols-3">
        <div
          className={`col-span-1 ${
            (showChat || showCompose) && "hidden"
          } lg:block`}
        >
          <ChatLists
            data={ChatList}
            showComposeHandler={showComposeHandler}
            chatClickHandler={chatClickHandler}
          />
        </div>
        <div
          className={`col-span-2 ${
            !(showChat || showCompose) && "hidden"
          } lg:block`}
        >
          {showCompose && <ComposeScreen backHandler={backHandler} />}
          {!showCompose && ChatList.length === 0 && (
            <div className="m-auto flex h-full items-center justify-center">
              No Chat
            </div>
          )}
        </div>
      </div>
    </AgencyLayout>
  );
};

export default Chats;
