/* eslint-disable no-undef */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import { BASE_IMG_URL } from "components/shared/constants";
import Tabs from "components/shared/Tabs";
import StudentProfile from "./designs/StudentProfile";
import {
  agencySidebar,
  studentSidebar,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getStudentActivities, getStudentDetail } from "store/action/user";

const data = [
  {
    name: "Student Profile",
    icon: <img src={`${BASE_IMG_URL}/img/person.svg`} alt="" />,
  },
];

const CustomStudentProfile = () => {
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();

  const role = sessionStorage.getItem("odin-auth-role");

  const onSelect = (index) => {
    setTab(index);
  };
  const param = useParams();

  useEffect(() => {
    dispatch(getStudentDetail(param));
    dispatch(getStudentActivities(param));
  }, []);
  return (
    <AgencyLayout
      currentPage="Students"
      sidebarData={
        role === "student"
          ? studentSidebar
          : role === "tutor"
          ? tutorSidebar
          : agencySidebar
      }
    >
      <div className="flex flex-col justify-between  gap-6">
        <div>
          <Tabs data={data} selected={tab} onSelect={onSelect} />
        </div>
        <StudentProfile />
      </div>
    </AgencyLayout>
  );
};

export default CustomStudentProfile;
