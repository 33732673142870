import React from "react";

const TextArea = () => {
  return (
    <div className="col-span-1 hidden h-full items-center text-black md:col-span-3 md:flex">
      <div>
        <div className=" mb-4 text-4xl font-bold">
        Join a global community of learners.<br/> Build the skills to shape the world.
        </div>
        <span className="text-xl">Every Student Deserves a Mentor</span>
      </div>
    </div>
  );
};

export default TextArea;
