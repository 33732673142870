import { Skeleton } from "antd";
import React from "react";
import PropTypes from "prop-types";

const SkeletonWithAvatar = ({ rows }) => {
  return (
    <div>
      <Skeleton
        avatar
        paragraph={{
          rows,
        }}
      />
    </div>
  );
};
SkeletonWithAvatar.propTypes = {
  rows: PropTypes.number.isRequired,
};

export default SkeletonWithAvatar;
