/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { IoMailOutline } from "react-icons/io5";
import { FiLock } from "react-icons/fi";
import InputTextField from "components/ui/InputTextField";
import { Link } from "react-router-dom";
import API from "store/services/base.service";
import { toast } from "react-toastify";

const FormArea = () => {
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
        email: "",
        username: "Odin"
    });

    const handleChange = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        API.post("/user/register-admin", formData).then((res) => {
            setFormData({
                password: "",
                confirmPassword: "",
                role: "admin",
                email: "",
                username: "Odin"
            })
        }).catch((err) => toast.error(err))

    };

    return (
        <div className="col-span-1 mr-0 size-fit rounded-3xl bg-white p-3 text-center text-odinPrimary md:col-span-2 lg:p-8">
            <form
                className="flex h-full flex-col justify-between gap-4 py-2"
                onSubmit={submitHandler}
            >
                <div>
                    <div className="text-2xl font-bold text-black">Create an Account</div>
                </div>

                <div>
                    <InputTextField
                        className="mb-3 w-fit border-2 py-3"
                        type="text"
                        icon={<IoMailOutline fontSize={20} color="#0115B5" />}
                        placeholder="Username"
                        variant="outlined"
                        value={formData.username}
                        onChange={(e) => handleChange("username", e)}
                    />
                    <InputTextField
                        className="mb-3 w-fit border-2 py-3"
                        type="text"
                        icon={<IoMailOutline fontSize={20} color="#0115B5" />}
                        placeholder="Email Address"
                        variant="outlined"
                        value={formData.email}
                        onChange={(e) => handleChange("email", e)}
                    />
                    <InputTextField
                        className="mb-3 w-fit border-2 py-3"
                        type="password"
                        icon={<FiLock fontSize={20} color="#0115B5" />}
                        placeholder="Password"
                        variant="outlined"
                        value={formData.password}
                        onChange={(e) => handleChange("password", e)}
                    />
                    <InputTextField
                        className="mb-3 w-fit border-2 py-3"
                        type="password"
                        icon={<FiLock fontSize={20} color="#0115B5" />}
                        placeholder="Confirm Password"
                        variant="outlined"
                        value={formData.confirmPassword}
                        onChange={(e) => handleChange("confirmPassword", e)}
                    />
                </div>
                <div>
                    <button type="submit" className="w-full cursor-pointer rounded-lg border-none bg-odinPrimary py-3 font-semibold text-white outline-none">
                        Sign up
                    </button>
                    <div className="mt-2 text-center text-sm font-semibold text-secondary">
                        Already have an account?{" "}
                        <Link to="/admin/signin" className="text-black">
                            Login Here
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormArea;
