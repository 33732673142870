/* eslint-disable react/prop-types */
import Modal from "components/shared/Modal";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CancelSubscription from "./CancelSubscription";
import ReSubscribe from "components/modules/SubscriptionPlan/ReSubscribe";
import { Skeleton } from "antd";

const SubscriptionPlanView = ({ amount }) => {
  console.log("🚀 ~ SubscriptionPlanView ~ amount:", amount);
  const user = useSelector((state) => state.users.user);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const [modal, setModal] = useState(false);
  const [type, setType] = useState();

  const cancelHandler = (value) => {
    if (value === "subscribe") {
      setType(value);
      setModal(true);
    }
    setModal(true);
  };

  return (
    <div>
      <Modal
        open={modal}
        width={type === "subscribe" ? 1000 : 600}
        close={() => setModal(false)}
        element={
          type === "subscribe" ? (
            <ReSubscribe agencyFormData={user} close={() => setModal(false)} />
          ) : (
            <CancelSubscription close={() => setModal(false)} />
          )
        }
      />
      {user && user.subscriptionType ? (
        isLoading ? (
          <>
            <Skeleton paragraph />
          </>
        ) : (
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <div className="text-sm font-semibold text-secondary">
                {user?.subscriptionType}
              </div>
              <div className="text-lg font-semibold text-black">
                US ${amount} per month
              </div>
            </div>
            <div
              className="cursor-pointer rounded-lg border-2 px-6 py-2"
              onClick={() => cancelHandler()}
            >
              Cancel plan
            </div>
          </div>
        )
      ) : (
        <div className="flex w-full justify-center">
          <div
            className="w-fit cursor-pointer rounded-lg bg-odinPrimary p-3 text-white"
            onClick={() => cancelHandler("subscribe")}
          >
            Activate Subscription
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlanView;
