/* eslint-disable react/prop-types */

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import React, { useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import CustomToolbar from "./CustomToolbar";
import "./Calendar.css"; // Import your CSS file

const localizer = momentLocalizer(moment);

export default function Calendar(props) {
  const [showMoreCount, setShowMoreCount] = useState(0);
  const [view, setView] = useState("month");

  const handleShowMoreClick = (events, date) => {
    const newCount = showMoreCount + 1;
    setShowMoreCount(newCount);
    if (newCount >= 2) {
      setView("day");
    }
  };

  // Event style getter to disable past events
  const eventStyleGetter = (event, start, end, isSelected) => {
    const now = new Date();
    let backgroundColor = "#3174ad";

    if (start < new Date(now.setHours(0, 0, 0, 0))) {
      // Check if the event is in the past
      backgroundColor = "#d3d3d3"; // Light gray for disabled events
    }

    const style = {
      backgroundColor,
      opacity: start < new Date(now.setHours(0, 0, 0, 0)) ? 0.5 : 1, // Reduce opacity for past events
      cursor:
        start < new Date(now.setHours(0, 0, 0, 0)) ? "not-allowed" : "pointer",
    };
    return {
      style,
    };
  };

  const handleSelectEvent = (event) => {
    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);

    if (event.start < todayMidnight) {
      console.log("This event is in the past and cannot be modified.");
    } else {
      console.log("This event is in the future and can be modified.");
      props.onSelectEvent(event);
    }
  };

  return (
    <BigCalendar
      className="custom"
      {...props}
      localizer={localizer}
      defaultView="month"
      view={view}
      onView={setView}
      style={{ minHeight: 700 }}
      views={["month", "week", "day", "agenda"]}
      components={{
        toolbar: (toolbarProps) => (
          <CustomToolbar
            {...toolbarProps}
            showMoreCount={showMoreCount}
            setView={setView}
          />
        ), // Use the custom toolbar
      }}
      onShowMore={handleShowMoreClick} // Handle "Show More" click
      eventPropGetter={eventStyleGetter} // Apply custom styles for past events
      onSelectEvent={handleSelectEvent} // Prevent interaction with past events
    />
  );
}
