import { studentPaymentColumn } from "components/shared/constants/agencyConstants";
import TableUi from "components/ui/Table";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { studentPaymentHistory } from "store/action/user";

const Payment = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.users.payment);

  useEffect(() => {
    dispatch(studentPaymentHistory());
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-end">
        <div className="border-b-4 border-secondary text-2xl font-bold text-black">
          Payment History
        </div>
        <div className="flex-1 border-2"></div>
      </div>
      <div className="rounded-lg bg-white p-6">
        <TableUi
          headerBg="#303030"
          headerColor="#fff"
          rowSelection
          data={events}
          columns={studentPaymentColumn}
          pageSize={6}
        />
      </div>
    </div>
  );
};

export default Payment;
