/* eslint-disable react/prop-types */
import React from "react";
import { Divider, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BillingInfo = ({ data }) => {
  const user = useSelector((state) => state.users.user);
  const isLoading = useSelector((state) => state.notifications.isLoading);
  return (
    <div className="h-full rounded-lg bg-white p-4 shadow-md">
      <div className="flex">
        <div className="border-b-2 border-secondary text-xl font-semibold">
          Last Billing Information
        </div>
        <div className="flex-1 border-b-2"></div>
      </div>
      {isLoading && !data ? (
        <Skeleton paragraph />
      ) : (
        <>
          <div className="mt-4 flex flex-col gap-2 text-sm font-semibold text-secondary">
            <div className="flex text-start">
              <span className="w-[200px]">Name:</span>
              <span className="font-medium">{user?.username}</span>
            </div>
            <div className="flex">
              <span className="w-[200px]">Billing Address:</span>
              <span className="font-medium">{user?.email} </span>
            </div>
            <div className="flex">
              <span className="w-[200px]">Currency:</span>
              <span className="font-medium">{data?.currency} </span>
            </div>
          </div>
          <Link
            to={data?.receipt_url || ""}
            target="_blank"
            className="mt-20 text-lg font-semibold text-odinPrimary"
          >
            Reciept Download
          </Link>
          <Divider />
          <div className="flex flex-col gap-2 text-sm font-semibold text-secondary">
            <div className="flex justify-between text-lg">
              <span>Total (USD)</span>
              <span className="w-32  text-black">$ {data?.amount}</span>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default BillingInfo;
