/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import InputTextField from "components/ui/InputTextField";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCityOfState,
  getAllCountry,
  getAllStateOfCountry,
  updateUser,
} from "store/action/user";
import Image from "components/shared/Image";
import { Select } from "antd";

const ContactSetting = ({ onClose }) => {
  const dispatch = useDispatch();
  const { Countries, States, City } = useSelector((state) => state.supers);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  console.log("🚀 ~ ContactSetting ~ selectedState:", selectedState);
  const user = useSelector((state) => state.users.user);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    province: "",
    city: "",
    permanentAddress: "",
    gender: "",
    avatar: null,
    file: null,
  });

  const { Option } = Select;

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCountryChange = (value) => {
    const selectedCountry = Countries.find((state) => state.isoCode === value);
    setSelectedCountry(selectedCountry);
    handleChange("country", value);
    setFormData((prevData) => ({
      ...prevData,
      province: "",
      city: "",
    }));
    dispatch(getAllStateOfCountry({ countryCode: selectedCountry.isoCode }));
  };

  const handleStateChange = (value) => {
    const selectedState = States.find((state) => state.isoCode === value);
    setSelectedState(selectedState);
    handleChange("province", value);
    setFormData((prevData) => ({
      ...prevData,
      city: "",
    }));
    dispatch(
      getAllCityOfState({
        stateCode: value,
        countryCode: selectedCountry.isoCode,
      })
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      avatar: URL.createObjectURL(file),
      file,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();

    for (const key in formData) {
      form.append(key, formData[key]);
    }
    dispatch(updateUser({ form, role: "tutor" }));
    onClose();
  };

  useEffect(() => {
    setFormData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      country: user?.country,
      province: user?.province,
      city: user?.city,
      permanentAddress: user?.permanentAddress,
      gender: user?.gender,
      avatar: user?.avatar,
    });
    dispatch(getAllCountry());
  }, [user]);

  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
      <div>
        <div className="pb-1 text-left text-lg font-bold text-black">
          Contact Settings
        </div>
        <div className="w-full border-t border-ternary"></div>
      </div>

      <div className="relative m-auto flex w-fit flex-col items-center">
        {formData.avatar ? (
          <Image
            src={formData.avatar}
            alt=""
            className="size-20 rounded-full object-contain"
          />
        ) : (
          <Image
            src="/img/person-black.svg"
            alt=""
            className="size-20 cursor-pointer rounded-full"
          />
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="absolute inset-0 cursor-pointer opacity-0"
        />
        <div className="text-sm font-medium text-odinPrimary">
          Upload Picture
        </div>
      </div>
      <div>
        <InputTextField
          className="mb-3 border-2 py-3"
          type="text"
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={(value) => handleChange("firstName", value)}
        />
        <InputTextField
          className="mb-3 border-2 py-3"
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={(value) => handleChange("lastName", value)}
        />

        <div className="flex w-full flex-col justify-center gap-6 md:flex-row ">
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            placeholder="Email Address"
            name="email"
            value={formData.email}
            onChange={(e) => handleChange("email", e)}
          />
          <InputTextField
            className="mb-3 border-2 py-3"
            type="number"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={(e) => handleChange("phoneNumber", e)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <Select
          className="mb-3 h-12 w-full"
          placeholder="Country"
          name="country"
          value={formData.country}
          onChange={handleCountryChange}
        >
          {Countries?.map((country) => (
            <Option key={country.isoCode} value={country.isoCode}>
              {country.name}
            </Option>
          ))}
        </Select>
        <Select
          className="mb-3 h-12 w-full"
          placeholder="Province"
          name="province"
          value={formData.province}
          onChange={handleStateChange}
          disabled={!formData.country}
        >
          {States?.map((state) => (
            <Option key={state.isoCode} value={state.isoCode}>
              {state.name}
            </Option>
          ))}
        </Select>
        <Select
          className="mb-3 h-12 w-full"
          placeholder="City"
          name="city"
          value={formData.city}
          onChange={(value) => handleChange("city", value)}
          disabled={!formData.province}
        >
          {City?.map((city) => (
            <Option key={city.name} value={city.name}>
              {city.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
        <InputTextField
          className="mb-3 w-full border-2 py-3"
          type="text"
          placeholder="Permanent Address"
          name="permanentAddress"
          value={formData.permanentAddress}
          onChange={(e) => handleChange("permanentAddress", e)}
        />
        <Select
          placeholder="Select your gender"
          name="gender"
          className="mb-3 h-12 w-full"
          value={formData.gender}
          onChange={(e) => handleChange("gender", e)}
        >
          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
          <Option value="other">Other</Option>
        </Select>
      </div>
      <div className="flex justify-end">
        <div className="flex items-center justify-center gap-2">
          <div
            className="cursor-pointer rounded-lg border-2 border-secondary px-6 py-2 font-semibold text-black"
            onClick={onClose}
          >
            Cancel
          </div>
          <button
            type="submit"
            className="rounded-lg border-2 border-black bg-black px-6 py-2 font-semibold text-white"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactSetting;
