/* eslint-disable react/prop-types */
import AgencyLayout from "components/shared/AgencyLayout";
import React, { useEffect, useState } from "react";
import { studentSidebar } from "components/shared/constants/agencyConstants";
import CalendarUi from "components/ui/Calender";
import Modal from "components/shared/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getCalenderAction } from "store/action/timelines";
import Subscribed from "components/shared/Subscribed";
import { subscribeToTutor } from "store/action/user";
import API from "store/services/base.service";
import EventPopup from "components/shared/EventPopup";

const StudentCalender = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.users.events);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [updateEvent, setUpdateEvent] = useState(false);
  const [tutor, setTutor] = useState(null);

  useEffect(() => {
    dispatch(getCalenderAction());
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      API.get(`/tutors/tutor/${selectedEvent?.tutorId}`)
        .then((res) => {
          setTutor(res?.data?.data);
        })
        .catch(() => {});
    }
  }, [selectedEvent]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setUpdateEvent(false);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const updateHandler = () => {
    setUpdateEvent(true);
    setModalVisible(true);
  };

  const bookedSlots = (value, amount, subject) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      subscribeToTutor({
        classes: value,
        tutorId: selectedEvent?.tutorId,
        studentId: selectedEvent?.studentId,
        amount,
        timezone,
        subject,
        id: selectedEvent?.id,
        paid: true,
      })
    );
    handleModalCancel();
  };

  return (
    <AgencyLayout currentPage="Calender" sidebarData={studentSidebar}>
      <Modal
        open={modalVisible}
        close={handleModalCancel}
        element={
          updateEvent ? (
            <Subscribed
              chargesPerHour={tutor?.chargesPerHour}
              bookedSlots={bookedSlots}
              data={tutor}
              selectedEvent={selectedEvent}
              update
            />
          ) : (
            <EventPopup
              updateHandler={updateHandler}
              zoom={selectedEvent?.zoomLink}
            />
          )
        }
      />
      <div className="box-shadow h-full flex-1 rounded-xl bg-white  p-4">
        <CalendarUi events={events} onSelectEvent={handleSelectEvent} />
      </div>
    </AgencyLayout>
  );
};

export default StudentCalender;
