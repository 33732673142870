/* eslint-disable react/prop-types */
import { BASE_IMG_URL } from "components/shared/constants";
import InputMultiSelect from "components/ui/InputMultiSelect";
import InputTextField from "components/ui/InputTextField";
import React, { useState } from "react";

const MultipleSelection = ({
  name,
  type,
  dropDown,
  placeholder,
  getTags,
  onChange,
  value,
  required,
}) => {
  const [state, setState] = useState(false);
  return (
    <div>
      <div
        className={`mb-2 flex items-center justify-between ${
          dropDown && "cursor-pointer"
        }`}
        onClick={() => dropDown && setState(!state)}
      >
        <div className="text-sm font-medium text-secondary">{name}</div>
        {dropDown && (
          <div>
            <img
              className="size-2"
              src={`${BASE_IMG_URL}/img/Downvector.svg`}
              alt=""
            />
          </div>
        )}
      </div>
      {!state && (
        <>
          {type === "multiSelect" ? (
            <InputMultiSelect
              value={value}
              placeholder={placeholder}
              getTags={getTags}
            />
          ) : (
            <InputTextField
              type={type || "text"}
              className="border-2 py-3"
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              required={required}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MultipleSelection;
