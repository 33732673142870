/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { IoMailOutline } from "react-icons/io5";
import { FiLock } from "react-icons/fi";
import InputTextField from "components/ui/InputTextField";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "store/action/user";
import { validateEmail, validatePassword } from "components/utils/functions";
import { toast } from "react-toastify";

const FormArea = () => {
  const domain = useSelector((state) => state.theme.domain);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };
  const handleNavigate = () => {
    navigate("/forget-password");
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const emailError = validateEmail(formData.email);
    const passwordError = validatePassword(formData.password);

    if (emailError) {
      toast.error(emailError);
      return;
    }

    if (passwordError) {
      toast.error(passwordError);
      return;
    }

    dispatch(loginAction(formData));
  };

  return (
    <div className="col-span-1 mr-0 h-full rounded-3xl bg-white p-3 text-center text-odinPrimary md:col-span-2 lg:p-8">
      <form
        className="flex h-full flex-col justify-between py-8"
        onSubmit={submitHandler}
      >
        <div>
          <div className="text-2xl font-bold text-black">Log In</div>
          <div className="text-lg font-normal text-secondary">
            To your {domain} account
          </div>
        </div>
        <div>
          <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="text"
            icon={<IoMailOutline fontSize={20} color="#0115B5" />}
            placeholder="Your Email Address"
            variant="outlined"
            value={formData.email}
            onChange={(e) => handleChange("email", e)}
          />
          <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="password"
            icon={<FiLock fontSize={20} color="#0115B5" />}
            placeholder="Password"
            variant="outlined"
            value={formData.password}
            onChange={(e) => handleChange("password", e)}
          />
          <div
            className="mt-2 cursor-pointer text-right text-sm font-semibold text-black"
            onClick={handleNavigate}
          >
            Forgot password?
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="w-full cursor-pointer rounded-lg border-none bg-primary py-3 font-semibold text-white outline-none"
          >
            Log in
          </button>

          <Link
            to="/sign-up"
            className="mt-4 text-center text-sm font-semibold text-black"
          >
            Sign up
          </Link>
        </div>
      </form>
    </div>
  );
};

export default FormArea;
