/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import API from "store/services/base.service";
import { setSubscription } from "store/slices/super";
import { useDispatch } from "react-redux";
import { setAgencyStep } from "store/slices/alert";

export default function CheckoutForm({ agencyFormData, price, plan, close }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      return;
    }

    setLoading(true);

    const { error: submitError } = await elements.submit();

    if (submitError) {
      handleError(submitError);
      return;
    }

    const res = await API.post(
      close ? "/stripe/activate-subscription" : "/stripe/create-subscription",
      {
        name: agencyFormData?.agencyName,
        email: agencyFormData?.email,
        price,
        planType: plan,
      }
    );
    const { type, clientSecret } = res.data;
    dispatch(setSubscription({ ...res.data, subscriptionType: plan }));

    const confirmIntent =
      type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

    const { error } = await confirmIntent({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.href}`,
      },
    });
    close();
    dispatch(setAgencyStep(0));

    if (error) {
      console.log(error);
      handleError(error);
    } else {
      toast.success("Payment Done");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        className="mt-2 w-full rounded-md bg-odinPrimary py-2 text-white"
      >
        Submit Payment {loading && "loading"}
      </button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}
