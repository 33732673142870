/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { setPassword } from "store/action/user";

const NewPassword = () => {
  const [form] = Form.useForm();
  const forgotUser = useSelector((state) => state.users.forgotUser);
  const agency = useSelector((state) => state.supers.agency);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!forgotUser.email) navigate("/agency/forget-password");
  }, []);
  const onFinish = (values) => {
    dispatch(
      setPassword({
        newPassword: values.password,
        email: forgotUser.email,
      })
    );
  };
  return (
    <div className="flex h-full flex-col justify-around text-left text-sm font-bold text-odinPrimary">
      <Form
        form={form}
        layout="vertical"
        enabled
        // initialValues={data}
        onFinish={onFinish}
      >
        <div
          className={` ${
            agency?.backgroundColor ? "text-secondary" : "text-odinPrimary"
          }`}
        >
          New Password
        </div>
        <Form.Item
          name="password"
          label=""
          className="mb-4"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password placeholder="*******" className="custom-input" />
        </Form.Item>

        <div
          className={`text-left text-sm font-bold ${
            agency?.backgroundColor ? "text-secondary" : "text-odinPrimary"
          }`}
        >
          Confirm Password
        </div>
        <Form.Item
          name="confirmPassword"
          label=""
          dependencies={["password"]}
          rules={[
            { required: true, message: "Confirm password is required" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            className="custom-input"
          />
        </Form.Item>

        <button
          type="submit"
          className={` ${
            agency?.backgroundColor
              ? "bg-[--bg-primary-color] "
              : "bg-gradient-to-b from-[#0114B0] to-[#010B60] "
          }
                  w-full rounded-lg  py-2 font-semibold text-white
                `}
        >
          Save
        </button>
      </Form>
    </div>
  );
};

export default NewPassword;
