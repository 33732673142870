/* eslint-disable dot-notation */
/* eslint-disable no-undef */
import { createAlert } from "../slices/alert";
import { jwtDecode } from "jwt-decode";

export const interceptRequest = (config) => {
  let authToken;

  if (typeof sessionStorage !== "undefined") {
    const tokenString = sessionStorage.getItem("odin-auth-token");
    if (tokenString) {
      const decoded = jwtDecode(JSON.parse(tokenString));
      if (decoded && decoded.exp * 1000 > Date.now()) {
        authToken = JSON.parse(tokenString);
      }
    }
  }

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  return config;
};

export const interceptRequestError = (error) => {
  return Promise.reject(error);
};

export const interceptResponse = async (response) => {
  return response;
};

export const interceptResponseError = (error) => {
  if (error?.response?.status === 506) {
    createAlert({
      type: "error",
      message: "Your token is expired",
      status: true,
    });
    window.sessionStorage.clear();
    // push to login
  }
  return Promise.reject(error);
};
