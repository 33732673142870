/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import { BiMessage } from "react-icons/bi";
const data = [
  // {
  //   key: "subscribe",
  //   name: "Subscribe Tutor",
  //   icon: <img src="/img/subscribe.svg" style={{ marginTop: "4px" }} />,
  //   // icon: <MdJoinInner size={20} />,
  // },
  {
    key: "message",
    name: "Message",
    icon: <BiMessage size={20} />,
  },
  {
    key: "unsubscribe",
    name: "Unsubscribe",
    icon: <img src="/img/unsub.svg" style={{ marginTop: "4px" }} />,
  },
  {
    key: "review",
    name: "Review",
    icon: <img src="/img/review.svg" style={{ marginTop: "4px" }} />,
  },
];

const StudentInfoDropdown = ({ clickHandler }) => {
  const role = sessionStorage.getItem("odin-auth-role");
  return (
    <div className="rounded-xl border-2 bg-white p-2">
      {data?.map((item, idx) => {
        if (role === "student" || item.key === "message") {
          return (
            <div
              className={`flex cursor-pointer gap-3 ${
                role === "student" &&
                idx !== 0 &&
                "mt-2 border-t-2 border-[#EEEEEE] pt-2"
              }`}
              key={idx}
              onClick={() => clickHandler(item.key)}
            >
              <div>{item?.icon}</div>
              <div className="font-semibold text-secondary">{item?.name}</div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default StudentInfoDropdown;
