/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ColorPicker } from "antd";

const InputTextField = ({
  ref,
  type,
  icon,
  rightIcon,
  placeholder,
  className,
  value,
  onChange,
  required,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`w-full rounded-xl ${className}`}>
      <div className="mx-2 flex items-center">
        {icon && <div className="mr-2">{icon}</div>}
        {type === "textArea" ? (
          <textarea
            className="w-full border-none outline-none"
            placeholder={placeholder}
            rows="8"
            cols="50"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            required={!!required}
          />
        ) : (
          <input
            ref={ref}
            type={
              type === "password" ? (showPassword ? "text" : "password") : type
            }
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            className="flex-1 border-none text-black outline-none"
            required={!!required}
            min={type === "number" ? 0 : undefined}
          />
        )}

        {rightIcon && <div className="ml-2">{rightIcon}</div>}
        {type === "password" && (
          <div className="ml-2 cursor-pointer">
            {showPassword ? (
              <FiEye
                color="#CCCCCC"
                onClick={handleTogglePasswordVisibility}
                fontSize={20}
              />
            ) : (
              <FiEyeOff
                color="#CCCCCC"
                onClick={handleTogglePasswordVisibility}
                fontSize={20}
              />
            )}
          </div>
        )}
        {type === "colorPicker" && (
          <ColorPicker value={value} onChange={(_, e) => onChange(e)} />
        )}
      </div>
    </div>
  );
};

export default InputTextField;
