import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const MobilePricingTable = () => {
  const [activeTab, setActiveTab] = useState("Pro");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const data = [
    { feature: "Feature ", price: "Free" },
    { feature: "Feature ", price: "$30/mo" },
    { feature: "Feature ", price: "$30/mo" },
    { feature: "Feature ", price: "✔" },
    { feature: "Feature ", price: "✔" },
    { feature: "Feature ", price: "✔" },
  ];
  return (
    <div className="flex items-center justify-center md:hidden ">
      <div className="w-96 rounded-lg  p-6 ">
        <div className="mb-6 flex justify-around">
          {["Pro", "Advanced", "Popular"].map((tab) => (
            <motion.button
              key={tab}
              onClick={() => handleTabClick(tab)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`rounded-lg px-6 py-2 transition-all duration-300 ${
                activeTab === tab
                  ? "bg-[#3b24b2] text-white shadow-md"
                  : "bg-white text-black "
              }`}
            >
              {tab}
            </motion.button>
          ))}
        </div>
        <AnimatePresence mode="wait">
          {activeTab === "Pro" && (
            <motion.div
              key="Pro"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
              className="rounded-[16px] bg-white p-4 shadow"
            >
              <table className="w-full border-collapse overflow-hidden rounded-lg border border-gray-200 text-left">
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className="border-b border-r border-[#f7f5ff] px-4 py-3 text-[20px] text-[#130b46]">
                        {item.feature}
                      </td>
                      <td className="border-b border-[#f7f5ff] px-4 py-3 text-center text-[20px] text-[#130b46]">
                        {item.price}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </motion.div>
          )}
          {activeTab === "Advanced" && (
            <motion.div
              key="Advanced"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
              className="rounded-lg bg-white p-4 shadow"
            >
              <table className="w-full border-collapse overflow-hidden rounded-lg border border-gray-200 text-left">
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className="border-b border-r border-[#f7f5ff] px-4 py-3 text-[20px] text-[#130b46]">
                        {item.feature}
                      </td>
                      <td className="border-b border-[#f7f5ff] px-4 py-3 text-center text-[20px] text-[#130b46]">
                        {item.price}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </motion.div>
          )}{" "}
          {activeTab === "Popular" && (
            <motion.div
              key="Popular"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
              className="rounded-lg bg-white p-4 shadow"
            >
              <table className="w-full border-collapse overflow-hidden rounded-lg border border-gray-200 text-left">
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className="border-b border-r border-[#f7f5ff] px-4 py-3 text-[20px] text-[#130b46]">
                        {item.feature}
                      </td>
                      <td className="border-b border-[#f7f5ff] px-4 py-3 text-center text-[20px] text-[#130b46]">
                        {item.price}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default MobilePricingTable;
