import React from "react";

const TextArea = () => {
  return (
    <div className="flex h-full items-center text-white">
      <div>
        <div className=" mb-4 text-4xl font-bold">
          Making Learning Accessible, <br />
          Engaging and Transformational From
          <br />
          Anywhere!
        </div>
        <span className="text-xl">Learning Management System for Students</span>
      </div>
    </div>
  );
};

export default TextArea;
