import React from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "store/slices/alert";

const ResetCompleted = () => {
  const navigate = useNavigate();
  const agency = useSelector((state) => state.supers.agency);
  const { backgroundColor } = agency || {};

  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(setStep(0));
    navigate("/");
  };
  return (
    <div className="flex h-full flex-col items-center justify-around text-left text-sm font-bold">
      <div
        className={`rounded-[50%] border-[3px]  p-2 ${
          backgroundColor ? "border-[secondary]" : "border-odinPrimary"
        }`}
      >
        <img
          src={`${BASE_IMG_URL}/img/check-blue.svg`}
          alt=""
          className="size-6 rounded-full"
        />
      </div>
      <div
        className={`text-center text-2xl  ${
          backgroundColor ? "text-[secondary]" : "text-odinPrimary"
        }`}
      >
        <div>Reset Successfully</div>
      </div>
      <div className="text-secondary">
        Congratulations! You have been successfully authenticated
      </div>
      <div
        className={` ${
          backgroundColor
            ? "bg-[--bg-primary-color] "
            : "bg-gradient-to-b from-[#0114B0] to-[#010B60] "
        } w-full cursor-pointer rounded-lg py-2  text-center font-semibold text-white`}
        onClick={handleNavigate}
      >
        Login
      </div>
    </div>
  );
};

export default ResetCompleted;
