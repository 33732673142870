import { Divider } from "antd";
import InputTextField from "components/ui/InputTextField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agencyInviteAction } from "store/action/invite";
import PropTypes from "prop-types";
import { validateEmail } from "components/utils/functions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddTutor = ({ name, close }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  console.log("🚀 ~ AddTutor ~ navigate:", navigate);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    const emailError = validateEmail(email);
    if (emailError) {
      toast.error(emailError);
      return;
    }

    if (
      (!user?.role && user?.connectedAccountId === "") ||
      user?.connectedAccountId === "null" ||
      user?.connectedAccountId === null
    ) {
      navigate("/agency/admin/package");
      return;
    }
    dispatch(
      agencyInviteAction({
        inviteeRole: name?.toLowerCase(),
        inviteeEmail: email,
      })
    );

    close();
    setEmail("");
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="text-xl font-bold">Add {name || "Tutor"}</div>
      <Divider className="my-2 bg-gray-100" />
      <div className="py-2 font-medium">Email</div>
      <InputTextField
        className="mb-3 border-2 py-3"
        type="text"
        placeholder="e.g., Abc@company.com"
        value={email}
        isRequired
        onChange={(value) => setEmail(value)}
      />
      <div className="flex items-center justify-center gap-4 p-4 font-bold">
        <div
          className="flex h-10 w-24 cursor-pointer items-center justify-center rounded-lg  border-2 bg-white text-center text-black"
          onClick={() => close()}
        >
          Cancel
        </div>
        <button
          type="submit"
          className="flex h-10 w-24 items-center justify-center rounded-lg border-2 border-[black] bg-black text-center text-white outline-none"
        >
          Invite
        </button>
      </div>
    </form>
  );
};
AddTutor.propTypes = {
  name: PropTypes.string,
  close: PropTypes.func.isRequired,
};
export default AddTutor;
