/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import InputTextField from "components/ui/InputTextField";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerAction } from "store/action/user";
import {
  getSubdomain,
  setupStudentFromInvitation,
} from "components/utils/functions";

const FormArea = () => {
  const domain = useSelector((state) => state.theme.domain);
  const dispatch = useDispatch();
  const location = useLocation();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    role: "student",
    agencyDomain: `${getSubdomain()}.odin.onl`,
    email: "",
    firstName: "",
    lastName: "",
    username: "",
  });

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    setupStudentFromInvitation(location.search, handleChange);
  }, [location]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (formData.role === "student") {
      // formData.role = "student";
      const username = formData.email.split("@")[0];
      const data = { ...formData, username };
      setFormData({ ...formData, username });
      dispatch(registerAction(data));
    } else {
      const formDataTemp = new FormData();

      Object.entries({ ...formData }).forEach(([key, value]) => {
        formDataTemp.append(key, value);
      });

      dispatch(registerAction(formDataTemp));
      setFormData({
        password: "",
        confirmPassword: "",
        role: "student",
        agencyDomain: `${getSubdomain()}.odin.onl`,
        email: "",
        firstName: "",
        lastName: "",
        username: "",
      });
    }
  };

  return (
    <div className="col-span-1 mr-0 h-full rounded-3xl bg-white p-3 text-center text-odinPrimary md:col-span-2 lg:p-8">
      <form
        className="flex h-full flex-col justify-between gap-4 py-2"
        onSubmit={submitHandler}
      >
        <div>
          <div className="text-2xl font-bold text-black">Create an Account</div>
          <div className="text-lg font-normal text-secondary">
            To your {domain} account
          </div>
        </div>

        <div>
          <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="text"
            placeholder="First Name"
            variant="outlined"
            value={formData.firstName}
            onChange={(e) => handleChange("firstName", e)}
          />
          <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="text"
            placeholder="Last Name"
            variant="outlined"
            value={formData.lastName}
            onChange={(e) => handleChange("lastName", e)}
          />
          {/* <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="text"
            placeholder="Username"
            variant="outlined"
            value={formData.username}
            onChange={(e) => handleChange("username", e)}
          /> */}
          <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="text"
            placeholder="Email Address"
            variant="outlined"
            disabled={location.search}
            value={formData.email}
            onChange={(e) => handleChange("email", e)}
          />
          <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="password"
            placeholder="Password"
            variant="outlined"
            value={formData.password}
            onChange={(e) => handleChange("password", e)}
          />
          <InputTextField
            className="mb-3 w-fit border-2 py-3"
            type="password"
            placeholder="Confirm Password"
            variant="outlined"
            value={formData.confirmPassword}
            onChange={(e) => handleChange("confirmPassword", e)}
          />
        </div>
        <div>
          <button
            type="submit"
            className="w-full cursor-pointer rounded-lg border-none bg-primary py-3 font-semibold text-white outline-none"
          >
            Sign up
          </button>
          <div className="mt-2 text-center text-sm font-semibold text-secondary">
            Already have an account?{" "}
            <Link to="/" className="text-black">
              Login Here
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormArea;
