/* eslint-disable tailwindcss/no-custom-classname */
import AgencyLayout from "components/shared/AgencyLayout";
import React, { useState } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import Tabs from "components/shared/Tabs";
import Modal from "components/shared/Modal";
import { studentSidebar } from "components/shared/constants/agencyConstants";
import ContactSetting from "./design/ContactSetting";
import TimeSlots from "./design/TimeSlots";
import GeneralSetting from "./design/GeneralSetting";
import NotificationSetting from "./design/NotificationSetting";
import Payment from "./design/Payment";

const data = [
  {
    name: "General Settings",
    icon: <img src={`${BASE_IMG_URL}/img/person-black.svg`} alt="" />,
    iconWhite: <img src={`${BASE_IMG_URL}/img/person.svg`} alt="" />,
  },
  // {
  //   name: "Notifications",
  //   icon: <img src={`${BASE_IMG_URL}/img/notification.svg`} alt="" />,
  //   iconWhite: (
  //     <img src={`${BASE_IMG_URL}/img/notification-white.svg`} alt="" />
  //   ),
  // },
  {
    name: "Payments",
    icon: <img src={`${BASE_IMG_URL}/img/dollar-logo.svg`} alt="" />,
    iconWhite: <img src={`${BASE_IMG_URL}/img/dollar-logo-white.svg`} alt="" />,
  },
];

const StudentSetting = () => {
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [settingOptions, setSettingoptions] = useState();

  const openAddTutorHandler = (value) => {
    setModal(true);
    setSettingoptions(value);
  };

  const onSelect = (index) => {
    setTab(index);
  };

  const onClose = () => {
    setModal(false);
  };

  const rendered = (e) => {
    switch (e) {
      case 0:
        return <ContactSetting onClose={onClose} />;
      case 1:
        return <TimeSlots onClose={onClose} />;
      default:
        return <ContactSetting onClose={onClose} />;
    }
  };

  const TabsRendered = (e) => {
    switch (e) {
      case 0:
        return <GeneralSetting openAddTutorHandler={openAddTutorHandler} />;
      case 1:
        return <Payment />;
      case 2:
        return <NotificationSetting />;
      default:
        return <GeneralSetting openAddTutorHandler={openAddTutorHandler} />;
    }
  };

  return (
    <AgencyLayout currentPage="Settings" sidebarData={studentSidebar}>
      <Modal
        footer={false}
        open={modal}
        close={(e) => setModal(e)}
        element={rendered(settingOptions)}
        width={1000}
      />
      <div className="flex h-full flex-col gap-6">
        <div>
          <Tabs data={data} selected={tab} onSelect={onSelect} />
        </div>
        {TabsRendered(tab)}
      </div>
    </AgencyLayout>
  );
};

export default StudentSetting;
