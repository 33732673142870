/* eslint-disable react/prop-types */
import React from "react";
import TextArea from "./designs/TextArea";
import FooterArea from "./designs/FooterArea";
import LogoArea from "./designs/LogoArea";
import FormArea from "./designs/FormArea";
import AuthLayout from "components/shared/AuthLayout";

const StudentSignUp = ({ customAgency }) => {
  return (
    <AuthLayout>
      <div className="flex min-h-screen flex-col">
        <LogoArea />
        <div className=" flex flex-1  flex-col justify-between rounded-t-3xl bg-lightGrey p-4 shadow">
          <div className="mx-2 grid min-h-[500px] grid-cols-1 md:grid-cols-5 lg:mx-16">
            <TextArea />
            <FormArea agencyName={customAgency} />
          </div>
          <FooterArea />
        </div>
      </div>
    </AuthLayout>
  );
};

export default StudentSignUp;
