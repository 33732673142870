import {
  AGENCY_DELETE_TIMELINE,
  CREATE_AGENCY_POST,
  CREATE_TUTOR_POST,
  GET_AGENCY_TIMELINE,
  GET_CALENDER,
  GET_TUTOR_TIMELINE,
  TUTOR_DELETE_TIMELINE,
  UPDATE_TIMELINE,
} from "store/types/timeline";
import { STUDENT_DELETE, TUTOR_DELETE, TUTOR_UPDATE } from "store/types/user";

export const createAgencyTimelinePostAction = (value) => {
  return {
    type: CREATE_AGENCY_POST,
    payload: value,
  };
};

export const createTutorTimelinePostAction = (value) => {
  return {
    type: CREATE_TUTOR_POST,
    payload: value,
  };
};

export const getAgencyTimelineAction = (value) => {
  return {
    type: GET_AGENCY_TIMELINE,
    payload: value,
  };
};

export const getTutorTimelineAction = (value) => {
  return {
    type: GET_TUTOR_TIMELINE,
    payload: value,
  };
};

export const getCalenderAction = (value) => {
  return {
    type: GET_CALENDER,
    payload: value,
  };
};

export const updatePostAction = (value) => {
  return {
    type: UPDATE_TIMELINE,
    payload: value,
  };
};
export const tutorDeletePostAction = (value) => {
  return {
    type: TUTOR_DELETE_TIMELINE,
    payload: value,
  };
};
export const agencyDeletePostAction = (value) => {
  return {
    type: AGENCY_DELETE_TIMELINE,
    payload: value,
  };
};
export const tutorDeleteAction = (value) => {
  return {
    type: TUTOR_DELETE,
    payload: value,
  };
};
export const studentDeleteAction = (value) => {
  return {
    type: STUDENT_DELETE,
    payload: value,
  };
};
export const tutorUpdateAction = (value) => {
  return {
    type: TUTOR_UPDATE,
    payload: value,
  };
};
