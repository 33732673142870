/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { toObject } from "components/utils/functions";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";

const TutorDetail = ({ data }) => {
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const [dataset, setDataset] = useState([
    {
      title: "Education",
      desc: [],
    },
    {
      title: "Charges per Hour",
      desc: [0],
    },
    {
      title: "Languages",
      desc: [],
    },
  ]);

  useEffect(() => {
    const temp = toObject(data?.education);
    let education = [];

    if (temp.length > 0) {
      education = temp.map((item) => item.name);
    }

    setDataset([
      {
        title: "Education",
        desc: education,
      },
      {
        title: "Charges per Hour",
        desc: [`$ ${data?.chargesPerHour}`] || [0],
      },
      {
        title: "Languages",
        desc: (data?.languages && toObject(data?.languages)) || [],
      },
    ]);
  }, [data]);

  return (
    <div className="flex h-fit w-full flex-col gap-4 rounded-lg bg-white p-4">
      {isLoading && !dataset ? (
        <Skeleton.Input active={true} />
      ) : (
        dataset?.map((item, idx) => (
          <div key={idx}>
            <div
              className={`${
                idx === 0 ? "pb-2" : "py-1"
              } text-xl font-bold text-[#303030]`}
            >
              {item?.title}
            </div>
            {item?.desc?.length > 0 &&
              item?.desc?.map((desc, idx2) => (
                <div key={idx2} className="flex items-center gap-2 py-1">
                  <div className="text-sm font-medium text-secondary">
                    {desc}
                  </div>
                </div>
              ))}
          </div>
        ))
      )}
    </div>
  );
};

export default TutorDetail;
