import StudentSignUp from "components/modules/StudentSignUp";
import React from "react";
import { useParams } from 'react-router-dom';

const CustomStudentSignUpPage = () => {
  const { customAgency } = useParams();
  return <StudentSignUp customAgency={customAgency} />;
};

export default CustomStudentSignUpPage;
