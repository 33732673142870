import React from "react";
import TextArea from "./designs/TextArea";
import FormArea from "./designs/FormArea";
import FooterArea from "./designs/FooterArea";
import LogoArea from "./designs/LogoArea";
import AuthLayout from "components/shared/AuthLayout";

const SignIn = () => {
  return (
    <AuthLayout>
      <div className="flex min-h-screen flex-col">
        <LogoArea />

        <div className=" flex flex-1  flex-col justify-between rounded-t-3xl bg-gradient-to-b from-[#0114B0] to-[#00084A] p-4 shadow">
          <div className="my-auto grid h-[500px] grid-cols-1 md:mx-16 lg:grid-cols-3">
            <div className="col-span-2 hidden md:block">
              <TextArea />
            </div>
            <FormArea />
          </div>
          <FooterArea />
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignIn;
