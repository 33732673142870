/* eslint-disable react/prop-types */
import React from "react";
import { Modal as AntdModal } from "antd";
const Modal = ({ open, close, element, width }) => {
  const handleOk = () => {
    close(false);
  };
  const handleCancel = () => {
    close(false);
  };
  return (
    <AntdModal
      open={open}
      onOk={handleOk}
      footer={false}
      onCancel={handleCancel}
      width={width}
      centered
    >
      {element}
    </AntdModal>
  );
};
export default Modal;
