import { BASE_IMG_URL } from "components/shared/constants";
import { pathnameHandler } from "components/utils/functions";
import React from "react";
import { Link } from "react-router-dom";

const WalletButton = () => {
  const pathname = window.location.pathname;
  return (
    <Link
      to={pathname.includes("student") || pathname.includes("tutor") ? `${pathnameHandler()}/wallet` : "/agency/wallet"}
      className="flex cursor-pointer gap-2 px-2 text-black"
    >
      <img src={`${BASE_IMG_URL}/img/wallet.svg`} alt="" />
      <div>Wallet</div>
    </Link>
  );
};

export default WalletButton;
