import React, { useEffect, useState } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import ListCard from "./designs/ListCard";
import { BASE_IMG_URL } from "components/shared/constants";
import {
  agencyWritePostOptions,
  settings,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import Modal from "components/shared/Modal";
import AddTutor from "./designs/AddTutor";
import Slider from "react-slick";
import Post from "components/ui/Post";
import { useDispatch, useSelector } from "react-redux";
import {
  getClassRequest,
  getSubscribedStudentsAction,
  getTutorPosts,
  getUserFiles,
  subscribeToTutor,
} from "store/action/user";
import {
  getCalenderAction,
  tutorDeletePostAction,
} from "store/action/timelines";
import DashboardCard from "components/shared/DashbaordCard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CreatePost from "components/shared/CreatePost";
// import Image from "components/shared/Image";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";
import Upload from "../AgencyHandouts/designs/Upload";
import Subscribed from "components/shared/Subscribed";
import { useNavigate } from "react-router-dom";

const TutorDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [post, setPost] = useState("");

  const [group, setGroup] = useState("post");

  const subscribedStudents = useSelector(
    (state) => state.users.subscribedStudents
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [editPost, setEditPost] = useState(null);

  const user = useSelector((state) => state.users.user);
  const posts = useSelector((state) => state.users.posts);
  const classRequest = useSelector((state) => state.users.classRequest);
  const events = useSelector((state) => state.users.events);

  useEffect(() => {
    if (user?.id) {
      dispatch(getClassRequest(user?.id));
    }
  }, [user]);
  useEffect(() => {
    dispatch(getTutorPosts());
    dispatch(getSubscribedStudentsAction());
    dispatch(getCalenderAction());
    dispatch(getUserFiles(user));
  }, []);
  const openAddTutorHandler = (value) => {
    setEditPost(null);
    if (value === "create-post") {
      setPost(value);
      setGroup("post");
    } else if (value === "upload") {
      setPost(value);
    } else if (value === "announcement") {
      setPost(value);
      setGroup("announcement");
    }
    setModal(true);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };
  const handleNavigate = (data) => {
    navigate(`/tutor/student/${data?.studentId}`);
  };
  const handleDetail = (data) => {
    navigate(`/tutor/${data?.id}`);
  };

  const bookedSlots = (value, amount) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      subscribeToTutor({
        classes: value,
        tutorId: selectedEvent?.tutorId,
        studentId: selectedEvent?.studentId,
        amount,
        timezone,
        id: selectedEvent?.id,
        paid: true,
      })
    );
    handleModalCancel();
  };
  const handleUpdate = (data) => {
    setEditPost(data);
    if (data.group === "post") {
      setPost("create-post");
      setGroup(data.group);
    } else if (data.group === "handout") {
      setPost("upload");
      setGroup(data.group);
    } else if (data.group === "announcement") {
      setPost(data.group);
      setGroup(data.group);
    }

    setModal(true);
    console.log(data, "update");
  };
  const handleDelete = (data) => {
    dispatch(tutorDeletePostAction(data)); //
    console.log(data, "delete");
  };
  return (
    <AgencyLayout currentPage="Home" sidebarData={tutorSidebar}>
      <Modal
        open={modal}
        close={(e) => setModal(e)}
        element={
          post === "create-post" || post === "announcement" ? (
            <CreatePost
              close={() => setModal(false)}
              group={group}
              post={editPost}
            />
          ) : post === "upload" ? (
            <Upload
              close={() => setModal(false)}
              group="handout"
              fileId={editPost?.id}
            />
          ) : (
            <AddTutor name={"student"} close={() => setModal(false)} />
          )
        }
      />
      <Modal
        open={modalVisible}
        close={handleModalCancel}
        element={
          <Subscribed
            chargesPerHour={user?.chargesPerHour}
            bookedSlots={bookedSlots}
            data={user}
            update
          />
        }
      />
      <div className="grid grid-cols-1 gap-0 lg:gap-8 xl:grid-cols-4">
        <div className="col-span-2 flex flex-col gap-4 xl:col-span-3">
          <div>
            {events.length > 3 ? (
              <Slider {...settings}>
                {events?.map((item, idx) => (
                  <DashboardCard
                    key={idx}
                    data={item}
                    handleNavigate={handleNavigate}
                    handleSelectEvent={handleSelectEvent}
                  />
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-1 justify-between sm:grid-cols-2 md:grid-cols-3">
                {events?.map((item, idx) => (
                  <DashboardCard
                    key={idx}
                    handleNavigate={handleNavigate}
                    data={item}
                    handleSelectEvent={handleSelectEvent}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="box-shadow w-full rounded-lg bg-white">
            <div className="flex gap-4 p-2 md:p-6">
              <div className="size-12">
                <Avatar
                  avatar={user?.avatar}
                  alt={user?.username || user?.firstName}
                  className="size-12 cursor-pointer rounded-full"
                  handleClick={() => handleDetail(user)}
                />
              </div>
              <div className="flex-1">
                <div
                  className="cursor-pointer rounded-xl  border border-[#D5D5D5] py-3 pl-2 text-secondary"
                  onClick={() => openAddTutorHandler("create-post")}
                >
                  Share with your community!
                </div>
                <div className=" mt-4 flex flex-wrap justify-between px-3">
                  {agencyWritePostOptions?.map((item, idx) => (
                    <div
                      key={idx}
                      className="flex cursor-pointer items-center gap-2 font-medium text-secondary"
                      onClick={() =>
                        openAddTutorHandler(
                          item?.name === "Handouts"
                            ? "upload"
                            : item?.name === "Announcement"
                            ? "announcement"
                            : "create-post"
                        )
                      }
                    >
                      <img
                        src={`${BASE_IMG_URL}/img/${item?.img}.svg`}
                        alt=""
                      />
                      <div className="hidden md:block">{item?.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {posts?.map((item, idx) => (
            <Post
              key={idx}
              item={item}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
          ))}
        </div>
        <div className="mt-4 flex max-h-full w-full flex-col gap-4 xl:mt-0">
          <ListCard
            heading="Your Students"
            data={subscribedStudents}
            noItem={`No Subscribed Student Found!`}
            addHandler={openAddTutorHandler}
            viewProfile
          />
          <ListCard
            heading="Class Request"
            data={classRequest}
            noItem={`No Class Request at this moment!`}
            viewAll="/tutor/class-request"
          />
        </div>
      </div>
    </AgencyLayout>
  );
};

export default TutorDashboard;
