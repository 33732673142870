import React from "react";
import Header from "components/shared/Header";
import Otp from "./designs/Otp";
import NewPassword from "./designs/NewPassword";
import ResetCompleted from "./designs/ResetCompleted";
import AuthLayout from "components/shared/AuthLayout";
import { useSelector } from "react-redux";

const CodeVerification = () => {
  const step = useSelector((state) => state.notifications.step);
  const agency = useSelector((state) => state.supers.agency);
  const { backgroundColor } = agency || {};

  const rendered = (value) => {
    if (value === 0) {
      return <Otp />;
    } else if (value === 1) {
      return <NewPassword />;
    } else if (value === 2) {
      return <ResetCompleted />;
    } else {
      return <Otp />;
    }
  };

  return (
    <AuthLayout>
      <div className="flex h-screen flex-col bg-[#F9F9F9]">
        <Header
          className={
            backgroundColor
              ? "bg-[--bg-primary-color]"
              : "bg-gradient-to-b from-[#0114B0] to-[#010B60]"
          }
          path={"/"}
        />
        <div className="flex flex-1 items-center justify-center">
          <div className="h-[350px] w-[550px] rounded-sm border-2 bg-[#FFF] px-16 py-12 text-center">
            {rendered(step)}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default CodeVerification;
