/* eslint-disable react/prop-types */
/* eslint-disable tailwindcss/no-custom-classname */
import React from "react";
import { Divider } from "antd";

const Stepper = ({ totalSteps, currentStep, color, onStepClick }) => {
  const handleStepClick = (step) => {
    if (step <= currentStep) {
      onStepClick(step);
    }
  };

  const renderSteps = () => {
    const steps = [];
    for (let step = 1; step <= totalSteps; step++) {
      const isActive = step <= currentStep;
      const stepStyle = {
        background: isActive ? color : "#F6F6F6",
        color: isActive ? "#fff" : "#828282",
        cursor: step <= currentStep ? "pointer" : "not-allowed",
      };

      steps.push(
        <div key={step} className="flex items-center">
          <div
            className="flex size-8 items-center justify-center rounded-full text-white"
            style={stepStyle}
            onClick={() => handleStepClick(step)}
          >
            {step}
          </div>
          <div className="px-2 md:px-4">
            {step !== totalSteps && (
              <Divider
                className="h-[5px] w-8 md:w-24 sm:w-12 rounded-lg"
                style={stepStyle}
              />
            )}
          </div>
        </div>
      );
    }
    return steps;
  };

  return <div className="flex w-full items-center">{renderSteps()}</div>;
};

export default Stepper;
