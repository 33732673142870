import React, { useState } from "react";
import StepOne from "./designs/StepOne";
import StepTwo from "./designs/StepTwo";
import StepThree from "./designs/StepThree";
import StepFour from "./designs/StepFour";
import AuthLayout from "components/shared/AuthLayout";

const TutorStepperForm = () => {
  const [stepper, setStepper] = useState(0);
  const [formData, setFormData] = useState({
    stepOneData: {},
    stepTwoData: {
      education: [],
      experience: 0,
      subject: [],
      languages: [],
    },
    stepThreeData: {},
    stepFourData: {},
  });

  const handleStepper = (value) => {
    setStepper(value);
  };

  const handleFormDataChange = (step, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data,
    }));
  };

  const steps = {
    0: (
      <StepOne
        data={formData.stepOneData}
        onChange={(data) => handleFormDataChange("stepOneData", data)}
        handleStepper={handleStepper}
      />
    ),
    1: (
      <StepTwo
        data={formData.stepTwoData}
        onChange={(data) => handleFormDataChange("stepTwoData", data)}
        handleStepper={handleStepper}
      />
    ),
    2: (
      <StepThree
        data={formData.stepThreeData}
        onChange={(data) => handleFormDataChange("stepThreeData", data)}
        handleStepper={handleStepper}
      />
    ),
    3: (
      <StepFour
        data={formData.stepFourData}
        onChange={(data) => handleFormDataChange("stepFourData", data)}
        handleStepper={handleStepper}
        allData={formData}
      />
    ),
  };

  const Renderer = (step) => {
    return steps[step] || <StepOne />;
  };

  return <AuthLayout>{Renderer(stepper)}</AuthLayout>;
};

export default TutorStepperForm;
