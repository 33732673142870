import React from 'react';
import { Spin } from 'antd';

const Loader = () => {
    return (
        <div className='flex h-screen w-screen items-center justify-center'><Spin size="large" /></div>
    );
};

export default Loader;
