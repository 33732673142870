import { formatDate } from "components/utils/functions";
import React from "react";
import { BsClock } from "react-icons/bs";
import { useSelector } from "react-redux";

const NotificationDropdown = () => {
  const notification = useSelector((state) => state.users.notification);
  return (
    <div className="z-[1000] max-h-[22rem] w-fit overflow-y-auto rounded-xl bg-white p-4 md:w-[400px]">
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold text-black">Notifications</div>
      </div>
      <div className="flex flex-col gap-2 p-3">
        {notification?.map((item, idx2) => (
          <div
            key={idx2}
            className="flex items-center gap-2 rounded-xl bg-lightGrey p-2"
          >
            <div className="flex size-8 items-center justify-center rounded-[50%] bg-white">
              <BsClock />
            </div>
            <div>
              <div className="text-[16px]">{item.notification}</div>
              <div className="text-[12px] text-secondary">
                {formatDate(item?.createdAt)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationDropdown;
