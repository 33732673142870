/* eslint-disable react/prop-types */
import { Skeleton } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import ReactStars from "react-stars";

const ReviewCard = ({ data }) => {
  const isLoading = useSelector((state) => state.notifications.isLoading);

  return (
    <div className=" flex h-fit w-full gap-6 rounded-lg bg-white p-4">
      <div className="flex flex-col">
        <div className="text-xl font-semibold">
          Reviews
          <span className="text-gray text-lg "> ({data?.rating || 0})</span>
        </div>
        {isLoading && !data ? (
          <Skeleton.Input active={true} />
        ) : (
          <>
            <ReactStars
              count={5}
              size={20}
              color2={"#ffd700"}
              value={data?.rating || 0}
              edit={false}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
