/* eslint-disable react/prop-types */
import React from "react";

const TableDropdown = ({ data, clickHandler }) => {
  return (
    <div className="flex flex-col gap-3 rounded-xl border-2 bg-white p-3">
      {data?.map((item, idx) => (
        <div
          className="flex cursor-pointer gap-2 border-b-2 border-lightGrey pb-[1px]"
          key={idx}
          onClick={() => clickHandler(idx, item?.key || item?.name)}
        >
          <div>{item?.icon}</div>
          <div className="text-sm font-semibold text-secondary">
            {item?.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableDropdown;
