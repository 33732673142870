/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Header from "components/shared/Header";
import AgencyDetails from "./designs/AgencyDetails";
import TutorForm from "./designs/TutorForm";
import AgencyLoadingScreen from "./designs/AgencyLoadingScreen";
import AgencySuccessFullScreen from "./designs/AgencySuccessFullScreen";
import AuthLayout from "components/shared/AuthLayout";
import SubscriptionPlan from "../SubscriptionPlan";
import { useDispatch, useSelector } from "react-redux";
import { agencyRegisterAction, agencyUpdateDetail } from "store/action/user";
import { useNavigate } from "react-router-dom";
import { setSubscription } from "store/slices/super";
import Verification from "./designs/Verification";
import { setForgetUserSlice } from "store/slices/users";
import { setAgencyStep } from "store/slices/alert";
import { verifySubscription } from "store/services/user.service";

const AgencyForm = () => {
  const forgotUser = useSelector((state) => state.users.forgotUser);
  const [agencyFormData, setAgencyFormData] = useState({});
  const subscriptionId = useSelector((state) => state.supers.subscription);
  const step = useSelector((state) => state.notifications.agencyStep);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAgencyForm = (value) => {
    setAgencyFormData({ ...agencyFormData, ...value });
    sessionStorage.setItem(
      "agencyForm",
      JSON.stringify({ ...agencyFormData, ...value })
    );
  };

  const submitAgencyHandler = (data) => {
    setAgencyFormData(data);
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    dispatch(setForgetUserSlice({ email: data?.email }));
    dispatch(agencyRegisterAction(formData));
  };

  const handleUpdateAgencyHandler = (data) => {
    dispatch(agencyUpdateDetail(data));
  };

  const handleSubscriptionVerification = async () => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      try {
        const result = await verifySubscription(subscriptionId);
        if (result) {
          handleUpdateAgencyHandler({
            id: forgotUser.isVerified?.id,
            ...subscriptionId,
            subscriptionId: subscriptionId?.subscription,
          });
          dispatch(setSubscription({}));
          dispatch(setAgencyStep(0));
          navigate("/sign-in");
        }
      } catch (error) {
        console.error("Error verifying subscription:", error);
      }
    }
  };
  useEffect(() => {
    handleSubscriptionVerification();
  }, [subscriptionId]);

  const rendered = (value) => {
    switch (value) {
      case 0:
        return (
          <AgencyDetails
            data={agencyFormData}
            handleAgencyForm={submitAgencyHandler}
          />
        );
      case 1:
        return <Verification handleAgencyForm={submitAgencyHandler} />;
      case 2:
        return (
          <SubscriptionPlan
            handleAgencyForm={handleAgencyForm}
            agencyFormData={agencyFormData}
          />
        );
      case 3:
        return <TutorForm />;
      case 4:
        return <AgencyLoadingScreen />;
      case 5:
        return <AgencySuccessFullScreen />;
      default:
        return <AgencyDetails />;
    }
  };

  return (
    <AuthLayout>
      <div className="flex min-h-screen flex-col">
        <Header
          className="bg-gradient-to-b from-[#0114B0] to-[#010B60] pl-6"
          path={"/"}
        />
        <div className="flex h-full flex-1 items-center justify-center bg-[#f7f5ff] py-4">
          {rendered(step)}
        </div>
      </div>
    </AuthLayout>
  );
};

export default AgencyForm;
