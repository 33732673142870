/* eslint-disable react/prop-types */
import { BASE_IMG_URL } from "components/shared/constants";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { agencyDelete, verifyOtp } from "store/action/user";

const Verification = ({ setData }) => {
  const [otp, setOtp] = useState();
  const forgotUser = useSelector((state) => state.users.forgotUser);
  const agency = useSelector((state) => state.supers?.agency);

  const dispatch = useDispatch();
  const onChange = (value) => {
    setOtp(value);
  };
  const handleBack = () => {
    dispatch(agencyDelete(agency));
  };

  const handleSubmit = () => {
    if (otp?.length === 6) {
      dispatch(verifyOtp({ otp, email: forgotUser?.email }));
    } else {
      toast.error("enter your Otp");
    }
  };
  return (
    <div className="m-auto flex h-[350px] w-[550px] flex-col justify-between rounded-2xl bg-white p-4 text-center">
      <div className={` text-2xl font-bold `}>Code Verification</div>
      <div className="text-[15px] font-semibold text-secondary">
        Enter your one time password we sent on your email {forgotUser?.email}{" "}
      </div>

      <div className={`text-left text-sm font-bold `}>
        <OtpInput
          value={otp}
          onChange={onChange}
          numInputs={6}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            border: "1px solid #B5B5B5",
            margin: "auto",
            borderRadius: "5px",
          }}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="flex justify-between">
        <Link
          className="flex cursor-pointer items-center gap-2"
          onClick={handleBack}
        >
          <img src={`${BASE_IMG_URL}/img/arrow-left.svg`} alt="" />
          <div className="font-bold text-odinPrimary">Back</div>
        </Link>
        <button
          type="submit"
          onClick={handleSubmit}
          className={
            "w-auto rounded-lg bg-gradient-to-b  from-[#0114B0] to-[#010B60]  px-4 py-2 font-semibold text-white"
          }
        >
          Verify Code
        </button>{" "}
      </div>
    </div>
  );
};

export default Verification;
