/* eslint-disable no-undef */
import React, { useEffect } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import {
  agencySidebar,
  studentSidebar,
  tutorSidebar,
  walletTableColumn,
} from "components/shared/constants/agencyConstants";
import DemoColumn from "components/ui/ColumnGraph";
import TableUi from "components/ui/Table";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyWallet } from "store/action/user";
import WalletLine from "./WalletChart";

const Wallet = () => {
  const role = sessionStorage.getItem("odin-auth-role");
  const wallet = useSelector((state) => state.users.wallet);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAgencyWallet());
  }, []);
  return (
    <AgencyLayout
      sidebarData={
        role === "student"
          ? studentSidebar
          : role === "tutor"
          ? tutorSidebar
          : agencySidebar
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-end">
          <div className="w-fit border-b-4 border-secondary text-xl font-semibold">
            Wallet
          </div>
          <div className="flex-1 border-b-2"></div>
        </div>
        <div className="grid  grid-cols-3 gap-4 md:grid-cols-9">
          <div className="col-span-3 rounded-lg bg-white p-2 shadow-md">
            <div className="border-b-2 pb-2 text-secondary">Total Balance</div>
            <div className="my-2 text-2xl font-semibold text-black">
              $ {wallet?.totalPayment}{" "}
            </div>
            <div className="my-4 flex flex-col gap-4">
              <div className="flex justify-between">
                <div className="text-secondary">Tutoring Fee</div>
                <div>$00.00</div>
              </div>
              <div className="flex justify-between">
                <div className="text-secondary">Revenue</div>
                <div>$ {wallet?.totalPayment}</div>
              </div>
              <div className="flex justify-between">
                <div className="text-secondary">Expense</div>
                <div>$00.00</div>
              </div>
            </div>
          </div>
          <div className="col-span-3 rounded-lg bg-white p-2 shadow-md md:col-span-4">
            <div className="border-b-2 pb-2 text-secondary">Total Revenue</div>
            <WalletLine monthlyRevenueArray={wallet?.monthlyRevenueArray} />
          </div>
          <div className="col-span-3 rounded-lg bg-white p-2 shadow-md md:col-span-2">
            <div className="border-b-2 pb-2 text-secondary">
              Monthly Revenue
            </div>
            <div className="flex flex-col items-center justify-around">
              <DemoColumn />
              <div className="text-3xl font-semibold">
                $ {wallet?.totalPayment}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-end">
          <div className="w-fit border-b-4 border-secondary text-xl font-semibold">
            Payment History
          </div>
          <div className="flex-1 border-b-2 "></div>
        </div>
        <div className="rounded-xl bg-white p-4">
          <TableUi
            data={wallet?.studentHistory || []}
            headerBg="#303030"
            headerColor="#fff"
            rowSelection
            columns={walletTableColumn}
            pageSize={6}
          />
        </div>
      </div>
    </AgencyLayout>
  );
};

export default Wallet;
