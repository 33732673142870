/* eslint-disable react/prop-types */
import { Skeleton } from "antd";
import React from "react";
import { FaCcVisa } from "react-icons/fa";
import { useSelector } from "react-redux";

const CardItem = ({ paymentMethod }) => {
  const isLoading = useSelector((state) => state.notifications.isLoading);
  return (
    <div className="mt-8 flex flex-col gap-3">
      {isLoading && !paymentMethod ? (
        <Skeleton paragraph />
      ) : (
        paymentMethod?.length > 0 &&
        paymentMethod?.map((item, idx) => (
          <div
            key={idx}
            className="flex justify-between rounded-lg bg-[#8DA8FE33] px-2 py-3"
          >
            <div className="w-1/5">
              {item?.card?.display_brand === "visa" && (
                <FaCcVisa color="navy" size={20} />
              )}
            </div>
            *** *** *** {item?.card?.last4}
            <div className="flex gap-2">{/* <FiTrash2 size={20} /> */}</div>
          </div>
        ))
      )}
    </div>
  );
};

export default CardItem;
