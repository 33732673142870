import { BASE_IMG_URL } from "components/shared/constants";
import React from "react";

const LogoutButton = () => {
    return (
        <div className="flex gap-2 px-2">
            <img src={`${BASE_IMG_URL}/img/log-out.svg`} alt="" />
            <div>Logout</div>
        </div>
    );
};

export default LogoutButton;
