/* eslint-disable react/prop-types */
import React, { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePicker } from "antd";
import styles from "./time.css";
dayjs.extend(customParseFormat);

const TimePickerUi = ({ onChange, value, disabled }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (open) => {
    setOpen(open);
  };

  const handleOk = (value) => {
    setOpen(false);
    onChange(value);
  };
  return (
    <TimePicker
      className={styles.customOkButton}
      onChange={onChange}
      value={value}
      format="HH:mm"
      defaultOpenValue={dayjs("00:00", "HH:mm")}
      open={open}
      onOpenChange={handleOpenChange}
      onOk={handleOk}
      {...disabled}
    />
  );
};
export default TimePickerUi;
