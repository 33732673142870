import AuthLayout from 'components/shared/AuthLayout'
import React from 'react'
import FormArea from './designs/FormData'

const AdminSignin = () => {

    return (
        <AuthLayout>
            <div className='flex h-screen w-screen items-center justify-center'>
                <FormArea />
            </div>
        </AuthLayout>
    )
}

export default AdminSignin