/* eslint-disable tailwindcss/no-custom-classname */
import AgencyLayout from "components/shared/AgencyLayout";
import React, { useState } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import Tabs from "components/shared/Tabs";

import NotificationSetting from "./design/NotificationSetting";
import Payment from "./design/Payment";
import Feedback from "./design/Feedback";

const data = [
  {
    name: "Notifications",
    icon: <img src={`${BASE_IMG_URL}/img/notification.svg`} alt="" />,
    iconWhite: (
      <img src={`${BASE_IMG_URL}/img/notification-white.svg`} alt="" />
    ),
  },
  {
    name: "Payments",
    icon: <img src={`${BASE_IMG_URL}/img/dollar-logo.svg`} alt="" />,
    iconWhite: <img src={`${BASE_IMG_URL}/img/dollar-logo-white.svg`} alt="" />,
  },
  {
    name: "Feedback",
    icon: <img src={`${BASE_IMG_URL}/img/star-black.svg`} alt="" />,
    iconWhite: <img src={`${BASE_IMG_URL}/img/star-white.svg`} alt="" />,
  },
];

const AgencySetting = () => {
  const [tab, setTab] = useState(0);

  const onSelect = (index) => {
    setTab(index);
  };

  const TabsRendered = (e) => {
    switch (e) {
      case 0:
        return <NotificationSetting />;
      case 1:
        return <Payment />;
      case 2:
        return <Feedback />;
      default:
        return <NotificationSetting />;
    }
  };

  return (
    <AgencyLayout currentPage="Settings">
      <div className="flex size-full flex-col gap-6 ">
        <div>
          <Tabs data={data} selected={tab} onSelect={onSelect} />
        </div>
        {TabsRendered(tab)}
      </div>
    </AgencyLayout>
  );
};

export default AgencySetting;
