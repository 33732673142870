import React, { useState } from "react";
import { Divider } from "antd";
import TimeTableSettings from "components/modules/TutorSetting/design/TimeTableSettings";
import Stepper from "components/ui/Stepper";
import PropTypes from "prop-types";

const StepFour = ({ data, onChange, allData, handleStepper }) => {
  const [formData, setFormData] = useState(data);
  const handleUpdateFormData = (updatedData) => {
    const newFormData = {
      ...formData,
      ...updatedData,
    };
    setFormData(newFormData);
    onChange(newFormData);
  };

  const onClose = () => {
    // handleSubmit();
  };
  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex h-full flex-1 items-center justify-center">
        <div className="flex min-h-[500px] w-4/5 flex-col justify-between rounded-md bg-white p-4 md:p-8 shadow-md lg:w-[50%]">
          <div className="text-center text-2xl font-bold text-[#303030]">
            Tutor Time Slot Details{" "}
          </div>
          <div className="mx-auto">
            <Stepper totalSteps={4} currentStep={4} color="#303030" />
          </div>
          <Divider />
          <div>
            <TimeTableSettings
              onClose={onClose}
              setFormData={handleUpdateFormData}
              allData={allData}
            />
          </div>
          <div
            className="flex cursor-pointer gap-2"
            onClick={() => handleStepper(2)}
          >
            <div>Back</div>
          </div>
        </div>
      </div>
    </div>
  );
};
StepFour.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  handleStepper: PropTypes.func.isRequired,
  allData: PropTypes.object.isRequired,
};
export default StepFour;
