/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PlanItem from "./PlanItem";
import { Skeleton, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlanList } from "store/action/super";

const PlansList = ({ agencyFormData, selectedPlan, close }) => {
  const [selectPlan, setSelectPlan] = useState(1);
  const plans = useSelector((state) => state.supers.plans);
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPlanList());
  }, []);

  const handleSelect = (index) => {
    setSelectPlan(index);
  };

  const selectedPlanHandler = (index) => {
    selectedPlan(index);
  };

  return (
    <div className="flex flex-col flex-wrap justify-center gap-[35px] text-[16px] font-bold md:flex-row">
      {isLoading ? (
        [...Array(3)].map((_, idx) => (
          <Card
            key={idx}
            className="h-[544px]"
            style={{
              width: 387,
              borderRadius: 25,
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
              marginBottom: 35,
            }}
          >
            <Skeleton active paragraph={{ rows: 10 }}>
              <div className="flex flex-col items-center justify-between">
                <div className="mb-4 h-8 w-full"></div>
                <div className="mb-4 h-4 w-full"></div>
                <div className="mb-4 h-8 w-full"></div>
                <div className="mb-4 h-8 w-full"></div>
                <div className="mb-4 h-8 w-full"></div>
                <div className="mb-4 h-8 w-full"></div>
                <div className="mb-4 h-8 w-full"></div>
                <div className="h-10 w-full"></div>
              </div>
            </Skeleton>
          </Card>
        ))
      ) : plans && plans.length > 0 ? (
        plans.map((item, idx) => (
          <div key={idx}>
            <PlanItem
              agencyFormData={agencyFormData}
              item={item}
              handleSelect={handleSelect}
              selectPlan={selectPlan}
              selectedPlan={selectedPlanHandler}
              idx={idx}
              close={close}
            />
          </div>
        ))
      ) : (
        <div>No plans available at the moment.</div>
      )}
    </div>
  );
};

export default PlansList;
