export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const LOGIN_USER = "LOGIN_USER";
export const VALID_SUBDOMAIN = "VALID_SUBDOMAIN";
export const REGISTER_USER = "REGISTER_USER";
export const AGENCY_LOGIN = "AGENCY_LOGIN";
export const AGENCY_REGISTER = "AGENCY_REGISTER";
export const GET_AGENCY_BY_DOMAIN = "GET_AGENCY_BY_DOMAIN";
export const AGENCY_STUDENTS = "AGENCY_STUDENTS";
export const AGENCY_TUTORS = "AGENCY_TUTORS";
export const AGENCY_DELETE = "AGENCY_DELETE";
export const CREATE_TUTOR_TIMETABLE = "CREATE_TUTOR_TIMETABLE";
export const STUDENTS_SUBSCRIBED_TUTORS = "STUDENTS_SUBSCRIBED_TUTORS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SUBSCRIBE_TO_TUTOR = "SUBSCRIBE_TO_TUTOR";
export const GET_POST = "GET_POST";
export const GET_TUTOR_POST = "GET_TUTOR_POST";
export const GET_STUDENT_POST = "GET_STUDENT_POST";
export const GET_CLASS_REQUEST = "GET_CLASS_REQUEST";
export const GET_SUBSCRIBED_STUDENTS = "GET_SUBSCRIBED_STUDENTS";
export const GET_CONNECTED_ACCOUNT_STATUS = "GET_CONNECTED_ACCOUNT_STATUS";
export const TUTOR_DELETE = "TUTOR_DELETE";
export const STUDENT_DELETE = "STUDENT_DELETE";
export const TUTOR_UPDATE = "TUTOR_UPDATE";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const VERIFY_OTP = "VERIFY_OTP";
export const SET_PASSWORD = "SET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const USER_FILES = "USER_FILES";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const DELETE_USER_FILE = "DELETE_USER_FILE";
export const UPLOAD_HANDOUT = "UPLOAD_HANDOUT";
export const UPDATE_HANDOUT = "UPDATE_HANDOUT";
export const GET_AGENCY_WALLET = "GET_AGENCY_WALLET";
export const AGENCY_UPDATE = "AGENCY_UPDATE";
export const AGENCY_UPDATE_DETAIL = "AGENCY_UPDATE_DETAIL";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const AGENCY_ACCEPT_CLASS = "AGENCY_ACCEPT_CLASS";
export const TUTOR_ACCEPT_CLASS = "TUTOR_ACCEPT_CLASS";
export const TUTOR_ACCEPT_CLASS_ALL = "TUTOR_ACCEPT_CLASS_ALL";
export const ADD_POST_BY_AGENCY = "ADD_POST_BY_AGENCY";
export const STUDENT_PAYMENT_HISTORY = "STUDENT_PAYMENT_HISTORY";
export const AGENCY_CARD_PAYMENT_METHOD = "CARD_PAYMENT_METHOD";
export const GET_STUDENT_DETAIL = "GET_STUDENT_DETAIL";
export const ADD_REVIEW = "ADD_REVIEW";
export const GET_STUDENT_ACTIVITIES = "GET_STUDENT_ACTIVITIES";

export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_COUNTRY_STATE = "GET_COUNTRY_STATE";
export const GET_CITY_STATE = "GET_CITY_STATE";
export const GET_AGENCY_DETAIL = "GET_AGENCY_DETAIL";
export const GET_MAIL_FOR_AGENCY = "GET_MAIL_FOR_AGENCY";
export const GET_MAIL_FOR_TUTOR = "GET_MAIL_FOR_TUTOR";
