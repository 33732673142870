import React from "react";
import { BASE_IMG_URL } from "components/shared/constants";

const WelcomePage = () => {
  const containerStyle = {
    background: `url( "${BASE_IMG_URL}/img/welcome-page-back.svg") center center / cover no-repeat var(--primary-color)`,
    height: "100vh",
    width: "100vw",
  };

  return (
    <div
      className="h-screen w-screen bg-gradient-to-b from-[#0114B0] to-[#010B60] text-white"
      style={containerStyle}
    >
      <div className="grid h-full grid-cols-2">
        <div className="mr-[-100px] flex h-full items-center justify-end">
          <div>
            <div className="mb-4 text-center text-7xl">
              Welcome to ABC <br />
              Agency
            </div>
            <div className="text-center text-lg">We’re Here to Help you!</div>
          </div>
        </div>
        <div className="flex h-full items-center justify-center">
          <img src={`${BASE_IMG_URL}/img/men-holding-laptop.svg`} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
