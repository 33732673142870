/* eslint-disable camelcase */
/* eslint-disable no-undef */
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import AgencyFormPage from 'pages/agency/agency-form/index.js'
import AgencyWelcomePage from 'pages/agency/welcome-page/index.js'
import AgencyDashboardPage from 'pages/agency/dashboard/index.js'
import SignInPage from 'pages/sign-in/index.js'
import ForgetPasswordPage from 'pages/agency/forget-password/index.js'
import VerificationPage from 'pages/agency/forget-password/Verification'
import AgencyStudentPage from 'pages/agency/students'
import AgencyCalenderPage from 'pages/agency/calenders'
import AgencySettingPage from 'pages/agency/settings'
import AgencyTutorsPage from 'pages/agency/tutors'
import AgencyHandoutsPage from 'pages/agency/handouts'
import AgencyClassRequestPage from 'pages/agency/class-request'
import AgencyAdminPage from 'pages/agency/admin'
import AgencyAdminStatisticsPage from 'pages/agency/admin/statistics'
import AgencyAdminBillingPage from 'pages/agency/admin/billing'
import AgencyAdminPackagePage from 'pages/agency/admin/package'
import AgencyAdminUserManagementPage from 'pages/agency/admin/user-management'
import AdminDashboardPage from 'pages/admin/dashboard'
import AdminSettingsPage from 'pages/admin/settings'
import CustomStudentSignInPage from 'pages/student/sign-in'
import CustomStudentSignUpPage from './pages/student/sign-up'
import CustomStudentDashboardPage from 'pages/student/dashboard'
import CustomStudentCalenderPage from 'pages/student/calender'
import CustomStudentTutorPage from 'pages/student/tutor'
import CustomStudentSettingPage from 'pages/student/settings'
import CustomTutorDashboardPage from 'pages/tutor/dashboard'
import CustomTutorCalenderPage from 'pages/tutor/calender'
import CustomTutorSettingPage from 'pages/tutor/settings'
import CustomTutorHandoutsPage from 'pages/tutor/handouts'
import CustomTutorClassRequestPage from 'pages/tutor/class-request'
import CustomStudentsProfilePage from 'pages/student/id'
import CustomTutorProfilePage from 'pages/tutor/id'
import ChatPage from 'pages/chats'
import WalletPage from 'pages/Wallet'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuthentication, getSubdomain } from 'components/utils/functions'
import { setDomainSlice } from 'store/slices/theme'
import { getAgencyByDomainAction } from 'store/action/user'
import NotFound from 'pages/404'
import Loader from 'pages/Loader'
import SingleChatPage from 'pages/chats/id'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SuperAdminSignUp from 'pages/admin/signup'
import SuperAdminSignin from 'pages/admin/signin'
import TutorStepperForm from 'components/modules/TutorSignUp/TutorStepperForm'
import RedirectComponent from 'components/shared/Redirect'
import AgencyProfilePage from 'pages/agency/id'
import HomePage from 'pages/home'

const App = () => {
  const [subDomain, setDomain] = useState(false)
  const [loader, setLoader] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accessToken = useSelector(state => state.users.accessToken)
  const user = useSelector(state => state.users.user)
  const agency = useSelector(state => state.supers.agency)
  const role = sessionStorage.getItem('odin-auth-role')
  const tempSubdomain = getSubdomain()

  useLayoutEffect(() => {
    setDomain(tempSubdomain)
    dispatch(setDomainSlice(tempSubdomain))
    if (tempSubdomain) {
      dispatch(getAgencyByDomainAction(tempSubdomain))
    }
  }, [])

  useEffect(() => {
    if (
      (user && user?.role && user?.role === 'tutor') ||
      user?.role === 'student'
    ) {
      const root = document.documentElement
      root.style.setProperty(
        '--bg-primary-color',
        agency.backgroundColor || '"#828282"'
      )
      root.style.setProperty('--text-primary-color', agency.textColor || '#fff')
      console.log('root if')
    } else {
      console.log('root else')
      const root = document.documentElement
      root.style.setProperty('--bg-primary-color', '#828282')
      root.style.setProperty('--text-primary-color', '#fff')
    }
  }, [user?.role])

  useEffect(() => {
    setLoader(true)
    checkAuthentication(navigate, tempSubdomain, setAuthenticated)
    setLoader(false)
  }, [accessToken])

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <Routes>
          <Route path='/admin/signup' element={<SuperAdminSignUp />} />
          <Route path='/admin/signin' element={<SuperAdminSignin />} />
          <Route path='/forget-password/:id' element={<VerificationPage />} />

          <Route
            path='/agency/forget-password/:id'
            element={<VerificationPage />}
          />
          {!subDomain ? (
            <>
              {!authenticated ? (
                <>
                  <Route path='/sign-in' element={<SignInPage />} />
                  <Route path='/' element={<HomePage />} />
                  <Route
                    path='/agency/forget-password'
                    element={<ForgetPasswordPage />}
                  />
                  <Route
                    path='/agency/forget-password/:id'
                    element={<VerificationPage />}
                  />
                  <Route
                    path='/agency/welcome-page'
                    element={<AgencyWelcomePage />}
                  />
                  <Route
                    path='/agency/agency-form'
                    element={<AgencyFormPage />}
                  />
                </>
              ) : (
                <>
                  <Route path='/dashboard' element={<AgencyDashboardPage />} />

                  <Route path='/agency/tutors' element={<AgencyTutorsPage />} />

                  {/* Agency Screens */}
                  <Route
                    path='/agency/students'
                    element={<AgencyStudentPage />}
                  />
                  <Route
                    path='/agency/students/:id'
                    element={<CustomStudentsProfilePage />}
                  />
                  <Route
                    path='/agency/tutors/:id'
                    element={<CustomTutorProfilePage />}
                  />
                  <Route
                    path='/agency/class-request'
                    element={<AgencyClassRequestPage />}
                  />
                  <Route
                    path='/agency/settings'
                    element={<AgencySettingPage />}
                  />
                  <Route
                    path='/agency/calenders'
                    element={<AgencyCalenderPage />}
                  />
                  <Route
                    path='/agency/handouts'
                    element={<AgencyHandoutsPage />}
                  />
                  <Route path='/agency/admin' element={<AgencyAdminPage />} />
                  <Route
                    path='/agency/admin/statistics'
                    element={<AgencyAdminStatisticsPage />}
                  />
                  <Route
                    path='/agency/admin/billing'
                    element={<AgencyAdminBillingPage />}
                  />
                  <Route
                    path='/agency/admin/package'
                    element={<AgencyAdminPackagePage />}
                  />
                  <Route
                    path='/agency/admin/user-management'
                    element={<AgencyAdminUserManagementPage />}
                  />
                  <Route path='/agency/wallet' element={<WalletPage />} />
                  <Route path='/agency/chats' element={<ChatPage />} />
                  <Route path='/agency/chat/:id' element={<SingleChatPage />} />

                  {/* Admin Screens */}
                  <Route
                    path='/admin/dashboard'
                    element={<AdminDashboardPage />}
                  />
                  <Route
                    path='/admin/settings'
                    element={<AdminSettingsPage />}
                  />
                  <Route path='/admin/wallet' element={<WalletPage />} />
                  <Route path='/admin/chats' element={<ChatPage />} />
                </>
              )}
              <Route path='/agency/:id' element={<AgencyProfilePage />} />
            </>
          ) : (
            <>
              {!authenticated ? (
                <>
                  <Route path='/' element={<CustomStudentSignInPage />} />
                  <Route
                    path='/sign-up'
                    element={<CustomStudentSignUpPage />}
                  />
                  <Route path='/redirect' element={<TutorStepperForm />} />
                  <Route
                    path='/forget-password'
                    element={<ForgetPasswordPage />}
                  />
                  <Route
                    path='/forget-password/:id'
                    element={<VerificationPage />}
                  />{' '}
                </>
              ) : (
                <>
                  {/* Students Screens */}
                  <Route
                    path='/student/dashboard'
                    element={<CustomStudentDashboardPage />}
                  />
                  <Route
                    path='/student/calender'
                    element={<CustomStudentCalenderPage />}
                  />
                  <Route
                    path='/student/tutors'
                    element={<CustomStudentTutorPage />}
                  />
                  <Route
                    path='/student/settings'
                    element={<CustomStudentSettingPage />}
                  />
                  <Route path='/student/wallet' element={<WalletPage />} />
                  <Route path='/student/chats' element={<ChatPage />} />
                  <Route
                    path='/student/chat/:id'
                    element={<SingleChatPage />}
                  />

                  <Route
                    path='/tutor/dashboard'
                    element={<CustomTutorDashboardPage />}
                  />
                  <Route
                    path='/tutor/calender'
                    element={<CustomTutorCalenderPage />}
                  />
                  <Route
                    path='/tutor/settings'
                    element={<CustomTutorSettingPage />}
                  />
                  <Route
                    path='/tutor/handouts'
                    element={<CustomTutorHandoutsPage />}
                  />
                  <Route
                    path='/tutor/students'
                    element={<AgencyStudentPage />}
                  />
                  <Route
                    path='/tutor/student/:id'
                    element={<CustomStudentsProfilePage />}
                  />
                  <Route
                    path='/tutor/class-request'
                    element={<CustomTutorClassRequestPage />}
                  />
                  <Route
                    path='/tutor/:id'
                    element={<CustomTutorProfilePage />}
                  />
                  <Route path='/agency/:id' element={<AgencyProfilePage />} />
                  <Route path='/tutor/chats' element={<ChatPage />} />
                  <Route path='/tutor/chat/:id' element={<SingleChatPage />} />
                  {role !== 'student' && (
                    <>
                      <Route path='/tutor/wallet' element={<WalletPage />} />
                    </>
                  )}
                </>
              )}
            </>
          )}
          <Route path='*' element={<NotFound />} />
        </Routes>
      )}
      <RedirectComponent />
    </div>
  )
}

export default App
