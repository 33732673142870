/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Button, Row, Col, Upload, Select } from "antd";
import { useLocation } from "react-router-dom";
import Stepper from "components/ui/Stepper";
import {
  getSubdomain,
  setupUserFromInvitation,
} from "components/utils/functions";
import "./input.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCityOfState,
  getAllCountry,
  getAllStateOfCountry,
} from "store/action/user";

const { Option } = Select;

const StepOne = ({ data, onChange, handleStepper }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const { Countries, States, City } = useSelector((state) => state.supers);

  const username = Form.useWatch("username", { form, preserve: true });
  const agencyDomain = Form.useWatch("agencyDomain", { form, preserve: true });
  const role = Form.useWatch("role", { form, preserve: true });

  const [previewImage, setPreviewImage] = useState(
    data.avatar || "/img/person-black.svg"
  );

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    setupUserFromInvitation(
      location.search,
      (key, value) => {
        form.setFieldsValue({ [key]: value });
      },
      getSubdomain
    );
    dispatch(getAllCountry());
  }, [location.search, form]);

  const handleFileChange = (e) => {
    const fileURL = URL.createObjectURL(e.file);
    setPreviewImage(fileURL);
  };

  const onFinish = (values) => {
    onChange({
      ...values,
      username,
      agencyDomain,
      role,
      country: selectedCountry?.name,
      state: selectedState?.name,
      city: selectedCity?.name,
    });
    handleStepper(1); // Move to the next step
  };
  const handleCountryChange = (value) => {
    const selectedCountry = Countries.find(
      (country) => country.isoCode === value
    );
    setSelectedCountry(selectedCountry);
    setSelectedState(null);
    form.setFieldsValue({ province: null, city: null });

    // Fetch states
    dispatch(getAllStateOfCountry({ countryCode: value }));
  };

  const handleStateChange = (value) => {
    const selectedState = States.find((state) => state.isoCode === value);
    setSelectedState(selectedState);
    form.setFieldsValue({ city: null });
    dispatch(
      getAllCityOfState({
        stateCode: value,
        countryCode: selectedCountry.isoCode,
      })
    );
  };

  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex h-full flex-1 items-center justify-center">
        <div className="flex min-h-[500px] w-4/5 flex-col justify-between rounded-md bg-white p-4 shadow-md md:p-8 lg:w-[50%]">
          <div className="text-center text-2xl font-bold text-black">
            Tutor Contact Details
          </div>
          <div className="mx-auto">
            <Stepper totalSteps={4} currentStep={1} color="#303030" />
          </div>
          <Divider />
          <Form
            form={form}
            layout="vertical"
            enabled
            initialValues={{ ...data, gender: "male" }}
            onFinish={onFinish}
          >
            <Row justify="center" align="middle">
              <Form.Item name="avatar" label="">
                <Upload
                  name="avatar"
                  listType="picture-circle"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={() => false} // Prevent automatic upload
                  onChange={handleFileChange}
                >
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="avatar"
                      style={{ width: "100%", borderRadius: "50%" }}
                    />
                  ) : (
                    <div>
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Row>
            <Form.Item
              name="firstName"
              label=""
              rules={[{ required: true, message: "First name is required" }]}
            >
              <Input placeholder="First Name" className="custom-input" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label=""
              rules={[{ required: true, message: "Last name is required" }]}
            >
              <Input placeholder="Last Name" className="custom-input" />
            </Form.Item>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="email"
                  label=""
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input
                    readOnly
                    placeholder="Email Address"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="phoneNumber"
                  label=""
                  rules={[
                    { required: true, message: "Phone number is required" },
                  ]}
                >
                  <Input
                    placeholder="Phone Number"
                    type="number"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="password"
                  label=""
                  rules={[{ required: true, message: "Password is required" }]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="confirmPassword"
                  label=""
                  dependencies={["password"]}
                  rules={[
                    { required: true, message: "Confirm password is required" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords do not match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="country"
                  label=""
                  rules={[{ required: true, message: "Country is required" }]}
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Country"
                    optionFilterProp="children"
                    onChange={handleCountryChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Countries?.map((country) => (
                      <Option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="province"
                  label=""
                  rules={[{ required: true, message: "Province is required" }]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Province"
                    optionFilterProp="children"
                    onChange={handleStateChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={!selectedCountry}
                  >
                    {selectedCountry &&
                      States?.map((state) => (
                        <Option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="city"
                  label=""
                  rules={[{ required: true, message: "City is required" }]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="City"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setSelectedCity({ name: value });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={!selectedState}
                  >
                    {selectedState &&
                      City?.map((city) => (
                        <Option key={city.name} value={city.name}>
                          {city.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="address"
              label=""
              rules={[{ required: true, message: "Address is required" }]}
            >
              <Input placeholder="Permanent Address" className="custom-input" />
            </Form.Item>
            <Form.Item
              name="gender"
              label=""
              rules={[{ required: true, message: "Please select gender!" }]}
            >
              <Select size="large" placeholder="select your gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <div className="mt-2 flex items-center justify-between text-[#303030]">
                <Button type="default" disabled>
                  Back
                </Button>
                <Button type="text" htmlType="submit">
                  Continue
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
