/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MultipleSelection from "components/shared/MultiSelection";
// import { useDispatch } from "react-redux";
// import { updateUser } from "store/action/user";
import { toObject } from "components/utils/functions";

const UpdateTutor = ({ onClose, user, handleUpdateHandler }) => {
  //   const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    subject: [],
    chargesPerHour: "",
    experience: "",
  });

  const handleInputChange = (name, value) => {
    console.log("🚀 ~ handleInputChange ~ value:", value);
    console.log("🚀 ~ handleInputChange ~ name:", name);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = () => {
    handleUpdateHandler({ ...formData, role: "tutor", userId: user.userId });
    onClose();
  };

  useEffect(() => {
    setFormData({
      subject: toObject(user?.subject),
      chargesPerHour: user?.chargesPerHour || user?.chargePerHour,
      experience: user?.experience,
    });
  }, [user]);

  return (
    <div className="flex flex-col gap-3">
      <div>
        <div className="pb-1 text-left text-lg font-bold text-black">
          Update Detail
        </div>
        <div className="w-full border-t-[1px] border-ternary"></div>
      </div>

      <MultipleSelection
        name="Subjects"
        type="multiSelect"
        dropDown
        value={formData?.subject}
        getTags={(value) => handleInputChange("subject", value)}
      />
      <MultipleSelection
        name="Charges Per Hour"
        type="number"
        placeholder="$15"
        dropDown
        value={formData?.chargesPerHour}
        onChange={(value) => handleInputChange("chargesPerHour", value)}
      />
      <MultipleSelection
        name="Experience"
        type="number"
        placeholder="2"
        dropDown
        value={formData?.experience}
        onChange={(value) => handleInputChange("experience", value)}
      />
      <div className="flex justify-end">
        <div className="flex items-center justify-center gap-2">
          <div
            className="cursor-pointer rounded-lg border-2 border-secondary px-6 py-2 font-semibold text-black"
            onClick={onClose}
          >
            Cancel
          </div>
          <button
            className="rounded-lg border-2 border-black bg-black px-6 py-2 font-semibold text-white"
            onClick={submitHandler}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateTutor;
