/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionPlanGraph: {
    planNames: [],
    counts: [],
  },
  Countries: [],
  States: [],
  City: [],
  plans: [],
  subscription: {},
  agency: {},
};

const supers = createSlice({
  name: "supers",
  initialState,
  reducers: {
    getsubscriptionPlanGraphSlice: (state, action) => {
      return {
        ...state,
        subscriptionPlanGraph: action.payload,
      };
    },
    setSubscription: (state, action) => {
      return {
        ...state,
        subscription: action.payload,
      };
    },
    getAllPlans: (state, action) => {
      return {
        ...state,
        plans: action.payload,
      };
    },
    getAgencySlice: (state, action) => {
      return {
        ...state,
        agency: action.payload,
      };
    },
    getCountriesSlice: (state, action) => {
      return {
        ...state,
        Countries: action.payload.countries,
        States: [],
        City: [],
      };
    },
    getStateSlice: (state, action) => {
      return {
        ...state,
        States: action.payload.states,
        City: [],
      };
    },
    getCitySlice: (state, action) => {
      return {
        ...state,
        City: action.payload.cities,
      };
    },
  },
});

export const {
  getsubscriptionPlanGraphSlice,
  setSubscription,
  getAllPlans,
  getCitySlice,
  getStateSlice,
  getAgencySlice,
  getCountriesSlice,
} = supers.actions;
export default supers.reducer;
