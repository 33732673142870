import { createSlice } from "@reduxjs/toolkit";

const redirect = createSlice({
  name: "redirect",
  initialState: null,
  reducers: {
    setRedirectRoute(state, action) {
      return action.payload;
    },
    clearRedirectRoute(state) {
      return null;
    },
  },
});

export const { setRedirectRoute, clearRedirectRoute } = redirect.actions;
export default redirect.reducer;
