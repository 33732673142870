/* eslint-disable react/prop-types */
import { Skeleton } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const TutorCode = ({ data }) => {
  const isLoading = useSelector((state) => state.notifications.isLoading);
  return (
    <div className="h-fit w-full rounded-lg bg-white p-4">
      <div className="text-xl font-bold">Tutor Code</div>
      {isLoading && !data ? (
        <Skeleton.Input active={true} />
      ) : (
        <div className="text-sm font-semibold text-secondary">
          {data?.tutorCode}
        </div>
      )}
    </div>
  );
};

export default TutorCode;
