/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { pathnameHandler } from "components/utils/functions";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

import AvatarImage from "components/shared/AgencyLayout/designs/Avatar";
const ChatLists = ({ data, chatClickHandler, showComposeHandler }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) =>
        item.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, data]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };
  return (
    <div className="h-full rounded-xl bg-white p-4">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <span>Chat</span>
        </div>
        <div
          className="cursor-pointer rounded-lg bg-black px-2 py-1 text-white"
          onClick={showComposeHandler}
        >
          + Compose
        </div>
      </div>
      <div className="mt-2 flex items-center gap-2 rounded-md border-2 bg-white p-2">
        <FiSearch color="#828282" />
        <input
          type="text"
          placeholder="Search"
          className="w-full border-none outline-none"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {searchTerm && (
          <button onClick={clearSearch} className="text-red-500">
            Clear
          </button>
        )}
      </div>
      <div className="">
        {filteredData.map((item, idx) => (
          <Link
            to={`${pathnameHandler()}/chat/${item?.roomId}`}
            key={idx}
            className="flex w-full justify-between p-4"
            onClick={chatClickHandler}
          >
            <div className="flex gap-2">
              <div className="relative">
                {item?.avatar && (
                  <AvatarImage
                    avatar={item?.avatar}
                    alt={item?.username}
                    className="size-12 rounded-full "
                  />
                )}
                {item.online && (
                  <div className="absolute bottom-0 right-0 size-3 rounded-[50%] border-2 border-white bg-lime-500" />
                )}
              </div>
              <div>
                <div className="font-semibold">{item.username}</div>
                <div className="text-secondary">
                  {item.message?.slice(0, 30)}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <div className="text-sm text-secondary">
                {moment(item.createdAt).fromNow()}
              </div>
              {item.read && <GoDotFill />}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ChatLists;
