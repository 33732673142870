import { Skeleton } from "antd";
import AgencyLayout from "components/shared/AgencyLayout";
import {
  agencySidebar,
  studentSidebar,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAgencyDetail } from "store/action/user";

const AgencyDetail = () => {
  const parma = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const agencyDetail = useSelector((state) => state.users.agencyDetail);

  useEffect(() => {
    dispatch(getAgencyDetail(parma));
  }, []);

  return (
    <AgencyLayout
      sidebarData={
        user.role === "student"
          ? studentSidebar
          : user.role === "tutor"
          ? tutorSidebar
          : agencySidebar
      }
    >
      <div className="flex min-h-[80vh] items-center justify-center p-4">
        {isLoading && !agencyDetail?.agencyDetail ? (
          <Skeleton active title />
        ) : (
          <div className="m-auto flex w-full max-w-[90%] flex-col gap-8 lg:flex-row">
            <div className="lg:w-3/5">
              <h1 className="text-3xl font-bold capitalize">
                {agencyDetail?.agencyDetail?.agencyName}
              </h1>
              <h4 className="pt-4 text-xl">
                {agencyDetail?.agencyDetail?.agencyTagline}
              </h4>
              <div className="mt-12 flex flex-col gap-4 lg:flex-row">
                <div className="flex w-full items-center rounded-lg bg-white border border-[#E0E0E0] p-4 lg:w-1/2">
                  <div className="flex size-12 items-center justify-center rounded-full bg-orange-100 text-center">
                    <img
                      src="/img/teacher1.png"
                      alt="teacher"
                      className="inline-block max-h-full max-w-full align-middle"
                    />
                  </div>
                  <div className="pl-6 text-left">
                    <div className="text-lg text-[#828282]">Total Tutors</div>
                    <div className="text-2xl font-bold text-[#FFA361]">
                      {agencyDetail?.tutors?.length}
                    </div>
                  </div>
                </div>
                <div className="flex w-full items-center rounded-lg bg-white border border-[#E0E0E0] p-4 lg:w-1/2">
                  <div className="flex size-12 items-center justify-center rounded-full bg-blue-100 text-center">
                    <img
                      src="/img/education1.png"
                      alt="student"
                      className="inline-block max-h-full max-w-full align-middle"
                    />
                  </div>
                  <div className="pl-6 text-left">
                    <div className="text-lg text-[#828282]">Total Students</div>
                    <div className="text-2xl font-bold text-[#3CEBF9]">
                      {" "}
                      {agencyDetail?.students?.length}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-2/5">
              <img
                src="/img/SideImage.png"
                alt="Side Image"
                className="h-auto w-full"
              />
            </div>
          </div>
        )}
      </div>
    </AgencyLayout>
  );
};

export default AgencyDetail;
