/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from "react";

const DeleteScreen = ({ onClose, handleDeleteHandler, title }) => {
  return (
    <div className="flex flex-col items-center gap-6">
      <div className="text-3xl font-bold">Delete</div>
      <div className="text-lg text-secondary">
        Are you sure you want to delete this {title}?
      </div>
      <div className="flex items-center gap-4">
        <div
          className="cursor-pointer rounded-lg border-2 border-secondary p-2 px-6"
          onClick={onClose}
        >
          Cancel
        </div>
        <div
          className="cursor-pointer rounded-lg border-2 border-black bg-black p-2 px-6 text-white"
          onClick={handleDeleteHandler}
        >
          Yes, Delete
        </div>
      </div>
    </div>
  );
};

export default DeleteScreen;
