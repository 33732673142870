/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Divider } from "antd";
import InputTextField from "components/ui/InputTextField";
import Stepper from "components/ui/Stepper";
import { BASE_IMG_URL } from "components/shared/constants";

const StepThree = ({ data, onChange, handleStepper }) => {
  const [formData, setFormData] = useState(data);
  const [errors, setErrors] = useState({});

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const validate = () => {
    const errors = {};
    if (!formData.chargesPerHour)
      errors.chargesPerHour = "charges Per Hour is required";
    if (!formData.description) errors.description = "description is required";
    return errors;
  };
  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      onChange(formData); // Pass form data to parent component
      handleStepper(3);
    }
  };
  return (
    <div className="flex min-h-screen flex-col">
      {/* <Header /> */}
      <div className="flex h-full flex-1 items-center justify-center">
        <div className="flex min-h-[500px] w-4/5 flex-col justify-between rounded-md bg-white p-4 md:p-8 shadow-md lg:w-[50%]">
          <div className="text-center text-2xl font-bold text-[#303030]">
            Tutor Profile Details{" "}
          </div>
          <div className="mx-auto">
            <Stepper totalSteps={4} currentStep={3} color="#303030" />
          </div>
          <Divider />
          <div>
            <InputTextField
              type="number"
              placeholder="Charges per hours"
              className="mb-3 border-2 py-3"
              value={formData.chargesPerHour}
              onChange={(value) => handleChange("chargesPerHour", value)}
            />
            {errors.chargesPerHour && (
              <div className="mb-2 text-sm text-red-500 ">
                {errors.chargesPerHour}
              </div>
            )}

            <InputTextField
              type="textArea"
              placeholder="Profile description"
              value={formData.description}
              onChange={(value) => handleChange("description", value)}
              className="mb-3 border-2 py-3"
            />
            {errors.description && (
              <div className="mb-2 text-sm text-red-500 ">
                {errors.description}
              </div>
            )}
          </div>
          <div className="flex items-center justify-between text-[#303030]">
            <div
              className="flex cursor-pointer gap-2"
              onClick={() => handleStepper(1)}
            >
              <div>Back</div>
            </div>
            <div
              className="flex cursor-pointer gap-2"
              onClick={() => handleSubmit()}
            >
              <div>Continue</div>
              <img
                src={`${BASE_IMG_URL}/img/arrow-left.svg`}
                alt=""
                className="scale-x-[-1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
