import AgencyLayout from "components/shared/AgencyLayout";
import { adminSidebar } from "components/shared/constants/agencyConstants";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedAccountStatus } from "store/action/user";
import API from "store/services/base.service";
import BillingInfo from "./designs/BillingInfo";
import { Skeleton } from "antd";

const AgencyAdminPackage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const connectedAccount = useSelector(
    (state) => state.users.connectedAccountStatus
  );

  useEffect(() => {
    dispatch(getConnectedAccountStatus());
  }, []);

  const createAccountHandler = () => {
    API.post("/stripe/connected-account")
      .then((res) => {
        const { accountLink } = res.data;
        if (accountLink && accountLink.url) {
          window.location.href = accountLink.url;
        } else {
          console.log("Missing account link URL in the response");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <AgencyLayout sidebarData={adminSidebar} currentPage="Package">
      <div className="flex size-full flex-col gap-6 rounded-lg bg-white p-4">
        <div className="flex">
          <div className="border-b-2 border-secondary text-2xl font-semibold ">
            Connected Account
          </div>
          <div className="flex-1 border-b-2"></div>
        </div>
        {!connectedAccount?.charges_enabled ||
        !connectedAccount?.details_submitted ||
        user.connectedAccountId === null ||
        user.connectedAccountId === "null" ||
        user.connectedAccountId === "" ? (
          <div
            className=" flex w-full flex-1 items-center justify-center text-center"
            onClick={createAccountHandler}
          >
            <div className="cursor-pointer">
              <div>+</div>
              <div>Connect Account</div>
            </div>
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  <div className="background-gradient col-span-1 flex cursor-pointer flex-col gap-4 rounded-lg border-2 p-4 text-start shadow-sm">
                    <div className="flex flex-col gap-6">
                      <div className="font-semibold">
                        Acc Id: {connectedAccount?.id}
                      </div>
                      <div className="flex items-end gap-6">
                        {
                          <div className="rounded-lg border-2 border-white bg-white px-6 py-1 text-sm text-odinPrimary">
                            {connectedAccount?.type}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <div className="text-xs">
                        <span className="text-base font-semibold">
                          Bank Name:
                        </span>{" "}
                        {
                          connectedAccount?.external_accounts?.data[0]
                            ?.bank_name
                        }
                      </div>
                      <div className="text-xs">
                        <span className="text-base font-semibold">
                          Default Currency:
                        </span>{" "}
                        {connectedAccount?.external_accounts?.data[0]?.currency}
                      </div>
                      <div className="text-xs">
                        <span className="text-base font-semibold">
                          Routing Number:
                        </span>{" "}
                        {
                          connectedAccount?.external_accounts?.data[0]
                            ?.routing_number
                        }
                      </div>
                      <div className={`text-base font-semibold text-white`}>
                        {connectedAccount?.payouts_enabled && "Payouts Enabled"}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="col-span-2">
                <BillingInfo connectedAccount={connectedAccount} />
              </div>
            </div>
          </div>
        )}
      </div>
    </AgencyLayout>
  );
};

export default AgencyAdminPackage;
