/* eslint-disable react/prop-types */
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactStars from "react-stars";
import "./Feedback.css"; // Import your custom CSS
import { feedbackData } from "components/shared/constants/agencyConstants";

const Feedback = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="flex  flex-col gap-4">
      <div className="flex items-end">
        <div className="border-b-4 border-secondary text-2xl font-bold text-black">
          Student Feedback
        </div>
        <div className="flex-1 border-2"></div>
      </div>

      <div className="mx-auto my-4 w-[82vw]">
        <Slider {...settings}>
          {feedbackData.map((item, idx) => (
            <div
              key={idx}
              className="feedback-slide mx-4 flex rounded-xl bg-white p-4"
            >
              <div className="flex  items-center gap-2">
                <img
                  src={"/img/person-black.svg" || item.image}
                  alt=""
                  className="size-24"
                />
                <div className="">
                  <div className="font-semibold">{item.name}</div>
                  <div className="text-secondary">
                    Grade: <span className="text-black">{item.grade}</span>
                  </div>
                </div>
              </div>
              <div className="px-4 text-center  text-secondary ">
                {item.feedback}
              </div>
              <div className="flex items-center justify-center">
                <ReactStars
                  count={5}
                  value={item.rating}
                  size={24}
                  edit={false}
                  color2={"#ffd700"}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Feedback;
