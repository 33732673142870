import React from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import { Link } from "react-router-dom";

const LogoArea = () => {
  return (
    <div className="ml-32 flex h-[100px] items-center">
      <Link to="/">
        <img src={`${BASE_IMG_URL}/img/logo.svg`} alt="" />
      </Link>
    </div>
  );
};

export default LogoArea;
