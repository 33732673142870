/* eslint-disable react/prop-types */

import React, { useMemo } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import { Divider, Dropdown } from "antd";
import moment from "moment";
import Avatar from "../AgencyLayout/designs/Avatar";
import { MoreOutlined, CalendarOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import SkeletonWithAvatar from "../Skeleton";

const DashboardCard = ({ data, handleSelectEvent, handleNavigate }) => {
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const {
    username,
    grade,
    experience,
    avatar,
    title,
    date,
    start,
    end,
    zoomLink,
  } = data || {};

  const items = useMemo(
    () => [
      {
        key: "1",
        label: <a onClick={() => handleSelectEvent(data)}>Reschedule</a>,
      },
      {
        key: "2",
        label: (
          <a target="_blank" rel="noopener noreferrer" href={zoomLink}>
            Zoom Link
          </a>
        ),
      },
    ],
    [data, handleSelectEvent, zoomLink]
  );

  const isUpcomingEvent = useMemo(() => {
    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);
    return start > todayMidnight;
  }, [start]);

  return (
    <div className="mx-auto w-[92%] rounded-md bg-white p-2">
      {isLoading && !data ? (
        <SkeletonWithAvatar rows={5} />
      ) : (
        <>
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <Avatar
                avatar={avatar}
                alt={username}
                className="size-12 cursor-pointer rounded-full"
                handleClick={() => handleNavigate(data)}
              />
              <div className="text-black">
                <div className="font-semibold capitalize">
                  {username || "Unknown User"}
                </div>
                <div className="text-xs">
                  <span className="text-secondary">
                    {experience ? "Experience" : "Grade"}:
                  </span>{" "}
                  <span className="font-semibold">
                    {grade || experience || "N/A"}
                  </span>
                </div>
              </div>
            </div>
            {isUpcomingEvent && (
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomLeft"
              >
                <MoreOutlined className="cursor-pointer" />
              </Dropdown>
            )}
          </div>
          <Divider dashed />
          <div className="flex flex-col gap-2 py-3 text-sm">
            <div className="flex gap-2">
              <img
                src={`${BASE_IMG_URL}/img/monitor-black.svg`}
                alt="Monitor Icon"
              />
              <div className="text-secondary">
                Subject:
                <span className="font-bold capitalize text-black">
                  {" "}
                  {title || "N/A"}
                </span>
              </div>
            </div>
            <div className="flex gap-2">
              <CalendarOutlined className="text-lg" />
              <div className="text-secondary">
                Date:
                <span className="font-bold text-black"> {date || "N/A"}</span>
              </div>
            </div>
            <div className="flex gap-2">
              <img
                src={`${BASE_IMG_URL}/img/clock-black.svg`}
                alt="Clock Icon"
              />
              <div className="text-secondary">
                Time:{" "}
                <span className="font-bold text-black">
                  {moment(start).format("HH:mm")}{" "}
                </span>{" "}
                to{" "}
                <span className="font-bold text-black">
                  {moment(end).format("HH:mm")}{" "}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardCard;
