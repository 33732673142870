import React from "react";
import { Divider } from "antd";

const FooterArea = () => {
  return (
    <div className="text-black">
      <Divider className="bg-black" />
      <div className="flex flex-col-reverse justify-between text-center md:flex-row">
        <div className="mt-4 md:mt-0">{new Date()?.getFullYear()} Odin Inc. All rights reserved.</div>
        <div className="flex flex-wrap gap-4">
          <div>Honor Code</div>
          <div>Cookie Notice</div>
          <div>Privacy Policy</div>
          <div>General Policies</div>
          <div>IP Rights</div>
        </div>
      </div>
    </div>
  );
};

export default FooterArea;
