import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import rootSaga from "./rootSaga";
import reducer from "./slices";

const persistConfig = {
  key: "root",
  whitelist: ["supers", "users"],
  storage,
};
const middleware = [];

const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: () => [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
