import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleNavigate = (value) => {
    navigate(value);
  };

  return (
    <div className="m-auto w-full max-w-[1200px] px-4">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        <div className="max-w-full py-[60px] md:max-w-[533px]">
          <div className="relative ">
            <motion.h1
              className="pb-8 text-[48px] font-[400] leading-tight md:text-[60px]"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              Streamline Your Tutoring Business with Odin
            </motion.h1>
            <img
              src="https://cdn.prod.website-files.com/609b3427f37846455f9de8db/60e55a65c96f3f169a45d662_Curve%20Wave.svg"
              className="absolute bottom-0 left-0 "
              alt="Curve Wave"
            />
          </div>
          <p className="mb-10 mt-4 text-lg">
            Manage schedules, content, and payments effortlessly
          </p>
          <div className="flex flex-col justify-center items-center sm:block">
            <motion.button
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => handleNavigate("/agency/agency-form")}
              transition={{ duration: 1 }}
              className="mt-6  rounded-full bg-[#0114b0] px-8 py-3 uppercase text-white transition hover:bg-blue-700"
            >
              Get started for free
            </motion.button>
            <span className="mx-4 font-semibold mt-6 sm:mt-0">OR</span>
            <motion.button
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => handleNavigate("/sign-in")}
              transition={{ duration: 1 }}
              className="mt-6 rounded-full bg-[#0114b0] px-10 py-3 uppercase text-white transition hover:bg-blue-700"
            >
              Sign In
            </motion.button>
            <motion.div
              animate={{ y: [0, 20, 0] }}
              transition={{ duration: 1, repeat: Infinity }}
              className="mt-2 w-[255px]"
            >
              <img
                src="https://cdn.prod.website-files.com/609b3427f37846455f9de8db/60e55a1ea1127c367aaa06a9_Arrow%20Bottom.svg"
                className="m-auto"
              />
            </motion.div>
          </div>
        </div>
        <div className="relative flex h-[430px] max-w-full items-center  md:min-h-[630px] md:max-w-[570px]">
          <motion.img
            src="https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e43b2bb7aac479bf91aa3_Techvention%20posts%20(4)%201%20(1).png"
            className="absolute"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          />
          <div className="mb-20 h-4/5 w-full  rounded-[20px] bg-[#0114b0] md:mb-0"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
