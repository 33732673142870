import AdminLayout from "components/shared/AdminLayout";
import { BASE_IMG_URL } from "components/shared/constants";
import {
  adminPaymentHistroyColumn,
  agencyAdminSidebar,
} from "components/shared/constants/agencyConstants";
import Image from "components/shared/Image";
import DropDownUi from "components/ui/DropDown";
import InputTextField from "components/ui/InputTextField";
import DemoLine from "components/ui/MultiLineGraph";
import PieChart from "components/ui/PieChart";
import TableUi from "components/ui/Table";
import React, { useEffect, useState } from "react";
import { FaChevronDown, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionPlanGrpahAction } from "store/action/super";
import API from "store/services/base.service";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const subscriptionPlanGraph = useSelector(
    (state) => state?.supers?.subscriptionPlanGraph
  );
  console.log(
    "🚀 ~ AdminDashboard ~ subscriptionPlanGraph:",
    subscriptionPlanGraph
  );
  const [data, setData] = useState([
    {
      img: "money-bag-yellow.svg",
      name: "Total Balance:",
      value: `$ ${20}`,
      color: "#FFF5D9",
    },
    {
      img: "money-hand-blue.svg",
      name: "Monthly Income:",
      value: `$ ${20}`,
      color: "#E7EDFF",
    },
    {
      img: `house-pink.svg`,
      name: "Total Agency:",
      value: 10,
      color: "#FFE0EB",
    },
    {
      img: `recycle-green.svg`,
      name: "Subscription Plan:",
      value: 10,
      color: "#DCFAF8",
    },
  ]);
  const [paymentHistroy, setPaymentHistroy] = useState([]);
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    API.get("/agency/agencies/total")
      .then((res) => {
        const { data: apiData } = res?.data;
        console.log("🚀 ~ .then ~ data:", apiData);
        if (apiData) {
          const updatedData = [
            { ...data[0], value: `$${apiData.totalBalance}` },
            { ...data[1], value: `$${apiData.monthlyIncome}` },
            { ...data[2], value: apiData.totalAgencies },
            { ...data[3], value: apiData.totalSubscriptionPlans },
          ];
          setData(updatedData);
        }
      })
      .catch((err) => console.log(err));

    API.get("/stripe/admin-payment-histroy")
      .then((res) => {
        const temp = res?.data?.paymentHistory;
        setPaymentHistroy(temp);
        setFilteredData(temp);
      })
      .catch((err) => console.log(err));

    dispatch(subscriptionPlanGrpahAction());
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterByAmount, setFilterByAmount] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(false);
  const [amountThreshold, setAmountThreshold] = useState(0);
  const [statusFilter, setStatusFilter] = useState("succeeded");

  useEffect(() => {
    let data = paymentHistroy;

    // Search Filter
    if (searchQuery) {
      data = data.filter(
        (item) =>
          item.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.created.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Custom Filters
    if (filterByAmount) {
      data = data.filter((item) => parseFloat(item.amount) > amountThreshold);
    }

    if (filterByStatus) {
      data = data.filter((item) => item.status === statusFilter);
    }

    setFilteredData(data);
  }, [
    searchQuery,
    filterByAmount,
    filterByStatus,
    amountThreshold,
    statusFilter,
    paymentHistroy,
  ]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (filter) => {
    if (filter === "amount") {
      setFilterByAmount(!filterByAmount);
    } else if (filter === "status") {
      setFilterByStatus(!filterByStatus);
    }
  };

  const handleAmountThresholdChange = (e) => {
    setAmountThreshold(parseFloat(e.target.value));
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  return (
    <AdminLayout sidebarData={agencyAdminSidebar} currentPage="Dashboard">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
          {data.map((item, idx) => (
            <div
              key={idx}
              className="flex w-full flex-col items-center rounded-lg bg-white py-6"
            >
              <div
                className="rounded-[50%] p-4"
                style={{ background: item.color }}
              >
                <Image
                  className="size-8"
                  src={`${BASE_IMG_URL}/img/${item.img}`}
                  alt=""
                />
              </div>
              <div className="mt-6 font-semibold text-odinPrimary">
                {item.name}{" "}
                <span className="font-medium text-secondary">{item.value}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-2 rounded-lg bg-white p-4">
            <div className="border-b-2 pb-4 font-semibold text-odinPrimary">
              Total Revenue
            </div>
            <div className="">
              <DemoLine />
            </div>
          </div>
          <div className="col-span-2 rounded-lg bg-white p-4">
            <div className="border-b-2 pb-4 font-semibold text-odinPrimary">
              Montly Revenue
            </div>
            <div>
              <PieChart data={subscriptionPlanGraph} />
            </div>
          </div>
        </div>
        <div className="my-8 flex items-end">
          <div className="border-b-2 border-odinPrimary text-2xl font-bold text-odinPrimary ">
            Agency Payment Histroy
          </div>
          <div className="flex-1 border-b-2"></div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex h-10 w-fit justify-center rounded-xl bg-white p-2 md:w-[17%]">
            <DropDownUi
              arrow={false}
              placement="bottomCenter"
              item={
                <div className="flex w-20 items-center justify-between">
                  <div>Filter</div>
                  <FaChevronDown size={15} color="#000" />
                </div>
              }
              dropdownRender={
                <div className="flex flex-col gap-3 rounded-lg bg-white p-4">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={filterByAmount}
                      onChange={() => handleFilterChange("amount")}
                    />
                    <div>Filter by Amount</div>
                  </div>
                  {filterByAmount && (
                    <div className="flex items-center gap-3">
                      <input
                        type="number"
                        value={amountThreshold}
                        onChange={handleAmountThresholdChange}
                        placeholder="Amount Threshold"
                        className="rounded border p-1"
                      />
                    </div>
                  )}
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      checked={filterByStatus}
                      onChange={() => handleFilterChange("status")}
                    />
                    <div>Filter by Status</div>
                  </div>
                  {filterByStatus && (
                    <div className="flex items-center gap-3">
                      <select
                        value={statusFilter}
                        onChange={handleStatusFilterChange}
                        className="rounded border p-1"
                      >
                        <option value="succeeded">Succeeded</option>
                        <option value="failed">Failed</option>
                      </select>
                    </div>
                  )}
                  <div className="flex justify-end gap-2 text-xs font-semibold">
                    <div
                      className="cursor-pointer text-secondary"
                      onClick={() => {
                        setFilterByAmount(false);
                        setFilterByStatus(false);
                        setAmountThreshold(0);
                        setStatusFilter("succeeded");
                      }}
                    >
                      Clear
                    </div>
                  </div>
                </div>
              }
            />
          </div>

          <div className="hidden h-10 flex-1 items-center gap-2 rounded-xl bg-white md:flex">
            <InputTextField
              icon={<FaSearch color="#0115B5" />}
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="rounded-lg bg-white p-4">
          <TableUi
            headerBg="#0115B5"
            headerColor="#fff"
            rowSelection
            data={filteredData}
            columns={adminPaymentHistroyColumn}
            pageSize={10}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
