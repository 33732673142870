import React, { useEffect, useState } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import { BASE_IMG_URL } from "components/shared/constants";
import {
  adminSidebar,
  agencyPaymentColumn,
} from "components/shared/constants/agencyConstants";
import DemoLine from "components/ui/MultiLineGraph";
import TableUi from "components/ui/Table";
import API from "store/services/base.service";

const Statistics = () => {
  const [data, setData] = useState([
    {
      img: "money-bag-yellow.svg",
      name: "Total Tutors:",
      value: 0,
      color: "#FFF5D9",
    },
    {
      img: "money-hand-blue.svg",
      name: "Total students:",
      value: 0,
      color: "#E7EDFF",
    },
    {
      img: `clock-pink.svg`,
      name: "Classes Per Day:",
      value: 0,
      color: "#FFE0EB",
    },
    {
      img: `people-green.svg`,
      name: " Classes Per Month:",
      value: 0,
      color: "#DCFAF8",
    },
  ]);
  const [payments, setPayment] = useState();
  const [graph, setGraph] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get("/stripe/history")
      .then((res) => {
        setPayment(res?.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    API.get("/subscription/tutors-and-students")
      .then((res) => {
        setData([
          {
            img: "money-bag-yellow.svg",
            name: "Total Tutors:",
            value: res.data.data.tutorsCount,
            color: "#FFF5D9",
          },
          {
            img: "money-hand-blue.svg",
            name: "Total students:",
            value: res.data.data.studentsCount,
            color: "#E7EDFF",
          },
          {
            img: `clock-pink.svg`,
            name: "Classes Per Day:",
            value: res.data.data.classesPerDay,
            color: "#FFE0EB",
          },
          {
            img: `people-green.svg`,
            name: " Classes Per Month:",
            value: res.data.data.classesPerMonth,
            color: "#DCFAF8",
          },
        ]);
      })
      .catch((err) => console.log(err));
    API.get("/student/registrations")
      .then((res) => setGraph(res?.data?.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <AgencyLayout sidebarData={adminSidebar} currentPage="Statistics">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-4">
        {data.map((item, idx) => (
          <div
            key={idx}
            className="flex w-full flex-col items-center rounded-lg bg-white py-6"
          >
            <div
              className="rounded-[50%] p-4"
              style={{ background: item.color }}
            >
              <img
                className="size-8"
                src={`${BASE_IMG_URL}/img/${item.img}`}
                alt=""
              />
            </div>
            <div className="mt-6 font-semibold text-black">
              {item.name}{" "}
              <span className="font-medium text-secondary"> {item.value}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-3 flex w-full flex-col justify-between rounded-lg bg-white p-4">
        <div>Total Number of Tutors and Students</div>
        <DemoLine students={graph?.studentCounts} tutors={graph?.tutorCounts} />
      </div>

      <div className="flex flex-col gap-4 p-4">
        <div className="text-xl font-bold">Payment History</div>
        <div className="border-b-2 border-secondary"></div>
        {/* <div> */}
        <div className="rounded-lg bg-white p-4">
          <TableUi
            data={payments}
            headerBg="#303030"
            headerColor="#fff"
            rowSelection
            loading={loading}
            columns={agencyPaymentColumn}
            pageSize={10}
            // viewRedirect={viewRedirectHandler}
          />
          {/* </div> */}
        </div>
      </div>
    </AgencyLayout>
  );
};

export default Statistics;
