/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { Divider, Input } from "antd";
import InputTextField from "components/ui/InputTextField";
import React, { useState } from "react";
import { FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { IoChevronBackSharp } from "react-icons/io5";
import { BASE_IMG_URL } from "components/shared/constants";
import { useDispatch } from "react-redux";
import { registerAction } from "store/action/user";
import Image from "components/shared/Image";

const TutorForm = ({ setData }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    province: "",
    city: "",
    permanentAddress: "",
    gender: "",
    ageConfirmation: false,
    newsletterSubscription: false,
  });

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
        setFormData({ ...formData, avatar: uploadedFile });
        setFile(uploadedFile);
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleClearImage = () => {
    setImageUrl("");
    setFile(null);
    document.getElementById("image-input").value = "";
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData(3);
    dispatch(registerAction({ ...formData, file, role: "tutor" }));
  };

  return (
    <form
      className="flex h-fit w-[90%] flex-col justify-between rounded-md bg-white p-8 shadow-md md:w-[80%] lg:w-[50%]"
      onSubmit={handleSubmit}
    >
      <div className="relative">
        <div className="absolute text-odinPrimary">
          <IoChevronBackSharp size={25} />
        </div>
        <div className="text-center text-2xl font-bold text-odinPrimary">
          Your Details
        </div>
        <div className="flex justify-center p-2">
          <div className="relative size-24 rounded-[50%] bg-ternary">
            <input
              type="file"
              accept="image/*"
              id="image-input"
              className="absolute inset-0 size-full opacity-0"
              onChange={handleImageUpload}
              required
            />
            {imageUrl ? (
              <>
                <Image
                  src={imageUrl}
                  alt="Uploaded"
                  className="absolute inset-0 size-24 rounded-[50%] object-cover object-top"
                />
                <FaTimesCircle
                  className="absolute bottom-0 right-0 cursor-pointer rounded-[50%] border-2 border-white bg-white text-odinPrimary"
                  size={30}
                  onClick={handleClearImage}
                />
              </>
            ) : (
              <FaPlusCircle
                className="absolute bottom-0 right-0 cursor-pointer rounded-[50%] border-2 border-white bg-white text-odinPrimary"
                size={30}
              />
            )}
          </div>
        </div>
        <div className="text-center text-xl font-bold text-odinPrimary">
          Upload Profile Picture
        </div>
        <Divider />
        <div>
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            placeholder="First Name"
            value={formData.firstName}
            onChange={(e) => handleInputChange("firstName", e)}
          />
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={(e) => handleInputChange("lastName", e)}
          />

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <InputTextField
              className="mb-3 border-2 py-3"
              type="text"
              placeholder="Email Address "
              value={formData.email}
              onChange={(e) => handleInputChange("email", e)}
            />
            <InputTextField
              className="mb-3 border-2 py-3"
              type="text"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={(e) => handleInputChange("phoneNumber", e)}
            />
          </div>

          <div className="grid w-full grid-cols-1 gap-2 md:grid-cols-3">
            <InputTextField
              className="mb-3 border-2 py-3"
              type="text"
              placeholder="Country"
              value={formData.country}
              onChange={(e) => handleInputChange("country", e)}
            />
            <InputTextField
              className="mb-3 border-2 py-3"
              type="text"
              placeholder="Province"
              value={formData.province}
              onChange={(e) => handleInputChange("province", e)}
            />
            <InputTextField
              className="mb-3 border-2 py-3"
              type="text"
              placeholder="City"
              value={formData.city}
              onChange={(e) => handleInputChange("city", e)}
            />
          </div>
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            placeholder="Permanent Address"
            value={formData.permanentAddress}
            onChange={(e) => handleInputChange("permanentAddress", e)}
          />
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            placeholder="Gender"
            value={formData.gender}
            onChange={(e) => handleInputChange("gender", e)}
          />
        </div>

        <div className="flex gap-2 p-2 text-sm text-secondary">
          <div>
            <input
              className="size-4"
              type="checkbox"
              checked={formData.ageConfirmation}
              onChange={(e) =>
                handleInputChange("ageConfirmation", e.target.checked)
              }
            />
          </div>
          <div>
            I agree that I am 18 and above, I have read and accept the{" "}
            <u className="text-odinPrimary">
              Terms of Services,Privacy Notice, Cookie Policy
            </u>{" "}
            and{" "}
            <span className="text-odinPrimary">Data Processing Agreement</span>
          </div>
        </div>

        <div className="flex gap-2 p-2 text-sm text-secondary">
          <div>
            <Input
              className="size-4"
              type="checkbox"
              checked={formData.newsletterSubscription}
              onChange={(e) =>
                handleInputChange("newsletterSubscription", e.target.checked)
              }
            />
          </div>
          <div>
            (Optional) If you want more information about Odin, its software
            products and services, recommendations and promotions subscribe to
            Odin’s monthly newsletter by ticking the box. You can always
            unsubscribe if you change your mind.{" "}
          </div>
        </div>

        <div className="flex items-center justify-between py-6 text-xl font-bold text-odinPrimary">
          <div className="flex cursor-pointer gap-2" onClick={() => setData(0)}>
            <img
              src={`${BASE_IMG_URL}/img/arrow-left.svg`}
              alt=""
              className="scale-x-[1]"
            />
            <div>Back</div>
          </div>
          <button type="submit" className="flex cursor-pointer gap-2">
            <div>Create Agency</div>
            <img
              src={`${BASE_IMG_URL}/img/arrow-left.svg`}
              alt=""
              className="scale-x-[-1]"
            />
          </button>
        </div>
      </div>
    </form>
  );
};

export default TutorForm;
