import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const AgencySuccessFullScreen = () => {
  return (
    <div className="flex h-fit w-[90%] flex-col justify-between rounded-md bg-white p-8 shadow-md md:w-[80%] lg:w-[50%]">
      <div className="mx-auto flex w-full flex-col items-center justify-center gap-12 md:w-[70%] ">
        <div className="text-center text-2xl font-bold text-odinPrimary">
          Congratulations your site has been successfully created!
        </div>
        <Link
          to="/dashboard"
          className="flex w-full cursor-pointer items-center justify-center gap-4 rounded-xl border-2 bg-odinPrimary py-3 text-center font-bold text-white"
        >
          <div>Take me to my Odin site</div>
          <FaArrowRightLong />
        </Link>
      </div>
    </div>
  );
};

export default AgencySuccessFullScreen;
