import React from "react";
import { Form, Input, Button, Rate, Divider } from "antd";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { giveReview } from "store/action/user";

const ReviewForm = ({ close, tutorId }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    dispatch(giveReview({ ...values, tutorId }));
    form.resetFields();
    close();
  };

  const handleCancel = () => {
    close();
    form.resetFields();
  };

  return (
    <div>
      <div className="text-xl font-bold">Write Your Review Here</div>
      <Divider className="my-2 bg-secondary" />
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="reviewText"
          rules={[{ required: true, message: "Please type your review!" }]}
        >
          <Input.TextArea
            className="mb-1 border-2 py-3"
            placeholder="Type your review here"
          />
        </Form.Item>
        <Form.Item name="rating" initialValue={3}>
          <Rate />
        </Form.Item>
        <Form.Item>
          <div className="flex items-center justify-center gap-4 font-bold">
            <Button className="h-10 w-24" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="h-10 w-24 bg-black"
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
ReviewForm.propTypes = {
  close: PropTypes.func.isRequired,
  tutorId: PropTypes.number,
};

export default ReviewForm;
