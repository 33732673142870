/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { RiEditLine } from "react-icons/ri";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { MdArrowBackIos } from "react-icons/md";
// import InputTextField from "components/ui/InputTextField";
import { GiCancel } from "react-icons/gi";
import socket from "socket";
import { useDispatch, useSelector } from "react-redux";
import API from "store/services/base.service";
import { useNavigate } from "react-router-dom";
import { pathnameHandler } from "components/utils/functions";
import { Select } from "antd";
import { getMailForAgency, getMailForTutor } from "store/action/user";
import { getChatList } from "store/action/super";
const { Option } = Select;
const ComposeScreen = ({ backHandler }) => {
  const [recipient, setRecipient] = React.useState("");
  const [message, setMessage] = React.useState("");
  const role = sessionStorage.getItem("odin-auth-role");
  const [userRole, setUserRole] = React.useState("tutor");

  const user = useSelector((state) => state.users.user);
  const emailList = useSelector((state) => state.users.emailList);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    const data = {
      email: recipient,
      message,
      role: role || "agency",
      id: user?.userId ? user?.userId : user.id,
      createdAt: new Date(),
    };
    API.post("/chat/createChat", data)
      .then((res) => {
        console.log(res?.data?.data?.room, "success");
        dispatch(getChatList(user));
        navigate(`${pathnameHandler()}/chat/${res?.data?.data?.room}`);
        backHandler();
        setRecipient("");
        setMessage("");
      })
      .catch((err) => {
        console.log(err);
      });
    socket.emit("firstMessage", data);
  };
  useEffect(() => {
    if (role) {
      dispatch(getMailForTutor(user));
      console.log("if");
    } else {
      console.log("else");
      dispatch(getMailForAgency({ role: userRole }));
    }
  }, [userRole, user]);

  return (
    <form
      className="flex h-full flex-col rounded-xl bg-white"
      onSubmit={submitHandler}
    >
      <div className="flex items-center justify-between border-b-2 p-4 pb-3">
        <div className="flex items-center gap-3">
          <MdArrowBackIos onClick={backHandler} className="block lg:hidden" />
          <div className="flex items-center gap-3">
            <div>To: </div>
            {!role && (
              <Select
                value={userRole}
                onChange={(value) => {
                  console.log(value);
                  setUserRole(value);
                  setRecipient(null);
                }}
                style={{ width: 140 }}
              >
                <Option value="tutor">Tutor</Option>
                <Option value="student">Student</Option>
              </Select>
            )}
            <Select
              className="w-[200px]"
              placeholder="Add Recipient"
              name="email"
              value={recipient}
              onChange={setRecipient}
            >
              {emailList?.map((mail) => (
                <Option key={mail.email} value={mail.email}>
                  {mail.email}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <GiCancel onClick={backHandler} className="cursor-pointer" />
      </div>
      <div className="flex flex-1 flex-col justify-end py-4"></div>
      <div className="flex items-center gap-2 border-t-2 p-4 pt-3">
        <div className="flex flex-1 items-center gap-2 rounded-lg border-2 bg-white p-2">
          <RiEditLine color="#000" size={25} />
          <input
            type="text"
            placeholder="Type your message"
            className="w-full border-none outline-none"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="flex items-center gap-2 rounded-lg border-2 border-black bg-black p-2 px-3 text-white"
        >
          <div>Send</div>
          <PiPaperPlaneRightFill color="#fff" />
        </button>
      </div>
    </form>
  );
};

export default ComposeScreen;
