/* eslint-disable react/prop-types */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

export default function CheckoutForm({ clientSecret, slotConfirmed, classes }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe
      .confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.href}?classes=${classes}`,
        },
      })
      .then((res) => {
        console.log(res);
        slotConfirmed();
      });

    if (error.type === "card_error" || error.type === "validation_error") {
      toast(error.message);
    } else {
      toast("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="mt-2 w-full rounded-md bg-odinPrimary py-2 text-white"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">
              Loading ...
            </div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
    </form>
  );
}
