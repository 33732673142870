/* eslint-disable react/prop-types */
// /* eslint-disable react/prop-types */
// // /* eslint-disable react/prop-types */
import React from "react";
import { Table, Tag, ConfigProvider, Skeleton } from "antd";
import DropDownUi from "../DropDown";
import { SlOptionsVertical } from "react-icons/sl";
import { IoEyeOutline } from "react-icons/io5";
import { GoTrash } from "react-icons/go";
import TableDropdown from "./design/TableDropdown";
import { toObject } from "components/utils/functions";
const { Column } = Table;

const dropdownData = [
  {
    name: "View Details",
    icon: <IoEyeOutline size={25} />,
  },
  {
    name: "Delete",
    icon: <GoTrash size={25} />,
  },
];

const TableUi = ({
  data,
  columns,
  pageSize,
  viewRedirect,
  deleteRow,
  headerBg,
  headerColor,
  actionOptions,
  actionUi,
  edit,
  editRow,
  download,
  downloadRow,
  loading,
}) => {
  const clickHandler = (index, name, value) => {
    if (index === 0) {
      viewRedirect && viewRedirect(value);
    }
    if (name === "Delete") {
      deleteRow && deleteRow(index, value);
    }
    if (name.includes("Edit")) {
      edit && editRow(index, value);
    }
    if (name.includes("Download")) {
      download && downloadRow(index, value);
    }
  };
  const renderSkeleton = () => (
    <Table
      dataSource={[...Array(pageSize || 5)]}
      rowKey={(record, index) => `skeleton-${index}`}
      pagination={false}
    >
      {columns.map((col, colIndex) => (
        <Column
          key={colIndex}
          title={col.title}
          render={() => (
            <Skeleton
              active
              title={false}
              avatar={colIndex === 0}
              paragraph={{ rows: 1, width: "80%" }}
            />
          )}
        />
      ))}
    </Table>
  );
  return (
    <div className="overflow-auto lg:w-full">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg,
              headerColor,
            },
          },
        }}
      >
        {loading && !data ? (
          renderSkeleton()
        ) : (
          <Table
            dataSource={data}
            rowKey="id"
            pagination={{ pageSize: pageSize || 5 }}
          >
            {columns?.map((item, idx) => (
              <>
                {item?.key === "action" ? (
                  <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                      <>
                        {" "}
                        {actionUi ? (
                          <>{actionUi(record)}</>
                        ) : (
                          <DropDownUi
                            item={
                              <SlOptionsVertical className="cursor-pointer" />
                            }
                            dropdownRender={
                              <TableDropdown
                                data={actionOptions || dropdownData}
                                clickHandler={(e, name) =>
                                  clickHandler(e, name, record)
                                }
                              />
                            }
                          />
                        )}
                      </>
                    )}
                  />
                ) : item?.key === "tags" ? (
                  <Column
                    title="Tags"
                    dataIndex="tags"
                    key="tags"
                    render={(tags) => (
                      <>
                        {tags?.map((tag) => (
                          <Tag color="blue" key={tag}>
                            {toObject(tag)}
                          </Tag>
                        ))}
                      </>
                    )}
                  />
                ) : (
                  <Column
                    title={item?.title}
                    dataIndex={item?.index}
                    key={item?.key}
                    render={item?.render}
                  />
                )}
              </>
            ))}
          </Table>
        )}
      </ConfigProvider>
    </div>
  );
};
export default TableUi;
