/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Image from "components/shared/Image";
import { capitalizeFirstWord } from "components/utils/functions";
import { DownloadOutlined, MoreOutlined } from "@ant-design/icons";
import AvatarImage from "components/shared/AgencyLayout/designs/Avatar";
import { useSelector } from "react-redux";
import SkeletonWithAvatar from "components/shared/Skeleton";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal } from "antd";

const Post = ({ item, handleUpdate, handleDelete }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.notifications.isLoading);
  const user = useSelector((state) => state.users.user);
  const toggleShow = () => setShow(!show);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    link.click();
  };

  const handleNavigate = (item) => {
    if (item.role === "tutor") {
      navigate(
        user.role === "student"
          ? `/tutor/${item.tutorId}`
          : user.role === "tutor"
          ? `/tutor/${item.tutorId}`
          : `/agency/tutors/${item.tutorId}`
      );
    } else {
      navigate(`/agency/${item.agencyId}`);
    }
  };

  const showOptions = () => {
    if (
      item.role === "agency" &&
      user.id === item.agencyId &&
      user?.role !== "student" &&
      user?.role !== "tutor"
    ) {
      return true;
    }
    if (
      item.role === "tutor" &&
      user.id === item.tutorId &&
      user.role === item.role
    ) {
      return true;
    }
    if (user?.role !== "tutor" && user.role !== "student") {
      return true;
    }
    return false;
  };
  const confirmDelete = (item) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(item);
      },
    });
  };
  const items = [
    {
      label: (
        <a className="" onClick={() => handleUpdate(item)}>
          Edit
        </a>
      ),
      key: "0",
    },
    {
      label: <a onClick={() => confirmDelete(item)}>Delete</a>,
      key: "1",
    },
  ];
  return (
    <div className="rounded-xl bg-white p-4 shadow-md">
      {isLoading && !item ? (
        <SkeletonWithAvatar rows={4} />
      ) : (
        <>
          <div className="flex gap-6">
            {item?.avatar ? (
              <AvatarImage
                avatar={item.avatar}
                alt={item?.username}
                handleClick={() => handleNavigate(item)}
                className="size-12 cursor-pointer rounded-full"
              />
            ) : (
              <div
                className="flex size-16 items-center justify-center rounded-full border-2 border-lightGrey bg-lightGrey text-center uppercase"
                onClick={() => handleNavigate(item)}
              >
                {item?.username?.slice(0, 2)}
              </div>
            )}
            <div className="flex flex-1 flex-col">
              <div className="font-semibold text-black">{item?.username}</div>
              <div className="text-xs text-secondary">
                {capitalizeFirstWord(item?.role)} |
                {item?.createdAt?.slice(0, 10)}
              </div>
              <div className="py-4">
                {item?.group === "announcement" ? (
                  <div className="mb-4">
                    <div className="flex space-x-2">
                      <img
                        src="/img/announcementlogo.svg"
                        alt="Announcement Logo"
                        className="size-6"
                      />
                      <div className="whitespace-pre-wrap text-lg text-secondary">
                        {show ? (
                          item?.content
                        ) : (
                          <>
                            {item?.content.slice(0, 100)}
                            {item.content.length > 100 && (
                              <span>
                                ...{" "}
                                <button
                                  onClick={toggleShow}
                                  className="ml-2 text-blue-500 underline"
                                >
                                  See More
                                </button>
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="whitespace-pre-wrap text-lg text-secondary">
                    {show ? (
                      item?.content
                    ) : (
                      <>
                        {item?.content.slice(0, 100)} {item?.note}
                        {item.content.length > 100 && (
                          <span>
                            ...{" "}
                            <button
                              onClick={toggleShow}
                              className="ml-2 text-blue-500 underline"
                            >
                              See More
                            </button>
                          </span>
                        )}
                      </>
                    )}
                  </div>
                )}
                {show && item.content.length > 100 && (
                  <div className="flex justify-end mt-2 mr-4">
                    <button
                      onClick={toggleShow}
                      className="text-blue-500 underline "
                    >
                      See Less
                    </button>
                  </div>
                )}
              </div>
            </div>
            {showOptions() && (
              <div className="flex items-start justify-end">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <MoreOutlined style={{ fontSize: "24px" }} />
                </Dropdown>
              </div>
            )}
          </div>
          <div className="flex items-center justify-center ">
            {item?.file?.includes("image") && (
              <Image
                src={item?.file || item?.url}
                className="h-80 w-auto rounded-[4px] md:w-3/5 object-contain"
              />
            )}

            {(item?.file?.includes("csv") ||
              item?.file?.includes("excel") ||
              item?.file?.includes("xls") ||
              item?.file?.includes("pdf") ||
              item?.file?.includes("xlsx")) && (
              <div className="flex flex-col">
                <div className=" text-secondary">{item?.filename}</div>
                <button
                  onClick={() => handleDownload(item.file)}
                  className="mt-4 rounded bg-black px-4 py-2 text-white "
                >
                  <DownloadOutlined className="pr-2" />
                  Download File
                </button>
              </div>
            )}
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default Post;
