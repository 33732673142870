import API from "./base.service";

export const createAgencyPostApi = async (payload) =>
  API.post("/post/agency", payload);
export const createTutorPostApi = async (payload) =>
  API.post("/post/tutor", payload);
export const getAgencyApi = async (payload) =>
  API.post("/post/posts/agency", payload);
export const getTutorApi = async (payload) => API.post("/post/posts", payload);

export const postUpdateApi = async (payload) => {
  const postId = payload?.form?.get("postId"); // Extract the ID from FormData
  console.log("🚀 ~ postUpdateApi ~ postId:", postId);

  return API.put(`/post/${postId}`, payload.form);
};

export const tutorDeletePostApi = async (payload) =>
  API.delete(`/post/post/${payload.id}`);

export const agencyDeletePostApi = async (payload) =>
  API.delete(`/post/AgencyPost/${payload.id}`);

export const getCalenderApi = async () =>
  API.get("/subscription/accepted-classes");
