import React, { useState } from "react";

const images = [
  {
    id: 1,
    src: "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e60e71cbaed1cafe71389_95.png", // Replace with the actual path to your image
    alt: "Tutors",
    topics: "100",
    overlayText: "",
    text: "Tutoring Agencies",
  },
  {
    id: 2,
    src: "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e61ce5723c943ba975c72_Social%20media%20posts%20(1)-p-500.png", // Replace with the actual path to your image
    alt: "Business Course",
    overlayText: "Tutors",
    text: "writing Course",
    topics: "100",
  },
  {
    id: 3,
    src: "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e60e7a389689351b2e994_96-p-500.png", // Replace with the actual path to your image
    alt: "Students",
    topics: "100",
    text: "Business Course",
    overlayText: "Students",
  },
];

const Benefit = () => {
  const [hovered, setHovered] = useState(1);

  return (
    <div className="m-auto w-full bg-white p-8">
      <div className="m-auto max-w-[1200px] py-12">
        <h1 className="text-center text-4xl font-bold">
          How Everyone Benefits from Odin
        </h1>
        <p className="my-8 text-center text-lg font-medium text-gray-600">
          Discover how Odin enhances the tutoring experience for tutors,
          agencies, and students.
        </p>
        <div className="items-center justify-center gap-6 md:flex md:gap-12">
          {images.map((image) => (
            <div
              key={image.id}
              className={`duration-500 relative mb-4 overflow-hidden rounded-[20px] shadow-lg transition-all ease-in-out cursor-pointer ${
                hovered === image.id ? "grow" : "shrink"
              } `}
              onMouseEnter={() => setHovered(image.id)}
              onMouseLeave={() => setHovered(1)}
              style={{
                flexBasis:
                  hovered === null
                    ? "30%"
                    : hovered === image.id
                    ? "50%"
                    : "20%",
              }}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full object-cover md:h-[590px]"
              />
              <div className="absolute bottom-10 left-0 flex w-full justify-between bg-gradient-to-t from-black/100 to-transparent p-4 text-white">
                <div className="text-lg font-semibold">
                  {hovered === image.id ? image.text : image.overlayText}
                </div>
                {hovered === image.id && image.topics && (
                  <div className="text-sm">{image.topics} Topics</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefit;
