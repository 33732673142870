/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from "react";
import { logoutSlice } from "store/slices/users";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogoutScreen = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logoutSlice());
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <div className="text-3xl font-bold">Logout</div>
      <div className="text-lg text-secondary">
        Are you sure you want to log out?
      </div>
      <div className="flex items-center gap-4">
        <div className="cursor-pointer rounded-lg border-2 border-secondary p-2 px-6" onClick={onClose}>
          Cancel
        </div>
        <div
          className="cursor-pointer rounded-lg border-2 border-black bg-black p-2 px-6 text-white"
          onClick={logoutHandler}
        >
          Yes, Logout
        </div>
      </div>
    </div>
  );
};

export default LogoutScreen;
