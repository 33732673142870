/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";
import { useSelector } from "react-redux";
import SkeletonWithAvatar from "components/shared/Skeleton";

const TutorCard = ({
  heading,
  data,
  noItem,
  addHandler,
  viewAll,
  handleRedirect,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const handleSearchIconClick = () => {
    setShowSearch(!showSearch);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data?.filter((item) =>
    (item?.username || `${item?.user?.firstName} ${item?.user?.lastName}`)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  return (
    <div className="flex h-fit w-full flex-col rounded-lg bg-white p-4">
      <div className="mb-4 flex justify-between text-xl">
        <div className="font-semibold">{heading}</div>
        <div className="flex gap-2">
          <FiSearch
            className="cursor-pointer"
            onClick={handleSearchIconClick}
          />
          <FaPlusCircle className="cursor-pointer" onClick={addHandler} />
        </div>
      </div>
      {showSearch && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full rounded border px-2 py-1"
          />
        </div>
      )}
      {isLoading && !filteredData ? (
        <SkeletonWithAvatar rows={4} />
      ) : (
        <div className="flex flex-col  gap-3 overflow-auto text-center text-sm text-secondary">
          {filteredData?.length > 0 ? (
            filteredData?.map((item, idx) => (
              <div key={idx} className="flex items-center gap-2 pr-1">
                <Avatar
                  avatar={item.avatar}
                  alt={item?.username}
                  className="cursor-pointer size-10 rounded-full"
                  handleClick={() => handleRedirect(item)}
                />
                <div className="text-start">
                  <div className="text-sm font-semibold text-black">
                    {item?.username}
                  </div>
                  {heading === "Students" ? (
                    <div className="text-xs">
                      Grade:{" "}
                      <span className="font-semibold">{item?.grade}</span>
                    </div>
                  ) : (
                    <div className="text-xs">
                      Experience:{" "}
                      <span className="font-semibold">
                        {item.experience} years
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="  flex h-full items-center justify-center">
              {noItem}
            </div>
          )}
        </div>
      )}
      {viewAll && (
        <Link
          to={viewAll}
          className="mt-2 w-full cursor-pointer rounded-lg bg-black py-1 text-center text-sm text-white"
        >
          View All
        </Link>
      )}
    </div>
  );
};

export default TutorCard;
