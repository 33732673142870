import { Divider } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { cancelSubs } from "store/action/user";

const CancelSubscription = ({ close }) => {
  const dispatch = useDispatch();
  const cancelSubscriptionHandler = () => {
    dispatch(cancelSubs());
    close();
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-xl font-bold">Cancel Subscription</div>
      <Divider className="my-2 bg-gray-100" />

      <div className="text-lg font-bold">Are you sure?</div>
      <div className="flex items-center justify-center gap-4 p-4 font-bold">
        <div
          className="flex h-10 w-24 cursor-pointer items-center justify-center rounded-lg  border-2 bg-white text-center text-black"
          onClick={() => close()}
        >
          Cancel
        </div>
        <button
          className="flex h-10 w-32 items-center justify-center rounded-lg border-2 border-[black] bg-black text-center text-white outline-none"
          onClick={cancelSubscriptionHandler}
        >
          Yes, Proceed
        </button>
      </div>
    </div>
  );
};

CancelSubscription.propTypes = {
  close: PropTypes.func.isRequired,
};

export default CancelSubscription;
