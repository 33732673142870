import { createSlice } from "@reduxjs/toolkit";

const theme = createSlice({
  name: "theme",
  initialState: {
    domain: "",
  },
  reducers: {
    setDomainSlice: (state, action) => {
      return {
        ...state,
        domain: action.payload,
      };
    }
  },
});

export const {
  setDomainSlice,
} = theme.actions;
export default theme.reducer;
