import React, { useEffect, useState } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import { adminSidebar } from "components/shared/constants/agencyConstants";
import TableUi from "components/ui/Table";
import AddTutor from "../AgencyDashboard/designs/AddTutor";
import Modal from "components/shared/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgencyStudentsAction,
  getAgencyTutorsAction,
} from "store/action/user";
import { toObject } from "components/utils/functions";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";

const AgencyAdminUserManagement = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const students = useSelector((state) => state.users.students);
  const tutors = useSelector((state) => state.users.tutors);

  useEffect(() => {
    dispatch(getAgencyStudentsAction());
    dispatch(getAgencyTutorsAction());
  }, []);

  const openAddTutorHandler = (value) => {
    setModalType(value);

    setModal(true);
  };

  const data = [
    {
      name: "Tutor List",
      data: tutors || [],
      column: [
        {
          title: "Tutor Name",
          key: "username",
          index: "username",
          render: (_, val) => {
            return (
              <div className="flex items-center gap-2">
                <Avatar avatar={val?.avatar} alt={val.username} />
                <div>{val.username}</div>
              </div>
            );
          },
        },
        {
          title: "Subject",
          key: "subject",
          index: "subject",
          render: (_, val) => {
            const subjectsArray = toObject(val?.subject);
            if (subjectsArray.length > 2) {
              return (
                <div title={subjectsArray.join(" / ")}>
                  {subjectsArray.slice(0, 2).join(" / ")} / ...
                </div>
              );
            }
            return subjectsArray.join(" / ");
          },
        },
      ],
    },
    {
      name: "Student List",
      data: students,
      column: [
        {
          title: "Student Name",
          key: "username",
          index: "username",
          render: (_, val) => {
            return (
              <div className="flex items-center gap-2">
                <Avatar avatar={val?.avatar} alt={val.username} />
                <div>{val.username}</div>
              </div>
            );
          },
        },
        { title: "Grade", key: "grade", index: "grade" },
      ],
    },
  ];

  return (
    <AgencyLayout sidebarData={adminSidebar} currentPage="User Management">
      <Modal
        open={modal}
        close={(e) => setModal(e)}
        element={<AddTutor name={modalType} close={() => setModal(false)} />}
      />
      <div className="flex h-full flex-col gap-2 rounded-lg bg-white p-3 md:p-6">
        <div className="flex items-end flex-wrap gap-2">
          <div className="w-fit border-b-2 border-secondary font-bold text-2xl ">
            User Management
          </div>
          <div className="flex flex-1 justify-end gap-2 border-b-2 pb-2">
            <div
              className="flex cursor-pointer items-center justify-center gap-2 rounded-lg border-2 border-black bg-black px-6 py-1 text-base font-bold text-white"
              onClick={() => openAddTutorHandler("Student")}
            >
              <div>+</div>
              <div>Add Students</div>
            </div>
            <div
              className="flex cursor-pointer items-center justify-center gap-2 rounded-lg border-2 bg-white px-6 py-1 text-base font-bold text-black"
              onClick={() => openAddTutorHandler("tutor")}
            >
              <div>+</div>
              <div>Add Tutors</div>
            </div>
          </div>
        </div>

        <div className="mt-5 grid h-full  grid-cols-1 items-center justify-center gap-6 md:grid-cols-2">
          {data?.map((item, idx) => (
            <div key={idx} className="h-full rounded-lg border-2 p-4">
              <div className="mb-4 flex">
                <div className="border-b-2 border-secondary text-2xl font-semibold">
                  {item?.name}
                </div>
                <div className="flex-1 border-b-2"></div>
              </div>

              <TableUi
                data={item?.data}
                headerBg="#303030"
                headerColor="#fff"
                rowSelection
                columns={item.column}
                pageSize={6}
              />
            </div>
          ))}
        </div>
      </div>
    </AgencyLayout>
  );
};

export default AgencyAdminUserManagement;
