import React from "react";

const Management = () => {
  return (
    <div className="w-full p-8" id="about">
      <div className="m-auto max-w-[1200px]">
        <div className="justify-around px-4 py-12 md:flex md:px-0">
          <div className="">
            <img
              src="https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e4e65089b043f29882d36_ee.jpg"
              className="mb-4 max-w-[90%] rounded-2xl md:mb-0 md:h-[375px]"
            />
          </div>
          <div className="mt-8 md:mt-0 md:max-w-[35%]">
            <h1 className="text-4xl font-semibold leading-[56px]	">
              Learning Management System for Tutors & Students
            </h1>
            <p className="mt-10 text-xl">
              Odin is a comprehensive learning management system designed for
              tutors, tutoring agencies, and students
            </p>
          </div>
        </div>
        <div className="flex  justify-between rounded-lg py-6 md:justify-around md:p-6 md:py-12 gap-8">
          <div className="mb-4 md:mb-0 md:text-center">
            <h4 className="text-3xl font-bold md:text-5xl">30+</h4>
            <p className="text-xl text-gray-600 ">Tutors</p>
          </div>
          <div className="mb-4 md:mb-0 md:text-center">
            <h4 className="text-3xl font-bold md:text-5xl">100+</h4>
            <p className="text-xl text-gray-600">Students</p>
          </div>
          <div className="mb-4 md:mb-0 md:text-center w-fit">
            <h4 className="text-3xl font-bold md:text-5xl">10+</h4>
            <p className="text-xl text-gray-600">Tutoring Agencies</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Management;
