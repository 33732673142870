/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import MobilePricingTable from "./MobileTable";

const PricingTable = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/agency/agency-form");
  };

  const plans = [
    {
      name: "Basic",
      features: {
        scheduling: true,
        contentManagement: true,
        automatedPayments: true,
        multiUserSupport: true,
        classTracking: true,
        videoCall: false,
        detailedAnalytics: false,
        prioritySupport: false,
        customBranding: false,
      },
    },
    {
      name: "Pro",
      features: {
        scheduling: true,
        contentManagement: true,
        automatedPayments: true,
        multiUserSupport: true,
        classTracking: true,
        videoCall: true,
        detailedAnalytics: true,
        prioritySupport: true,
        customBranding: true,
      },
    },
    {
      name: "Premium",
      features: {
        scheduling: true,
        contentManagement: true,
        automatedPayments: true,
        multiUserSupport: true,
        classTracking: true,
        videoCall: true,
        detailedAnalytics: true,
        prioritySupport: true,
        customBranding: true,
      },
    },
  ];

  const FeatureRow = ({ feature, plans }) => (
    <tr>
      <td className="border border-gray-100 px-6 py-3 text-left font-bold capitalize">
        {feature}
      </td>
      {plans.map((plan, index) => (
        <td
          key={index}
          className="border border-gray-100 px-4 py-3 text-center font-bold"
        >
          {plan.features[feature] ? "✓" : "✕"}
        </td>
      ))}
    </tr>
  );

  const featureList = [
    "scheduling",
    "contentManagement",
    "automatedPayments",
    "multiUserSupport",
    "classTracking",
    "videoCall",
    "detailedAnalytics",
    "prioritySupport",
    "customBranding",
  ];

  return (
    <div className="mx-auto my-8 md:my-16 max-w-[1200px]" id="pricing">
      <h1 className="text-center text-xl">FLEXIBLE PRICING PLANS</h1>
      <h1 className="my-4 text-center text-4xl font-bold md:text-5xl">
        Choose Your Plan
      </h1>
      <div className="hidden overflow-x-auto pb-12 md:block">
        <table className="min-w-full rounded-2xl bg-white">
          <thead>
            <tr>
              <th className="border-gray-200 p-4  text-left"></th>
              {plans.map((plan, index) => (
                <th
                  key={index}
                  className="border-b-2 border-gray-200 p-4 text-center"
                >
                  <div className="text-lg font-semibold">{plan.name}</div>
                  <motion.button
                    className="mt-2 rounded-full border bg-[#0114b0] px-4 py-2 text-white hover:border-[#0114b0] hover:bg-white hover:text-[#0114b0]"
                    onClick={handleNavigate}
                    whileHover={{ scale: 1, y: -5 }}
                  >
                    Select Plan
                  </motion.button>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {featureList.map((feature, index) => (
              <FeatureRow key={index} feature={feature} plans={plans} />
            ))}
          </tbody>
        </table>
      </div>
      <MobilePricingTable />
    </div>
  );
};

export default PricingTable;
