/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Divider, Select } from "antd";
import Stepper from "components/ui/Stepper";
import { BASE_IMG_URL } from "components/shared/constants";
// import Modal from "components/shared/Modal";
// import AddDegree from "components/modules/TutorSetting/design/AddDegree";
import MultipleSelection from "components/shared/MultiSelection";

const StepTwo = ({ data, onChange, handleStepper }) => {
  const [formData, setFormData] = useState(data);
  // const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const { Option } = Select;

  const validate = () => {
    const newErrors = {};
    if (!formData.subject.length) newErrors.subject = "Subject is required";
    if (!formData.languages.length)
      newErrors.languages = "Language is required";
    if (!formData.experience) newErrors.experience = "Experience is required";
    return newErrors;
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const addDegreeHandler = (value) => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      onChange(updatedFormData);
      handleStepper(2);
      return updatedFormData;
    });
  };
  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      addDegreeHandler();
    }
  };

  return (
    <div className="flex min-h-screen flex-col">
      {/* <Header /> */}
      {/* <Modal
        footer={false}
        open={modal}
        close={(e) => setModal(e)}
        element={
          <AddDegree setData={addDegreeHandler} close={() => setModal(false)} />
        }
      /> */}
      <div className="flex h-full flex-1 items-center justify-center">
        <div className="flex min-h-[500px] w-4/5 flex-col justify-between rounded-md bg-white p-4 md:p-8 shadow-md lg:w-[50%]">
          <div className="text-center text-2xl font-bold text-[#303030]">
            Tutor Education Details{" "}
          </div>
          <div className="mx-auto">
            <Stepper totalSteps={4} currentStep={2} color="#303030" />
          </div>
          <Divider />
          <div>
            <div className="mb-3">
              <MultipleSelection
                name="Subject"
                type="multiSelect"
                dropDown
                value={formData?.subject}
                getTags={(value) => handleInputChange("subject", value)}
              />
              {errors.subject && (
                <div className="text-sm text-red-500">{errors.subject}</div>
              )}
              {/* <MultipleSelection
                name="languages"
                type="multiSelect"
                dropDown
                value={formData?.languages}
                getTags={(value) => handleInputChange("languages", value)}
              />
              {errors.languages && (
                <div className="text-sm text-red-500">{errors.languages}</div>
              )}
            </div> */}
              <div className="mb-3">
                <div className="text-sm font-medium text-secondary mb-2">
                  Languages
                </div>
                <Select
                  mode="multiple"
                  allowClear
                  size="large"
                  placeholder="Select Languages"
                  value={formData.languages}
                  onChange={(value) => handleInputChange("languages", value)}
                  style={{ width: "100%" }}
                >
                  <Option value="English">English</Option>
                  <Option value="Spanish">Spanish</Option>
                  <Option value="French">French</Option>
                  <Option value="Italian">Italian</Option>
                  <Option value="Dutch">Dutch</Option>
                  <Option value="Thai">Thai</Option>
                </Select>
                {errors.languages && (
                  <div className="text-sm text-red-500">{errors.languages}</div>
                )}
              </div>
              <MultipleSelection
                className="mb-3 border-2 py-3"
                name="Experience"
                type="number"
                placeholder="Experience"
                value={formData?.experience}
                onChange={(value) =>
                  setFormData({ ...formData, experience: value })
                }
              />
              {errors.experience && (
                <div className="text-sm text-red-500">{errors.experience}</div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between text-[#303030]">
            <div
              className="flex cursor-pointer gap-2"
              onClick={() => handleStepper(0)}
            >
              <div>Back</div>
            </div>
            <div className="flex cursor-pointer gap-2" onClick={handleSubmit}>
              <div>Continue</div>
              <img
                src={`${BASE_IMG_URL}/img/arrow-left.svg`}
                alt=""
                className="scale-x-[-1]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
/* eslint-disable react/prop-types */
// import React, { useState } from "react";
// import { Divider, Select } from "antd";
// import Stepper from "components/ui/Stepper";
// import { BASE_IMG_URL } from "components/shared/constants";

// const { Option } = Select;

// const StepTwo = ({ data, onChange, handleStepper }) => {
//   const [formData, setFormData] = useState(data);
//   const [errors, setErrors] = useState({});

//   const validate = () => {
//     const newErrors = {};
//     if (!formData.subject.length) newErrors.subject = "Subject is required";
//     if (!formData.languages.length)
//       newErrors.languages = "Language is required";
//     if (!formData.experience) newErrors.experience = "Experience is required";
//     return newErrors;
//   };

//   const handleInputChange = (name, value) => {
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = () => {
//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       setErrors({});
//       onChange(formData);
//       handleStepper(2);
//     }
//   };

//   return (
//     <div className="flex min-h-screen flex-col">
//       <div className="flex h-full flex-1 items-center justify-center">
//         <div className="flex min-h-[500px] w-4/5 flex-col justify-between rounded-md bg-white p-4 md:p-8 shadow-md lg:w-[50%]">
//           <div className="text-center text-2xl font-bold text-[#303030]">
//             Tutor Education Details{" "}
//           </div>
//           <div className="mx-auto">
//             <Stepper totalSteps={4} currentStep={2} color="#303030" />
//           </div>
//           <Divider />
//           <div>
//             <div className="mb-3">
//               <Select
//                 mode="multiple"
//                 allowClear
//                 size="large"
//                 placeholder="Select Subject"
//                 value={formData.subject}
//                 onChange={(value) => handleInputChange("subject", value)}
//                 style={{ width: "100%" }}
//               >
//                 {/* Replace with actual subject options */}
//                 <Option value="Math">Math</Option>
//                 <Option value="Science">Science</Option>
//                 <Option value="History">History</Option>
//               </Select>
//               {errors.subject && (
//                 <div className="text-sm text-red-500">{errors.subject}</div>
//               )}
//             </div>
//             <div className="mb-3">
//               <Select
//                 mode="multiple"
//                 allowClear
//                 size="large"
//                 placeholder="Select Languages"
//                 value={formData.languages}
//                 onChange={(value) => handleInputChange("languages", value)}
//                 style={{ width: "100%" }}
//               >
//                 {/* Replace with actual language options */}
//                 <Option value="English">English</Option>
//                 <Option value="Spanish">Spanish</Option>
//                 <Option value="French">French</Option>
//               </Select>
//               {errors.languages && (
//                 <div className="text-sm text-red-500">{errors.languages}</div>
//               )}
//             </div>
//             <div className="mb-3">
//               <input
//                 className="mb-3 border-2 py-3 w-full px-3 rounded-md"
//                 name="Experience"
//                 type="number"
//                 placeholder="Experience"
//                 value={formData?.experience}
//                 onChange={(e) =>
//                   handleInputChange("experience", e.target.value)
//                 }
//               />
//               {errors.experience && (
//                 <div className="text-sm text-red-500">{errors.experience}</div>
//               )}
//             </div>
//           </div>
//           <div className="flex items-center justify-between text-[#303030]">
//             <div
//               className="flex cursor-pointer gap-2"
//               onClick={() => handleStepper(0)}
//             >
//               <div>Back</div>
//             </div>
//             <div className="flex cursor-pointer gap-2" onClick={handleSubmit}>
//               <div>Continue</div>
//               <img
//                 src={`${BASE_IMG_URL}/img/arrow-left.svg`}
//                 alt=""
//                 className="scale-x-[-1]"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StepTwo;
