import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";

const AddDegree = ({ setData, close, degree }) => {
  console.log("🚀 ~ AddDegree ~ degree:", degree);
  const [form] = Form.useForm();

  useEffect(() => {
    if (degree) {
      form.setFieldsValue(degree);
    } else {
      form.resetFields();
    }
  }, [degree, form]);

  const handleSubmit = (values) => {
    setData(values);
    form.resetFields();
    close();
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      className="flex flex-col gap-3 font-medium"
    >
      <div className="pb-1 text-left text-lg font-bold text-black">
        {degree ? "Edit Degree" : "Add Degree"}
      </div>

      <Form.Item
        name="name"
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input
          className="rounded-xl border-2 py-2 text-secondary"
          type="text"
          placeholder="Name"
        />
      </Form.Item>

      <Form.Item
        name="majorSubject"
        rules={[{ required: true, message: "Major Subject is required" }]}
      >
        <Input
          className="rounded-xl border-2 py-2 text-secondary"
          type="text"
          placeholder="Major Subject"
        />
      </Form.Item>

      <Form.Item
        name="minorSubject"
        rules={[{ required: true, message: "Minor Subject is required" }]}
      >
        <Input
          className="border-2 py-2 text-secondary"
          type="text"
          placeholder="Minor Subject"
        />
      </Form.Item>

      <Form.Item
        name="institutionName"
        rules={[{ required: true, message: "Institution Name is required" }]}
      >
        <Input.TextArea
          className="h-24 w-full rounded-lg border-2 p-2 text-secondary"
          placeholder="Institution Name"
        />
      </Form.Item>

      <div className="flex justify-end">
        <div className="flex items-center justify-center gap-2">
          <button
            type="button"
            className="rounded-lg border-2 border-secondary px-6 py-2 font-semibold text-black"
            onClick={close}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-lg border-2 border-black bg-black px-9 py-2 font-semibold text-white"
          >
            {degree ? "Edit" : "Add"}
          </button>
        </div>
      </div>
    </Form>
  );
};

AddDegree.propTypes = {
  setData: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  degree: PropTypes.object,
};

export default AddDegree;
