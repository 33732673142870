/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import Topbar from "./designs/Topbar";
import Header from "./designs/Header";
import ChooseOdin from "./designs/ChooseOdin";
import Benefit from "./designs/Benfit";
import TestimonialSlider from "./designs/TestimonialSlider";
import Management from "./designs/Management";
import PricingTable from "./designs/PricingTable";
import Footer from "./designs/Footer";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
      }
    }
  }, [hash]);

  return (
    <div className="bg-[#f7f5ff]">
      <Topbar />
      <Header />
      <div className="min-h-[56px] bg-[#3b24b2] px-[5%] py-4 font-[500] text-white md:text-center">
        <div className=" items-center justify-center sm:flex">
          <div className="mb-2 mr-3 w-fit rounded-[50px] bg-[#642eff] px-4 py-2 text-[12px] md:mb-0">
            BETA VERSION
          </div>
          Join our Founders Club: Shape Odin's future during its beta!
        </div>
      </div>
      <ChooseOdin />
      <Benefit />
      <Management />
      <TestimonialSlider />
      <PricingTable />
      <Footer />
    </div>
  );
};

export default Home;
