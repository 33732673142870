/* eslint-disable no-undef */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { agencySidebar } from "../constants/agencyConstants";
import HeaderRightArea from "./designs/HeaderRightArea";
import { Drawer } from "antd";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "store/action/user";
import {
  setNotificationList,
  setTokenSlice,
  updateChatListSlice,
} from "store/slices/users";
import "react-toastify/dist/ReactToastify.css";
import Image from "../Image";
import { useNavigate } from "react-router-dom";
import socket from "store/services/socketConfig";
import API from "store/services/base.service";

const AgencyLayout = ({ currentPage, children, sidebarData, noSidebar }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const token = useSelector((state) => state.users.accessToken);
  const role = sessionStorage.getItem("odin-auth-role");
  const user = useSelector((state) => state.users.user);
  const agency = useSelector((state) => state.supers.agency);
  const [chatList, setChatList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    const localToken = sessionStorage.getItem("odin-auth-token");
    if (localToken) {
      if (token === "") {
        dispatch(setTokenSlice(localToken));
      }
      if (user && !user.subscriptionType && !user.role) {
        navigate("/agency/admin/billing");
      }
      dispatch(getCurrentUser());
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const handleReceiveNotification = (data) => {
      if (
        user?.userId === data?.receiverData?.id ||
        user?.id === data?.receiverData?.id
      ) {
        dispatch(setNotificationList(data?.receiverData?.notificationList));
        dispatch(updateChatListSlice(data));
      }
    };

    if (socket) {
      // chatList.forEach((item) =>
      //   socket.emit("join_room", { room: item?.roomId })
      // );
      socket.on("receive_message", handleReceiveNotification);
    }

    return () => {
      socket.off("receive_message", handleReceiveNotification);
    };
  }, [chatList, user, dispatch]);

  const fetchChatList = (endpoint, setChatList) => {
    API.get(endpoint)
      .then((res) => {
        setChatList(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let chatListEndpoint;
    if (role === "student") {
      chatListEndpoint = "/chat/chatList";
    } else if (role === "tutor") {
      chatListEndpoint = "/chat/list";
    } else {
      chatListEndpoint = "/chat/chatListAgency";
    }

    fetchChatList(chatListEndpoint, setChatList);
  }, [role, user?.userId, user?.id, setChatList]);

  return (
    <div
      className="flex min-h-screen overflow-y-auto"
      style={{ backgroundColor: "var(--bg-primary-color)" }}
    >
      <div>
        <div className="hidden lg:block">
          {!noSidebar && (
            <Sidebar
              search
              data={sidebarData || agencySidebar}
              currentPage={currentPage}
              className="bg-primary"
            />
          )}
        </div>

        <div className="block lg:hidden">
          {!noSidebar && (
            <Drawer
              placement="left"
              closable={false}
              onClose={onClose}
              open={showSidebar}
              style={{ width: "0" }}
            >
              <Sidebar
                search
                data={sidebarData || agencySidebar}
                currentPage={currentPage}
                className="bg-primary"
              />
            </Drawer>
          )}
        </div>
      </div>
      <div className="flex max-h-full min-h-screen flex-1 flex-col">
        <div className="relative h-[70px] w-full">
          <Header
            logo={
              <Image
                src={role ? agency?.logo : user?.avatar}
                alt=""
                className="size-12  rounded-full"
              />
            }
            role={role}
            rightArea={
              <HeaderRightArea
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              />
            }
            className={`fixed left-0 right-0 top-0 z-50 h-[70px] bg-white pl-8 pr-8 shadow-lg ${!noSidebar && "lg:left-60"
              }`}
          />
        </div>
        <ToastContainer />
        <div className="h-full flex-1 bg-[#EDEFF1] p-4 md:p-8">{children}</div>
      </div>
    </div>
  );
};

export default AgencyLayout;
