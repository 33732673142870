import { combineReducers } from "@reduxjs/toolkit";
import users from "./users";
import AlertSlice from "./alert";
import theme from "./theme"
import supers from "./super"
import redirect from "./redirect";

const reducers = combineReducers({
  users,
  notifications: AlertSlice,
  theme,
  supers,
  redirect
});

export default reducers;
