import React, { useEffect, useState } from "react";
import { IoMailOutline } from "react-icons/io5";
import { FiLock } from "react-icons/fi";
import InputTextField from "components/ui/InputTextField";
import { agencyLoginAction } from "store/action/user";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { validateEmail, validatePassword } from "components/utils/functions";

const FormArea = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.users.accessToken);
  const router = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleNavigate = () => {
    router("/agency/forget-password");
  };

  useEffect(() => {
    if (accessToken === "asdsa") {
      router.push("/");
    }
  }, [accessToken]);

  const handleChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const emailError = validateEmail(formData.email);
    const passwordError = validatePassword(formData.password);

    if (emailError) {
      toast.error(emailError);
      return;
    }

    if (passwordError) {
      toast.error(passwordError);
      return;
    }

    dispatch(agencyLoginAction(formData));
  };

  return (
    <div className="col-span-1 h-full rounded-3xl bg-white p-4 text-center text-odinPrimary md:p-8">
      <form
        className="flex h-full flex-col justify-between py-8"
        onSubmit={submitHandler}
      >
        <div>
          <div className="text-2xl font-bold">Sign in</div>
          <div className="text-lg font-normal">To your Odin account</div>
        </div>
        <div>
          <InputTextField
            className="mb-3 border-2 py-3"
            type="text"
            icon={<IoMailOutline fontSize={20} color="#0115B5" />}
            placeholder="Email Address"
            variant="outlined"
            value={formData.email}
            onChange={(e) => handleChange("email", e)}
            required
          />
          <InputTextField
            className="mb-3 border-2 py-3"
            type="password"
            icon={<FiLock fontSize={20} color="#0115B5" />}
            placeholder="Password"
            variant="outlined"
            value={formData.password}
            onChange={(e) => handleChange("password", e)}
            required
          />
        </div>
        <div>
          <button
            type="submit"
            className="w-full cursor-pointer rounded-lg bg-gradient-to-l from-blue-900 to-blue-700 py-3 font-semibold text-white"
          >
            Sign In
          </button>
          <div
            className="mt-6 cursor-pointer text-sm font-semibold text-odinPrimary "
            onClick={handleNavigate}
          >
            Forgot password?
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormArea;
