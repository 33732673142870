/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { currentWeek } from "components/utils/functions";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const WalletLine = ({ monthlyRevenueArray }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true, // Show the toolbar
          tools: {
            download: true, // Hide download icon
            selection: false, // Hide selection icon
            zoom: false, // Hide zoom icon
            zoomin: false, // Hide zoom-in icon
            zoomout: false, // Hide zoom-out icon
            pan: false, // Hide pan icon
            reset: false, // Hide reset icon
          },
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      markers: {
        size: 0,
      },
    },
    series: [
      {
        name: "Full Year Revenue",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  useEffect(() => {
    setState({
      ...state,
      series: [
        {
          name: "Full Year Revenue",
          data: monthlyRevenueArray,
        },
      ],
    });
  }, [monthlyRevenueArray]);

  return (
    <div className="w-full">
      <Chart
        options={state.options}
        series={state.series}
        type="line"
        height={300}
      />
    </div>
  );
};

export default WalletLine;
