import React, { useState } from "react";
import { agencyAdminSidebar } from "components/shared/constants/agencyConstants";
import AdminLayout from "components/shared/AdminLayout";
import { BASE_IMG_URL } from "components/shared/constants";
import Modal from "components/shared/Modal";
import SubscriptionPlan from "./design/SubscriptionPlan";
import Payments from "./design/Payments";

export const settingGeneralOptions = [
  {
    name: "Subscription Plan",
    desc: "To change subscription plan. Click here to",
  },
  // {
  //   name: "Payments",
  //   desc: "To change payment methods. Click here to",
  // },
];

const AdminSettings = () => {
  const [modal, setModal] = useState(false);
  const [settingOptions, setSettingoptions] = useState();

  const openAddTutorHandler = (value) => {
    setModal(true);
    setSettingoptions(value);
  };

  const rendered = (e) => {
    switch (e) {
      case 0:
        return <SubscriptionPlan />;
      case 1:
        return <Payments />;
      default:
        return <SubscriptionPlan />;
    }
  };

  return (
    <AdminLayout sidebarData={agencyAdminSidebar} currentPage="Settings">
      <Modal
        footer={false}
        open={modal}
        close={(e) => setModal(e)}
        element={rendered(settingOptions)}
        width={700}
      />
      <div className="flex flex-1 flex-col gap-4 ">
        <div className="flex items-end">
          <div className="border-b-2 border-odinPrimary text-2xl font-bold text-odinPrimary ">
            Account Settings
          </div>
          <div className="flex-1 border-b-2"></div>
        </div>
        {settingGeneralOptions?.map((item, idx) => (
          <div
            key={idx}
            className="h-fit w-full cursor-pointer rounded-lg bg-white p-6"
            onClick={() => openAddTutorHandler(idx)}
          >
            <div className="text-xl font-semibold text-black">{item?.name}</div>
            <div className="flex justify-end">
              <img src={`${BASE_IMG_URL}/img/book.svg`} alt="" />
            </div>
            <div className="text-sm text-secondary">
              {item?.desc}{" "}
              <span className="font-bold text-black">Add Changes</span>{" "}
            </div>
          </div>
        ))}
      </div>
    </AdminLayout>
  );
};

export default AdminSettings;
