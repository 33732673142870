/* eslint-disable react/prop-types */
// /* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import { Row, Col, Space, Button, Typography, Grid } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const CustomToolbar = ({ showMoreCount, setView, ...props }) => {
  const screens = useBreakpoint();
  const isSmallScreen = !screens.md;

  useEffect(() => {
    if (showMoreCount >= 2) {
      setView("day");
    }
  }, [showMoreCount, setView]);

  const viewAdjustments = {
    month: (date, amount) => date.add(amount, "month"),
    week: (date, amount) => date.add(amount, "week"),
    day: (date, amount) => date.add(amount, "day"),
  };

  const adjustDate = (amount) => {
    const currentMoment = moment(props.date);
    const newDate = viewAdjustments[props.view](currentMoment, amount);
    return newDate.toDate();
  };

  const goToBack = () => {
    const newDate = adjustDate(-1);
    props.onNavigate("prev", newDate);
  };

  const goToNext = () => {
    const newDate = adjustDate(1);
    props.onNavigate("next", newDate);
  };

  const handleViewChange = (view) => {
    setView(view);
    props.onView(view);
  };

  return (
    <>
      <Row
        className="rounded-xl border border-[#D5D5D5] bg-white p-3"
        justify="space-between"
        align="middle"
        style={{ marginBottom: 10 }}
      >
        <Col span={isSmallScreen ? 24 : 8}>
          <Space align="center">
            <Button onClick={goToBack} icon={<LeftOutlined />} />
            <Title level={4} style={{ margin: 0 }}>
              {props.label}
            </Title>
            <Button onClick={goToNext} icon={<RightOutlined />} />
          </Space>
        </Col>
        <Col
          span={isSmallScreen ? 24 : 8}
          style={{
            textAlign: isSmallScreen ? "center" : "end",
            marginTop: isSmallScreen ? 10 : 0,
          }}
        >
          <Space align="center">
            <button
              type="button"
              className={`flex h-10 w-16 items-center justify-center rounded-lg text-center ${
                props.view === "month" ? "bg-black text-white" : "text-black"
              }`}
              onClick={() => handleViewChange("month")}
            >
              Month
            </button>
            <button
              type="button"
              className={`flex h-10 w-16 items-center justify-center rounded-lg text-center ${
                props.view === "week" ? "bg-black text-white" : "text-black"
              }`}
              onClick={() => handleViewChange("week")}
            >
              Week
            </button>
            <button
              type="button"
              className={`flex h-10 w-16 items-center justify-center rounded-lg text-center ${
                props.view === "day" ? "bg-black text-white" : "text-black"
              }`}
              onClick={() => handleViewChange("day")}
            >
              Day
            </button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default CustomToolbar;
