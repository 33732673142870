import React from "react";
import { Switch } from "antd";
import { BASE_IMG_URL } from "components/shared/constants";

const NotificationSetting = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-4 pb-8">
      <div className="font-semibold">What notifications you receive</div>
      <div className="flex items-center justify-between rounded-lg border-2 p-2">
        <div className="flex items-center gap-2">
          <img src={`${BASE_IMG_URL}/img/clock-black.svg`} alt="" />
          <div className="text-secondary">Class Reminder</div>
        </div>
        <Switch className="bg-secondary" />
      </div>
      <div className="flex items-center justify-between rounded-lg border-2 p-2">
        <div className="flex items-center gap-2">
          <img src={`${BASE_IMG_URL}/img/dollar-border-rounded.svg`} />
          <div className="text-secondary">Payment Reminder</div>
        </div>
        <Switch className="bg-secondary" />
      </div>
    </div>
  );
};

export default NotificationSetting;
