/* eslint-disable react/prop-types */
import React from "react";
import TutorInfoCard from "./TutorInfoCard";
import TutorDetail from "./TutorDetail";
import TutorCode from "./TutorCode";
import ReviewCard from "./ReviewCard";

const Profile = ({ tutorData }) => {
  return (
    <div className=" grid grid-cols-1  md:grid-cols-5 md:gap-4">
      <TutorInfoCard data={tutorData} />
      <div className="col-span-2 mt-4 flex flex-col gap-4 md:mt-0">
        <TutorDetail data={tutorData} />
        <TutorCode data={tutorData} />
        <ReviewCard data={tutorData} />
      </div>
    </div>
  );
};

export default Profile;
