/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { motion } from "framer-motion";

const ChooseOdin = () => {
  return (
    <div
      className="m-auto mt-8 w-full max-w-[1200px] overflow-hidden bg-purple-50 px-2  text-center font-sans md:mt-0 md:p-8"
      id="feature"
    >
      <header className="mb-8">
        <h1 className="text-4xl font-bold">Why Choose ODIN</h1>
        <p className="my-4 text-gray-700">
          Discover Odin's key features and benefits that streamline and enhance
          your tutoring management experience.
        </p>
      </header>
      <div className="mb-8 flex flex-wrap justify-center">
        <div className="flex w-full flex-col justify-between sm:w-1/3 md:items-end md:pr-4">
          {features.slice(0, 3).map((feature, index) => (
            <FeatureCard
              key={index}
              index={index}
              feature={feature}
              textAlign="md:text-right text-left"
              justify="md:justify-end justify-center"
              row="md:flex-row-reverse flex-col"
            />
          ))}
        </div>
        <div className="mb-8 flex w-full justify-center px-2 sm:w-1/3">
          <img
            src="https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e4b81123fa1a83e17e9af_1-p-500.png"
            alt="Odin Interface"
            className="h-[700px] max-w-full rounded-[20px] object-cover"
          />
        </div>
        <div className="flex w-full flex-col items-start justify-between sm:w-1/3 md:pl-4 ">
          {features.slice(3).map((feature, index) => (
            <FeatureCard
              key={index + 3}
              index={index + 3}
              feature={feature}
              textAlign="text-left"
              row="md:flex-row flex-col"
              justify="md:justify-start  justify-center"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ index, feature, textAlign, justify, row }) => (
  <motion.div
    className={`m-4 w-full rounded-lg md:p-6 ${textAlign} flex gap-4 md:items-center ${row} group`}
    transition={{ type: "spring", stiffness: 300, damping: 20 }}
  >
    <div className={`flex ${justify}`}>
      <div className="mb-2 flex size-[80px] items-center justify-center rounded-full bg-[#f6f7f9] text-2xl transition-colors duration-500 ease-in-out hover:text-white group-hover:bg-[#BFB5E9]">
        {index + 1}
      </div>
    </div>
    <div>
      <h2 className="mb-2 text-xl font-semibold">{feature.title}</h2>
      <p className="text-gray-600">{feature.description}</p>
    </div>
  </motion.div>
);

const features = [
  {
    title: "Effortless Scheduling",
    description:
      "Easily organize and manage your classes with our intuitive scheduling tools.",
  },
  {
    title: "Content Management",
    description: "Upload, organize, and share tutoring materials seamlessly.",
  },
  {
    title: "Automated Payments",
    description: "Streamline your payment processes with our automated system.",
  },
  {
    title: "Multi-User Support",
    description: "Manage multiple tutors and students with ease.",
  },
  {
    title: "Detailed Analytics",
    description:
      "Gain insights into your tutoring business with comprehensive reports.",
  },
  {
    title: "Class Tracking and Management",
    description:
      "Keep track of class progress and student performance effortlessly.",
  },
];

export default ChooseOdin;
