/* eslint-disable react/prop-types */
import React from "react";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";
import { Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "store/services/base.service";
import SkeletonWithAvatar from "components/shared/Skeleton";

const StudentInfoCard = ({ data, activity }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const isLoading = useSelector((state) => state.notifications.isLoading);

  const handleNavigate = () => {
    if (!user?.role) {
      API.post("/chat/createChat", {
        email: data.User.email,
        id: user.id,
        role: "agency",
        createAt: Date.now(),
      }).then((response) => {
        navigate(`/agency/chat/${response.data.data.room}`);
      });
    } else {
      API.post("/chat/createChat", {
        email: data.User.email,
        id: user.userId,
        role: user?.role,
        createAt: Date.now(),
      }).then((response) => {
        navigate(`/${user.role}/chat/${response.data.data.room}`);
      });
    }
  };
  const items = [
    {
      key: "2",
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={handleNavigate}>
          Message
        </a>
      ),
    },
  ];
  return (
    <div className="box-shadow col-span-1  min-h-80 rounded-lg bg-white p-4 md:col-span-2 lg:col-span-3">
      {isLoading ? (
        <SkeletonWithAvatar rows={5} />
      ) : (
        <div className="flex">
          <div className="flex justify-between p-4">
            <Avatar
              avatar={data?.url}
              alt={`${data?.User?.firstName} ${data?.User?.lastName}`}
              className="size-24 rounded-full   object-cover object-center"
            />
          </div>
          <div className="flex-1 p-4">
            <div className="text-2xl font-bold">
              {data?.User?.firstName} {data?.User?.lastName}
            </div>
            <div className="py-2 text-sm text-secondary">
              Grade: <span className="font-bold text-black">{data?.grade}</span>
            </div>

            <div className="flex flex-wrap items-center gap-2 text-sm">
              <div className="text-secondary">Subjects: </div>
              {activity?.map((item, i) => (
                <div
                  className="size-fit rounded-lg border-2 bg-black px-2 py-1 text-xs text-white"
                  key={i}
                >
                  {item.subject}
                </div>
              ))}
            </div>
            <div className="py-6 text-base text-secondary">
              {data?.description}
            </div>
          </div>
          <div>
            {" "}
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <MoreOutlined className="cursor-pointer" />
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentInfoCard;
