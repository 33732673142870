import React, { useEffect, useState } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import ListCard from "./designs/ListCard";
import { BASE_IMG_URL } from "components/shared/constants";
import {
  agencyWritePostOptions,
  settings,
} from "components/shared/constants/agencyConstants";
import Modal from "components/shared/Modal";
import AddTutor from "./designs/AddTutor";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgencyStudentsAction,
  getAgencyTutorsAction,
  getPosts,
  getUserFiles,
  subscribeToTutor,
} from "store/action/user";
import Post from "components/ui/Post";
import {
  agencyDeletePostAction,
  getCalenderAction,
} from "store/action/timelines";
import DashboardCard from "components/shared/DashbaordCard";
import CreatePost from "components/shared/CreatePost";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "components/shared/Image";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";
import Upload from "../AgencyHandouts/designs/Upload";
import Subscribed from "components/shared/Subscribed";
import { useNavigate } from "react-router-dom";

const AgencyDashboard = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [role, setRole] = useState("tutor");
  const [post, setPost] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [tutor, setTutor] = useState(null);
  const [group, setGroup] = useState("post");
  const navigate = useNavigate();
  const [editPost, setEditPost] = useState(null);

  const students = useSelector((state) => state.users.students);
  const tutors = useSelector((state) => state.users.tutors);
  const posts = useSelector((state) => state.users.posts);
  const events = useSelector((state) => state.users.events);
  const user = useSelector((state) => state.users.user);

  const openAddTutorHandler = (value) => {
    setEditPost(null);
    if (value === "create-post") {
      setPost(value);
      setGroup("post");
    } else if (value === "upload") {
      setPost(value);
    } else if (value === "announcement") {
      setPost(value);
      setGroup("announcement");
    } else {
      setPost(null);
      setRole(value);
    }
    setModal(true);
  };

  useEffect(() => {
    dispatch(getPosts());
    dispatch(getAgencyTutorsAction());
    dispatch(getAgencyStudentsAction());
    dispatch(getCalenderAction());
    dispatch(getUserFiles(user));
  }, []);

  const handleSelectEvent = (event) => {
    setTutor(tutors.find((tutor) => tutor.id === event.tutorId));
    setSelectedEvent(event);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };
  const handleNavigate = (data) => {
    navigate(`/agency/students/${data?.studentId}`);
  };
  const handleDetailPage = (type, data) => {
    navigate(`/agency/${type}/${data?.id}`);
  };
  const bookedSlots = (value, amount, subject) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      subscribeToTutor({
        classes: value,
        tutorId: selectedEvent?.tutorId,
        studentId: selectedEvent?.studentId,
        amount,
        timezone,
        subject,
        id: selectedEvent?.id,
        paid: true,
      })
    );
    handleModalCancel();
  };
  const handleDetail = (data) => {
    navigate(`/agency/${data?.id}`);
  };
  const handleUpdate = (data) => {
    console.log("🚀 ~ handleUpdate ~ data:", data);
    setEditPost(data);
    if (data.group === "post") {
      setPost("create-post");
      setGroup(data.group);
    } else if (data.group === "handout") {
      setPost("upload");
      setGroup(data.group);
    } else if (data.group === "announcement") {
      setPost("announcement");

      setPost(data.group);
      setGroup(data.group);
    }
    setModal(true);
  };
  console.log("first", editPost);
  const handleDelete = (data) => {
    dispatch(agencyDeletePostAction(data));
  };
  return (
    <AgencyLayout currentPage="Home">
      <Modal
        open={modal}
        close={(e) => setModal(e)}
        element={
          post === "create-post" ? (
            <CreatePost
              close={() => setModal(false)}
              group={group}
              post={editPost}
            />
          ) : post === "announcement" ? (
            <CreatePost
              close={() => setModal(false)}
              group={group}
              post={editPost}
            />
          ) : post === "upload" ? (
            <Upload
              close={() => setModal(false)}
              group={"handout"}
              fileId={editPost?.id}
            />
          ) : (
            <AddTutor name={role} close={() => setModal(false)} />
          )
        }
      />
      <Modal
        open={modalVisible}
        close={handleModalCancel}
        element={
          <Subscribed
            chargesPerHour={tutor?.chargesPerHour}
            bookedSlots={bookedSlots}
            data={tutor}
            selectedEvent={selectedEvent}
            update
          />
        }
      />
      <div className="grid grid-cols-1 gap-0 lg:gap-8 xl:grid-cols-4">
        <div className="col-span-2 flex flex-col gap-4 xl:col-span-3">
          <>
            {events.length > 3 ? (
              <Slider {...settings}>
                {events?.map((item, idx) => (
                  <DashboardCard
                    key={idx}
                    data={item}
                    handleNavigate={handleNavigate}
                    handleSelectEvent={handleSelectEvent}
                  />
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-1 justify-between sm:grid-cols-2 md:grid-cols-3">
                {events?.map((item, idx) => (
                  <DashboardCard
                    key={idx}
                    handleNavigate={handleNavigate}
                    data={item}
                    handleSelectEvent={handleSelectEvent}
                  />
                ))}
              </div>
            )}
          </>
          <div className="w-full rounded-lg bg-white shadow-md">
            <div className="flex gap-4 p-2 md:p-6">
              <div className="size-14">
                <Avatar
                  avatar={user?.avatar}
                  alt={user?.username || user?.firstName}
                  className="size-12 rounded-full cursor-pointer"
                  handleClick={() => handleDetail(user)}
                />
              </div>
              <div className="flex-1">
                <div
                  className="rounded-xl border  border-[#D5D5D5] py-3 pl-2 text-[#828282] cursor-pointer"
                  onClick={() => openAddTutorHandler("create-post")}
                >
                  Share with your community!
                </div>

                <div className="mt-4 flex justify-between">
                  {agencyWritePostOptions?.map((item, idx) => (
                    <div
                      key={idx}
                      className="flex cursor-pointer items-center gap-2 font-medium text-secondary"
                      onClick={() =>
                        openAddTutorHandler(
                          item?.name === "Handouts"
                            ? "upload"
                            : item?.name === "Announcement"
                            ? "announcement"
                            : "create-post"
                        )
                      }
                    >
                      <Image
                        src={`${BASE_IMG_URL}/img/${item?.img}.svg`}
                        alt=""
                      />
                      <div className="hidden md:block">{item?.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {posts.map((item, idx) => (
            <Post
              key={idx}
              item={item}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
            />
          ))}
        </div>
        <div className="mt-4 flex w-full flex-col gap-4 md:mt-2">
          <ListCard
            heading="Tutors"
            data={tutors}
            noItem={`Click to add Tutors to the Agency!`}
            addHandler={() => openAddTutorHandler("Tutor")}
            handleRedirect={(item) => handleDetailPage("tutors", item)}
            viewAll=""
          />
          <ListCard
            heading="Students"
            data={students}
            noItem={`Click to add Students to the Agency!`}
            addHandler={() => openAddTutorHandler("Student")}
            handleRedirect={(item) => handleDetailPage("students", item)}
            viewAll=""
          />
        </div>
      </div>
    </AgencyLayout>
  );
};

export default AgencyDashboard;
