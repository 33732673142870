/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import LogoutButton from "./designs/LogoutButton";
import WalletButton from "./designs/WalletButton";
import SidebarItems from "./designs/SidebarItems";
import { BASE_IMG_URL } from "../constants";
// import SearchBar from "./designs/SearchBar";
import Modal from "../Modal";
import LogoutScreen from "./designs/LogoutScreen";
import Image from "../Image";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Sidebar = ({
  search,
  nologo,
  logo,
  data,
  currentPage,
  className,
  noWallet,
}) => {
  const [modal, setModal] = useState(false);
  const role = sessionStorage.getItem("odin-auth-role");
  const user = useSelector((state) => state.users.user);
  const agency = useSelector((state) => state.supers.agency);
  const openAddTutorHandler = () => {
    setModal(true);
  };

  const onClose = () => {
    setModal(false);
  };

  return (
    <div className="relative z-[1000]  lg:block lg:w-60">
      <Modal
        open={modal}
        close={(e) => setModal(e)}
        element={<LogoutScreen onClose={onClose} />}
        width={400}
      />
      <div
        className={`fixed left-0 top-0 flex h-screen w-60 flex-col justify-between p-4  transition-all duration-300 ease-in-out ${className}`}
      >
        <div>
          {logo && (
            <img
              src={`${BASE_IMG_URL}/img/logo-white.svg`}
              alt=""
              className="mx-auto mb-8"
            />
          )}
          <Link
            to={
              role === "student"
                ? "/student/dashboard"
                : role === "tutor"
                ? "/tutor/dashboard"
                : "/dashboard"
            }
          >
            {!nologo && (
              <Image
                src={role ? agency?.logo : user?.avatar}
                alt=""
                className="mx-auto  mb-8 hidden size-12 object-contain object-center lg:block"
              />
            )}
          </Link>
          <SidebarItems data={data} currentPage={currentPage} />
        </div>
        <div className="flex w-full flex-col gap-2">
          {role !== "student" && role !== "admin" && role !== "tutor" && (
            <div className="w-full rounded-lg bg-white py-1 text-black">
              <WalletButton />
            </div>
          )}
          <div
            className="w-full cursor-pointer rounded-lg bg-white py-1 text-black"
            onClick={openAddTutorHandler}
          >
            <LogoutButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
