import { BASE_IMG_URL } from "components/shared/constants";
import React, { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { Link } from "react-router-dom";

const Topbar = () => {
  const [visible, setVisible] = useState(false);
  console.log("🚀 ~ Topbar ~ visible:", visible);

  const showDrawer = () => {
    setVisible(true);
  };

  const items = [
    {
      key: "1",
      label: <Link to="#feature">Features</Link>,
    },
    {
      key: "2",
      label: <Link to="#pricing">Pricing</Link>,
    },
    {
      key: "3",
      label: <Link to="#about">About Us</Link>,
    },
    {
      key: "4",
      label: (
        <button className="h-10 w-44 rounded-[20px] border border-[#0114b0] text-[#0114b0] hover:bg-[#0114b0] hover:text-white">
          Book demo
        </button>
      ),
    },
  ];

  return (
    <nav className="flex h-[134px] flex-col bg-[#f7f5ff]">
      <div className="m-auto flex w-full max-w-[1200px] items-center justify-between px-8 py-4">
        <div className="text-2xl font-bold text-purple-600">
          <img
            src={`${BASE_IMG_URL}/img/logo.svg`}
            alt=""
            className="h-[54px] max-w-[120px]"
          />
        </div>
        <div className="hidden items-center gap-8 md:flex">
          <Link to="#feature" className="text-[#626262] hover:text-black">
            Features
          </Link>
          <Link to="#pricing" className="text-[#626262] hover:text-black">
            Pricing
          </Link>
          <Link to="#about" className="text-[#626262] hover:text-black">
            About Us
          </Link>
          <button className="h-10 w-44 rounded-[20px] border border-[#0114b0] text-[#0114b0] hover:bg-[#0114b0] hover:text-white">
            Book demo
          </button>
        </div>
        <div className="flex items-center md:hidden">
          <Dropdown
            menu={{
              items,
            }}
          >
            <MenuOutlined
              onClick={showDrawer}
              className="text-2xl text-[#626262]"
            />
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
