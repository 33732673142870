/* eslint-disable react/prop-types */
import { Skeleton } from "antd";
import React from "react";
import { useSelector } from "react-redux";

// eslint-disable-next-line react/prop-types
const ClassList = ({ data }) => {
  const isLoading = useSelector((state) => state.notifications.isLoading);
  return (
    <div className="box-shadow col-span-1 h-full rounded-lg bg-white p-4 md:col-span-2">
      <div className="flex items-end  pr-4">
        <div className="w-fit border-b-4 border-secondary text-xl font-bold text-black">
          Classes
        </div>
        <div className="mb-[1px] flex-1 border-b-[1px]  border-[#D0D0D0]"></div>
      </div>
      {isLoading ? (
        <Skeleton paragraph={{ row: 1 }} />
      ) : (
        <div className=" overflow-auto">
          {data?.length > 0 ? (
            data?.map((item, index) => (
              <div
                className="mt-6 h-fit w-full rounded-lg border bg-white p-4"
                key={index}
              >
                <div className="font-bold">{item?.subject}</div>
                <div className="flex justify-between text-secondary">
                  <div className="capitalize">{item?.days}</div>
                  {/* 11am - 12pm */}
                </div>
              </div>
            ))
          ) : (
            <div className="m-auto text-center capitalize">No class found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClassList;
