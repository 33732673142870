import Modal from "components/shared/Modal";
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { LiaEditSolid } from "react-icons/lia";
import Plan from "./Plan";
import API from "store/services/base.service";
import { toast } from "react-toastify";

// const Plans = ["Free Trial", "Basic Package", "Premium Package"];

const SubscriptionPlan = () => {
  const [modal, setModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState();
  const [plans, setPlans] = useState([]);

  const getData = () => {
    API.get("/stripe/products")
      .then((res) => setPlans(res?.data))
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    getData();
  }, []);

  const openAddTutorHandler = (value) => {
    setModal(true);
    setCurrentPlan(value);
  };

  const updatedPlan = () => {
    getData();
  };

  return (
    <div>
      <Modal
        footer={false}
        open={modal}
        close={(e) => setModal(e)}
        element={<Plan currentPlan={currentPlan} updatedPlan={updatedPlan} />}
        width={700}
      />
      <div className="flex items-end">
        <div className="border-b-2 border-odinPrimary text-2xl font-bold text-odinPrimary ">
          Membership Plan
        </div>
        <div className="flex-1 border-b-2"></div>
      </div>

      <div className="mt-6 flex flex-col gap-2">
        {plans?.map((item, idx) => (
          <div
            key={idx}
            className="flex w-full justify-between rounded-lg border-2 p-4"
            onClick={() => openAddTutorHandler(item)}
          >
            <div className="font-semibold text-odinPrimary">{item?.name}</div>
            <div className="flex items-center gap-3">
              <span>$ {item?.prices?.unit_amount / 100}</span>
              <LiaEditSolid size={15} color="#0115B5" />
              <FaTrash size={15} color="#0115B5" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlan;
