/* eslint-disable tailwindcss/no-custom-classname */
import AgencyLayout from "components/shared/AgencyLayout";
import React, { useEffect } from "react";
import { BASE_IMG_URL } from "components/shared/constants";
import {
  classRequestTableColumn,
  tutorSidebar,
} from "components/shared/constants/agencyConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  getClassRequest,
  tutorAcceptClass,
  tutorAcceptClassAll,
} from "store/action/user";
import TableUi from "components/ui/Table";
import { capitalizeFirstWord } from "components/utils/functions";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";
import SkeletonWithAvatar from "components/shared/Skeleton";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

const TutorClassRequest = () => {
  const user = useSelector((state) => state.users.user);
  const classRequest = useSelector((state) => state.users.classRequest);
  const isLoading = useSelector((state) => state.notifications.isLoading);
  console.log("🚀 ~ TutorClassRequest ~ classRequest:", classRequest);
  console.log("🚀 ~ AgencyClassRequest ~ isLoading:", isLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      dispatch(getClassRequest(user?.id));
    }
  }, [user]);

  const handleRequest = (val, accept, requestId) => {
    dispatch(tutorAcceptClass({ subscriptionId: val?.id, accept, requestId }));
  };
  const handleRequestAll = (val, accept) => {
    dispatch(tutorAcceptClassAll({ studentId: val, accept }));
  };
  const handleNavigate = (item) => {
    navigate(`/tutor/student/${item?.studentId}`);
  };
  return (
    <AgencyLayout currentPage="Class Request" sidebarData={tutorSidebar}>
      <div className="flex h-full flex-col gap-6">
        <div className="mb-2 flex items-end">
          <div className="w-fit border-b-2 border-black text-xl font-bold text-black">
            Class Request
          </div>
          <span className="flex-1 border "></span>
        </div>
        {isLoading && !classRequest ? (
          <SkeletonWithAvatar rows={5} />
        ) : classRequest.length > 0 ? (
          classRequest.map((item, idx) => (
            <div
              key={idx}
              className="flex h-fit w-full flex-col gap-4 rounded-lg bg-white p-4"
            >
              <div className="flex items-center">
                <Avatar
                  avatar={item?.avatar}
                  className="size-20"
                  handleClick={() => handleNavigate(item)}
                  alt={capitalizeFirstWord(item?.username)}
                />
                <div className="ml-4 flex w-full flex-wrap justify-between md:w-[80%]">
                  <div className="flex flex-col">
                    <div className="text-lg font-bold">
                      {capitalizeFirstWord(item?.username)}
                    </div>
                    <div className="text-secondary">
                      Grade: <span className="text-black">{item?.grade}</span>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 text-xs text-secondary">
                    <div className="flex items-center gap-3 ">
                      <div>
                        <img
                          src={`${BASE_IMG_URL}/img/monitor-black.svg`}
                          alt=""
                        />
                      </div>
                      <div>
                        Subject:{" "}
                        <span className="font-semibold text-black">
                          {item?.subject}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-3 ">
                      <div>
                        <img src={`${BASE_IMG_URL}/img/group1.svg`} alt="" />
                      </div>
                      <div>
                        Subscription Status:
                        <span className="font-semibold text-black">
                          {" "}
                          Per Day
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 text-xs text-secondary">
                    <div className="flex items-center gap-3">
                      <div>
                        <img
                          src={`${BASE_IMG_URL}/img/clock-black.svg`}
                          alt=""
                        />
                      </div>
                      <div>
                        Total Hours:
                        <span className="font-semibold text-black">
                          {" "}
                          {item?.classes?.length} Hours
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-3">
                      <div>
                        <img src={`${BASE_IMG_URL}/img/dollar.svg`} alt="" />
                      </div>
                      <div>
                        Total Payment:
                        <span className="font-medium text-black">
                          {" "}
                          $ {item?.classes?.length * user?.chargesPerHour}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-center text-xs text-secondary">
                    <div className="flex ">
                      <div className="font-bold text-black">Time Slot</div>
                    </div>
                    <div className="flex ">
                      <div>
                        {item?.classes[0]?.date} /{" "}
                        {item?.classes[0]?.slot?.startTime}-
                        {item?.classes[0]?.slot?.endTime}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <Spin size="large" />
              ) : (
                <>
                  <TableUi
                    columns={classRequestTableColumn}
                    data={item.classes}
                    loading={isLoading}
                    actionUi={(val) => (
                      <div>
                        <div className="flex gap-2">
                          <div
                            className="flex h-8 w-24 cursor-pointer items-center justify-center rounded-lg border-2 border-ternary bg-white text-sm font-medium"
                            onClick={() => handleRequest(val, false, item.id)}
                          >
                            Reject
                          </div>
                          <div
                            className="flex h-8 w-24 cursor-pointer items-center  justify-center rounded-lg border-2 border-black bg-black text-sm font-medium text-white"
                            onClick={() => handleRequest(val, true, item.id)}
                          >
                            Accept
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <div className="flex flex-1 items-end justify-end gap-2">
                    <div
                      className="flex h-8 w-24 cursor-pointer items-center justify-center rounded-lg border-2 border-ternary bg-white text-sm font-medium"
                      onClick={() => handleRequestAll(item?.studentId, false)}
                    >
                      Reject all
                    </div>
                    <div
                      className="flex h-8 w-24 cursor-pointer items-center justify-center rounded-lg border-2 border-black bg-black text-sm font-medium text-white"
                      onClick={() => handleRequestAll(item?.studentId, true)}
                    >
                      Accept all
                    </div>
                  </div>
                </>
              )}
            </div>
          ))
        ) : (
          <div className="text-xl text-gray-600">
            No Request Available at the moment
          </div>
        )}
      </div>
    </AgencyLayout>
  );
};

export default TutorClassRequest;
