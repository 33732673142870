import React from "react";
import { Form, Input } from "antd";
import { changePassword } from "store/action/user";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

const ChangePassword = ({ close }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);

  const onFinish = (values) => {
    dispatch(changePassword({ ...values, agencyId: user.id }));

    close();
  };
  return (
    <div className="flex flex-col gap-3 font-medium">
      <div className="text-xl font-bold">Change Password</div>
      <div className="border-b-[1px]"></div>
      <Form
        form={form}
        layout="vertical"
        enabled
        // initialValues={data}
        onFinish={onFinish}
      >
        <Form.Item
          name="oldPassword"
          label=""
          rules={[{ required: true, message: "Current Password is required" }]}
        >
          <Input.Password
            placeholder="Current Password"
            className="custom-input"
          />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label=""
          rules={[{ required: true, message: "New Password is required" }]}
        >
          <Input.Password placeholder="New Password" className="custom-input" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label=""
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Confirm password is required" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            className="custom-input"
          />
        </Form.Item>

        <button
          type="submit"
          className="mx-auto mt-4 flex h-10 w-28 items-center justify-center rounded-lg border-2 bg-black text-base font-bold text-white"
        >
          Save
        </button>
      </Form>
    </div>
  );
};
ChangePassword.propTypes = {
  close: PropTypes.func.isRequired,
};
export default ChangePassword;
