/* eslint-disable react/prop-types */
import { BASE_IMG_URL } from "components/shared/constants";
import React from "react";
import { Link } from "react-router-dom";

const SidebarItems = ({ data, currentPage }) => {
  return (
    <div className="flex w-full flex-col gap-2">
      {data?.map((item, idx) => (
        <Link
          to={item.url}
          key={idx}
          className={`flex cursor-pointer items-center gap-2 rounded-lg p-2 text-white hover:bg-[#00000050] ${
            item?.name === currentPage && "bg-[#00000050] font-bold"
          }`}
        >
          <img
            src={`${BASE_IMG_URL}${item?.img}`}
            alt=""
            className="size-6 text-primary"
          />
          <div className="text-primary">{item?.name}</div>
        </Link>
      ))}
    </div>
  );
};

export default SidebarItems;
