import React from "react";
import PropTypes from "prop-types";

function Avatar({ avatar, className, handleClick, alt }) {
  const handleError = (e) => {
    e.target.src = "/img/person-black.svg"; // Path to the default image
  };
  const defaultClass = "size-10 rounded-full";
  if (!avatar || avatar.includes("undefined")) {
    return (
      <div
        className={`${
          className || defaultClass
        } flex cursor-pointer items-center justify-center rounded-full bg-gray-300 text-xl`}
        onClick={handleClick}
      >
        {alt ? alt.charAt(0).toUpperCase() : "A"}{" "}
      </div>
    );
  }

  return (
    <img
      src={avatar}
      alt="Avatar"
      onClick={handleClick}
      onError={handleError}
      className={className || "size-10 rounded-full cursor-pointer"}
    />
  );
}

Avatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Avatar;
