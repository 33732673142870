/* eslint-disable no-undef */
import { call, put, takeEvery } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { subscriptionPlanGraphApi } from "store/services/super.service";
import { getPlans, getUserChatListApi } from "store/services/user.service";
import { setLoading, unsetLoading } from "store/slices/alert";
import { getsubscriptionPlanGraphSlice, getAllPlans } from "store/slices/super";
import { getChatListSlice } from "store/slices/users";
import { GET_ALL_PLANS, GET_CHAT } from "store/types/chat";
import { SET_SUBSCRIPTION_PLAN_GRAPH } from "store/types/super";

function* subscriptionPlanGraphHandler() {
  try {
    const response = yield call(subscriptionPlanGraphApi);
    if (response.status === 200) {
      yield put(getsubscriptionPlanGraphSlice(response.data));
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.error || "Error occurred while registering user",
      { title: "Error" }
    );
  }
}
function* getUserChatHandler(action) {
  try {
    const response = yield call(getUserChatListApi, action.payload);
    if (response.status === 200) {
      yield put(getChatListSlice(response.data.data));
    }
  } catch (err) {}
}
function* getAllPlansHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(getPlans, action.payload);
    if (response.status === 200) {
      yield put(getAllPlans(response.data));
    }
  } catch (err) {
  } finally {
    yield put(unsetLoading());
  }
}

export function* waitForSuper() {
  yield takeEvery(GET_CHAT, getUserChatHandler);
  yield takeEvery(GET_ALL_PLANS, getAllPlansHandler);
  yield takeEvery(SET_SUBSCRIPTION_PLAN_GRAPH, subscriptionPlanGraphHandler);
}
