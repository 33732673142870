import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import useOnScreen from "components/utils/useOnScreenHook";
import { Button } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const testimonials = [
  {
    quote:
      "Odin has streamlined our operations and improved communication between tutors and students.",
    name: "Rob Zuber",
    title: "Agency Manager",
    image:
      "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e4dc3123fa1a83e1a63f9_customers_ava-02.webp",
  },
  {
    quote:
      "The scheduling and payment automation features of Odin have saved me so much time and hassle.",
    name: "Melanie Pickett",
    title: "Tutor",
    image:
      "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e4dc4123fa1a83e1a643b_customers_ava-06.webp",
  },
  {
    quote:
      "Odin's content management system is fantastic. It's easy to upload and share materials with students.",
    name: "Regis Wilson",
    title: "Tutor",
    image:
      "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e4dc4123fa1a83e1a6422_customers_ava-03.webp",
  },
  {
    quote:
      "Using Odin has made my tutoring sessions more organized and efficient. I love the video call feature!",
    name: "Emma Watson",
    title: "Student",
    image:
      "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e4dc4123fa1a83e1a6413_customers_ava-01.webp",
  },
  {
    quote:
      "The detailed analytics provided by Odin have given us valuable insights to grow our business.",
    name: "Emma Watson",
    title: "Agency Manager",
    image:
      "https://cdn.prod.website-files.com/669e436f3473a5db411044db/669e4dc4123fa1a83e1a640a_customers_ava-04.webp",
  },
  // Add more testimonials as needed
];

const TestimonialSlider = () => {
  const ref = useRef();
  const sliderRef = useRef(null);

  const isVisible = useOnScreen(ref);

  const variants = {
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, x: 50 },
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full bg-white py-8 md:py-16" ref={ref}>
      <div className="m-auto w-full  p-4">
        <h1 className="text-center text-4xl font-bold">What our Users Say?</h1>
        <p className="py-4 text-center text-lg font-medium text-gray-600">
          See what our happy users say about how Odin has improved their
          tutoring experience.
        </p>
        <div className="m-auto my-6 md:my-12 max-w-[1470px]">
          <Slider {...settings} ref={sliderRef}>
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate={isVisible ? "visible" : "hidden"}
                variants={variants}
                className="m-6 -ml-0 h-56 w-full rounded-2xl border-2 border-lightGrey bg-white p-8 shadow-md md:max-w-[350px] "
              >
                <p className="mb-8">{testimonial.quote}</p>
                <div className="mt-4 flex items-center">
                  <img
                    className="mr-4 size-14 rounded-full"
                    src={testimonial.image}
                    alt={testimonial.name}
                  />
                  <div>
                    <h3 className="font-semibold">{testimonial.name}</h3>
                    <p className="text-gray-600">{testimonial.title}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </Slider>
          <div className="m-auto w-fit gap-4">
            <Button
              type="default"
              size="large"
              shape="circle"
              className="mr-4"
              onClick={() => sliderRef.current.slickPrev()}
              icon={<ArrowLeftOutlined />}
            />
            <Button
              type="default"
              size="large"
              shape="circle"
              onClick={() => sliderRef.current.slickNext()}
              icon={<ArrowRightOutlined />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
