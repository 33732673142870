import AgencyLayout from "components/shared/AgencyLayout";
import { adminSidebar } from "components/shared/constants/agencyConstants";
import React, { useEffect } from "react";
import BillingInfo from "./designs/BillingInfo";
import CardItem from "./designs/CardItem";
import SubscriptionPlanView from "./designs/SubscriptionPlanView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cardPaymentMethod } from "store/action/user";

const AgencyAdminBilling = () => {
  const user = useSelector((state) => state.users.user);
  const paymentMethod = useSelector((state) => state.users.paymentMethod);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cardPaymentMethod());
  }, []);

  const navigate = useNavigate();

  const removeQueryParam = () => {
    navigate({
      pathname: window.location.pathname,
    });
  };

  useEffect(() => {
    removeQueryParam();
  }, [window.location.search]);

  return (
    <AgencyLayout sidebarData={adminSidebar} currentPage="Billing">
      <div className="size-full rounded-lg bg-white p-4">
        <div className="flex">
          <div className="border-b-2 border-secondary text-2xl font-semibold">
            Current Plan
          </div>
          <div className="flex-1 border-b-2"></div>
        </div>
        <div className="mt-8 grid md:grid-cols-2 gap-4">
          <div className="p-4">
            <SubscriptionPlanView amount={paymentMethod?.lastPayment?.amount} />
            {user && user.subscriptionType && (
              <CardItem
                paymentMethod={paymentMethod?.paymentMethods?.data || []}
              />
            )}
          </div>
          <BillingInfo data={paymentMethod?.lastPayment} />
        </div>
      </div>
    </AgencyLayout>
  );
};

export default AgencyAdminBilling;
