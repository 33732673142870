/* eslint-disable no-undef */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { agencySidebar } from "../constants/agencyConstants";
import HeaderRightArea from "./designs/HeaderRightArea";
import { Drawer } from "antd";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "store/action/user";
import { setTokenSlice } from "store/slices/users";

const AdminLayout = ({ currentPage, children, sidebarData }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const token = useSelector((state) => state.users.accessToken);
  // const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();

  const onClose = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    const localToken = sessionStorage.getItem("odin-auth-token");
    if (localToken) {
      if (token === "") {
        dispatch(setTokenSlice(localToken));
      }
      dispatch(getCurrentUser());
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className=" flex min-h-screen">
      <div>
        <div className="hidden lg:block">
          <Sidebar
            nologo
            logo
            data={sidebarData || agencySidebar}
            currentPage={currentPage}
            className="bg-odinPrimary"
          />
        </div>

        <div className="block lg:hidden">
          <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            open={showSidebar}
            style={{ width: "0" }}
          >
            <Sidebar
              logo
              data={sidebarData || agencySidebar}
              currentPage={currentPage}
              className="bg-odinPrimary"
            />
          </Drawer>
        </div>
      </div>
      <div className="flex max-h-full min-h-screen flex-1 flex-col">
        <div className="relative h-[70px] w-full">
          <Header
            noLogo
            rightArea={
              <HeaderRightArea
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              />
            }
            className="fixed left-0 right-0 top-0 z-50 h-[70px] bg-odinPrimary pl-8 pr-8 shadow-lg lg:left-60"
          />
        </div>
        <ToastContainer />
        <div className="h-full flex-1 bg-[#EDEFF1] p-4 md:p-8">{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;
