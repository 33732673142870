import React, { useEffect, useState } from "react";
import AgencyLayout from "components/shared/AgencyLayout";
import ListCard from "./designs/ListCard";
import {
  settings,
  studentSidebar,
} from "components/shared/constants/agencyConstants";
import Modal from "components/shared/Modal";
import Slider from "react-slick";
import {
  getAgencyTutorsAction,
  getStudentPosts,
  getSubscribedTutorsAction,
  subscribeToTutor,
} from "store/action/user";
import { useDispatch, useSelector } from "react-redux";
import Post from "components/ui/Post";
import { getCalenderAction } from "store/action/timelines";
import DashboardCard from "components/shared/DashbaordCard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Subscribed from "components/shared/Subscribed";
import { useNavigate } from "react-router-dom";

const StudentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [tutor, setTutor] = useState(null);
  const tutors = useSelector((state) => state.users.tutors);
  const subscribedTutors = useSelector((state) => state.users.subscribedTutors);
  const posts = useSelector((state) => state.users.posts);
  const events = useSelector((state) => state.users.events);

  useEffect(() => {
    dispatch(getStudentPosts());
    dispatch(getAgencyTutorsAction());
    dispatch(getSubscribedTutorsAction());
    dispatch(getCalenderAction());
  }, []);

  const handleNavigate = (data) => {
    navigate(`/tutor/${data?.tutorId}`);
  };

  const handleDetail = (data) => {
    navigate(`/tutor/${data?.id}`);
  };
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setTutor(tutors.find((tutor) => tutor.id === event.tutorId));
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const bookedSlots = (value, amount, subject) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      subscribeToTutor({
        classes: value,
        tutorId: selectedEvent?.tutorId,
        studentId: selectedEvent?.studentId,
        amount,
        timezone,
        subject,
        id: selectedEvent?.id,
        paid: true,
      })
    );
    handleModalCancel();
  };

  return (
    <AgencyLayout currentPage="Home" sidebarData={studentSidebar}>
      <Modal
        open={modalVisible}
        close={handleModalCancel}
        element={
          <Subscribed
            chargesPerHour={tutor?.chargesPerHour}
            bookedSlots={bookedSlots}
            data={tutor}
            selectedEvent={selectedEvent}
            update
          />
        }
      />
      <div className="grid grid-cols-1 gap-0 lg:gap-8 xl:grid-cols-4">
        <div className="col-span-2 flex flex-col gap-4 xl:col-span-3">
          <div>
            {events.length > 3 ? (
              <Slider {...settings}>
                {events?.map((item, idx) => (
                  <DashboardCard
                    key={idx}
                    data={item}
                    handleNavigate={handleNavigate}
                    handleSelectEvent={handleSelectEvent}
                  />
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-1 justify-between sm:grid-cols-2 md:grid-cols-3">
                {events?.map((item, idx) => (
                  <DashboardCard
                    key={idx}
                    handleNavigate={handleNavigate}
                    data={item}
                    handleSelectEvent={handleSelectEvent}
                  />
                ))}
              </div>
            )}
          </div>
          {posts?.map((item, idx) => (
            <Post key={idx} item={item} />
          ))}
        </div>
        <div className="mt-4 flex max-h-full w-full flex-col gap-4 md:mt-0">
          <ListCard
            heading="Subscribed Tutors"
            data={subscribedTutors}
            handleDetail={(item) => handleDetail(item)}
            noItem="No Subscribed Tutor"
            noAdd
          />
          <ListCard
            heading="Agency Tutors"
            data={tutors}
            noAdd
            handleDetail={(item) => handleDetail(item)}
            noItem="No Tutor In Agency"
            viewProfile
            starBelow
          />
        </div>
      </div>
    </AgencyLayout>
  );
};

export default StudentDashboard;
