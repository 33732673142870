import { createSlice } from "@reduxjs/toolkit";

const AlertSlice = createSlice({
  name: "alert",
  initialState: {
    message: "",
    title: "",
    type: "",
    status: false,
    step: 0,
    agencyStep: 0,
    isLoading: false,
    pre: {},
    supplierPre: {},
    winnerPre: {},
  },
  reducers: {
    createAlert: (state, action) => {
      return {
        ...state,
        message: action.payload.message,
        title: action.payload.title,
        type: action.payload.type,
        status: true,
        isLoading: false,
      };
    },
    alertOff: (state) => {
      return {
        ...state,
        status: false,
      };
    },
    setLoading: (state) => {
      state.isLoading = true;
    },
    unsetLoading: (state) => {
      state.isLoading = false;
    },
    getPreState: (state, action) => {
      return {
        ...state,
        pre: action.payload,
      };
    },
    setStep: (state, action) => {
      return {
        ...state,
        step: action.payload,
      };
    },
    setAgencyStep: (state, action) => {
      return {
        ...state,
        agencyStep: action.payload,
      };
    },
    getSupplierPreState: (state, action) => {
      return {
        ...state,
        supplierPre: action.payload,
      };
    },
    getWinnerPreState: (state, action) => {
      return {
        ...state,
        winnerPre: action.payload,
      };
    },
  },
});

export const {
  createAlert,
  setLoading,
  unsetLoading,
  setStep,
  setAgencyStep,
  getPreState,
  getSupplierPreState,
  getWinnerPreState,
  alertOff,
} = AlertSlice.actions;

export const turnOffAlertAfterTimeout = () => (dispatch) => {
  setTimeout(() => {
    dispatch(alertOff());
  }, 1000);
};

export default AlertSlice.reducer;
