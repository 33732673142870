import { all } from "@redux-saga/core/effects";
import { waitForUser } from "./sagas/user.saga";
import { waitForTimeline } from "./sagas/timeline.saga";
import { waitForSuper } from "./sagas/super.saga";

export default function* rootSaga() {
  yield all([
    waitForUser(),
    waitForTimeline(),
    waitForSuper()
  ]);
}
