/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
import {
  displaySubject,
  formatDate,
  pathnameHandler,
  toObject,
} from "components/utils/functions";
import Avatar from "../AgencyLayout/designs/Avatar";
import { Link } from "react-router-dom";

export const agencySidebar = [
  {
    name: "Home",
    img: "/img/home.svg",
    url: "/dashboard",
  },
  {
    name: "Calender",
    img: "/img/calendar-white.svg",
    url: "/agency/calenders",
  },
  {
    name: "Students",
    img: "/img/people.svg",
    url: "/agency/students",
  },
  {
    name: "Tutors",
    img: "/img/people.svg",
    url: "/agency/tutors",
  },
  {
    name: "Handouts",
    img: "/img/documents.svg",
    url: "/agency/handouts",
  },
  {
    name: "Class Request",
    img: "/img/git.svg",
    url: "/agency/class-request",
  },
  // {
  //   name: "Settings",
  //   img: "/img/settings.svg",
  //   url: "/agency/settings",
  // },
];

export const EducationDegreeColumn = [
  { title: "Name", index: "name", key: "name" },
  { title: "Major Subject", index: "majorSubject", key: "majorSubject" },
  { title: "Minor Subject", index: "minorSubject", key: "minorSubject" },
  { title: "Institution", index: "institutionName", key: "institutionName" },
  {
    title: "Action",
    key: "action",
  },
];

export const agencyAdminSidebar = [
  {
    name: "Dashboard",
    img: "/img/home.svg",
    url: "/admin/dashboard",
  },
  {
    name: "Settings",
    img: "/img/calendar-white.svg",
    url: "/admin/settings",
  },
];

export const adminSidebar = [
  {
    name: "Agency Settings",
    img: "/img/home.svg",
    url: "/agency/admin",
  },
  {
    name: "Statistics",
    img: "/img/calendar-white.svg",
    url: "/agency/admin/statistics",
  },
  // {
  //   name: "User Management",
  //   img: "/img/people.svg",
  //   url: "/agency/admin/user-management",
  // },
  {
    name: "Billing",
    img: "/img/people.svg",
    url: "/agency/admin/billing",
  },
  {
    name: "Package",
    img: "/img/documents.svg",
    url: "/agency/admin/package",
  },
];

export const agencyWritePostOptions = [
  {
    name: "Handouts",
    img: "hangoutbaglogo",
  },
  {
    name: "Photo",
    img: "photologo",
  },
  {
    name: "Announcement",
    img: "announcementlogo",
  },
];

export const studentsFiltersOptions = ["Filters", "Student", "New Student"];

export const settingGeneralOptions = [
  {
    name: "Contact Settings",
    desc: "To change the contact settings. Click here to",
  },
  {
    name: "Profile Settings",
    desc: "To change the contact settings. Click here to",
  },
  {
    name: "Education Settings",
    desc: "To change the contact settings. Click here to",
  },
  {
    name: "Timetable Settings",
    desc: "To change the contact settings. Click here to",
  },
];

export const studentSettingGeneralOptions = [
  {
    name: "Profile Settings",
    desc: "To change the contact settings. Click here to",
  },
];
export const studentActivityTableColumn = [
  {
    title: "Tutor Name",
    key: "tutorName",
    index: "tutorName",
    render: (_, val) => {
      const role = sessionStorage.getItem("odin-auth-role");

      return (
        <Link
          to={
            role
              ? `/tutor/${val.tutorId}`
              : `${pathnameHandler()}/tutors/${val.tutorId}`
          }
        >
          <div className="flex items-center gap-2">
            <Avatar avatar={val?.avatar || val?.url} alt={val?.tutorName} />
            <div>{val?.tutorName}</div>
          </div>
        </Link>
      );
    },
  },
  {
    title: "Subject",
    key: "subject",
    index: "subject",
  },
  {
    title: "Day",
    key: "day",
    index: "day",
    render: (text) => {
      // eslint-disable-next-line react/react-in-jsx-scope
      return <span className="capitalize">{text}</span>;
    },
  },
  {
    title: "Time",
    key: "time",
    index: "time",
  },
  {
    title: "Date",
    key: "date",
    index: "date",
    render: (text) => formatDate(text),
  },
];
export const tempTableColumn = [
  { title: "First Name", key: "firstName" },
  { title: "Last Name", key: "lastName" },
  { title: "Age", key: "age" },
  { title: "Address", key: "address" },
  { title: "Tags", key: "tags" },
  { title: "Action", key: "action" },
];
export const walletTableColumn = [
  { title: "User Name", key: "username", index: "username" },
  { title: "Amount", key: "amount", index: "amount" },
  {
    title: "Status",
    key: "status",
    index: "status",
  },
  {
    title: "Created At",
    key: "createdAt",
    index: "createdAt",
    render: (text) => formatDate(text),
  },
];
export const handTableColumn = [
  { title: "File Name", key: "filename", index: "filename" },
  {
    title: "Subject",
    key: "content",
    index: "content",
  },
  { title: "Type", key: "type", index: "type" },
  {
    title: "Uploaded",
    key: "createdAt",
    index: "createdAt",
    render: (text) => formatDate(text),
  },

  { title: "Action", key: "action" },
];

export const adminPaymentHistroyColumn = [
  { title: "Id", key: "id", index: "id" },
  { title: "Amount", key: "amount", index: "amount" },
  { title: "Currency", key: "currency", index: "currency" },
  { title: "Status", key: "status", index: "status" },
  { title: "Created", key: "created", index: "created" },
];

export const agencyPaymentColumn = [
  { title: "Id", key: "id", index: "id" },
  { title: "Amount", key: "amount", index: "amount" },
  { title: "Currency", key: "currency", index: "currency" },
  {
    title: "Payment Method",
    key: "payment_method",
    index: "payment_method",
    render: (_, val) => {
      return (
        <div className="flex items-center gap-2">
          <div>Card</div>
        </div>
      );
    },
  },
  { title: "Status", key: "status", index: "status" },
  { title: "Created", key: "created", index: "created" },
];

export const studentPaymentColumn = [
  {
    title: "Tutor Name",
    key: "username",
    index: "username",
    render: (_, val) => {
      const role = sessionStorage.getItem("odin-auth-role");

      return (
        <Link
          to={
            role
              ? `/tutor/${val?.tutorId}`
              : `${pathnameHandler()}/tutors/${val?.tutorId}`
          }
        >
          <div className="flex items-center gap-2">
            <Avatar avatar={val?.avatar || val?.url} alt={val?.username} />
            <div>{val?.username}</div>
          </div>
        </Link>
      );
    },
  },
  {
    title: "Status",
    key: "status",
    index: "status",
    render: (_, val) => "Paid",
  },
  {
    title: "Subject",
    key: "title",
    index: "title",
  },
  {
    title: "Date",
    key: "createdAt",
    index: "createdAt",
    render: (_, val) => val?.createdAt?.slice(0, 10),
  },
  {
    title: "Amount",
    key: "amount",
    index: "amount",
    render: (_, val) => val?.amount || 0,
  },
];

export const classRequestTableColumn = [
  {
    title: "Day",
    key: "day",
    index: "day",
    render: (_, val) => val?.day?.charAt(0)?.toUpperCase() + val?.day?.slice(1),
  },
  { title: "Subject", key: "subject", index: "subject" },
  { title: "Date", key: "date", index: "date" },
  {
    title: "Status",
    key: "status",
    index: "status",
    render: (_, val) =>
      val?.status?.charAt(0)?.toUpperCase() + val?.status?.slice(1),
  },
  { title: "Start Time", key: "startTime", index: ["slot", "startTime"] },
  { title: "End Time", key: "endTime", index: ["slot", "endTime"] },
  { title: "Action", key: "action", index: "action" },
];

export const agencyStudentsColumn = [
  {
    title: "Student Name",
    key: "username",
    index: "username",
    render: (_, val) => {
      const role = sessionStorage.getItem("odin-auth-role");
      return (
        <Link
          to={
            role
              ? `${pathnameHandler()}/student/${val.id}`
              : `${pathnameHandler()}/students/${val.id}`
          }
        >
          <div className="flex items-center gap-2">
            <Avatar avatar={val?.avatar} alt={val?.username} />
            <div>{val.username}</div>
          </div>
        </Link>
      );
    },
  },
  { title: "Email", key: "email", index: "email" },
  { title: "Grade", key: "grade", index: "grade" },
  {
    title: "Subscription Type",
    key: "subscriptionType",
    index: "subscriptionType",
  },
  {
    title: "Session",
    key: "session",
    index: "session",
    render: (_, val) => {
      return <div>1 hr</div>;
    },
  },
  {
    title: "Subject",
    key: "subject",
    index: "subject",
    render: (_, val) => {
      const subjectsArray = toObject(val?.subject);

      return displaySubject([...new Set(subjectsArray)]);
    },
  },
  { title: "Action", key: "action", index: "action" },
];

export const agencyTutorsColumn = [
  {
    title: "Tutor Name",
    key: "username",
    index: "username",
    render: (_, val) => {
      const role = sessionStorage.getItem("odin-auth-role");

      return (
        <Link
          to={
            role ? `/tutor/${val.id}` : `${pathnameHandler()}/tutors/${val.id}`
          }
        >
          <div className="flex items-center gap-2">
            <Avatar avatar={val?.avatar} alt={val?.username} />
            <div>{val.username}</div>
          </div>
        </Link>
      );
    },
  },
  { title: "Email", key: "email", index: "email" },
  {
    title: "Subject",
    key: "subject",
    index: "subject",
    render: (_, val) => {
      const subjectsArray = toObject(val?.subject);
      return displaySubject([...new Set(subjectsArray)]);
    },
  },
  { title: "Experience Type", key: "experience", index: "experience" },
  { title: "Charge Per Hour", key: "chargePerHour", index: "chargePerHour" },
  { title: "Rating", key: "rating", index: "rating" },
  { title: "Action", key: "action", index: "action" },
];

export const studentTutorsColumn = [
  {
    title: "Tutor Name",
    key: "firstname",
    index: ["User", "firstName"],
    render: (_, val) => {
      const role = sessionStorage.getItem("odin-auth-role");

      return (
        <Link
          to={
            role ? `/tutor/${val.id}` : `${pathnameHandler()}/tutors/${val.id}`
          }
        >
          <div className="flex items-center gap-2">
            <Avatar avatar={val?.url} alt={val?.User?.firstName} />
            <div>{val?.User?.firstName + " " + val?.User?.lastName}</div>
          </div>
        </Link>
      );
    },
  },
  {
    title: "Subject",
    key: "subject",
    index: "subject",
    render: (_, val) => {
      const subjectsArray = toObject(val?.subject);
      return displaySubject([...new Set(subjectsArray)]);
    },
  },
  { title: "Experience Type", key: "experience", index: "experience" },
  { title: "Charge Per Hour", key: "chargesPerHour", index: "chargesPerHour" },
  { title: "Rating", key: "rating", index: "rating" },
  { title: "Action", key: "action", index: "action" },
];

export const agencyAdminUserManagementTableColumn = [
  { title: "Tutor Name", key: "firstName" },
  { title: "Subject", key: "age" },
  { title: "Action", key: "action" },
];

export const studentSidebar = [
  {
    name: "Home",
    img: "/img/home.svg",
    url: "/student/dashboard",
  },
  {
    name: "Calender",
    img: "/img/calendar-white.svg",
    url: "/student/calender",
  },
  {
    name: "Tutors",
    img: "/img/people.svg",
    url: "/student/tutors",
  },
  {
    name: "Settings",
    img: "/img/calendar-white.svg",
    url: "/student/settings",
  },
];

export const tutorSidebar = [
  {
    name: "Home",
    img: "/img/home.svg",
    url: "/tutor/dashboard",
  },
  {
    name: "Calender",
    img: "/img/calendar-white.svg",
    url: "/tutor/calender",
  },
  {
    name: "Students",
    img: "/img/people.svg",
    url: "/tutor/students",
  },
  {
    name: "Handouts",
    img: "/img/documents.svg",
    url: "/tutor/handouts",
  },
  {
    name: "Class Request",
    img: "/img/git.svg",
    url: "/tutor/class-request",
  },
  {
    name: "Settings",
    img: "/img/calendar-white.svg",
    url: "/tutor/settings",
  },
];

export const feedbackData = [
  {
    name: "Emma Smith",
    grade: 6,
    feedback:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    rating: 4,
    image: `/img/person.svg`,
  },
  {
    name: "Emma Smith",
    grade: 6,
    feedback:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    rating: 4,
    image: `/img/person.svg`,
  },
  {
    name: "Emma Smith",
    grade: 6,
    feedback:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    rating: 4,
    image: `/img/person.svg`,
  },
  {
    name: "John Doe",
    grade: 7,
    feedback:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    rating: 5,
    image: `/img/person.svg`,
  },
  {
    name: "Jane Doe",
    grade: 8,
    feedback:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    rating: 4,
    image: `/img/person.svg`,
  },
];

export const filterHandoutOptions = [
  { value: "filename", label: "File Name" },
  { value: "content", label: "Subject" },
  { value: "type", label: "Type" },
];

export const filterTutorOptions = [
  { value: "username", label: "Name" },
  { value: "subject", label: "Subject" },
  { value: "experience", label: "Experience" },
];

export const filterStudentOptions = [
  { value: "username", label: "Name" },
  { value: "subject", label: "Subject" },
  { value: "grade", label: "Grade" },
];

export const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 3000,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  currentSlide: 0,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        centerMode: false,
        infinite: true,
      },
    },

    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        infinite: true,
      },
    },
  ],
};
export const groupByProperty = (array = [], property) => {
  const transformedArray = [];

  array.forEach((item) => {
    const existingIndex = transformedArray.findIndex(
      (obj) => obj[property] === item[property]
    );

    if (existingIndex !== -1) {
      // If the property already exists, add the day to the days array
      transformedArray[existingIndex].days.push(item.day);
    } else {
      // If the property does not exist, create a new entry
      transformedArray.push({
        [property]: item[property],
        avatar: item.avatar,
        tutorName: item.tutorName,
        days: [item.day],
      });
    }
  });

  return transformedArray.map((obj) => ({
    ...obj,
    days: obj.days.join(", "),
  }));
};
