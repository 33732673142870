/* eslint-disable no-undef */
// /* eslint-disable no-undef */
import { Divider } from "antd";
import InputTextField from "components/ui/InputTextField";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createAgencyTimelinePostAction,
  createTutorTimelinePostAction,
  updatePostAction,
} from "store/action/timelines";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Avatar from "../AgencyLayout/designs/Avatar";

const CreatePost = ({ close, group, post }) => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [name, setName] = useState(null);
  const user = useSelector((state) => state.users.user);
  const [formData, setFormData] = useState({
    content: post?.content || "test",
    file: null,
    group,
  });

  console.log("🚀 ~ CreatePost ~ formData:", formData);
  useEffect(() => {
    if (post) {
      if (post.file && post.file.includes("image")) {
        setImagePreview(post.file);
        // setName(post.filename);
      } else {
        setImagePreview(null);
      }
      setFormData({
        content: post.content,
        file: post.file,
        group: post.group,
      });
    }
  }, [post]);

  const uploadFileHandler = (e) => {
    const file = e?.target?.files[0];
    if (file.type.includes("image")) {
      setName(null);
      console.log("Uploading");
    } else {
      setName(file.name);
    }
    setFormData({
      ...formData,
      file,
    });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formData.content.trim() === "" && !formData.file) {
      return toast.error("Please enter content");
    }
    const role = sessionStorage.getItem("odin-auth-role");
    const tempFormData = new FormData();

    Object.entries({ ...formData, group }).forEach(([key, value]) => {
      tempFormData.append(key, value);
    });

    if (post) {
      // Update existing post
      tempFormData.append("postId", post.id);
      console.log("update existing tutor");
      dispatch(updatePostAction({ form: tempFormData }));
    } else {
      // Create new post
      if (role === "tutor") {
        dispatch(createTutorTimelinePostAction(tempFormData));
      } else {
        dispatch(createAgencyTimelinePostAction(tempFormData));
      }
    }

    setFormData({
      content: "",
      file: null,
    });
    setImagePreview(null);
    close();
  };

  useEffect(() => {
    if (!post) {
      setFormData({
        content: "",
        file: null,
      });
      setImagePreview(null);
      setName(null);
    }
  }, [close]);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="text-center text-xl font-semibold">
        {post ? "Edit Post" : "Create a Post"}
      </div>
      <Divider className="my-2 bg-gray-100" />
      <div className="my-4 flex items-center gap-3 font-bold">
        <div className="size-12">
          <Avatar
            avatar={user?.avatar}
            alt={user?.role ? user?.firstName : user?.username}
            className="size-10 rounded-full"
          />
        </div>
        <span>
          {user?.role ? user?.firstName + " " + user?.lastName : user?.username}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <InputTextField
          className="mb-1"
          type="textArea"
          placeholder="What do you want to talk about?"
          value={formData?.content}
          onChange={(value) => setFormData({ ...formData, content: value })}
        />
      </div>
      <div className="flex justify-end gap-2 py-4">
        <img
          className="cursor-pointer"
          src="/img/camera.png"
          size={20}
          onClick={handleImageClick}
        />
        <img
          className="cursor-pointer"
          src="/img/File.png"
          onClick={handleImageClick}
          size={18}
        />
        <input
          ref={inputRef}
          type="file"
          className="absolute top-0 opacity-0"
          onChange={uploadFileHandler}
        />
      </div>
      {name ? (
        <div className="pl-2 pb-2 font-bold">{name}</div>
      ) : (
        imagePreview && (
          <img
            src={imagePreview}
            alt="Image Preview"
            className="mb-3 h-auto max-w-full"
          />
        )
      )}
      <div className="flex justify-end gap-2 border-t-2 pt-4">
        <button
          type="button"
          onClick={close}
          className="w-fit cursor-pointer rounded-lg border bg-white px-8 text-center font-bold text-black"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="h-10 w-fit cursor-pointer rounded-lg bg-black px-8 text-center font-bold text-white"
        >
          {post ? "Update" : "Post"}
        </button>
      </div>
    </form>
  );
};

CreatePost.propTypes = {
  close: PropTypes.func.isRequired,
  group: PropTypes.string,
  post: PropTypes.object, // Add this prop type for post
};

export default CreatePost;
