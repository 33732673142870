/* eslint-disable no-undef */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const AuthLayout = ({ children }) => {
  const navigate = useNavigate()
  const token = sessionStorage.getItem("odin-auth-token");
  const role = sessionStorage.getItem("odin-auth-role");

  useEffect(() => {
    if (token) {
      if (role) {
        navigate(`/${role}/dashboard`)
      } else {
        navigate("/dashboard")
      }
    }
  }, [token, role])

  return (
    <div className="min-h-screen ">
      <ToastContainer />
      <div className="h-full">{children}</div>
    </div>
  );
};

export default AuthLayout;
