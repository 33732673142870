/* eslint-disable react/prop-types */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "antd";
import { BASE_IMG_URL } from "components/shared/constants";
import React, { useState } from "react";
import CheckoutForm from "./Checkout";

const stripePromise = loadStripe(
  "pk_test_51OlsJgJVe6WBmKLIW4VucQG72Mw0ICSatuvfIZeRAZgR9r3yM5kBO6N12NoWcmx2mIBmpoRvdFGkyc1Ni5vRh9YC00rRsJpmtD"
);

const fromDollarToCent = (amount) => Number(amount * 100);

const PlanItem = ({
  agencyFormData,
  item,
  selectPlan,
  idx,
  handleSelect,
  close,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const appearance = {
    theme: "stripe",
  };

  const options = {
    mode: "subscription",
    amount: fromDollarToCent(item?.prices?.unit_amount / 100),
    currency: "usd",
    appearance,
  };

  return (
    <div
      className={`box-shadow flex h-[544px] w-full shrink-0 flex-col items-center justify-between rounded-[25px] md:w-[387px]  ${
        selectPlan === idx
          ? " bg-odinPrimary text-white"
          : "mt-10 bg-white text-odinPrimary"
      }  p-[30px] text-center `}

      // onClick={() => selectedPlan(item?.planName)}
    >
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            agencyFormData={agencyFormData}
            price={item?.default_price}
            plan={item?.name}
            close={close}
          />
        </Elements>
      </Modal>
      <div
        className={`w-fit rounded-xl px-10 py-3  ${
          selectPlan !== idx
            ? "bg-odinPrimary text-white"
            : "bg-white text-odinPrimary"
        }`}
      >
        {item?.name}
      </div>
      <div
        className={`${selectPlan !== idx ? "text-secondary" : "text-white"}`}
      >
        {item?.desc}
        <span
          className={`${
            selectPlan !== idx ? "text-odinPrimary" : "text-white"
          }`}
        >
          {item?.description}
        </span>
      </div>
      <div>
        <span className="text-4xl">$ {item?.prices?.unit_amount / 100}</span>{" "}
        /Month
      </div>
      <div>
        {Object?.entries(item?.metadata)?.map(([key, value], idx2) => (
          <div key={idx2} className="flex gap-2">
            <img
              src={`${BASE_IMG_URL}${
                selectPlan !== idx
                  ? "/img/check-circle-blue.svg"
                  : "/img/check-circle-white.svg"
              }`}
              alt=""
            />
            <div>{value}</div>
          </div>
        )) ?? []}
      </div>
      <div
        className={`w-fit cursor-pointer rounded-xl px-28 py-4 ${
          selectPlan !== idx
            ? "bg-odinPrimary text-white"
            : "bg-white text-odinPrimary"
        }`}
        onClick={() => {
          handleSelect(idx);
          setIsModalOpen(true);
        }}
      >
        Get Started
      </div>
    </div>
  );
};

export default PlanItem;
