/* eslint-disable no-undef */
// /* eslint-disable no-undef */

import React, { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import UploadFileArea from "./UploadFileArea";
import { Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import InputTextField from "components/ui/InputTextField";
import { uploadFile, updateFile } from "store/action/user";

const Upload = ({ close, fileId, group }) => {
  const role = sessionStorage.getItem("odin-auth-role");
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [subject, setSubject] = useState("");
  const [note, setNote] = useState("");
  const user = useSelector((state) => state.users.user);
  const agency = useSelector((state) => state.supers.agency);
  const existingFile = useSelector((state) =>
    state.users.files.find((file) => file.id === fileId)
  );

  useEffect(() => {
    if (existingFile) {
      setFileName(existingFile.filename);
      setSubject(existingFile.content);
      setNote(existingFile.note);
    } else {
      setFile(null);
      setFileName("");
      setSubject("");
      setNote("");
    }
  }, [existingFile, close]);

  const handleUploadClick = async () => {
    if (!file && !fileName && !subject)
      return message.error(`please add all fields`);

    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("fileName", fileName);
    formData.append("content", subject);
    formData.append("note", note);
    formData.append("agencyId", agency.id);
    formData.append("group", group);
    if (role) {
      formData.append("userId", user.id);
    }

    try {
      if (fileId) {
        formData.append("id", fileId);
        dispatch(updateFile({ form: formData }));
      } else {
        dispatch(uploadFile({ form: formData }));
        // message.success("File uploaded successfully");
      }
      setFile(null);
      setFileName("");
      setSubject("");
      setNote("");
      close();
    } catch (error) {
      console.error("Error uploading file:", error);
      // message.error("Failed to upload file");
    }
  };

  return (
    <div>
      <div className="text-xl font-bold text-black">
        {fileId ? "Update File" : "Upload File"}
      </div>
      <div className="my-2 border-b-2"></div>
      <div className="text-secondary">Subject</div>

      <Select
        className="w-full"
        value={subject}
        size="large"
        onChange={(value) => setSubject(value)}
        options={user?.subject?.map((subject) => ({
          value: subject,
          label: subject,
        }))}
        placeholder="Select a subject"
      ></Select>
      <div className="my-1 text-secondary">Note</div>
      <InputTextField
        className="mb-3 border-2 py-3"
        type="text"
        placeholder="Note"
        value={note}
        onChange={(value) => setNote(value)}
      />
      <UploadFileArea
        setFile={setFile}
        file={file}
        fileName={fileName}
        setFileName={setFileName}
      />
      <div className="flex justify-end">
        <div
          className="mt-4 flex cursor-pointer items-center gap-2 rounded-lg bg-black px-4 py-2 text-white"
          onClick={handleUploadClick}
        >
          <AiOutlineCloudUpload color="white" size={20} />
          <div>{fileId ? "Update" : "Upload"}</div>
        </div>
      </div>
    </div>
  );
};

Upload.propTypes = {
  close: PropTypes.func.isRequired,
  fileId: PropTypes.string, // Add fileId prop type
  group: PropTypes.string.isRequired, // Add fileId prop type
};

export default Upload;
