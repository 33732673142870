/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { FaPlusCircle } from "react-icons/fa";
// import Image from "components/shared/Image";
import Avatar from "components/shared/AgencyLayout/designs/Avatar";

const TutorCard = ({
  heading,
  data,
  noItem,
  addHandler,
  noAdd,
  handleDetail,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const handleSearchIconClick = () => {
    setShowSearch(!showSearch);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data?.filter((item) =>
    (item?.username || `${item?.User?.firstName} ${item?.User?.lastName}`)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <div className="hidden h-fit w-full flex-col rounded-lg bg-white p-4 lg:flex">
      <div className="mb-4 flex justify-between border-b-2 pb-1">
        <div className="font-semibold">{heading}</div>
        <div className="flex gap-2">
          <FiSearch
            className="cursor-pointer"
            onClick={handleSearchIconClick}
          />
          {!noAdd && (
            <FaPlusCircle className="cursor-pointer" onClick={addHandler} />
          )}
        </div>
      </div>
      {showSearch && (
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full rounded border px-2 py-1"
          />
        </div>
      )}
      <div className="flex h-[250px] flex-1 flex-col justify-center gap-3 overflow-auto text-center text-sm text-secondary">
        {filteredData?.length > 0 ? (
          filteredData?.map((item, idx) => (
            <div key={idx} className="flex items-center gap-2 pr-1">
              <Avatar
                avatar={item?.avatar || item?.url || ""}
                alt={item?.username || item?.User?.firstName}
                className="size-12 rounded-full cursor-pointer"
                handleClick={() => handleDetail(item)}
              />
              <div className="text-start">
                <div className="text-sm font-semibold text-black">
                  {item?.username || item?.User?.firstName}{" "}
                  {item?.User?.lastName}
                </div>
                <div className="text-xs">
                  Experience:{" "}
                  <span className="font-semibold">{item?.experience}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>{noItem}</>
        )}
      </div>
    </div>
  );
};

export default TutorCard;
