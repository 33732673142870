/* eslint-disable no-undef */
import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  ADD_POST_BY_AGENCY,
  ADD_REVIEW,
  AGENCY_CARD_PAYMENT_METHOD,
  AGENCY_DELETE,
  AGENCY_LOGIN,
  AGENCY_REGISTER,
  AGENCY_STUDENTS,
  AGENCY_TUTORS,
  AGENCY_UPDATE,
  AGENCY_UPDATE_DETAIL,
  CANCEL_SUBSCRIPTION,
  CHANGE_PASSWORD,
  CREATE_TUTOR_TIMETABLE,
  DELETE_USER_FILE,
  FORGET_PASSWORD,
  GET_AGENCY_BY_DOMAIN,
  GET_AGENCY_DETAIL,
  GET_AGENCY_WALLET,
  GET_ALL_COUNTRIES,
  GET_CITY_STATE,
  GET_CLASS_REQUEST,
  GET_CONNECTED_ACCOUNT_STATUS,
  GET_COUNTRY_STATE,
  GET_CURRENT_USER,
  GET_MAIL_FOR_AGENCY,
  GET_MAIL_FOR_TUTOR,
  GET_NOTIFICATION,
  GET_POST,
  GET_STUDENT_ACTIVITIES,
  GET_STUDENT_DETAIL,
  GET_STUDENT_POST,
  GET_SUBSCRIBED_STUDENTS,
  GET_TUTOR_POST,
  LOGIN_USER,
  REGISTER_USER,
  SET_PASSWORD,
  STUDENTS_SUBSCRIBED_TUTORS,
  STUDENT_DELETE,
  STUDENT_PAYMENT_HISTORY,
  SUBSCRIBE_TO_TUTOR,
  TUTOR_ACCEPT_CLASS,
  TUTOR_ACCEPT_CLASS_ALL,
  TUTOR_DELETE,
  TUTOR_UPDATE,
  UPDATE_HANDOUT,
  UPDATE_USER,
  UPLOAD_HANDOUT,
  USER_FILES,
  VALID_SUBDOMAIN,
  VERIFY_OTP,
} from "../types/user";
import {
  UpdateHandout,
  UploadHandout,
  addPostByAgency,
  agencyDataByDomainApi,
  agencyDelete,
  agencyInviteHandlerApi,
  agencyLoginApi,
  agencyPaymentMethod,
  agencyRegisterApi,
  agencyStudentsApi,
  agencyTutorsApi,
  agencyUpdate,
  agencyUpdateDetail,
  cancelSubscription,
  checkDomainValid,
  connectedAccountStatusApi,
  createReview,
  createTutorTimetableApi,
  deleteStudentApi,
  deleteTutorApi,
  deleteUserFileApi,
  forgetPasswordApi,
  getAgencyDetails,
  getAgencyWalletHistory,
  getCity,
  getClassRequestApi,
  getCountry,
  getCurrentUserApi,
  getMailForAgency,
  getMailForTutor,
  getNotificationUser,
  getPostsApi,
  getState,
  getStudentPostsApi,
  getSubscribedStudentsApi,
  getTutorPostsApi,
  loginUsersApi,
  registerApi,
  setPasswordApi,
  studentActivities,
  studentDetail,
  studentHistory,
  studentSubscribedTutorsApi,
  subscribeToTutorApi,
  tutorAcceptClassAll,
  tutorAcceptClassApi,
  updateStudentApi,
  updateTutorApi,
  userChangePasswordApi,
  userFilesApi,
  verifyOTPdApi,
} from "../services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addUsersFiles,
  checkDomain,
  deleteFileSlice,
  getAgencyDetailSlice,
  getCalenderSlice,
  getClassRequestSlice,
  getConnectedAccountStatusSlice,
  getMails,
  getPaymentMethodSlice,
  getPostsSlice,
  getStudentActivitiesSlice,
  getStudentDetailSlice,
  getStudentsPaymentSlice,
  getStudentsSlice,
  getSubscribedStudentsSlice,
  getSubscribedTutorsSlice,
  getTutorsSlice,
  getUserNotificationSlice,
  getUserSlice,
  getUsersFiles,
  loginUserSlice,
  setForgetUserSlice,
  updateAllClassSlice,
  updateClassSlice,
  walletHistory,
} from "../slices/users";
import { AGENCY_INVITE } from "store/types/invite";
import { GET_CALENDER } from "store/types/timeline";
import { getCalenderApi } from "store/services/timeline.service";
import { setRedirectRoute } from "store/slices/redirect";
import {
  setAgencyStep,
  setLoading,
  setStep,
  unsetLoading,
} from "store/slices/alert";
import {
  getCitySlice,
  getCountriesSlice,
  getStateSlice,
  getAgencySlice,
} from "store/slices/super";

function* registerHandler(action) {
  try {
    const response = yield call(registerApi, action.payload);
    if (response.status === 200) {
      const user = response?.data?.user || response?.data?.data?.tutor;
      const token = "";
      toast.success(response.data.message, { title: "Success" });
      yield put(loginUserSlice({ user, token }));
      yield put(setRedirectRoute("/"));
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.error || "Error occurred while registering user",
      { title: "Error" }
    );
  }
}

function* loginUserHandler(action) {
  try {
    const response = yield call(loginUsersApi, action.payload);
    if (response.status === 200) {
      const accessToken = response.data.data.accessToken;
      yield put(loginUserSlice({ accessToken, user: response.data.data }));
      sessionStorage.setItem("odin-auth-token", JSON.stringify(accessToken));
      sessionStorage.setItem("odin-auth-role", response.data.data.role);

      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.error || err.response.data.message || "Error",
      { title: "Error" }
    );
  }
}

function* agencyLoginHandler(action) {
  try {
    const response = yield call(agencyLoginApi, action.payload);
    if (response.status === 200) {
      const accessToken = response.data.accessToken;
      const agency = response.data.agency;
      if (
        agency.connectedAccountId === "" ||
        agency.connectedAccountId === "null" ||
        agency.connectedAccountId == null
      ) {
        yield put(setRedirectRoute("/agency/admin/package"));
        toast.success("Please Connect Account");
      }
      sessionStorage.setItem("odin-auth-token", JSON.stringify(accessToken));
      yield put(loginUserSlice({ accessToken, user: agency }));
      yield put(getAgencySlice(agency));
      toast.success("Login Successful!");
    }
  } catch (err) {
    console.log(err?.response?.data?.error);
    toast.error(
      err?.response?.data?.error || err.response.data.message || "Error",
      { title: "Error" }
    );
  }
}

function* agencyRegisterHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(agencyRegisterApi, action.payload);
    if (response.status === 200) {
      yield put(setAgencyStep(1));
      const agency = response?.data?.data?.dataValues;
      const accessToken = response.data.accessToken;
      yield put(loginUserSlice({ accessToken, user: agency }));
      yield put(getAgencySlice(agency));
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    // toast.error(err?.response?.data?.error || "Error", { title: "Error" });
    toast.error(
      err?.response?.data?.error || err.response.data.message || "Error",
      { title: "Error" }
    );
  } finally {
    yield put(unsetLoading());
  }
}

function* agencyDataByDomain(action) {
  try {
    const response = yield call(agencyDataByDomainApi, action.payload);

    if (response.status === 200) {
      const agency = response.data.data;
      yield put(getAgencySlice(agency));
    }
  } catch (err) {
    // toast.error(err?.response?.data?.error || "Error", { title: "Error" });
    toast.error(
      err?.response?.data?.error || err.response.data.message || "Error",
      { title: "Error" }
    );
  }
}

function* agencyInviteHandler(action) {
  try {
    const response = yield call(agencyInviteHandlerApi, action.payload);
    if (response.status === 200) {
      toast.success("Invitation Sent Successfully", { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* addReviewToTutor(action) {
  try {
    const response = yield call(createReview, action.payload);
    if (response.status === 201) {
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* agencyTutorDelete(action) {
  try {
    const response = yield call(deleteTutorApi, action.payload);
    if (response.status === 200) {
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* agencyStudentDelete(action) {
  try {
    const response = yield call(deleteStudentApi, action.payload);
    if (response.status === 200) {
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* agencyStudentsHandler() {
  try {
    yield put(setLoading());
    const response = yield call(agencyStudentsApi);
    if (response.status === 200) {
      const students = response.data.data;
      yield put(getStudentsSlice(students));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error, { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}
function* studentsPaymentHistoryHandler() {
  try {
    const response = yield call(studentHistory);
    if (response.status === 200) {
      yield put(getStudentsPaymentSlice(response.data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error, { title: "Error" });
  }
}
function* getAgencyPaymentMethod() {
  try {
    yield put(setLoading());
    const response = yield call(agencyPaymentMethod);
    if (response.status === 200) {
      yield put(getPaymentMethodSlice(response.data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error, { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}

function* agencyTutorsHandler() {
  try {
    yield put(setLoading());
    const role = sessionStorage.getItem("odin-auth-role");
    const response = yield call(agencyTutorsApi, role);
    if (response.status === 200) {
      let tutors;
      if (role === "student") {
        tutors = response.data.data;
      } else {
        tutors = response.data.data.tutors;
      }

      yield put(getTutorsSlice(tutors));
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.error || err.response.data.message || "Error",
      { title: "Error" }
    );
  } finally {
    yield put(unsetLoading());
  }
}

function* studentSubscribedTutorsHandler() {
  try {
    const response = yield call(studentSubscribedTutorsApi);
    if (response.status === 200) {
      const tutors = response.data?.data;
      yield put(getSubscribedTutorsSlice(tutors));
    }
  } catch (err) {
    console.log("🚀 ~ function*studentSubscribedTutorsHandler ~ err:", err);
  }
}

function* studentDetailHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(studentDetail, action.payload);
    if (response.status === 200) {
      yield put(getStudentDetailSlice(response.data?.data));
    }
  } catch (err) {
    console.log("🚀 ~ function*studentSubscribedTutorsHandler ~ err:", err);
  } finally {
    yield put(unsetLoading());
  }
}
function* getCountriesDataHandler() {
  try {
    const response = yield call(getCountry);
    if (response.status === 200) {
      yield put(getCountriesSlice(response.data?.data));
    }
  } catch (err) {
    console.log("🚀 ~ function*getCountriesDataHandler ~ err:", err);
  }
}
function* getStateDataHandler(action) {
  try {
    const response = yield call(getState, action.payload);
    if (response.status === 200) {
      yield put(getStateSlice(response?.data?.data));
    }
  } catch (err) {
    console.log("🚀 ~ function*getCountriesDataHandler ~ err:", err);
  }
}
function* getCityDataHandler(action) {
  try {
    const response = yield call(getCity, action.payload);
    if (response.status === 200) {
      yield put(getCitySlice(response.data?.data));
    }
  } catch (err) {
    console.log("🚀 ~ function*getCountriesDataHandler ~ err:", err);
  }
}
function* studentActivitiesHandler(action) {
  try {
    const response = yield call(studentActivities, action.payload);
    if (response.status === 200) {
      yield put(getStudentActivitiesSlice(response.data?.data?.subscriptions));
    }
  } catch (err) {
    console.log("🚀 ~ function*studentSubscribedTutorsHandler ~ err:", err);
  }
}
function* updateAgencyHandler(action) {
  try {
    const response = yield call(agencyUpdate, action.payload);
    if (response.status === 200) {
      yield* getCurrentUserHandler();
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    console.log("🚀 ~ function*updateAgencyHandler ~ err:", err);
  }
}
function* updateAgencyDetailHandler(action) {
  try {
    const response = yield call(agencyUpdateDetail, action.payload);
    if (response.status === 200) {
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    console.log("🚀 ~ function*updateAgencyHandler ~ err:", err);
  }
}
function* agencyDeleteHandler(action) {
  try {
    const response = yield call(agencyDelete, action.payload);
    if (response.status === 200) {
      yield put(setAgencyStep(0));
      yield put(getAgencySlice());
    }
  } catch (err) {
    console.log("🚀 ~ function*agencyDeleteHandler ~ err:", err);
  }
}
function* createTutorTimetableHandler(action) {
  try {
    const response = yield call(createTutorTimetableApi, action.payload);
    if (response.status === 200) {
      yield* getCurrentUserHandler();
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* getUserNotificationHandler(action) {
  try {
    const response = yield call(getNotificationUser, action.payload);
    if (response.status === 200) {
      yield put(getUserNotificationSlice(response.data?.data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* getCurrentUserHandler() {
  try {
    const response = yield call(getCurrentUserApi);
    if (response.status === 200) {
      yield put(getUserSlice(response?.data?.data));
      const role = sessionStorage.getItem("odin-auth-role");
      if (!role) {
        yield put(getAgencySlice(response?.data?.data));
      }
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* updateUserHandler(action) {
  try {
    const response = yield call(updateStudentApi, action.payload);
    if (response.status === 200) {
      const data = response?.data?.data;
      console.log("🚀 ~ function*updateUserHandler ~ data:", data);
      yield* getCurrentUserHandler();
      toast.success("User updated successfully!", { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* getAgencyDetailHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(getAgencyDetails, action.payload);
    if (response.status === 200) {
      const data = response?.data?.data;
      console.log("🚀 ~ function*updateUserHandler ~ data:", data);
      yield put(getAgencyDetailSlice(data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}
function* getMailForAgencyHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(getMailForAgency, action.payload);
    if (response.status === 200) {
      const data = response?.data?.data;
      console.log("🚀 ~ function*updateUserHandler ~ data:", data);
      yield put(getMails(data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}
function* getMailForTutorHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(getMailForTutor, action.payload);
    if (response.status === 200) {
      const data = response?.data?.data;
      console.log("🚀 ~ function*updateUserHandler ~ data:", data);
      yield put(getMails(data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}
function* updateUserTutorHandler(action) {
  try {
    const response = yield call(updateTutorApi, action.payload);
    if (response.status === 200) {
      toast.success("Tutor updated successfully!", { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* uploadFileHandler(action) {
  try {
    const response = yield call(UploadHandout, action.payload);
    if (response.status === 200) {
      if (response.data.data?.post?.group === "handout") {
        yield put(addUsersFiles(response.data.data.post));
      }
      const group = action?.payload?.form?.get("group");
      const userId = action?.payload?.form?.get("userId");
      if (userId) {
        console.log("userId");
        yield* getTutorPostsHandler();
      } else {
        yield* getPostsHandler();
      }
      if (group === "handout") {
        toast.success("upload file successfully", { title: "Success" });
      } else {
        toast.success(response.data.message, { title: "Success" });
      }
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* updateFileHandler(action) {
  try {
    const response = yield call(UpdateHandout, action.payload);
    if (response.status === 200) {
      if (response.data.data.updatedFileData) {
        yield put(addUsersFiles(response.data.data.updatedFileData));
      }
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* getCalenderHandler() {
  try {
    const response = yield call(getCalenderApi);
    if (response.status === 200) {
      yield put(getCalenderSlice(response?.data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.message || "Error", { title: "Error" });
  }
}

function* subscribeToTutorHandler(action) {
  try {
    const response = yield call(subscribeToTutorApi, action.payload);
    if (response.status === 200) {
      yield* getCalenderHandler();
      toast.success(response?.data?.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* forgetPasswordHandler(action) {
  try {
    const response = yield call(forgetPasswordApi, action.payload);
    if (response.status === 200) {
      yield put(setForgetUserSlice({ email: response?.data?.data }));
      toast.success(response.data.message, { title: "Success" });
      if (action.payload.sub) {
        yield put(setRedirectRoute("forget-password/1"));
      } else {
        yield put(setRedirectRoute("agency/forget-password/1"));
      }
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* verifyOtpHandler(action) {
  try {
    const response = yield call(verifyOTPdApi, action.payload);
    if (response.status === 200) {
      yield put(
        setForgetUserSlice({
          email: response?.data?.data,
          isVerified: response?.data?.isVerified,
        })
      );
      toast.success(response.data.message, { title: "Success" });
      yield put(setStep(1));
      yield put(setAgencyStep(2));
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.error || err?.response?.data?.message || "Error",
      { title: "Error" }
    );
  }
}
function* setPasswordHandler(action) {
  try {
    const response = yield call(setPasswordApi, action.payload);
    if (response.status === 200) {
      yield put(setForgetUserSlice({ email: response?.data?.data }));
      toast.success(response.data.message, { title: "Success" });
      yield put(setStep(2));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* changePasswordHandler(action) {
  try {
    const response = yield call(userChangePasswordApi, action.payload);
    if (response.status === 200) {
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* getUserFileHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(userFilesApi, action.payload);
    if (response.status === 200) {
      yield put(getUsersFiles(response?.data?.data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}
function* deleteUserFileHandler(action) {
  try {
    const response = yield call(deleteUserFileApi, action.payload);
    if (response.status === 200) {
      yield put(deleteFileSlice(action.payload));

      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* getPostsHandler() {
  try {
    const response = yield call(getPostsApi);
    if (response.status === 200) {
      const data = response?.data?.data;
      yield put(getPostsSlice(data));
    } else {
      throw new Error("Failed to fetch posts");
    }
  } catch (err) {
    // toast.error(err?.response?.data?.error || "Error", { title: "Error" });
    toast.error(
      err?.response?.data?.error || err?.response?.data?.message || "Error",
      { title: "Error" }
    );
  }
}
function* getTutorPostsHandler() {
  try {
    const response = yield call(getTutorPostsApi);
    if (response.status === 200) {
      const data = response?.data?.data;
      yield put(getPostsSlice(data));
    } else {
      throw new Error("Failed to fetch posts");
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.error || err?.response?.data?.message || "Error",
      { title: "Error" }
    );
  }
}
function* getStudentPostsHandler() {
  try {
    const response = yield call(getStudentPostsApi);
    if (response.status === 200) {
      const data = response?.data?.data;
      yield put(getPostsSlice(data));
    } else {
      throw new Error("Failed to fetch posts");
    }
  } catch (err) {
    toast.error(
      err?.response?.data?.error || err?.response?.data?.message || "Error",
      { title: "Error" }
    );
  }
}
function* addAgencyPost(action) {
  try {
    const response = yield call(addPostByAgency, action.payload);
    if (response.status === 200) {
      toast.success(response.data.message, { title: "Success" });
      yield* getPostsHandler();
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* getClassRequestHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(getClassRequestApi, action.payload);
    if (response.status === 200) {
      const data = response?.data;
      yield put(getClassRequestSlice(data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}
function* checkValidSubDomain(action) {
  try {
    const response = yield call(checkDomainValid, action.payload);
    if (response.status === 200) {
      const data = response?.data;
      yield put(checkDomain(data));
    }
  } catch (err) {
    yield put(checkDomain(err?.response?.data));

    // toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}

function* acceptClassRequestHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(tutorAcceptClassApi, action.payload);
    if (response.status === 200) {
      yield put(updateClassSlice(action.payload));
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}
function* acceptClassRequestAllHandler(action) {
  try {
    yield put(setLoading());
    const response = yield call(tutorAcceptClassAll, action.payload);
    if (response.status === 200) {
      yield put(updateAllClassSlice(action.payload));
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}

function* getSubscribedStudentsHandler() {
  try {
    const response = yield call(getSubscribedStudentsApi);
    if (response.status === 200) {
      const tutors = response.data?.data;
      yield put(getSubscribedStudentsSlice(tutors));
    }
  } catch (err) {
    // toast.error(
    //   err?.response?.data?.error || err?.response?.data?.message || "Error",
    //   { title: "Error" }
    // );

    console.log("🚀 ~ function*getSubscribedStudentsHandler ~ err:", err);
  }
}

function* getAgencyWalletHistoryHandler() {
  try {
    const response = yield call(getAgencyWalletHistory);
    if (response.status === 200) {
      yield put(walletHistory(response.data));
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* cancelSubHandler() {
  try {
    const response = yield call(cancelSubscription);
    if (response.status === 200) {
      toast.success("Subscription canceled successfully", { title: "Success" });
      yield* getCurrentUserHandler();
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* getConnectedAccountHandler() {
  try {
    yield put(setLoading());
    const response = yield call(connectedAccountStatusApi);
    if (response.status === 200) {
      const status = response.data;
      console.log(status);
      yield put(getConnectedAccountStatusSlice(status));
      toast.success(response.data.message, { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  } finally {
    yield put(unsetLoading());
  }
}

export function* waitForUser() {
  yield takeEvery(REGISTER_USER, registerHandler);
  yield takeEvery(LOGIN_USER, loginUserHandler);
  yield takeEvery(AGENCY_LOGIN, agencyLoginHandler);
  yield takeEvery(AGENCY_REGISTER, agencyRegisterHandler);
  yield takeEvery(GET_AGENCY_BY_DOMAIN, agencyDataByDomain);
  yield takeEvery(AGENCY_INVITE, agencyInviteHandler);
  yield takeEvery(AGENCY_STUDENTS, agencyStudentsHandler);
  yield takeEvery(AGENCY_TUTORS, agencyTutorsHandler);
  yield takeEvery(AGENCY_UPDATE, updateAgencyHandler);
  yield takeEvery(AGENCY_DELETE, agencyDeleteHandler);
  yield takeEvery(AGENCY_UPDATE_DETAIL, updateAgencyDetailHandler);
  yield takeEvery(AGENCY_CARD_PAYMENT_METHOD, getAgencyPaymentMethod);
  yield takeEvery(ADD_POST_BY_AGENCY, addAgencyPost);
  yield takeEvery(STUDENTS_SUBSCRIBED_TUTORS, studentSubscribedTutorsHandler);
  yield takeEvery(CREATE_TUTOR_TIMETABLE, createTutorTimetableHandler);
  yield takeEvery(GET_CURRENT_USER, getCurrentUserHandler);
  yield takeEvery(UPDATE_USER, updateUserHandler);
  yield takeEvery(SUBSCRIBE_TO_TUTOR, subscribeToTutorHandler);
  yield takeEvery(GET_POST, getPostsHandler);
  yield takeEvery(GET_TUTOR_POST, getTutorPostsHandler);
  yield takeEvery(GET_STUDENT_POST, getStudentPostsHandler);
  yield takeEvery(GET_CLASS_REQUEST, getClassRequestHandler);
  yield takeEvery(GET_SUBSCRIBED_STUDENTS, getSubscribedStudentsHandler);
  yield takeEvery(GET_STUDENT_ACTIVITIES, studentActivitiesHandler);
  yield takeEvery(GET_STUDENT_DETAIL, studentDetailHandler);
  yield takeEvery(GET_CALENDER, getCalenderHandler);
  yield takeEvery(TUTOR_DELETE, agencyTutorDelete);
  yield takeEvery(STUDENT_DELETE, agencyStudentDelete);
  yield takeEvery(STUDENT_PAYMENT_HISTORY, studentsPaymentHistoryHandler);
  yield takeEvery(TUTOR_UPDATE, updateUserTutorHandler);
  yield takeEvery(FORGET_PASSWORD, forgetPasswordHandler);
  yield takeEvery(VERIFY_OTP, verifyOtpHandler);
  yield takeEvery(SET_PASSWORD, setPasswordHandler);
  yield takeEvery(USER_FILES, getUserFileHandler);
  yield takeEvery(UPLOAD_HANDOUT, uploadFileHandler);
  yield takeEvery(UPDATE_HANDOUT, updateFileHandler);
  yield takeEvery(DELETE_USER_FILE, deleteUserFileHandler);
  yield takeEvery(CHANGE_PASSWORD, changePasswordHandler);
  yield takeEvery(GET_NOTIFICATION, getUserNotificationHandler);
  yield takeEvery(GET_AGENCY_WALLET, getAgencyWalletHistoryHandler);
  yield takeEvery(TUTOR_ACCEPT_CLASS_ALL, acceptClassRequestAllHandler);
  yield takeEvery(TUTOR_ACCEPT_CLASS, acceptClassRequestHandler);
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubHandler);
  yield takeEvery(VALID_SUBDOMAIN, checkValidSubDomain);
  yield takeEvery(ADD_REVIEW, addReviewToTutor);
  yield takeEvery(GET_ALL_COUNTRIES, getCountriesDataHandler);
  yield takeEvery(GET_CITY_STATE, getCityDataHandler);
  yield takeEvery(GET_COUNTRY_STATE, getStateDataHandler);
  yield takeEvery(GET_AGENCY_DETAIL, getAgencyDetailHandler);
  yield takeEvery(GET_MAIL_FOR_AGENCY, getMailForAgencyHandler);
  yield takeEvery(GET_MAIL_FOR_TUTOR, getMailForTutorHandler);
  yield takeEvery(GET_CONNECTED_ACCOUNT_STATUS, getConnectedAccountHandler);
}
