/* eslint-disable no-undef */
import { call, takeEvery, put } from "@redux-saga/core/effects";
import {
  AGENCY_DELETE_TIMELINE,
  CREATE_AGENCY_POST,
  CREATE_TUTOR_POST,
  GET_AGENCY_TIMELINE,
  GET_TUTOR_TIMELINE,
  TUTOR_DELETE_TIMELINE,
  UPDATE_TIMELINE,
} from "store/types/timeline";
import {
  agencyDeletePostApi,
  createAgencyPostApi,
  createTutorPostApi,
  postUpdateApi,
  tutorDeletePostApi,
} from "store/services/timeline.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPostsApi, getTutorPostsApi } from "store/services/user.service";
import { deletePostSlice, getPostsSlice, updatePost } from "store/slices/users";

function* getPostsHandler() {
  try {
    const response = yield call(getPostsApi);
    if (response.status === 200) {
      const data = response?.data?.data;
      yield put(getPostsSlice(data));
    } else {
      throw new Error("Failed to fetch posts");
    }
  } catch (err) {
    console.log("🚀 ~ function*getPostsHandler ~ err:", err);
    toast.error(err?.response?.data?.error || "Error", { title: "Error" });
  }
}
function* createAgencyTimelineHandler(action) {
  try {
    const response = yield call(createAgencyPostApi, action.payload);
    if (response.status === 200) {
      yield* getPostsHandler();
      toast.success("Post created successfully!", { title: "Success" });
    }
  } catch (err) {
    console.log("🚀 ~ function*createAgencyTimelineHandler ~ err:", err);
  }
}
function* updateTimelineHandler(action) {
  try {
    const response = yield call(postUpdateApi, action.payload);

    console.log(
      "🚀 ~ function*updateTimelineHandler ~ response:",
      response?.data?.data
    );
    if (response.status === 200) {
      yield put(updatePost(response?.data?.data));
      toast.success("Post update successfully!", { title: "Success" });
    }
  } catch (err) {
    console.log("🚀 ~ function*createAgencyTimelineHandler ~ err:", err);
  }
}
function* deleteAgencyTimelineHandler(action) {
  try {
    const response = yield call(agencyDeletePostApi, action.payload);
    if (response.status === 200) {
      yield put(deletePostSlice(action.payload));
      // yield* getPostsHandler();
      toast.success("Post delete successfully!", { title: "Success" });
    }
  } catch (err) {
    console.log("🚀 ~ function*createAgencyTimelineHandler ~ err:", err);
  }
}
function* tutorDeleteTimelineHandler(action) {
  try {
    const response = yield call(tutorDeletePostApi, action.payload);
    if (response.status === 200) {
      yield put(deletePostSlice(action.payload));

      // yield* getTutorPostsHandler();
      toast.success("Post delete successfully!", { title: "Success" });
    }
  } catch (err) {
    console.log("🚀 ~ function*createAgencyTimelineHandler ~ err:", err);
  }
}
function* getTutorPostsHandler() {
  try {
    const response = yield call(getTutorPostsApi);
    if (response.status === 200) {
      const data = response?.data?.data;
      yield put(getPostsSlice(data));
    } else {
      throw new Error("Failed to fetch posts");
    }
  } catch (err) {
    console.log("🚀 ~ function*getTutorPostsHandler ~ err:", err);
    toast.error(
      err?.response?.data?.error || err?.response?.message || "Error",
      { title: "Error" }
    );
  }
}
function* createTutorTimelineHandler(action) {
  try {
    const response = yield call(createTutorPostApi, action.payload);
    if (response.status === 200) {
      yield* getTutorPostsHandler();
      toast.success("Post created successfully!", { title: "Success" });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message || "Error", { title: "Error" });
  }
}

function* getAgencyTimelineHandler(action) {
  try {
    const response = yield call(createAgencyPostApi, action.payload);
    if (response.status === 200) {
      toast.success("Agency timeline fetched successfully!", {
        title: "Success",
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message || "Error", { title: "Error" });
  }
}

function* getTutorTimelineHandler(action) {
  try {
    const response = yield call(createAgencyPostApi, action.payload);
    if (response.status === 200) {
      toast.success("Tutor timeline fetched successfully!", {
        title: "Success",
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message || "Error", { title: "Error" });
  }
}

export function* waitForTimeline() {
  yield takeEvery(CREATE_AGENCY_POST, createAgencyTimelineHandler);
  yield takeEvery(CREATE_TUTOR_POST, createTutorTimelineHandler);
  yield takeEvery(GET_AGENCY_TIMELINE, getAgencyTimelineHandler);
  yield takeEvery(GET_TUTOR_TIMELINE, getTutorTimelineHandler);
  yield takeEvery(TUTOR_DELETE_TIMELINE, tutorDeleteTimelineHandler);
  yield takeEvery(AGENCY_DELETE_TIMELINE, deleteAgencyTimelineHandler);
  yield takeEvery(UPDATE_TIMELINE, updateTimelineHandler);
}
