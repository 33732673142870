import { GET_ALL_PLANS, GET_CHAT, UPDATE_CHAT_LIST } from "store/types/chat";
import { SET_SUBSCRIPTION_PLAN_GRAPH } from "store/types/super";

export const subscriptionPlanGrpahAction = () => {
  return {
    type: SET_SUBSCRIPTION_PLAN_GRAPH,
  };
};
export const getChatList = (payload) => {
  return {
    type: GET_CHAT,
    payload,
  };
};
export const updateChatList = (newMessage) => ({
  type: UPDATE_CHAT_LIST,
  payload: newMessage,
});

export const getAllPlanList = () => {
  return {
    type: GET_ALL_PLANS,
  };
};
