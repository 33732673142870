import React from 'react';

const NotFound = () => {
    return (
        <div className="flex h-screen items-center justify-center bg-gray-100">
            <div className="text-4xl text-gray-600">404 - Not Found</div>
        </div>
    );
};

export default NotFound;
